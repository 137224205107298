import { Menu } from './components/Menu/Menu';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Wallet } from './pages/Wallet/Wallet';
import { Play } from './pages/Play/Play';
import { LandingPage } from './pages/LandingPage/LandingPage';
import { Leaderboard } from './pages/Leaderboard/Leaderboard';
import { Inventory } from './pages/Inventory/Inventory';
import ProtectedRoute from './routing/ProtectedRoute';
import { AdminPane } from './pages/AdminPane/AdminPane';
import { GameUiOverlay } from './pages/3dGameUI/GameUiOverlay';
import { useEffect } from 'react';
import { StandaloneAds } from './pages/Leaderboard/StandaloneAds/StandaloneAds';
import { handleStandaloneUrlParams } from './routing/standaloneUrlParams';
import './App.scss';


function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  const { isAdsApp } = handleStandaloneUrlParams();

  return (
    <div className="App">
      <BrowserRouter>
        <Menu />
        <GameUiOverlay />
        <Switch>
          <Route exact path="/" component={isAdsApp ? StandaloneAds : LandingPage} />
          <ProtectedRoute exact path="/inventory" component={Inventory} />
          <ProtectedRoute exact path="/wallet" component={Wallet} />
          <ProtectedRoute exact path="/leaderboard" component={Leaderboard} />
          <ProtectedRoute exact path="/play" component={Play} />
          <ProtectedRoute exact path="/admin" component={AdminPane} />
          <Redirect to="/"  />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
