import { DAYS } from '../../../../../generalUtils/constants';
import { HOURS, MINUTES } from '../constants';
import { getTravelDuration } from '../travels';


const prepDurationDisplay = ({ spaceshipId, travellingTo }) => {
  const value = getTravelDuration({ spaceshipId, travellingTo });
  if (value === 0) return 'no wait';
  if (value < HOURS) return `${value / MINUTES} minutes`;
  if (value < DAYS) return `${value / HOURS} hour`;
};


export const SS_INFO = {
  TO_L0: {
      NAME: 'Airbus', ENGINE: 'Antimatter reactors',
      SRC: 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23tRtN5H6gYHm4RhxyZ6DXsRKvB9yB3FP9TEyB7TKP75PrzUAL8CcAGhBgo8yz2F7bdun.gif',
      DURATION: `no wait`, // no wait
  },
  TO_L1: [
    {
      NAME: 'Sequeira', ENGINE: 'Magneto-inertial fusion engine',
      SRC: 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23wMMuVgkyFwTJDE53byinnZe931ZKT2kVhLNTr7iyT65Eden15pGhjJE3bFKqEcFqsMc.gif',
      DURATION: `${prepDurationDisplay({ spaceshipId: 1, travellingTo: 1 })}`, // 1 h
    },
    {
      NAME: 'Exeter', ENGINE: 'Holtzman Engines',
      SRC: 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23wWpjqY9LGEsybVpBBMmefrerors9unJ4XHYYf4nevy2CKLGYh3grYADJ7Wi38z37epV.gif',
      DURATION: `${prepDurationDisplay({ spaceshipId: 2, travellingTo: 1 })}`, // 15 min
    },
    {
      NAME: 'Firefly', ENGINE: 'Quantum Engines',
      SRC: 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23wg923VWUe5sia5MP4fP8RgspfQwA1uvYiy8N9Fs5bC54nFC325LQJe6ir3HfPkpeZMR.gif',
      DURATION: `${prepDurationDisplay({ spaceshipId: 3, travellingTo: 1 })}`, // no wait
    },
  ],
  TO_L2: [
    {
      NAME: 'Exarch', ENGINE: 'Ion Engine',
      SRC: 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23t74ZLNca3SJajHtuyyXhowMQjjQ9SzitH7upwMw6DLzQYhhEwfYR2FN73feiurtfWmR.gif',
      DURATION: `${prepDurationDisplay({ spaceshipId: 1, travellingTo: 2 })}`, // 1 h
    },
    {
      NAME: 'Arthas', ENGINE: 'Lightspeed Engines',
      SRC: 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23t77KoqkPxyN9Rn6u6YeYi9n8U697qw3dXmw97ZhwErLcNTWrFPv6z2pgC2wDfXWzMR2.gif',
      DURATION: `${prepDurationDisplay({ spaceshipId: 2, travellingTo: 2 })}`, // 15 min
    },
    {
      NAME: 'Genesis', ENGINE: 'Warp Engines',
      SRC: 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23t773NYK47k1FioLTPfYPjtaTB89dz5JNC4zpCwY3T7DfHLaffkawtK1xEpW6TTz8srg.gif',
      DURATION: `${prepDurationDisplay({ spaceshipId: 3, travellingTo: 2 })}`, // no wait
    },
  ],
  TO_L3: [],
};
