/* eslint-disable no-useless-concat */
import { isLocal, isTestnet } from "../constants/envs";
import { cacheBustParam } from "../helpers/client";
import { BYPASS_INVENTORY_CODE, BYPASS_OUTAGE_CODE } from "./appConfig";


// ## MAINTENANCE

const maintenanceStatus = {
  underMaintenance: false,
  reason: null,
  inventoryDisabled: false,
};



const fetchMaintenance = () => {
  isTestnet && console.debug('fetching maintenance status..');
  fetch(`/json/out.json${cacheBustParam()}`)
    .then(res => res.json())
    .then((data = {}) => {
      isTestnet && console.debug('Maintenance status:', data);
      maintenanceStatus.underMaintenance = data.underMaintenance;
      maintenanceStatus.reason = data.reason;
      maintenanceStatus.inventoryDisabled = data.inventoryDisabled;
    })
    .catch(err => console.error('Maintenance status fetch err:', err));
};
fetchMaintenance();


// # OUTAGE

export const isUnderMaintenance = () => {
  if (localStorage.getItem(BYPASS_OUTAGE_CODE)) return false;
  return maintenanceStatus.underMaintenance;
};

export const maintenanceReason = () => maintenanceStatus.reason;


// # INVENTORY STATUS

export const isInventoryDisabled = () => {
  if (localStorage.getItem(BYPASS_INVENTORY_CODE)) return false;
  return maintenanceStatus.inventoryDisabled;
};


// ## PROMOS

const promoStatus = {
  level0: {
    isFast: false,
    isFree: false,
    untilTime: 0,
  },
  level1: {
    isFast: false,
    isFree: false,
    untilTime: 0,
  },
};

// {
//   level0: { isFast: false, isFree: false }
// }
const fetchPromos = () => {
  isTestnet && console.debug('fetching Promos status..');
  fetch(`/json/promos.json${cacheBustParam()}`)
    .then(res => res.json())
    .then((data = {}) => {
      isTestnet && console.debug('Promos status:', data);
      // const { level0 = {}, level1 = {}, level2 = {}, level3 = {}, level4 = {} } = data;
      const levels = Object.keys(data);
      levels.forEach((level) => {
        const { isFast, isFree, untilTime } = (data[level] || {});
        if (!promoStatus[level]) {
          promoStatus[level] = { isFast, isFree, untilTime };
        } else {
          promoStatus[level].isFast = isFast;
          promoStatus[level].isFree = isFree;
          promoStatus[level].untilTime = untilTime;
        }
      });
    })
    .catch(err => console.error('Promos status err:', err));
};
fetchPromos();


// ## CHEAP TRIALS WL

export const cheapTrials = { whitelist: [] };

const fetchCheapTrials = () => {
  isLocal && console.debug('fetching cheap trials folks..');
  fetch(`/json/cheapTrials.json${cacheBustParam()}`)
    .then(res => res.json())
    .then((json = {}) => {
      const { data } = json;
      isLocal && console.debug('CheapTrials status:', { wl: data });
      cheapTrials.whitelist = [...data];
    })
    .catch(err => console.error('Maintenance status fetch err:', err));
};
fetchCheapTrials();
