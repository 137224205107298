
// -------------------------------
const ENABLE_TOURNAMENT = true;
const IS_FREE_TO_EARN = true;
// -------------------------------

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const dayOfWeekName = () => daysOfWeek[new Date().getDay()];

const currentTimeFormatted = ({ delayMins = 0 } = {}) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate.getTime() + delayMins * 60 * 1000);
  const hours = String(targetDate.getUTCHours()).padStart(2, '0');
  const minutes = String(targetDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(targetDate.getUTCSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};


export const testDataTournament = {
  "tournaments": [
    {
      "day": ENABLE_TOURNAMENT ? dayOfWeekName() : "Monday",
      "start": ENABLE_TOURNAMENT ? currentTimeFormatted({ delayMins: 0 }) : "00:00:44",
      "end": ENABLE_TOURNAMENT ? currentTimeFormatted({ delayMins: 30 }) : "00:00:45",
      "streamer": {
        "displayName": "Tester",
        "pvpUsername": "tester@hive",
        "streamUrl": "https://twitch.tv/tester",
        "streamDescription": "10 BTC to every winner lol"
      },
      "sponsor": {
        "sponsorSymbol": "SUB",
        "weekTotBalance": 60,
        "tokenPrecision": 2,
        "sendStaked": false,
        "projectName": "STICKUPBOYS",
        "ownerDiscordId": "300032917151809556",
        "sponsorDiscordInvite": "https://stickupboys.co.uk"
      },
      "autoRewardsOnTourneyEnd": true,
      "raffleEnabled": true,
      "rewardMultiplier": 0.1,
      "tournamentRegionOverride": "USW",
      "anyoneCreateCustomRoom": true,
      "skipPvpPassCheck": IS_FREE_TO_EARN
    }
  ],
  "NOTES": [
    "Times in UTC!",
    "IS_TEST affects extra-rewards and bot-stats, plus Unity UI",
    "Consumed by: cs-pvp, cs-react, cs-wrapper, cs-discord"
  ],
  "extraRewardsTourneyOnly": true,
  "sponsoredRewards": {
    "avgPlayersSingleSponsoredTourney": 6,
    "note": "sponsor chain players only",
    "howManySponsoredTourneysPerWeek": 4,
    "howManyPartnerTourneysPerWeek": 3
  }
}
