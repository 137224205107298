import { UALJs } from 'ual-plainjs-renderer';

import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';
import { isEmpty } from 'lodash';

import { storeAppDispatch } from '../GlobalState/Store';
import { setPlayerData, setPlayerLogout } from '../reducers/UserReducer';
import { setPlayerWaxTokensBalances, setPlayerWaxBalance } from '../reducers/WalletReducer';
import { newUserTs } from '../sagas/helpers/inventory/cooldownUtils';
import { getCurrentEnvName, isLocal, isTestnet } from '../constants/envs';
import { postLoginOverride, usernamesChecks } from '../generalUtils/secure';
import { WAX_NODES } from '../config/endpoints';
import { nukeAnchorSavedAccount, nukeCloudWalletAutoChoice } from './autoLogin';
import { extractRowBalance } from '../sagas/helpers/wallet/waxUtils';
import { uiHacks } from '../generalUtils/uiHacks/uiHacks';
import Swal from 'sweetalert2';


const env = getCurrentEnvName();
const isMainnetEnv = (env === 'demo' || env === 'prod');

const tokensContract = isTestnet ? 'boomfungible' : 'csboomcsboom';

// WAX NODE IN USE
const randId = Math.floor(Math.random() * WAX_NODES.ALTS.length);
const waxNode = (Math.random() > 0.5 ? WAX_NODES.DEFAULT : WAX_NODES.ALTS[randId])
  .replace('https://', '');


/**
 * Class to manage user data; it will be saved on Login and deleted on Logout
 */
export class User {
    // Swaps side session for users logged in from Hive
    isSideSession = false;
    flyingData = null;

    constructor({ isSideSession = false } = {}) {
      this.isSideSession = isSideSession;
      this.flyingData = isSideSession ? {} : null;
    }
  
    appName = 'ual_template';
    myChain = this.getChainID();
    balance = 0;
    ual;
    // User session data
    authName = undefined;
    serviceLoginName = undefined;
    // Shows petition signing and current balance obtaining methods
    session = undefined;
    // Callback to refer to successful login
    callbackServerUserData = undefined;

    getName() {
      return this.authName;
    }

    getChainID() {
        var myChainData;
        if (env === 'demo' || env === 'prod') {
          // WAX Mainnet configuration
          myChainData = {
              chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
              rpcEndpoints: [{
                protocol: 'https',
                host: waxNode,
                port: '',
              }],
          };
        } else {
          // WAX Testnet configuration
          myChainData = {
            chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
            rpcEndpoints: [{
              protocol: 'https',
              host: waxNode,
              port: '',
            }]
          };
        }
        return myChainData;
    }

    login(callback) {
      const ualButton = document.querySelector(".ual-button-gen");
      ualButton.click();
      this.callbackServerUserData = callback;
    }

    isLogged() {
      const auth = !isEmpty(this.authName) && !isEmpty(this.session);
      return auth;
    }

    logout() {
      isLocal && console.debug('[user-service] Logging out..');
      this.authName = undefined;
      this.session = undefined;
      this.ual.logoutUser();
      !this.isSideSession && storeAppDispatch(setPlayerLogout());
      if (this.callbackServerUserData !== undefined) {
        this.callbackServerUserData();
      }
      // remove default to wax cloud
      nukeCloudWalletAutoChoice()
      !this.isSideSession && window.location.reload(); // otheriwise they can share the keys to one account with all weapons!
    }

    fetchWaxTokens() {
      isLocal && console.debug('Fetching wax tokens for', this.authName);
      const tokensFetch = this.session.rpc.get_table_rows({
        json: true,              // Get the response as json
        code: tokensContract, // Contract that we target
        scope: this.authName,         // Account that owns the data
        table: 'accounts',        // Table name
        limit: 10,               // Maximum number of rows that we want to get
        reverse: false,         // Optional: Get reversed data
        show_payer: false,      // Optional: Show ram payer
      })
        .then((resp = { rows: [] }) => {
          isLocal && console.debug('Balances:', resp);
          const { rows = [] } = resp;
          const tokens = {
            boom: extractRowBalance(rows, 'boom'),
            food: extractRowBalance(rows, 'food'),
            gas: extractRowBalance(rows, 'gas'),
            mars: extractRowBalance(rows, 'mars'),
          };
          !this.isSideSession && storeAppDispatch(setPlayerWaxTokensBalances({
            tokens, debug: 1,
          }));
          return tokens;
        });
      return tokensFetch;
    }

    isNewCheaterAccount() {
      return this.session.rpc.get_account(this.authName)
        .then((data = {}) => {
          const waxBalance = data.core_liquid_balance ? +data.core_liquid_balance.split(' ')[0] : null;
          const accountAgeDays = (Date.now() - new Date(data.created).getTime()) / 1000 / (24 * 60 * 60);
          isLocal && console.debug('multi-new-acc cheat check:', { waxBalance, accountAgeDays });
          if (waxBalance !== null && accountAgeDays) {
            if (waxBalance > 50) return false; // $3
            if (accountAgeDays > 3) return false; // 3 days old
            return true;
          }
          return false; // best effort
        })
    }

    fetchWaxBalance() {
      // THE OLD WAY (sometimes core_liquid_balance missing in response)
      // const balance = this.session.rpc.get_account(this.authName);
      // balance
      //   .then((balance) => {
      //     this.balance = balance.core_liquid_balance;
      //     isLocal && console.debug('[user-service] WAX balance:', balance);
      //     const wax = (this.balance && +this.balance.split(' ')[0]) || 0;
      //     !this.isSideSession && storeAppDispatch(setPlayerWaxBalance({ wax }));
      //     this.fetchWaxTokens();
      //   })
      isLocal && console.debug('Fetching wax balance for', this.authName);
      const balance = this.session.rpc.get_table_rows({
        json: true,              // Get the response as json
        code: 'eosio.token', // Contract that we target
        scope: this.authName, // Account that owns the data
        table: 'accounts',        // Table name
        limit: 10,               // Maximum number of rows that we want to get
        reverse: false,         // Optional: Get reversed data
        show_payer: false,      // Optional: Show ram payer
      })
        .then((resp = { rows: [] }) => {
          isLocal && console.debug('WAX balance:', resp);
          const { rows = [] } = resp;
          const waxBalance = extractRowBalance(rows, 'wax');
          !this.isSideSession && storeAppDispatch(setPlayerWaxBalance({ wax: waxBalance }));
          this.fetchWaxTokens();
          return waxBalance;
        });
      return balance;
    }

    getBalance() {
      isLocal && console.debug('[user-service] getting balance');
      if (!this.session.rpc) {
        !this.isSideSession && storeAppDispatch(setPlayerLogout());
        return; // breaks otherwise on refresh
      }
      try {
        if (isLocal) { window.session = this.session; } // DEBUG @@
        this.fetchWaxBalance()
          .catch((err) => {
            console.error('Error during login:', err);
            !this.isSideSession && storeAppDispatch(setPlayerLogout());
            localStorage.clear();
            const expectedEnv = isMainnetEnv ? 'MAINNET' : 'TESTNET';
            Swal.fire({text: `That did not work. Make sure that you are logging in with a ${expectedEnv} account`})
          });
      } catch (err) {
        console.error('Login error in getBalance:', err);
        !this.isSideSession && storeAppDispatch(setPlayerLogout());
        document.dispatchEvent(new Event('login-error'));
      }
    }

    // UAL API call response
    async ualCallback(userObject) {
      isLocal && console.debug('[user-service] init callback called');
      this.session = userObject[0];
      try {
        if (this.session.client) {
          // @@ @@ -- solve memory leak?? @@ @@
          this.session.client.v1.history.client = {};
          // this.session.client.v1.chain.client.v1.chain.client = {}; // breaks it
        }
      } catch (err) { console.error('Failed with cloud wallet?', err); }
      this.serviceLoginName = this.session.constructor.name;
      this.authName = this.session.accountName;        
      !this.isSideSession && storeAppDispatch(setPlayerData({
        name: this.authName,
        isLogged: this.isLogged(),
      }));
      if (this.isLogged()) {
        usernamesChecks(this.authName);
        newUserTs(this.authName);
        postLoginOverride(this.authName);
        localStorage.setItem('waxUsername', this.authName);
      }
      this.getBalance();
      if (this.callbackServerUserData !== undefined) {
        this.callbackServerUserData();
      }
    }

    // UserService init called to prepare UAL Login.
    init() {
      try {
        isLocal && console.debug('[user-service] UserService init');
        this.ualCallback = this.ualCallback.bind(this);
        const wax = new Wax([this.myChain], { appName: this.appName });
        const anchor = new Anchor([this.myChain], { appName: this.appName });
        const divUal = document.createElement('div')
        divUal.setAttribute('id', 'ual-login');
        document.body.appendChild(divUal);
        const divLoginRoot = document.getElementById('ual-login');
        if (this.isSideSession) {
          isLocal && console.debug('Nuking auto-choice of wallet for side session');
          nukeCloudWalletAutoChoice();
          nukeAnchorSavedAccount();
        }
        this.ual = new UALJs(
          this.ualCallback,
          [this.myChain], this.appName, [wax, anchor], { containerElement: divLoginRoot },
        );
        this.ual.init();
        // for wrapper
        window.out = this.logout;
      } catch (err) {
        console.error('Login error in init:', err);
        document.dispatchEvent(new Event('login-error'));
      }
    }

    static new({ isSideSession = false } = {}) {
      if (isSideSession) {
        if (!User.seperateInstance) {
          User.seperateInstance = new User({ isSideSession: true });
        }
        return User.seperateInstance;
      } else {
        if (!User.instance) {
          User.instance = new User();
        }
        return User.instance;
      }
    }
}

export const UserService = User.new();


// Wax SIDE SESSION for users logged in from Hive
export const WaxFlyingSession = User.new({ isSideSession: true });

export const flyingSessionLogin = (waxSession = WaxFlyingSession) => new Promise((resolve) => {
  uiHacks.chevron();
  waxSession.init();
  // setUrlParamApiCalls({ chain: UserState.chainLogin });
  waxSession.login(() => resolve(waxSession.isLogged()));
  setTimeout(() => resolve(false), 30 * 1000);
});
