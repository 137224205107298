import React, { useState } from 'react';
import { isMainnet } from '../../../../constants/envs';

const LOCAL_BANNER_TEST = 'https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23tvD1xjoABB5dA4oBzGe2Fk8kL9uVvgjVQraAxwKFBLZS8snLnLsTZFcNeQ2gfAi8sgJ.png';


export const LandingBanner = ({ isLogged, isFirstLoad, bannersDynConfig }) => {
  const { adSrc, adWebpage, adAlt } = bannersDynConfig;
  const imgSrc = adSrc || (isMainnet ? "" : LOCAL_BANNER_TEST);

  const [isBannerOpen, setBannerOpen] = useState(true);

  return (<>
    {!isLogged &&
      <div id="banners">
        {(imgSrc && isBannerOpen) && <>
          <img
            src={imgSrc} onClick={() => window.open(adWebpage, '_blank')}
            alt={adAlt}
          />
          <button onClick={() => setBannerOpen(false)}>
            <p>X</p>
          </button>
        </>
          /* CONTACT MARKETING for banner - meh */
          /* // {!isFirstLoad && <label id="banner-sub">
          //   Want your banner here? Contact the marketing team in our&nbsp;
          //   <a href="https://crypto-shots.com/discord" target="_blank" rel="noreferrer">
          //     Discord
          //   </a>
          // </label>} */
        }
      </div>}
    {/* ANON ADS */}
    {/* {!UserState.isLogged && !isFirstLoad && <AnonAdBanner />} */}
  </>);
};
