import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, Button, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  LEADERBOARD_ADMINS,
  difficulties,
  leaderboardInterval,
  levels,
  MAX_USERS_CHART,
  periods,
  recentStatsCategories,
  tokens
} from "../../../config/leaderboardConfig";
import { client } from "../../../helpers/client";
import { isLocal, isTestnet } from "../../../constants/envs";
import { get } from "lodash";
import { capitalize, getPlayerUsername } from "../../../generalUtils/utils";
import ReportChart from "../../../components/report/ReportChart";
import LeaderboardTable from "./LeaderboardTable";
import { LoadingSpinner } from "../../../images/gifs/LoadingSpinner";
import { UserService } from "../../../services/UserService";


// ## UTILS

const checkIsAdmin = (uname) => LEADERBOARD_ADMINS.includes(uname);

const maxUsers = (username) => checkIsAdmin(username) ? 100000 : MAX_USERS_CHART;

// const localCacheDuration = 5 * 60 * 1000;

const loadRecentStatsTab = async (username, level, difficulty, period) => {
  let dataRecentStats = {};

  for (const level of levels) {
    const resRecentStatsBestTime = await client.get(
      `/api/leaderboard/${period}/besttime/${level}?account=${username}`
    );
    dataRecentStats[`bestTimeHard${level}`] =
      resRecentStatsBestTime.data[`res${capitalize(period)}BestTime`];
  }

  const resRecentStatsKills = await client.get(
    `/api/leaderboard/${period}/kills?account=${username}`
  );
  dataRecentStats.kills =
    resRecentStatsKills.data[`res${capitalize(period)}Kills`];

  for (const token of tokens) {
    const resRecentTokenStaked = await client.get(
      `/api/leaderboard/staked/${period}/${token}?account=${username}`
    );
    // to filter out 0 as well negative values
    const tokens = resRecentTokenStaked.data[`resTokenStaked`];
    dataRecentStats[`${token}Staked`] = tokens.filter((token) => token.amount >= 0.001);
  }
  return ({ ...dataRecentStats });
};

// const loadReportTabData = async (username, level, difficulty, period) => {
//   // need to change to single data later
//   isTestnet && console.log("Fetching leaderboard data for report..");
//   const resReportWeekly = await client.get(`/api/report?account=${username}&period=${'weekly'}`);
//   const dataReportWeekly = resReportWeekly.data.resReport;
//   const resReportMonthly = await client.get(`/api/report?account=${username}&period=${'monthly'}`);
//   const dataReportMonthly = resReportMonthly.data.resReport;
//   isLocal && console.debug('Partial state for leaderboards data Report:', { level, difficulty, dataReportWeekly, dataReportMonthly });
//   return ({ monthly: dataReportMonthly, weekly: dataReportWeekly });
// }

const loadReportTabDataMonthly = async (username, level, difficulty, period) => {
  // need to change to single data later
  isTestnet && console.log("Fetching leaderboard data for report..");

  const resReportMonthly = await client.get(`/api/report?account=${username}&period=${'monthly'}`);
  const dataReportMonthly = resReportMonthly.data.resReport;

  isLocal && console.debug('Partial state for leaderboards data Report:', { level, difficulty, dataReportMonthly });

  return ({ monthly: dataReportMonthly });
};

const loadReportTabDataWeekly = async (username, level, difficulty, period) => {
  // need to change to single data later
  isTestnet && console.log("Fetching leaderboard data for report..");
  const resReportWeekly = await client.get(`/api/report?account=${username}&period=${'weekly'}`);
  const dataReportWeekly = resReportWeekly.data.resReport;

  isLocal && console.debug('Partial state for leaderboards data Report:', { level, difficulty, dataReportWeekly });

  return ({ weekly: dataReportWeekly });
};

const loadBestTimesTabData = async (username, level, difficulty) => {
  isTestnet && console.log("Fetching leaderboard data for best times..");
  const resBestTime = await client.get(
    `/api/leaderboard/besttime/${level}/${difficulty}?account=${username}`
  );
  const dataBestTime = resBestTime.data.resBestTime;

  isLocal && console.debug('Partial state for leaderboards best times:', { level, difficulty, dataBestTime });

  return dataBestTime;
};

const loadPlayedTimesTabData = async (username, level, difficulty) => {
  isTestnet && console.log("Fetching leaderboard data for played times..");
  // need to check redux
  const resPlayedTime = await client.get(
    `/api/leaderboard/playedtime/${level}/${difficulty}?account=${username}`
  );
  const dataPlayedTime = resPlayedTime.data.resPlayedTime;

  isLocal && console.debug('Partial state for leaderboards played times:', { level, difficulty, dataPlayedTime });

  return dataPlayedTime;
};

const loadKillsTabData = async (username, level) => {
  isTestnet && console.log("Fetching leaderboard data for Kills..");
  const dataTotalKills = await client.get(`/api/leaderboard/totalkills/${level}?account=${username}`);
  isLocal && console.debug('Partial state for leaderboards:', { dataTotalKills });

  return dataTotalKills;
};

const loadSavingsTabData = async (username, token) => {
  isTestnet && console.log("Fetching leaderboard data for savings..");

  const savingsData = await client.get(`/api/leaderboard/${token}-saving?account=${username}`);

  isLocal && console.debug('Partial state for leaderboards token balance for ', { token, savingsData });
  return savingsData;
};

const loadClaimsTabData = async (username, token) => {
  isTestnet && console.log("Fetching leaderboard data for claims..");

  const claimsData = await client.get(`/api/leaderboard/tot-${token}-claimed?account=${username}`);

  isLocal && console.debug('Partial state for leaderboards token claim for ', { token, claimsData });
  return claimsData;
};

export const LeaderboardTabs = () => {
  const UserState = useSelector((store) => store.user);
  const account = UserState.name;

  const [loading, setLoading] = useState(true);

  const [totalKillsData, setTotalKillsData] = useState([]);

  const [boomSavingData, setBoomSavingData] = useState([]);
  const [marsSavingData, setMarsSavingData] = useState([]);
  const [boomClaimedData, setBoomClaimedData] = useState([]);
  const [marsClaimedData, setMarsClaimedData] = useState([]);
  const [bestTimeData, setBestTimeData] = useState([]);
  const [playedTimeData, setPlayedTimeData] = useState([]);
  const [report, setReport] = useState({});
  const [recentStats, setRecentStats] = useState([]);

  const InventoryState = useSelector((store) => store.inventory);

  const [tabSelected, setTabSelected] = useState({
    parent: 'saving',
    level: levels[0],
    token: tokens[0],
    period: periods[0],
    difficulty: difficulties[0],
    recentStatsCategory: recentStatsCategories[0],
    chartPeriodSelected: periods[0]
  });

  useEffect(() => {
    const loadTabsData = async () => {
      const { parent: selectedTab, level: selectedLevel, token: selectedToken, difficulty: selectedDifficulty, period: selectedPeriod, recentStatsCategory: recentStatsCategorySelected } = tabSelected;

      setLoading(true);
      try {
        const username = getPlayerUsername({
          UserService,
          UserState,
          InventoryState,
        });

        switch (selectedTab) {
          case 'kills':
            const killsData = await loadKillsTabData(username, selectedLevel);
            isLocal && console.debug('Leaderboard data "kills ":', killsData);
            const totalKills = get(killsData, "data", { resTotalKills: [] });
            setTotalKillsData(totalKills.resTotalKills.slice(0, maxUsers(account)));
            setLoading(false);
            break;
          case 'saving':
            const savingsData = await loadSavingsTabData(username, selectedToken);
            isLocal && console.debug('Leaderboard data "savings ":', savingsData);
            if (selectedToken === 'boom') {
              const boomSavings = get(savingsData, "data", { resBoomSaving: [] });
              setBoomSavingData(
                boomSavings.resBoomSaving.slice(0, maxUsers(account))
              );
            } else if (selectedToken === 'mars') {
              const marsSavings = get(savingsData, "data", { resMarsSaving: [] });
              setMarsSavingData(
                marsSavings.resMarsSaving.slice(0, maxUsers(account))
              );
            }
            setLoading(false);
            break;
          case 'claimed':
            const claimsData = await loadClaimsTabData(username, selectedToken);
            isLocal && console.debug('Leaderboard data "claims ":', claimsData);
            if (selectedToken === 'boom') {
              const totBoomClaimed = get(claimsData, "data", {
                resBoomClaimed: [],
              });
              setBoomClaimedData(
                totBoomClaimed.resBoomClaimed.slice(0, maxUsers(account))
              );
            } else if (selectedToken === 'mars') {
              const totMarsClaimed = get(claimsData, "data", {
                resMarsClaimed: [],
              });
              setMarsClaimedData(
                totMarsClaimed.resMarsClaimed.slice(0, maxUsers(account))
              );
            }
            setLoading(false);
            break;
          case 'best-time':
            const bestTimesData = await loadBestTimesTabData(username, selectedLevel, selectedDifficulty);
            isLocal && console.debug('Leaderboard data "best times ":', bestTimesData);
            setBestTimeData(bestTimesData);
            setLoading(false);
            break;
          case 'played-time':
            const playedTimesData = await loadPlayedTimesTabData(username, selectedLevel, selectedDifficulty);
            isLocal && console.debug('Leaderboard data "played times ":', playedTimesData);
            setPlayedTimeData(playedTimesData);
            setLoading(false);
            break;
          case 'report':
            let report = null;
            // const report = await loadReportTabData(username, selectedLevel, selectedDifficulty, selectedPeriod)
            if (tabSelected.chartPeriodSelected === 'weekly') {
              report = await loadReportTabDataWeekly(username, selectedLevel, selectedDifficulty, selectedPeriod);
            } else if (tabSelected.chartPeriodSelected === 'monthly') {
              report = await loadReportTabDataMonthly(username, selectedLevel, selectedDifficulty, selectedPeriod);
            }
            isLocal && console.debug('Leaderboard data report:', report);
            // const dataReport = {
            //   monthly: report['monthly'],
            //   weekly: report['weekly']
            // }
            // setReport(dataReport);
            // we now load weekly/monthly separate
            setReport(report);
            setLoading(false);
            break;
          case 'recent-stats':
            const dataRecentStats = await loadRecentStatsTab(username, selectedLevel, selectedDifficulty, selectedPeriod);
            let tempRecentStats = [];
            if (recentStatsCategorySelected === "bestTime") {
              tempRecentStats =
                dataRecentStats[`bestTimeHard${selectedLevel}`];
            } else if (recentStatsCategorySelected === "kills") {
              tempRecentStats = dataRecentStats.kills;
            } else if (recentStatsCategorySelected === "staked") {
              tempRecentStats = dataRecentStats[`${selectedToken}Staked`];
            }
            if (tempRecentStats) setRecentStats(tempRecentStats);
            setLoading(false);
            break;
          default:
          // nothing here
        }

      } catch (e) {
        console.error("Caught error fetching leaderboard data:", e);
        toast.error("Error updating leaderboard", e);
        setLoading(false);
        return [];
      }
    };
    loadTabsData();
    let timer = setInterval(() => {
      isTestnet && console.log(new Date(), "Fetching leaderboard..");
      loadTabsData();
    }, leaderboardInterval);
    return () => {
      // clear leaderboard loading 
      clearInterval(timer);
    };
  }, [
    InventoryState,
    // UserState,
    tabSelected
  ]);


  const handleParentTabChange = (parentTabName) => {
    setTabSelected({ ...tabSelected, parent: parentTabName, level: 0, token: tokens[0], difficulty: difficulties[0] });
  };

  const handleChildTabLevelChange = (parentTabName, key) => {
    key = levels.includes(key) ? key : key.slice(-1);
    setTabSelected({ ...tabSelected, parent: parentTabName, level: key, difficulty: difficulties[0] });
  };

  const handleChildTabTokenChange = (parentTabName, token) => {
    const tokenName = token.split('-')[0];
    setTabSelected({ ...tabSelected, parent: parentTabName, token: tokenName, difficulty: difficulties[0] });
  };

  const handleChildTabPeriodChange = (parentTabName, period) => {
    setTabSelected({ ...tabSelected, parent: parentTabName, period, level: levels[0], difficulty: difficulties[0] });
  };

  const handleChildTabDifficultyChange = (parentTabName, difficulty) => {
    setTabSelected({ ...tabSelected, parent: parentTabName, difficulty });
  };

  const handleReportTabPeriodChange = (parentTabName, chartPeriodSelected) => {
    setTabSelected({ ...tabSelected, parent: parentTabName, chartPeriodSelected });
  };

  const Loader = () => <>
    <LoadingSpinner style={{ width: '80px', marginTop: '20px', border: 'none' }} />
    <p style={{ color: 'red', fontWeight: 'bolder' }}>Loading may take a minute...</p>
  </>;

  return (
    <Tabs
      onSelect={handleParentTabChange}
      defaultActiveKey="saving"
      id="table-tabs"
      className="text-white thin-column"
    >
      <Tab
        eventKey="saving"
        title="Balance"
        className="thin-column"
      >
        <Tabs
          activeKey={`${tabSelected.token}-balance`}
          defaultActiveKey="boom-balance"
          id="balance-tabs"
          className="text-white thin-column"
          onSelect={(k) => handleChildTabTokenChange('saving', k)}>
          <Tab eventKey='boom-balance' title="igBOOM" className="thin-column">
            {!loading ? (
              <>
                <LeaderboardTable
                  loading={loading}
                  dataList={boomSavingData}
                  title="boomSavingData"
                ></LeaderboardTable>
              </>
            ) : <Loader />}
          </Tab>
          <Tab eventKey='mars-balance' title="igMARS" className="thin-column">
            {!loading ? (
              <>
                <LeaderboardTable
                  loading={loading}
                  dataList={marsSavingData}
                  title="marsSavingData"
                ></LeaderboardTable>
              </>
            ) : <Loader />}
          </Tab>
        </Tabs>
      </Tab>
      <Tab
        eventKey="claimed"
        title="Claims"
        className="thin-column"
      >
        <Tabs
          activeKey={`${tabSelected.token}-claimed`}
          defaultActiveKey="boom-claimed"
          id="claim-tabs"
          onSelect={(k) => handleChildTabTokenChange('claimed', k)}
          className="text-white thin-column">
          <Tab eventKey='boom-claimed' title="BOOM" className="thin-column">
            {!loading ? (
              <>
                <LeaderboardTable
                  loading={loading}
                  dataList={boomClaimedData}
                  title="boomClaimedData"
                ></LeaderboardTable>
              </>
            ) : <Loader />}
          </Tab>
          <Tab eventKey='mars-claimed' title="MARS" className="thin-column">
            {!loading ? (
              <>
                <LeaderboardTable
                  loading={loading}
                  dataList={marsClaimedData}
                  title="marsClaimedData"
                ></LeaderboardTable>
              </>
            ) : <Loader />}
          </Tab>
        </Tabs>
      </Tab>
      <Tab eventKey="kills" title="Kills" className="thin-column">
        <Tabs
          activeKey={`totalKills${tabSelected.level}`}
          // eventKey="kills-child"
          defaultActiveKey={`totalKills${tabSelected.level}`}
          id="kill-tabs"
          className="text-white thin-column"
          onSelect={(k) => handleChildTabLevelChange('kills', k)}
        >
          {levels.map((level) => {
            const dataName = `totalKillsData${level}`;

            return <Tab key={`totalKills${level}`} eventKey={`totalKills${level}`}
              title={`Level ${level}`} className="thin-column">
              {!loading ? (
                <>
                  <LeaderboardTable
                    loading={loading}
                    dataList={totalKillsData}
                    title={dataName}
                  ></LeaderboardTable>
                </>
              ) : <Loader />}
            </Tab>;
          })}
        </Tabs>
      </Tab>
      <Tab eventKey="best-time" title="Best Times" className="thin-column">
        <Tabs
          activeKey={`best-time-level-${tabSelected.level}`}
          defaultActiveKey="best-time-level-0"
          id="best-time-tabs"
          className="text-white thin-column"
          onSelect={(k) => handleChildTabLevelChange('best-time', k)}
        >
          {levels.map((level) => {
            const eventKey = `best-time-level-${level}`;
            const title = `Level ${level}`;

            return (
              <Tab
                key={'best-time-level-' + level}
                eventKey={eventKey}
                title={title}
                className="thin-column"
              >
                <ButtonGroup className="m-2">
                  {difficulties.map((difficulty, index) => (
                    <Button
                      variant={difficulty === tabSelected.difficulty ? "warning" : "secondary"}
                      key={index}
                      onClick={() => handleChildTabDifficultyChange('best-time', difficulty)}
                    >
                      {difficulty}
                    </Button>
                  ))}
                </ButtonGroup>
                {!loading ? (
                  <>
                    <LeaderboardTable
                      loading={loading}
                      dataList={bestTimeData}
                      title="bestTimeData"
                    ></LeaderboardTable>
                  </>
                ) : <Loader />}
              </Tab>
            );
          })}
        </Tabs>
      </Tab>
      <Tab
        eventKey="played-time"
        title="Play Time"
        className="thin-column"
      >
        <Tabs
          activeKey={`played-time-level-${tabSelected.level}`}
          defaultActiveKey="played-time-level-0"
          id="played-time-tabs"
          className="text-white thin-column"
          onSelect={(k) => handleChildTabLevelChange('played-time', k)}
        >
          {levels.map((level) => {
            const eventKey = `played-time-level-${level}`;
            const title = `Level ${level}`;

            return (
              <Tab
                key={'played-time-level-' + level}
                eventKey={eventKey}
                title={title}
                className="thin-column"
              >
                <ButtonGroup className="m-2">
                  {difficulties.map((difficulty, index) => (
                    <Button
                      variant={difficulty === tabSelected.difficulty ? "warning" : "secondary"}
                      key={index}
                      onClick={() => handleChildTabDifficultyChange('played-time', difficulty)}
                    >
                      {difficulty}
                    </Button>
                  ))}
                </ButtonGroup>
                {!loading ? (
                  <>
                    <LeaderboardTable
                      loading={loading}
                      dataList={playedTimeData}
                      title="playedTimeData"
                    ></LeaderboardTable>
                  </>
                ) : <Loader />}
              </Tab>
            );
          })}
        </Tabs>
      </Tab>
      <Tab eventKey="report" title="Charts" className="thin-column">
        <ButtonGroup className="m-2">
          {periods.map((period) => (
            <Button
              variant={period === tabSelected.chartPeriodSelected ? "warning" : "secondary"}
              key={period}
              onClick={() => handleReportTabPeriodChange('report', period)}
            >
              {period}
            </Button>
          ))}
        </ButtonGroup>
        <ReportChart dataReport={report} loading={loading}></ReportChart>
      </Tab>
      <Tab eventKey="recent-stats" title="Prizes" className="thin-column">
        <div className="text-left">
          <ButtonGroup className="m-2">
            {periods.map((period) => (
              <Button
                variant={period === tabSelected.period ? "warning" : "secondary"}
                key={period}
                onClick={() => handleChildTabPeriodChange('recent-stats', period)}
              >
                {period}
              </Button>
            ))}
          </ButtonGroup>
          <ButtonGroup className="m-2">
            {recentStatsCategories.map((category, index) => (
              <Button
                variant={category === tabSelected.recentStatsCategory
                  ? "warning"
                  : "secondary"}
                key={index}
                onClick={() => setTabSelected({ ...tabSelected, recentStatsCategory: category })}
              >
                {category}
              </Button>
            ))}
          </ButtonGroup>
          {tabSelected.recentStatsCategory === recentStatsCategories[2] && (
            <ButtonGroup className="m-2">
              {levels.map((level) => (
                <Button
                  variant={level === tabSelected.level ? "warning" : "secondary"}
                  key={level}
                  onClick={() => handleChildTabLevelChange('recent-stats', level)}
                >
                  Level {level}
                </Button>
              ))}
            </ButtonGroup>
          )}
          {tabSelected.recentStatsCategory === recentStatsCategories[0] && (
            <ButtonGroup className="m-2">
              {tokens.map((token) => (
                <Button
                  variant={token === tabSelected.token ? "warning" : "secondary"}
                  key={token}
                  onClick={() => handleChildTabTokenChange('recent-stats', token)}
                >
                  {token}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </div>
        {!loading ? (
          <LeaderboardTable
            loading={loading}
            dataList={recentStats}
            title="Recent Stats"
            category={tabSelected.recentStatsCategory}
            token={tabSelected.token}
          ></LeaderboardTable>
        ) : <Loader />}
      </Tab>
    </Tabs>
  );
};
