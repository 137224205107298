import hivejs from '@hiveio/hive-js';
import { get } from 'lodash';

import { isLocal } from '../../../constants/envs';
import { pickHiveNode } from '../../../generalUtils/hiveData/reader';
import { storeAppDispatch } from '../../../GlobalState/Store';
import { setPlayerHiveTokensBalances } from '../../../reducers/WalletReducer';
import { unsafeBalanceRead } from '../../../generalUtils/pvp/poolBalance'; // eslint-disable-line


export const validateHiveUsername = ({ username }) => new Promise((resolve) => {
  // random node
  const pickedNode = pickHiveNode();
  hivejs.api.setOptions({ url: pickedNode });

  // username format validation (dots, lenght, etc)
  const err = hivejs.utils.validateAccountName(username);
  if (err) {
    console.error('Invalid username. Reason:', err);
    return resolve({ err });
  }

  // check account exists
  try {
    hivejs.api.getAccounts([username], (err, result) => {
      isLocal && console.debug('Hive user name check - result:', { err, result });
      if (err) {
        resolve({ err: 'Something broke with this Hive node. Try again.' });
        return;
      }
      const resultName = get(result, '[0].name');
      if (resultName !== username) {
        resolve({ err: `The Hive account @${username} does not exist` });
      }
      else resolve({});
    });
  } catch (err) {
    console.error('Caught err validating uname:', err);
    return ({ caughtErr: err });
  }
});


export const fetchDoomBalance = async ({ username }) => {
  return fetch(
    'https://api.hive-engine.com/rpc/contracts',
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0', id: 1, method: 'find', params: {
          query: { account: username },
          contract: 'tokens', table: 'balances',
          limit: 1000, offset: 0, indexes: '',
        }
      }),
      method: 'POST',
    })
      .then(res => res.json())
      .then((data) => {
        const doomBalance = data.result?.find(balObj => balObj.symbol === 'DOOM') || {};
        const res = doomBalance.balance || 0;
        isLocal && console.debug('DOOM balance:', res);
        return res;
      })
      .catch(err => console.error('Caught error fetching doom bal:', err));
};

export const updateDoomBalance = async ({ hiveUsername }) => {
  const doomBal = await fetchDoomBalance({ username: hiveUsername });
  console.log('DOOM balance:', doomBal);
  storeAppDispatch(setPlayerHiveTokensBalances({ hiveTokens: { doom: doomBal } }));
};

export const fetchDoomStakedInPool = async ({ username, tokenName = 'DOOM' }) => {
  return fetch(
    'https://api.hive-engine.com/rpc/contracts',
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0', id: 1, method: 'find', params: {
          query: { account: username },
          contract: 'marketpools', table: 'liquidityPositions',
          limit: 1000, offset: 0, indexes: '',
        }
      }),
      method: 'POST',
    })
      .then(res => res.json())
      .then(async ({ result } = {}) => {
        isLocal && console.log('Player has stake in these pools:', { result });
        const stakeDetails = result.find(balObj => balObj.tokenPair === `${tokenName}:SWAP.HIVE`) || {};
        isLocal && console.debug('Pool stakeDetails:', { username, stakeDetails });
        // { account: "marcocasario", shares: "103.16795016799662388128", timeFactor: 1674624567000
        //   tokenPair: "DOOM:SWAP.HIVE", _id: 61972 }
        const { shares = 0 } = stakeDetails;
        if (!shares) return ({ shares: 0, stakedTokenAmount: 0 });
        const playerShares = +shares;
        const { stakedTokenAmount, sharesPc, err } = await fetchStakedTokenAmount({ playerShares, tokenName });
        if (err) return ({ err: `fsta-err: ${stakedTokenAmount}` });
        return ({ shares: playerShares, sharesPc, stakedTokenAmount });
      })
      .catch((err) => {
        console.error('Caught error in fsip:', err);
        return ({ err });
      });
};

const fetchStakedTokenAmount = ({ playerShares, tokenName }) => {
  return fetch(
    'https://api.hive-engine.com/rpc/contracts',
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0', id: 1, method: 'find', params: {
          query: { tokenPair: `${tokenName}:SWAP.HIVE` },
          contract: 'marketpools', table: 'pools',
          limit: 1000, offset: 0, indexes: '',
        }
      }),
      method: 'POST',
    })
      .then(res => res.json())
      .then((data) => {
        isLocal && console.debug('Pool general info:', data.result[0]);
        // basePrice: "0.01994691", baseQuantity: "368017.96265685", baseVolume: "99297.84404313",
        // creator: "wagginston", precision: 8, quotePrice: "50.13306962", quoteQuantity: "7340.82244385",
        // quoteVolume: "2689.80313600", tokenPair: "DOOM:SWAP.HIVE", totalShares: "51956.32327339272131934828"
        const { totalShares, baseQuantity } = data.result[0];
        // (player) shares / totalShares * baseQuantity (tot DOOM in pool) = tot DOOM staked
        const stakedTokenAmount = playerShares / +totalShares * +baseQuantity;
        const sharesPc = playerShares / +totalShares * 100;
        isLocal && console.log(`Player staked in pool: ${stakedTokenAmount} ${tokenName}`);
        return ({ stakedTokenAmount, sharesPc });
      })
      .catch((err) => {
        console.error('Caught error in fsta:', err);
        return ({ err });
      });
};