import { JsonRpc } from 'eosjs';
import fetch from 'node-fetch';
import { WAX_NODES } from "../../../config/endpoints";
import { isLocal } from "../../../constants/envs";

const waxEndpoint = WAX_NODES.DEFAULT;


export const checkTokenBalance = async ({ username = '', symbol = '' }) => {
  const rpc = new JsonRpc(waxEndpoint, { fetch });
  const contract = symbol === 'WAX' ? 'eosio.token' : 'csboomcsboom';
  const result = await rpc.get_currency_balance(contract, username, symbol.toUpperCase());
  const balance = +(result[0] || '').split(' ')[0];
  isLocal && console.log('WAX TOKEN BALANCE CHECK >>>>>>>>>>>>>>>', { username, symbol, result, balance });
  return balance;
};
