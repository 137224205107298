import { windowSetChecksum } from "../generalUtils/secure/checksums";
import { isTestnet } from "./envs";
import { HIVE_TEMPLATES } from "./hiveNfts";

// TODO: CUSTOMIZED ORDER FOR NEW USER !!
// TODO: CUSTOMIZED ORDER FOR NEW USER !!
// TODO: CUSTOMIZED ORDER FOR NEW USER !!

// Reminder: NEED TO UPLOAD S3 @@ @@ @@ <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const ITEMS_BY_CATEGORY = {
  CYBERGIRLS: {
    KIJ: { ID: '647900', NAME: 'K_i_a_r_a - GPT Cybergirl - January', IMG: 'QmZ5E14Gj9ThonCgLX6rD36dQrKKhvqyLcYMMPn3bK7Vyc', SCHEMA: 'cs.cybergirl' },
    KAJ: { ID: '647898', NAME: 'KarinaYana - GPT Cybergirl - January', IMG: 'QmZycUnAHBkSPkiGc6ZEozK4y4q3f1ZxN1CYuXmSCjkRtL', SCHEMA: 'cs.cybergirl' },
    MAJ: { ID: '647901', NAME: 'Mary Marte - GPT Cybergirl - January', IMG: 'QmbC7j3j5kEo6cApHmLkUzYsNsWNCaRZ7zbceZHRb7uYHe', SCHEMA: 'cs.cybergirl' },
    KIF: { ID: '655330', NAME: 'K_i_a_r_a - GPT Cybergirl - February', IMG: 'QmTVUFqNPuDu1v6qTms4SaWXjFoqfkdxHVMdXzSPQTfMAE', SCHEMA: 'cs.cybergirl' },
    KAF: { ID: '655322', NAME: 'KarinaYana - GPT Cybergirl - February', IMG: 'QmPR48H1yUJcGYYhJrFuYnbt4ZJnTXTRNiCqzWqDdRfNWA', SCHEMA: 'cs.cybergirl' },
    MAF: { ID: '655331', NAME: 'Mary Marte - GPT Cybergirl - February', IMG: 'Qmdy8QbL5uRpaNmGMoDBuLtuqyZWp3j6eSTtB4fCjzstFm', SCHEMA: 'cs.cybergirl' },
    KIM:  { ID: '664429', NAME: 'K_i_a_r_a - GPT Cybergirl - March', IMG: 'QmZcNATG43zvSUKzBSmbWtsoLLRwUg1ciMNCjwqcmjxmQb', SCHEMA: 'cs.cybergirl' },
    KAM: { ID: '664428', NAME: 'KarinaYana - GPT Cybergirl - March', IMG: 'QmSEjKuit1MfP37s5dAbiVivbYRjZZHsmDSD8SM8Vds68A', SCHEMA: 'cs.cybergirl' },
    MAM: { ID: '664430', NAME: 'Mary Marte - GPT Cybergirl - March', IMG: 'QmWESNKFDQSLu8mxJ6pykoCrV8yxBKhFu9JtgThB7ZJBtH', SCHEMA: 'cs.cybergirl' },
    KIA:  { ID: '673815', NAME: 'K_i_a_r_a - GPT Cybergirl - April', IMG: 'QmbnLp8JmW2ftWpbGcmthrmtEaFg2vqufCAVuaj7zkuw4i', SCHEMA: 'cs.cybergirl' },
    KAA: { ID: '673813', NAME: 'KarinaYana - GPT Cybergirl - April', IMG: 'QmauQsj15BSo6KQWRePh8f3MnzyPHs1iuM7n3XHRpWVZ3J', SCHEMA: 'cs.cybergirl' },
    MAA: { ID: '673816', NAME: 'Mary Marte - GPT Cybergirl - April', IMG: 'QmR9KjAsjZvF8omUQ3VtgvS2ntkApyh8ymQRJYgsHe8rMM', SCHEMA: 'cs.cybergirl' },
    KIMA: { ID: '682095', NAME: 'K_i_a_r_a - GPT Cybergirl - May', IMG: 'QmRBUhbS9pFQRcZmDsMmGjjxz1HFC9VhR86a3u95yWPcda', SCHEMA: 'cs.cybergirl' },
    KAMA: { ID: '682096', NAME: 'KarinaYana - GPT Cybergirl - May', IMG: 'QmZJxmeLR665cfY55M9CuCkMm3eNG6mfQiMTNNYF87vUuT', SCHEMA: 'cs.cybergirl' },
    MMAA: { ID: '682098', NAME: 'Mary Marte - GPT Cybergirl - May', IMG: 'QmTLFEFLvjKiecyBdjoxrFypHnWKLWzuBDiwsCnCchCxd5', SCHEMA: 'cs.cybergirl' },

    KIJU: { ID: '700303', NAME: 'K_i_a_r_a - GPT Cybergirl - June', IMG: 'QmboTNWD1LSyF95NbPei1odqRotwBNQyUcmzhYmAgrHgh3', SCHEMA: 'cs.cybergirl' },
    KAJU: { ID: '700304', NAME: 'KarinaYana - GPT Cybergirl - June', IMG: 'QmRLwcL4SNSi871yn76CTEVjeJCX8zNFquYm2FyEDDQbm3', SCHEMA: 'cs.cybergirl' },
    MMJU: { ID: '700302', NAME: 'Mary Marte - GPT Cybergirl - June', IMG: 'QmQMFuQzsQBsRBtoe1qUH4PoKbVQpPyuV8pvVQcHWQWyqM', SCHEMA: 'cs.cybergirl' },

    KIJL: { ID: '715237', NAME: 'K_i_a_r_a - GPT Cybergirl - July', IMG: 'QmaacmzCSbJHEqwx1qp6TS8CCZUSysDoVoWL9x8d3xUJaC', SCHEMA: 'cs.cybergirl' },
    KAJL: { ID: '715238', NAME: 'KarinaYana - GPT Cybergirl - July', IMG: 'QmT6r9g8694r91rx2g3LC7gdrjcYB3RqNsZa2xazsbB1kd', SCHEMA: 'cs.cybergirl' },
    MMJL: { ID: '715240', NAME: 'Mary Marte - GPT Cybergirl - July', IMG: 'QmeX3g1g7UktsCBpUrVVZ1n4noUv73uSxZ2YXuCjSd9C4t', SCHEMA: 'cs.cybergirl' },

    KIAU: { ID: '732842', NAME: 'K_i_a_r_a - GPT Cybergirl - August', IMG: 'QmRJNnYQoyZLhGRP288FGR4j5xLEy2RUPMMwUrqPuXfgvt', SCHEMA: 'cs.cybergirl' },
    KAAU: { ID: '732840', NAME: 'KarinaYana - GPT Cybergirl - August', IMG: 'QmYJmhAY7RKh1m6J1JTTEsxKmd7B7pTu7UE8Cu8D4NWajq', SCHEMA: 'cs.cybergirl' },
    MMAU: { ID: '732843', NAME: 'Mary Marte - GPT Cybergirl - August', IMG: 'Qmcr4sjM2JptDLSqXPzXPXDRFirerrhJPsoYgJcNk5bzYs', SCHEMA: 'cs.cybergirl' },
    
    KIAS: { ID: '751371', NAME: 'K_i_a_r_a - GPT Cybergirl - September', IMG: 'QmSwHeBghGidbxURdDPU4DymUTWiuJPECeatsynYsQuVCa', SCHEMA: 'cs.cybergirl' },
    KAAS: { ID: '751366', NAME: 'KarinaYana - GPT Cybergirl - September', IMG: 'QmWps6P3QYLyow5dLHeksYZCcFJUVvZBQivcz3pEuzAZ3e', SCHEMA: 'cs.cybergirl' },
    MMAS: { ID: '751377', NAME: 'Mary Marte - GPT Cybergirl - September', IMG: 'QmQHdJBALkzwr7uVpjKNT9SCnHJz5htsSf46qMQPhXaMf5', SCHEMA: 'cs.cybergirl' },
    
    KIAO: { ID: '751373', NAME: 'K_i_a_r_a - GPT Cybergirl - October', IMG: 'Qmbs6ExLyGNSY4WEhor9ebEm5hx9Xf1Bcbk4qPagCVrByD', SCHEMA: 'cs.cybergirl' },
    KAAO: { ID: '751367', NAME: 'KarinaYana - GPT Cybergirl - October', IMG: 'QmTHUTZa4xKPTobJ9keu95bSfEqY2MSwGtZWd2vezMvxsJ', SCHEMA: 'cs.cybergirl' },
    MMAO: { ID: '751378', NAME: 'Mary Marte - GPT Cybergirl - October', IMG: 'Qme8ibc8dPQ77UqNCBbJPRecZ9WYpL2WkSQmfmS9L6WaZp', SCHEMA: 'cs.cybergirl' },
    
    KIAN: { ID: '751375', NAME: 'K_i_a_r_a - GPT Cybergirl - November', IMG: 'QmU5KEryYD8sHjAbk4AReSrqLHLrVJV19wdK1sRVCNQ4ms', SCHEMA: 'cs.cybergirl' },
    KAAN: { ID: '751370', NAME: 'KarinaYana - GPT Cybergirl - November', IMG: 'QmTZmAaJNwXuYAQcGWh7ivJ2WS7V72JyHhhzJ9veMTG3co', SCHEMA: 'cs.cybergirl' },
    MMAN: { ID: '751379', NAME: 'Mary Marte - GPT Cybergirl - November', IMG: 'QmeeZqhCUyCgNdmMX15cRHStmgnBu2ut3RpemJoSiP7PNK', SCHEMA: 'cs.cybergirl' },
    
    KIAD: { ID: '751376', NAME: 'K_i_a_r_a - GPT Cybergirl - December', IMG: 'QmSCSNVLEUzmAtR2ZJCvD5KUNmo1piFSKNJZxQFvpwQxED', SCHEMA: 'cs.cybergirl' },
    KAAD: { ID: '751369', NAME: 'KarinaYana - GPT Cybergirl - December', IMG: 'QmRxENDChRQbeLNn5fomBvcceDXQ6YbDcbmPXVXXrEeQWX', SCHEMA: 'cs.cybergirl' },
    MMAD: { ID: '751380', NAME: 'Mary Marte - GPT Cybergirl - December', IMG: 'QmTn44LY6AyJZHQ4MJrAnEBQCecScaHdAaYP22JTUxkA2V', SCHEMA: 'cs.cybergirl' },

    // Where to add every month: here FE, S3 bucket, backend config: mint.js, cs-discord config: cgTemplateIDs
  },
  STARTER_KITS: {
    SK:  { ID: '417605', OG_NAME: '3D game Starter Kit', NAME: '3D game Starter Pack', IMG: 'QmeDPn83PvKUnoN23LuzCyP1ViMAEwc2FjGpxdDabKH8kJ', SCHEMA: 'cs.ahnbpacks' },
    AKS:  { ID: '444819', OG_NAME: '3D game Advanced Pack - S', NAME: '3D game Advanced Pack S', IMG: 'Qmej5WZa96RABPxyJg39vxoDtNuqbXzb7JyKzLAJ5SNiMN', SCHEMA: 'cs.startpack' },
    AKM:  { ID: '444822', OG_NAME: '3D game Advanced Pack - M', NAME: '3D game Advanced Pack M', IMG: 'QmUtd9vKSRZQ4tNsn7caMJGCW9VTwDWjA32fs9urojD6Xh', SCHEMA: 'cs.startpack' },
    AKL:  { ID: '444823', OG_NAME: '3D game Advanced Pack - L', NAME: '3D game Advanced Pack L', IMG: 'QmW1Vy9w6rYzb6NMYnwVJgWtoBT4FyWDxLQUZpQ44Qx59k', SCHEMA: 'cs.startpack' },
    GXL:  { ID: '445646', OG_NAME: '3D game Golden Pack - XL', NAME: '3D game Golden Pack XL', IMG: 'QmPrvpC2YXixDnqCTXJCSWjdhSLrx1bWis87QFFP6JG7nd', SCHEMA: 'cs.startpack' },
  },
  WEAPON_PACKS: {
    SP: { ID: '392158', OG_NAME: 'Small Box', NAME: 'Weapons Pack S', IMG: 'QmbqdDLvj8eTinW8qduXPyv96SRcfgReez357jBBr5gEd5', SCHEMA: 'cs.ahnbpacks' },
    MP: { ID: '392181', OG_NAME: 'Medium Box', NAME: 'Weapons Pack M', IMG: 'QmSLQvL42ncmrPJ8jnwoa3ovwsQktXDLYBbvnBydic16nQ', SCHEMA: 'cs.ahnbpacks' },
    LP: { ID: '392182', OG_NAME: 'Large Box', NAME: 'Weapons Pack L', IMG: 'QmbsG4x3p9B9H3tHSdsCoYyvvLYmMvAkwWatrsjpXeG3rz', SCHEMA: 'cs.ahnbpacks' },
  },
  AMMUNITION: {
    BA: { ID: '417628', NAME: 'Base Ammo', IMG: 'QmNNdnqihyrxz9GMjgiDRNPe8WzR2Au5bvQjtSrAnJwEC5', SCHEMA: 'cs.gameitems' },
    CEC: { ID: '403688', OG_NAME: 'Energy Cell', NAME: 'Energy Cell (common)', IMG: 'Qmbyano2MT9PCT4KuQFN5Jx489EdmcHtTczyGWxPT9xuXK', SCHEMA: 'cs.gameitems' },
    REC: { ID: '391901', OG_NAME: 'Energy Cell', NAME: 'Energy Cell (rare)', IMG: 'QmW7QuFzBHLf8m7ick1PT9Mhr1qLSqpTPmsr5tHY96viJ2', SCHEMA: 'cs.gameitems' },
    EEC: { ID: '403690', OG_NAME: 'Energy Cell', NAME: 'Energy Cell (epic)', IMG: 'QmUoq5JCwAxWYXeesFKKco2ixo18YrB1cmpi7FRTYgLg8j', SCHEMA: 'cs.gameitems' },
  },
  ARSENAL_ADVANCED_PACKS: {
    SP: { ID: '403678', OG_NAME: 'Advanced Box - Small', NAME: 'Arsenal Pack S', IMG: 'QmRXzGuBACnuSu99Qu6kzo7jm2S4ejXpCaiTXM67DMESAV', SCHEMA: 'cs.ahnbpacks' },
    MP: { ID: '403680', OG_NAME: 'Advanced Box - medium', NAME: 'Arsenal Pack M', IMG: 'QmV8LxzZt2utHnK4w8CVB1duchnt4CsNHzyTXShvjAbkdJ', SCHEMA: 'cs.ahnbpacks' },
  },
  LEVEL_PACKS: {
    // L1
    SS:  { ID: '493725', OG_NAME: 'Level-1 box, small', NAME: 'Level-1 pack, S', IMG: 'QmV4JodogBmNxk2ky4x73NGbVebNpsvWDqhrPtx2DYAiNB', SCHEMA: 'level.packs' },
    MM:  { ID: '493730', OG_NAME: 'Level-1 box, medium', NAME: 'Level-1 pack, M', IMG: 'QmPKz7nh9dbK6fBNQgcFVDd77JdPghWJrwtLf62ePSguWa', SCHEMA: 'level.packs' },
    LL:  { ID: '493735', OG_NAME: 'Level-1 box, LARGE', NAME: 'Level-1 pack, L', IMG: 'QmVdRmnBwSFh77ZZQFMUMxs5eQB3FDciFMec9duntXKgiz', SCHEMA: 'level.packs' },
    XL:  { ID: '493738', OG_NAME: 'Level-1 box, EXTRA LARGE', NAME: 'Level-1 pack, XL', IMG: 'QmNTHuuEN5kZAg6ABdjgLxTSRSibWHchNmCZrY2jKRYMqf', SCHEMA: 'level.packs' },
    // L2
    L2S:  { ID: '616633', OG_NAME: 'Level-2 box, SMALL', NAME: 'Level-2 pack, Small', IMG: 'QmUXW5kngDsYpccKKiE1Ra8P7hiuwNsX146AeAc9odZRdf', SCHEMA: 'level.packs' },
    L2M:  { ID: '616635', OG_NAME: 'Level-2 box, MEDIUM', NAME: 'Level-2 pack, Medium', IMG: 'QmfU1DFvQ8TZhU2Hag7zRgtmYVuPe8bqPXF7AUis6MbUoS', SCHEMA: 'level.packs' },
    L2L:  { ID: '610669', OG_NAME: 'Level-2 box, LARGE', NAME: 'Level-2 pack, Large', IMG: 'QmPKs71ajgV51JN3DXQVDx5PdbJWXTUYzUh9RFPKcM3bCq', SCHEMA: 'level.packs' },
  },
  WEAPONS: {
    BG: { ID: '365485', OG_NAME: 'Base Gun', NAME: 'Base Gun, All Levels', IMG: 'QmTBEN8pEfqqDExkH8RH7ViNumX5uutZhzhJ35jt6HRbWZ', SCHEMA: 'csnftweapons' },
    // level-0
    LG: { ID: '385492', OG_NAME: 'Laser Gun', NAME: 'Laser Gun, Level-0', IMG: 'QmXP9qWC9jt8wxs7pZN7hsFe2frE9gHkZvPnMR6Js1N2R6', SCHEMA: 'levelweapons' },
    LR: { ID: '385495', OG_NAME: 'Laser Rifle', NAME: 'Rifle, Level-0', IMG: 'QmQ6bnz5dusYSym524PqGyQfmok71e8DHkriTJmqAbrP4T', SCHEMA: 'levelweapons' },
    LS: { ID: '385499', OG_NAME: 'Laser Shotgun', NAME: 'Shotgun, Level-0', IMG: 'Qmb7CdFEwupaTNZuKECBRdLuSeBfkY8JrnNQukttZ7wTDs', SCHEMA: 'levelweapons' },
    // level-1
    SA: { ID: '514741', NAME: 'Savage, Level-1', IMG: 'Qmf3XMPphBoDSvHH1fmYJeTWdcunkbT8ttZmi9jg3EcMHN', SCHEMA: 'levelweapons' },
    TR: { ID: '514742', NAME: 'Tremor, Level-1', IMG: 'QmZkf4x42y36jA9Tw3WQUn4ARSNbZKDuTyzdYNBQDvmn2K', SCHEMA: 'levelweapons' },
    VO: { ID: '514744', NAME: 'Vortex, Level-1', IMG: 'QmZSurjiLR5UJAqQec91BfjHQgjYAjUhsqPvT9XpgAuPXx', SCHEMA: 'levelweapons' },
    SU: { ID: '514747', NAME: 'Supernova, Level-1', IMG: 'QmTe5imREGvJKbnZwG5ZQqxg2Ph6TvaUSVR7P3fpAJU2S4', SCHEMA: 'levelweapons' },
    // level-2
    MU2: { ID: '625187', NAME: 'Muran, Level-2', IMG: 'QmRsiUbSw62cY5kKSppa9eYyrP5hcLcHHSMcFeKaZA19yg', SCHEMA: 'levelweapons' },
    TH2: { ID: '625189', NAME: 'Theta, Level-2', IMG: 'QmcffKXj8J7c8FE6UENcFKEEdSvfZ26krFRufcuVpiKQiK', SCHEMA: 'levelweapons' },
    VE2: { ID: '625190', NAME: 'Venga, Level-2', IMG: 'QmSHEy3niNPAcfN2N66rWvrRjoa3dPSqnB3fd3VU7uN7Aa', SCHEMA: 'levelweapons' },
  },
  WEAPON_PARTS: {
    // level-0
    LGF: { ID: '385510', OG_NAME: 'Laser Gun Frame', NAME: 'Laser Gun, FRAME', IMG: 'QmePPA2kCuAn2VicPJn7reWyaZ7qtEmjzPHN6uPqXsYtLf', SCHEMA: 'weapon.parts' },
    LGB: { ID: '385506', OG_NAME: 'Laser Gun Electronic Board', NAME: 'Laser Gun, BOARD', IMG: 'QmRZjPjPd3gUjLn7ok8zWVDnQ87JMj9YYBHFm35erpeti5', SCHEMA: 'weapon.parts' },
    LGC: { ID: '385503', OG_NAME: 'Laser Gun Crystal', NAME: 'Laser Gun, CRYSTAL', IMG: 'QmakKrn4YFUgXR5F5JNEcGUibzEvGQ5kP5LfXRkXifCPJh', SCHEMA: 'weapon.parts' },
    LRF: { ID: '385514', OG_NAME: 'Laser Rifle Frame', NAME: 'Rifle FRAME', IMG: 'QmX51yPetTpRq3JWQgrkT7A6XzbRNW5xochWLs1jezLHXP', SCHEMA: 'weapon.parts' },
    LRB: { ID: '385513', OG_NAME: 'Laser Rifle Electronic Board', NAME: 'Rifle, BOARD', IMG: 'QmQ8RWoVABbzwEHb2CDBCGuorDkmMLcrchbZBZQvjwkSFi', SCHEMA: 'weapon.parts' },
    LRC: { ID: '385512', OG_NAME: 'Laser Rifle Crystal', NAME: 'Rifle, CRYSTAL', IMG: 'QmRST394HgH8MZAYEmoTiS94EM3JGrz4ajp9RAdn7z8tsH', SCHEMA: 'weapon.parts' },
    LSF: { ID: '385518', OG_NAME: 'Laser Shotgun Frame', NAME: 'Shotgun FRAME', IMG: 'QmaWEWXJ6RxPUTqg2EhrUav3L3eAHU5SzqQXBr87QFPCjv', SCHEMA: 'weapon.parts' },
    LSB: { ID: '385516', OG_NAME: 'Laser Shotgun Electronic Board', NAME: 'Shotgun, BOARD', IMG: 'QmVwrgSQRQtPyoHfH3qHdLAtakZvRGBYKsqzaedfpubVV7', SCHEMA: 'weapon.parts' },
    LSC: { ID: '385515', OG_NAME: 'Laser Shotgun Crystal', NAME: 'Shotgun, CRYSTAL', IMG: 'QmPatUV8KnnMEdWXArsTxp5EUoFUPwxuMtg7b3dM8cSU5H', SCHEMA: 'weapon.parts' },
    // level-1
    SAF: { ID: '514752', OG_NAME: 'Part of Level-1 weapon Savage: FRAME', NAME: 'Savage L-1, FRAME', IMG: 'QmRXjZLioXdhmrjgew4yUXujhH1UEzh4rbQZYbGfRmJkVc', SCHEMA: 'weapon.parts' },
    SAB: { ID: '514754', OG_NAME: 'Part of Level-1 weapon Savage: ELECTRIC BOARD', NAME: 'Savage L-1, BOARD', IMG: 'QmaqtEibscP4eCs69PryFuRtVJL6bcXecnjwk8gZ8EATqD', SCHEMA: 'weapon.parts' },
    SAC: { ID: '514753', OG_NAME: 'Part of Level-1 weapon Savage: CRYSTAL', NAME: 'Savage L-1, CRYSTAL', IMG: 'QmddeLXxrxYNe5NzbMA9qZ1kpLEYjesDzFH4DgQLkkdPdb', SCHEMA: 'weapon.parts' },
    TRF: { ID: '514757', OG_NAME: 'Part of Level-1 weapon Tremor: FRAME', NAME: 'Tremor L-1, FRAME', IMG: 'Qme1QrwfPWhbKW4ciGhFkVhoiz7Mz5Fq52SDQer6yRHVGN', SCHEMA: 'weapon.parts' },
    TRB: { ID: '514761', OG_NAME: 'Part of Level-1 weapon Tremor: ELECTRIC BOARD', NAME: 'Tremor L-1, BOARD', IMG: 'QmTk39wZ6SwiYyj9mSngrJYTXghbvwbZLQ1mXnKn3j2sFz', SCHEMA: 'weapon.parts' },
    TRC: { ID: '514759', OG_NAME: 'Part of Level-1 weapon Tremor: CRYSTAL', NAME: 'Tremor L-1, CRYSTAL', IMG: 'QmfUjL2ApfLXu6JG4yX4i3oHoQCiwqqpJqEPA5DPy8VMn6', SCHEMA: 'weapon.parts' },
    VOF: { ID: '514763', OG_NAME: 'Part of Level-1 weapon Vortex: FRAME', NAME: 'Vortex L-1, FRAME', IMG: 'Qmc69ZXZV2ws3Way3uZsR5fz4p33oWrEBoQm6qBLLK2P31', SCHEMA: 'weapon.parts' },
    VOB: { ID: '514768', OG_NAME: 'Part of Level-1 weapon Vortex: ELECTRIC BOARD', NAME: 'Vortex L-1, BOARD', IMG: 'QmSL5JwudsVzJucB1vHiSVgqCsah8QG947j1yCcPyzVm4b', SCHEMA: 'weapon.parts' },
    VOC: { ID: '514766', OG_NAME: 'Part of Level-1 weapon Vortex: CRYSTAL', NAME: 'Vortex L-1, CRYSTAL', IMG: 'QmQ4q2iueGL9vKrK7RW3VyGsXSTUqjuqPrpBYs6M9hGWyP', SCHEMA: 'weapon.parts' },
    // level-2
    MBA: { ID: '625176', OG_NAME: 'Part of Level-2 weapon Muran: BARREL', NAME: 'Muran L-2, BARREL', IMG: 'QmPjk3QgDDMKgbremRjL4GrDzYK7758UrgY3f2Lmo8v4Xz', SCHEMA: 'weapon.parts' },
    MBO: { ID: '625177', OG_NAME: 'Part of Level-2 weapon Muran: BODY', NAME: 'Muran L-2, BODY', IMG: 'QmV2MzRStSSceSSyrQYjVvfqpMrDTFiruRUFGS7aHgHM7G', SCHEMA: 'weapon.parts' },
    MTR: { ID: '625178', OG_NAME: 'Part of Level-2 weapon Muran: TRIGGER', NAME: 'Muran L-2, TRIGGER', IMG: 'QmVFP7XepxZevTHH2UphvwBbqNnZR5Y91KCqekemcq26YY', SCHEMA: 'weapon.parts' },
    TBA: { ID: '625179', OG_NAME: 'Part of Level-2 weapon Theta: BARREL', NAME: 'Theta L-2, BARREL', IMG: 'QmUj1EuhHZHGHRMsQNQVgcZVQXfdUGQNAY1hqfzFM2hw1U', SCHEMA: 'weapon.parts' },
    TBO: { ID: '625180', OG_NAME: 'Part of Level-2 weapon Theta: BODY', NAME: 'Theta L-2, BODY', IMG: 'QmdoDommoxqgpHJpck2hGGrrE8DMxD8DSFmgsbAXeKB7H5', SCHEMA: 'weapon.parts' },
    TTR: { ID: '625181', OG_NAME: 'Part of Level-2 weapon Theta: TRIGGER', NAME: 'Theta L-2, TRIGGER', IMG: 'QmfH3Xr81T4kcAKxBgLauC5rxa5XGo51uc71RRGBULJT7g', SCHEMA: 'weapon.parts' },
    VBA: { ID: '625182', OG_NAME: 'Part of Level-2 weapon Venga: BARREL', NAME: 'Venga L-2, BARREL', IMG: 'QmUC8ofTTEHZcoouXYMJXV54L7FAwMVRpmXxM6Gd57qoaw', SCHEMA: 'weapon.parts' },
    VBO: { ID: '625183', OG_NAME: 'Part of Level-2 weapon Venga: BODY', NAME: 'Venga L-2, BODY', IMG: 'QmQwDsvV8RmYbQo9oTw8UDaTE7HRV8LbBfU5ZyQZsAY6R3', SCHEMA: 'weapon.parts' },
    VTR: { ID: '625184', OG_NAME: 'Part of Level-2 weapon Venga: TRIGGER', NAME: 'Venga L-2, TRIGGER', IMG: 'QmRKm9Tvu1AUu7gzaYKTu2nK4Dhj77cHanhP3L3rEFfXtJ', SCHEMA: 'weapon.parts' },
  },
  WEAPON_SKINS: {
    HL21:  { ID: '617264', OG_NAME: 'Skin for Elektra', NAME: 'Elektra, Halloween22 Skin for Muran', IMG: 'QmUdxDPqnMdQWzY9BBSfA9kogsMJowseYao3ay2LwfK8js', SCHEMA: 'weapon.skins' }, // small
    HL23:  { ID: '617268', OG_NAME: 'Skin for Utopia', NAME: 'Utopia, Halloween22 Skin for Venga', IMG: 'QmTgWY5Lzr12pAK5X5fMZRQYnDESt8SyrpnHZC1VY9iPRR', SCHEMA: 'weapon.skins' }, // large
    HL22:  { ID: '617265', OG_NAME: 'Skin for Justice', NAME: 'Justice, Halloween22 Skin for Theta', IMG: 'Qma2GVagcgSBGCGFgS48p8QcjF3eXzKopfBwiMUPYDuuJD', SCHEMA: 'weapon.skins' }, // sniper
  },
  WEAPON_EXTENSIONS: {
    // L0
    GS: { ID: '445957', OG_NAME: 'Generic Scope', NAME: 'Level-0 Generic Scope', IMG: 'QmXGrCFD87pMvbre6JaCD1yQMYJzy6XmULaSw7Yz8mAx6w', SCHEMA: 'cs.gameitems' },
    // L1
    L1S: { ID: '514789', OG_NAME: 'Generic Scope, LEVEL 1', NAME: 'Level-1 Generic Scope', IMG: 'QmeRAx3W4iWCSXzBrDYx9K1tsEjEfuGrZbj397Jg5k4ZnL', SCHEMA: 'cs.gameitems' },
    // L2
    L2GS: { ID: '625018', OG_NAME: 'Generic Scope, LEVEL 2', NAME: 'Level-2 Generic Scope', IMG: 'QmWEo13jujjsRe6RsKXhZnW5JYarjKxUCGbdu5TJ9JZTC7', SCHEMA: 'cs.gameitems' },
    L2SS: { ID: '625020', OG_NAME: 'Sniper Scope, LEVEL 2', NAME: 'Level-2 Sniper Scope', IMG: 'QmVS2sL9D3iizYv3HdA6qkQk1oKUYFiehxZGPmBrjcoizR', SCHEMA: 'cs.gameitems' },
  },
  TRAINING: {
    ST: { ID: '391902', NAME: 'Shooting Training', IMG: 'QmSrotQtM8GBXGTTb5vhpQt9S4iKZ9KCLDdsWqjycaF5Ky', SCHEMA: 'cs.gameboost' },
    CT: { ID: '391903', NAME: 'Camouflage Training', IMG: 'QmTcuGG8m642kjXAX4jiCsv5UVfeS8ecEuUprkJbuqy77d', SCHEMA: 'cs.gameboost' },
    HWT: { ID: '391904', NAME: 'Heavy Weapons Training', IMG: 'QmV48bNqM7Sg6FnpCxigTdA4eMEspwTmzwrSjSZsMfmVho', SCHEMA: 'cs.gameboost' },
  },
  CHARACTERS: {
    // level-0
    VA: { ID: '417624', OG_NAME: 'Vance Tyrell', NAME: 'Vance, Level-0 common character', IMG: 'QmdZcSvdpkTGZd1ghoXW2a8X37htJG9NNHyvk6Fo5w9s78', SCHEMA: 'cscharacters' },
    MA: { ID: '445637', OG_NAME: 'Masha Talashova', NAME: 'Masha, Level-0 rare character', IMG: 'QmP86Bq9qDyRGWzW8QcZDBKkFJxmLaDkWMWKfvz9z1gZEx', SCHEMA: 'cscharacters' },
    // level-1
    IV: { ID: '514787', OG_NAME: 'Ivan Barrick, LEVEL 1', NAME: 'Ivan, Level-1 common character', IMG: 'QmTrBMBErWiPrNDLTXPN4jogZjKGLZtSyWShQ7U8yuJ7Kh', SCHEMA: 'cscharacters' },
    MI: { ID: '514788', OG_NAME: 'Minta H\'ani, LEVEL 1', NAME: 'Minta, Level-1 epic character', IMG: 'QmQBMPoZ71i9ftaFSqK8oEXSq5bRsHa2qFi9Si12gvFcX1', SCHEMA: 'cscharacters' },
    // level-2
    LEX: { ID: '625048', OG_NAME: 'Lexi Cayne', NAME: 'Lexi, Level-2 common character', IMG: 'QmV5X7y4J2WjYnmx4ukuGKbMCBDdnooaUN4KLNagXAKrpo', SCHEMA: 'cscharacters' },
    EVE: { ID: '625079', OG_NAME: 'Evelyn Moore', NAME: 'Evelyn, Level-2 rare character', IMG: 'QmXFRm258go5fWPJ6YanGUUCdB1o2vQ95D7Uyb2dPq2XDX', SCHEMA: 'cscharacters' },
  },
  ARMORS: {
    // level-0
    VC:  { ID: '445952', OG_NAME: 'Vance\'s SKIN', NAME: 'Vance\'s Armor', IMG: 'Qmcq9Prptnq4yrE1cBviGx7sVHkwo5wWyVthiYkwf39RQd', SCHEMA: 'skins.cschar' },
    VR:  { ID: '445953', OG_NAME: 'Vance\'s Special SKIN', NAME: 'Vance\' Special Armor', IMG: 'QmdCvBg91qMNz7snSVCFDVGtmrd6uzo6SoCzUVSrELuixP', SCHEMA: 'skins.cschar' },
    MR:  { ID: '445954', OG_NAME: 'Masha\'s SKIN', NAME: 'Masha\'s Armor', IMG: 'QmZgfBdUMBef93HGqiMFQruiNhPZhXgWx5kC2anFRffTUW', SCHEMA: 'skins.cschar' },
    MU:  { ID: '445955', OG_NAME: 'Masha\'s special SKIN', NAME: 'Masha\'s Special Armor', IMG: 'QmW3z9N1wUNYNKak2e8wLh1fa1mvnTUS6BYsR1yWhEB3sd', SCHEMA: 'skins.cschar' },
    // level-1
    IVS:  { ID: '514791', OG_NAME: 'Ivan\'s SKILL SKIN', NAME: 'Ivan\'s Armor', IMG: 'QmVinztqvZmh8kkutFWUr5i8NfToCdi6mw6Wa1aykEkNeA', SCHEMA: 'skins.cschar' },
    ISS:  { ID: '514792', OG_NAME: 'Ivan\'s SPECIAL SKILL SKIN', NAME: 'Ivan\'s Special Armor', IMG: 'QmYVXvcdRJiKRj7ctVSMBEaJHEo5ohZmgYJijWbUrFUss7', SCHEMA: 'skins.cschar' },
    MIS:  { ID: '514795', OG_NAME: 'Minta\'s SKILL SKIN', NAME: 'Minta\'s Armor', IMG: 'QmZyGpiDeiDXLBuBmXB4vLELHkX2HcertR35e4MdSVbgoH', SCHEMA: 'skins.cschar' },
    MSS:  { ID: '514796', OG_NAME: 'Minta\'s SPECIAL SKILL SKIN', NAME: 'Minta\'s Special Armor', IMG: 'QmQqLyhzSGYT7mcr6769jtx4Ei6k8SgUiCiSRSvLSHZBUN', SCHEMA: 'skins.cschar' },
    // level-2
    LERS:  { ID: '625169', NAME: 'Lexi\'s armor', IMG: 'QmQuQEogZo1WzsCaftAFYtW9dB6r3QEXGYpSGg1ip35AEG', SCHEMA: 'skins.cschar' },
    LESS:  { ID: '625172', NAME: 'Lexi\'s special armor', IMG: 'QmQ349gwoPy6dzSj1sa387yCgrFF5c6FXmT5T6LMjd4ULs', SCHEMA: 'skins.cschar' },
    EVRS:  { ID: '625173', NAME: 'Evelyn\'s armor', IMG: 'QmW3VcECMuAzCNSTJptPPcjXJmBtt9g4jCsVytSQc7eoD1', SCHEMA: 'skins.cschar' },
    EVSS:  { ID: '625175', NAME: 'Evelyn\'s special armor', IMG: 'QmcEiu33minq6ojrbbHJYp1YrGctXzCztYwGxmeEwj3URP', SCHEMA: 'skins.cschar' },
  },
  SHIELDS: {
    CS: { ID: '403685', OG_NAME: 'Energy Shield', NAME: 'Life Shield (common)', IMG: 'QmVHgk6JHAThNXnyE5ip5N8bPDrfZPxWzifyMChiNHGEmj', SCHEMA: 'cs.gameitems' },
    RS: { ID: '403686', OG_NAME: 'Energy Shield', NAME: 'Life Shield (rare)', IMG: 'QmXsGasmz8cNuY9yfKxLB8QPBBZbg17ni61azBEMUeXZxh', SCHEMA: 'cs.gameitems' },
    ES: { ID: '403687', OG_NAME: 'Energy Shield', NAME: 'Life Shield (epic)', IMG: 'QmQjmVs7DcutidcsnCZs4xbrv2pjrSQxBij43DQzDc5dCb', SCHEMA: 'cs.gameitems' },
  },
  CONDENSERS: {
    CC: { ID: '403681', OG_NAME: 'Energy Condenser', NAME: 'Energy Condendser (common)', IMG: 'QmP3VUWVf9znNJHTrGu2sEdcirL4QNvcQrKdfLyaaN1y3a', SCHEMA: 'cs.gameboost' },
    RC: { ID: '403683', OG_NAME: 'Energy Condenser', NAME: 'Energy Condenser (rare)', IMG: 'QmNb6xxhFeMQe5b7bUQAo5Mo9juxDuNzXx3yHAVZz4QxRr', SCHEMA: 'cs.gameboost' },
    EC: { ID: '403684', OG_NAME: 'Energy Condenser', NAME: 'Energy Condenser (epic)', IMG: 'QmcNUD7Jx1DRXTMpy9yPK1XZV4ESshibikGDiWMKtkB1St', SCHEMA: 'cs.gameboost' },
  },
  EARLY_ACCESS: {
    PA: { ID: '406580', OG_NAME: 'Early-access pack', NAME: 'Early Access Pack', IMG: 'QmP2G5Y7FFLjW1Ja8XB9Aovw2n4vWhh9WRV6DpnfU87tv6', SCHEMA: 'cs.ahnbpacks' },
    TD: { ID: '407511', OG_NAME: 'Teleport Device - alpha access to every new level', NAME: 'Teleport Device', IMG: 'QmSyXTamHh5MfDkdKAU3GV1xUNirCfmzDNvegqDci2K2o4', SCHEMA: 'early.access' },
    SPA: { ID: '407521', OG_NAME: 'Sneak peek access', NAME: 'Sneak-peek Pass', IMG: 'QmRJYZenmrTdwrtbzLGFCBHhRFtG7M3yfmBms7CW4HYzhp', SCHEMA: 'early.access' },
    PAA: { ID: '698515', OG_NAME: 'PVP Alpha Pass', NAME: 'PVP Alpha Pass', IMG: 'QmYsyabo21A7xK7FuXn6U2Xtm2CpNeV1HQ9NRT5wF1LQRp', SCHEMA: 'early.access' },
  },
  BADGES: {
    // 1st edition
    SB: { ID: '393667', NAME: 'Silver Badge', IMG: 'QmbYzum6usauaRbD1pBrzvVx6GjGGP6jvkUgT6vwvAN297', SCHEMA: 'cscommbadges' },
    GB: { ID: '393819', NAME: 'Gold Badge', IMG: 'QmdH1Jo79iDnkFpyRCyLJ7VSF2TzqmVVZavUamrGhAaPd9', SCHEMA: 'cscommbadges' },
    PB: { ID: '393669', NAME: 'Platinum Badge', IMG: 'QmVSdYkM1MEaYgaNA9j5aP1V3QfJifJdim2csVnpA1gubR', SCHEMA: 'cscommbadges' },
    // 2nd edition
    SB2: { ID: '515413', OG_NAME: 'Silver badge - 2nd edition', NAME: 'Silver Badge (2nd ed.)', IMG: 'QmbkHrHjo85uo6R1eY8fe4dG2oh3Dikoxe1hJRGuGByXZD', SCHEMA: 'cscommbadges' },
    GB2: { ID: '515416', OG_NAME: 'Gold badge - 2nd edition', NAME: 'Gold Badge (2nd ed.)', IMG: 'Qme47Yu7TsJkcxufQ5VxUk2FZPJiRT3VaHT3yo3cbzHKKs', SCHEMA: 'cscommbadges' },
    PB2: { ID: '515417', OG_NAME: 'Platinum badge - 2nd edition', NAME: 'Platinum Badge (2nd ed.)', IMG: 'QmRfpfNKLEMqLGKHw3JHVQGqhK4egiR6VqQaCtrUVs5cpT', SCHEMA: 'cscommbadges' },
    DB: { ID: '526002', OG_NAME: 'Diamond badge', NAME: 'Diamond Badge', IMG: 'QmVz2PsseDyGwdDchYE5NrjoqKc8UrPMarUFbEECZjPWi7', SCHEMA: 'cscommbadges' },
    // ANNIVERSARIES
    YE1: { ID: '613105', OG_NAME: 'Anniversary Badge - Meteorite', NAME: 'BETA SURVIVOR - 1yr Badge', IMG: 'QmP8eR9pQ4wUDGurUkp8rDPorBvEAz4gApLqzfhP5gpSY1', SCHEMA: 'cscommbadges' },
  },
  EPIC_COMPANIONS: {
    TP: { ID: '409161', NAME: 'Teknopolly', IMG: 'QmUbfH6YePkL9AGqvVgdBSx6QdnEg6hQnhhLNHR13NxQAi', SCHEMA: 'cs.epicchars' },
  },
  COINS: {
    BC: { ID: '445961', OG_NAME: 'Marble Coin', NAME: 'Marble Coin', IMG: 'QmaXd9LxRuh7BfZh9RceRqgCvjoCHBpoqjV9RivpG4yHbe', SCHEMA: 'cs.goldcoins' },
    SC: { ID: '445960', OG_NAME: 'Copper Coin', NAME: 'Copper Coin', IMG: 'QmPzi5MFqpS3Ke1SotAvApAV9UVCdcK14xR94QVL8qSrvb', SCHEMA: 'cs.goldcoins' },
    GC: { ID: '403692', OG_NAME: 'Gold coin - 50 BOOM', NAME: 'Gold Coin', IMG: 'QmbPxUdRfSG2pprjvfk76dBQM93nssztk8EMxiB99i8yGh', SCHEMA: 'cs.goldcoins' },
    PC: { ID: '407524', OG_NAME: 'Platinum coin - 100 BOOM', NAME: 'Platinum Coin', IMG: 'QmWynwYjJy4u6NVvt1A7BRhUertdPYg2AdWcy2853xwDze', SCHEMA: 'cs.goldcoins' },
    DC: { ID: '445959', OG_NAME: 'Diamond Coin - 1000 BOOM', NAME: 'Diamond Coin', IMG: 'QmVmVbUUBK92KVNpJWkfmUBLuZ16s9QnUUhaeuwWxvnRou', SCHEMA: 'cs.goldcoins' },
  },
  SCHOLARSHIPS: {
    STU:  { ID: '511098', OG_NAME: 'SCHOLAR - Crypto Shots', NAME: 'Scholar', IMG: 'QmWJN6fejBRz7exu24KhQRwRiydKcXoP9WLQ9c72nKi5JP', SCHEMA: 'scholarships' },
    TEA:  { ID: '511097', OG_NAME: 'TEACHER - Crypto Shots', NAME: 'Teacher', IMG: 'QmPtjAN9MR7ibbV373Bt3Y8znBy7RSi2YWKSzjRawMrUdr', SCHEMA: 'scholarships' },
  },
  MAP_PASSES: {
    L0: { ID: '531345', OG_NAME: 'Level-0 Trophy', NAME: 'Level-0 Pass', IMG: 'QmPjfK4f4XgvQTnqbLVJ1KzbeDA718cPo7ts533Vyuj9FA', SCHEMA: 'level.unlock' },
  },
  BOARDING_PASSES: {
    // level-0
    COR: { ID: '607749', OG_NAME: 'SPACESHIP PASS - FREE use of CORDOBA', NAME: 'FREE travels on Cordoba to Level-0 ($GAS)', IMG: 'QmWsa1i2Ya8qLZm6T7aX1w2PGdcJdzTijapLEvgeLRSEyF', SCHEMA: 'csspaceships' },
    FD5: { ID: '607747', OG_NAME: '50% DISCOUNT on FOOD - PASS', NAME: '50% Discount on FOOD', IMG: 'QmSD5mnjtnDvdhryiEUU9YLcdwJoYJzjCrWfxjJ3ikKtDn', SCHEMA: 'csspaceships' },
    FD9: { ID: '607748', OG_NAME: '90 % DISCOUNT on FOOD - PASS 😱', NAME: '90% Discount on FOOD', IMG: 'QmSD5mnjtnDvdhryiEUU9YLcdwJoYJzjCrWfxjJ3ikKtDn', SCHEMA: 'csspaceships' },
    // level-1
    SEQ:{ ID: '515111', OG_NAME: 'SPACESHIP PASS - FREE use of SEQUEIRA', NAME: 'FREE travels to Level-1 on Sequeira', IMG: 'QmZU5cLgY72iRmdAWLkofAStJoigf4xR65aWVsMMNaJwfr', SCHEMA: 'csspaceships' },
    FIR: { ID: '515121', OG_NAME: 'SPACESHIP PASS - FREE use of FIREFLY', NAME: 'FREE travels to Level-1 on Firefly', IMG: 'QmYcd5GUA1nTFtwFcQDZaDB5jFX4hxVTRChHvqTAxYbM8P', SCHEMA: 'csspaceships' },
    EXE: { ID: '516877', OG_NAME: 'SPACESHIP PASS - FREE use of EXETER', NAME: 'FREE travels to Level-1 on Exeter', IMG: 'QmU3JSVJXF9nd3nFMBwt6ncwAh9EAHf3HCBNvLtatzoYyZ', SCHEMA: 'csspaceships' },
    // level-2
    GEN: { ID: '607758', OG_NAME: 'SPACESHIP PASS - FREE use of GENESIS', NAME: 'FREE travels to Level-2 on Genesis', IMG: 'QmSHw6WTvuDgmHkt6Fn4SFhWY9rkZ9cTzszLgsUWsTUhbX', SCHEMA: 'csspaceships' },
    ART: { ID: '607753', OG_NAME: 'SPACESHIP PASS - FREE use of ARTHAS', NAME: 'FREE travels to Level-2 on Arthas', IMG: 'QmPhWa97rbPH9iMEzXcSHG8Ly8ZcLLFLmxEM72suXoSDNc', SCHEMA: 'csspaceships' },
    EXA: { ID: '607751', OG_NAME: 'SPACESHIP PASS - FREE use of EXARCH', NAME: 'FREE travels to Level-2 on Exarch', IMG: 'QmRMar39dHdxWHCvdCZJedFqrne6ehbk9edRwvhmz9sCKv', SCHEMA: 'csspaceships' },
  },
  SPACESHIPS: {
    // level-0
    COR:  { ID: '536514', OG_NAME: 'Cordoba - ENTIRE SPACESHIP', NAME: 'Lev0 - CORDOBA, Whole Spaceship', IMG: 'QmW8su2e4pfo9Tc41tndS4bo5G39nnLbyfY6eLHJN1DmmC', SCHEMA: 'csspaceships' },
    // level-1
    ESE:  { ID: '485411', OG_NAME: 'Sequeira - ENTIRE SPACESHIP', NAME: 'Lev1 - SEQUEIRA, Whole Spaceship', IMG: 'QmW66mSVZTJmb63Zu8EekZrTLtc1UWk1oVF5Zodnbb82rJ', SCHEMA: 'csspaceships' },
    ERA:  { ID: '485524', OG_NAME: 'Exeter - PART: Radar', NAME: 'Lev1 - Exeter, RADAR', IMG: 'QmdXJE1PUSpWLxEgVEFGbJSFRJFc9p5xukLsKGKhud4mYD', SCHEMA: 'csspaceships' },
    ELW:  { ID: '485529', OG_NAME: 'Exeter - PART: Left Wing', NAME: 'Lev1 - Exeter, LEFT WING', IMG: 'QmNt6r3uVvvZ5mnrYQgFHoKMAnkggdXck8aAbXLePMiKLE', SCHEMA: 'csspaceships' },
    ERW:  { ID: '485533', OG_NAME: 'Exeter - PART: Right Wing', NAME: 'Lev1 - Exeter, RIGHT WING', IMG: 'QmVh5LsaYqhFoX22fftbXckZS9uqRLoyRixsb5L3wxWU2X', SCHEMA: 'csspaceships' },
    ECR:  { ID: '485538', OG_NAME: 'Exeter - PART: Control Room', NAME: 'Lev1 - Exeter, CONTROL ROOM', IMG: 'QmdUtTrAmGEmBzxDb1ThKCmFBUfvXm5QH76GRBdrXZCQv7', SCHEMA: 'csspaceships' },
    EFU:  { ID: '485542', OG_NAME: 'Exeter - PART: Fuselage', NAME: 'Lev1 - Exeter, FUSELAGE', IMG: 'QmQgBp4Nf4SGNJ9LQzjAAoCV6HRoidf1h46biXmN3Yz1cF', SCHEMA: 'csspaceships' },
    EEN:  { ID: '485548', OG_NAME: 'Exeter - PART: Engine', NAME: 'Lev1 - Exeter, ENGINE', IMG: 'QmaLqEcCM7q3b8JrnHEELw6SNTuxeuELUzSs1cWSZw3gw9', SCHEMA: 'csspaceships' },
    FLW:  { ID: '485478', OG_NAME: 'Firefly - PART: Radar', NAME: 'Lev1 - Firefly, LEFT WING', IMG: 'QmcwAdES8o7KVEViaTxBpged7geZjfmjroXJivDXCFYrNU', SCHEMA: 'csspaceships' },
    FRW:  { ID: '485499', OG_NAME: 'Firefly - PART: Left Wing', NAME: 'Lev1 - Firefly, RIGHT WING', IMG: 'Qmek2pwP4zuutVTQJKSpaL6xpM8VqLUYd8qGdCRchRe4p1', SCHEMA: 'csspaceships' },
    FRA:  { ID: '485480', OG_NAME: 'Firefly - PART: Right Wing', NAME: 'Lev1 - Firefly, RADAR', IMG: 'QmNXzRe6GLFTPfA9vqYGoM44w2ukXYeBpr3uPhVqYFahtX', SCHEMA: 'csspaceships' },
    FCR:  { ID: '485505', OG_NAME: 'Firefly - PART: Control Room', NAME: 'Lev1 - Firefly, CONTROL ROOM', IMG: 'QmPXZ3Me3jm6wjnpqcqcTPb3pF99CxZmoRsSf9SrZP5rEh', SCHEMA: 'csspaceships' },
    FFU:  { ID: '485514', OG_NAME: 'Firefly - PART: Fuselage', NAME: 'Lev1 - Firefly, FUSELAGE', IMG: 'QmV2mnXTfUaBHVfLqPG3rtJ1RYfAKBhUV5Xy4ucvJfqMab', SCHEMA: 'csspaceships' },
    FEN:  { ID: '485521', OG_NAME: 'Firefly - PART: Engine', NAME: 'Lev1 - Firefly, ENGINE', IMG: 'QmdFNNiz4Sr7i17DYRe5axVs4JgKxkNXF8GYR5QRsS5YDx', SCHEMA: 'csspaceships' },
    // level-2
    EXRA: { ID: '607738', OG_NAME: 'EXARCH - PART: Radar', NAME: 'Lev2 - Exarch, Radar', IMG: 'QmR9WA5VFFDsVcuyYvGvHi3jkJb9MEFRBPBDJb95VDYCWx', SCHEMA: 'csspaceships' },
    EXLW: { ID: '607739', OG_NAME: 'EXARCH - PART: Left Wing', NAME: 'Lev2 - Exarch, Left Wing', IMG: 'QmRxDAAVdtLtUR5oNC6YMPwNkygBRbSJ3SoNdh2SwLoDrd', SCHEMA: 'csspaceships' },
    EXRW: { ID: '607740', OG_NAME: 'EXARCH - PART: Right Wing', NAME: 'Lev2 - Exarch, Right Wing', IMG: 'QmXH9KBKkTs2m22R4wqxGficVGAVcTu4Goz4GQgPaTxoP9', SCHEMA: 'csspaceships' },
    EXCR: { ID: '607741', OG_NAME: 'EXARCH - PART: Control Room', NAME: 'Lev2 - Exarch, Control Room', IMG: 'QmdTBt4eK8jgkFxnqj6wiShqMjG4ALg9gTtG9TPoVBVZhW', SCHEMA: 'csspaceships' },
    EXFU: { ID: '607742', OG_NAME: 'EXARCH - PART: Fuselage', NAME: 'Lev2 - Exarch, Fuselage', IMG: 'QmUTEmRMJ8PrqHtaU6oAw6LwvfRrkwrRxU8s51NiSGfEGo', SCHEMA: 'csspaceships' },
    EXEN: { ID: '607743', OG_NAME: 'EXARCH - PART: Engine', NAME: 'Lev2 - Exarch, Engine', IMG: 'QmTdRYjdeauuZWv9ZNwaCVAvZxv3XR3KmknnVPzNoCxeSW', SCHEMA: 'csspaceships' },

    ARA: { ID: '607732', OG_NAME: 'ARTHAS - PART: Radar', NAME: 'Lev2 - Arthas, Radar', IMG: 'QmXc9bDGiy6kZBgRxBHanNMwyeVrB6r9WiZuy2D2GaEfSd', SCHEMA: 'csspaceships' },
    ALW: { ID: '607733', OG_NAME: 'ARTHAS - PART: Left Wing', NAME: 'Lev2 - Arthas, Left Wing', IMG: 'QmYj5ZnBeuPQQvxC6h7V7RUCcmxNBxH8ZZsRKqCxMoF6TT', SCHEMA: 'csspaceships' },
    ARW: { ID: '607734', OG_NAME: 'ARTHAS - PART: Right Wing', NAME: 'Lev2 - Arthas, Right Wing', IMG: 'QmQcbyJQxGjC6rR7kvAReqhmmGgFEvyWNqZFDsY4L7nWhQ', SCHEMA: 'csspaceships' },
    ACR: { ID: '607735', OG_NAME: 'ARTHAS - PART: Control Room', NAME: 'Lev2 - Arthas, Control Room', IMG: 'QmSrugTHYSMHhAezVtenyLWrYqiStgStRCH7Aw4dR5mEtj', SCHEMA: 'csspaceships' },
    AFU: { ID: '607736', OG_NAME: 'ARTHAS - PART: Fuselage', NAME: 'Lev2 - Arthas, Fuselage', IMG: 'QmdJFGwYYrytdA2TpKhVzy1xVkMuGd9TVbQMFpegyneHkW', SCHEMA: 'csspaceships' },
    AEN: { ID: '607737', OG_NAME: 'ARTHAS - PART: Engine', NAME: 'Lev2 - Arthas, Engine', IMG: 'QmbjYW9fJEpwTkaqyoZT63NSMKoSGdxcJWhtAvWtyLQiNH', SCHEMA: 'csspaceships' },

    GRA: { ID: '607731', OG_NAME: 'GENESIS - PART: Radar', NAME: 'Lev2 - Genesis, Radar', IMG: 'QmUFAjALMHhKUhQfLxBgorqLGZRBhhy5onhuFPwEtTorpG', SCHEMA: 'csspaceships' },
    GLW: { ID: '644472', OG_NAME: 'GENESIS - PART: Left Wing', NAME: 'Lev2 - Genesis, Left Wing', IMG: 'QmV27CidPcVCFvpZRDScnoYAr7CmHsQFgfjzVtd5ZExBod', SCHEMA: 'csspaceships' },
    GRW: { ID: '607728', OG_NAME: 'GENESIS - PART: Right Wing', NAME: 'Lev2 - Genesis, Right Wing', IMG: 'QmQcbyJQxGjC6rR7kvAReqhmmGgFEvyWNqZFDsY4L7nWhQ', SCHEMA: 'csspaceships' },
    GCR: { ID: '607727', OG_NAME: 'GENESIS - PART: Control Room', NAME: 'Lev2 - Genesis, Control Room', IMG: 'QmSc9DMgxU7Qe9EnkDuYZiUmnfEK9tHprayqAvUAsSuVho', SCHEMA: 'csspaceships' },
    GFU: { ID: '607726', OG_NAME: 'GENESIS - PART: Fuselage', NAME: 'Lev2 - Genesis, Fuselage', IMG: 'QmNoaCd5htJUBc7n5Ru2zW349k58DKTCSTX76dvvD4bp4t', SCHEMA: 'csspaceships' },
    GEN: { ID: '607729', OG_NAME: 'GENESIS - PART: Engine', NAME: 'Lev2 - Genesis, Engine', IMG: 'QmNpTNBgHHgxSmW5bGcn1LqvVxB5py9WWPbDJVvTsE7eeH', SCHEMA: 'csspaceships' },
  },
  MAP_PARTS: {
    MP1: { ID: '542945', OG_NAME: 'Level-0, Map Part #1', NAME: 'LAND - Map Part #1', IMG: 'QmVj7BToS9DKjbLPF3VwunMDUUF7sedTeFEER8TPnWWEHC', SCHEMA: 'land.maps' },
    MP2: { ID: '542946', OG_NAME: 'Level-0, Map Part #2', NAME: 'LAND - Map Part #2', IMG: 'QmfK4bNQaVYvYQ13yiU4JJa5ZxfnssvfTLFn5Yi4WwiWLM', SCHEMA: 'land.maps' },
    MP3: { ID: '542962', OG_NAME: 'Level-0, Map Part #3', NAME: 'LAND - Map Part #3', IMG: 'QmXAhGGSzvxV7BmJDvZTUciY3eZjDthmhLz7yUsp2zgCEz', SCHEMA: 'land.maps' },
    MP4: { ID: '542948', OG_NAME: 'Level-0, Map Part #4', NAME: 'LAND - Map Part #4', IMG: 'QmNkE7FvK9SLS5MNqknrSvw1ePoNLrYXdRiGxAcrc17fXf', SCHEMA: 'land.maps' },
    MP5: { ID: '542950', OG_NAME: 'Level-0, Map Part #5', NAME: 'LAND - Map Part #5', IMG: 'QmSjcdFwRZds9bKuAKSc2csmuqtJdeZWCpCiQfR9PM93Nf', SCHEMA: 'land.maps' },
    MP6: { ID: '542952', OG_NAME: 'Level-0, Map Part #6', NAME: 'LAND - Map Part #6', IMG: 'QmTSK7kxrq6S42isdgbpg7QJpdzWX7YDVxhAvL6XZN8kVP', SCHEMA: 'land.maps' },
    MP7: { ID: '616765', OG_NAME: 'Level-0, Map Part #7', NAME: 'LAND - Map Part #7', IMG: 'QmQrvJswwHLodiUWdW84xXC8pDbfUB8k2k1XpHxuzx5cAn', SCHEMA: 'land.maps' },
    MP8: { ID: '542954', OG_NAME: 'Level-0, Map Part #8', NAME: 'LAND - Map Part #8', IMG: 'QmR6cLUvzNpMXu9SM5kJhRa6sv4kUWsk7xYGU1Ac2HAtyV', SCHEMA: 'land.maps' },
    MP9: { ID: '542958', OG_NAME: 'Level-0, Map Part #9', NAME: 'LAND - Map Part #9', IMG: 'QmQJd1npqgt8Kstxvvr5Z2vqCJNbQKw9CwDj57nvQTdwDq', SCHEMA: 'land.maps' },
    MP10: { ID: '542959', OG_NAME: 'Level-0, Map Part #10', NAME: 'LAND - Map Part #10', IMG: 'QmWBnxtqLQVXR5rxQ39hM4UYHmvm91euriDQXgs7ivPsWQ', SCHEMA: 'land.maps' },
    MP11: { ID: '607776', OG_NAME: 'Level-0, Map Part #11', NAME: 'LAND - Map Part #11', IMG: 'QmZcw6Se6j7JipfDhLFTY7JKUgx1YKkWz2LiR4UhEbcDiZ', SCHEMA: 'land.maps' },
    MP12: { ID: '607779', OG_NAME: 'Level-0, Map Part #12', NAME: 'LAND - Map Part #12', IMG: 'QmNyDcUgzfnzUj9fXRBvxDGzYzRjAtZFyD7yGyXY9bY1As', SCHEMA: 'land.maps' },
    MP13: { ID: '607780', OG_NAME: 'Level-0, Map Part #13', NAME: 'LAND - Map Part #13', IMG: 'QmPKsZEHLgqf2RRzV7SjsbpmDrEgx9Fy6vUbEYwA2TxXnk', SCHEMA: 'land.maps' },
    MP14: { ID: '607781', OG_NAME: 'Level-0, Map Part #14', NAME: 'LAND - Map Part #14', IMG: 'QmfPqQAgAMpYYsa9PcBW2PCZPxGKqvCejRB8RuueB7Qfn5', SCHEMA: 'land.maps' },
    MP15: { ID: '607782', OG_NAME: 'Level-0, Map Part #15', NAME: 'LAND - Map Part #15', IMG: 'QmfBXuqAvNxrHzmbiSQcKxwz7zyKKhWVaR1QWqhQkSvRXp', SCHEMA: 'land.maps' },
    MP16: { ID: '607783', OG_NAME: 'Level-0, Map Part #16', NAME: 'LAND - Map Part #16', IMG: 'QmcJXvahmLz5qqzpfRax9yQ6bU6joZXoj5EUerL2DD7jYK', SCHEMA: 'land.maps' },
    MP17: { ID: '607784', OG_NAME: 'Level-0, Map Part #17', NAME: 'LAND - Map Part #17', IMG: 'QmcVjbnNQVruvg5V1PZKoj8hi58VGhHyVvSgzCvL6dPKzt', SCHEMA: 'land.maps' },
    MP18: { ID: '607785', OG_NAME: 'Level-0, Map Part #18', NAME: 'LAND - Map Part #18', IMG: 'QmNbbdm5iZ72388GZQ6jRDMFwU8VsRNEQfUKdTK5LRHWsm', SCHEMA: 'land.maps' },
    MP19: { ID: '607787', OG_NAME: 'Level-0, Map Part #19', NAME: 'LAND - Map Part #19', IMG: 'QmVUsNwBHUzUjba8vcmU9N6grJUgQDFj4ZFqf8sAhcUNNZ', SCHEMA: 'land.maps' },
    MP20: { ID: '607788', OG_NAME: 'Level-0, Map Part #20', NAME: 'LAND - Map Part #20', IMG: 'QmYDMapniJHpk4QuH9cPzQqn1JRbJDBvJf7bAuo8GF52aH', SCHEMA: 'land.maps' },
  },
  OTHERS: {
    XYZ: { ID: 'xyz', NAME: 'n/a', IMG: '' },
  },
};

// Set immutable global
window.items = ITEMS_BY_CATEGORY;
windowSetChecksum({ key: 'items', value: ITEMS_BY_CATEGORY }); // @@ wtf
try {
  const original = window.items;
  Object.defineProperty(window, 'items', { value: original, writable: false });
} catch (_) {}


const categoriesByTemplateId = {
  // '365485': 'WEAPONS',
  // '385492': 'WEAPONS', ...
  // '403680': 'ADVANCED_PACK', ...
};

const prepCategoriesByItem = () => {
  const allCategories = Object.keys(ITEMS_BY_CATEGORY);
  allCategories.forEach((categoryName) => {
    const templateIds = Object.values(ITEMS_BY_CATEGORY[categoryName]).map(el => el.ID);
    templateIds.forEach(templateId => { categoriesByTemplateId[templateId] = categoryName });
  });
  isTestnet && console.log('<><><> Reverse index result:', categoriesByTemplateId);
  return categoriesByTemplateId;
};

export const CATEGORIES_BY_ITEM = prepCategoriesByItem();

// [{ ID: '123', etc }, { ID: '456', etc }, ...]
export const ALL_NFTS_FLAT = [];
Object.values(ITEMS_BY_CATEGORY).forEach(categoryGroup => ALL_NFTS_FLAT.push(...Object.values(categoryGroup)))


// ## HIVE

const {
  // ## ALL-LEVELS
  MYTHIC_AMMO, LEGENDARY_AMMO, // ammo
  // ## LEVEL-0
  MYTHIC_PACK, STARTER_PACK, // packs
  BASE_GUN, LASER_RIFLE, LASER_SHOTGUN, // weapons
  VANCE_TYRRELL, MASHA_TALASHOVA, // chars
  // ## LEVEL-1
  LEV_1_PACK_COMMON, LEV_2_PACK_EPIC, // packs
  SAVAGE, TREMOR, VORTEX, // weapons
  IVAN_BARRICK, MINTA_HANI, // chars
  FORCE_FIELD, // shield
  // ## LEVEL-2
  LEV_2_PACK_COMMON, LEV_2_PACK_RARE, // packs
  MURAN, THETA, VENGA, // weapons
  LEXI_CAYNE, EVELYN_MOORE, // chars
  FORCE_FIELD_L2, // shield
  PVP_ALPHA_PASS, // early access
} = HIVE_TEMPLATES;

export const HIVE_ITEMS_BY_CATEGORY = {
  AMMO: {
    MYTHIC_AMMO: { SCHEMA: MYTHIC_AMMO.schema, NAME: MYTHIC_AMMO.name },
    LEGENDARY_AMMO: { SCHEMA: LEGENDARY_AMMO.schema, NAME: LEGENDARY_AMMO.name },
  },
  SHIELDS: {
    L1_SHIELD: { SCHEMA: FORCE_FIELD.schema, NAME: FORCE_FIELD.name },
    L2_SHIELD: { SCHEMA: FORCE_FIELD_L2.schema, NAME: FORCE_FIELD_L2.name },
  },
  WEAPONS: {
    // L0
    BASE_GUN: { SCHEMA: BASE_GUN.schema, NAME: BASE_GUN.name },
    LASER_RIFLE: { SCHEMA: LASER_RIFLE.schema, NAME: LASER_RIFLE.name },
    LASER_SHOTGUN: { SCHEMA: LASER_SHOTGUN.schema, NAME: LASER_SHOTGUN.name },
    // L1
    SAVAGE: { SCHEMA: SAVAGE.schema, NAME: SAVAGE.name },
    TREMOR: { SCHEMA: TREMOR.schema, NAME: TREMOR.name },
    VORTEX: { SCHEMA: VORTEX.schema, NAME: VORTEX.name },
    // L2
    MURAN: { SCHEMA: MURAN.schema, NAME: MURAN.name },
    THETA: { SCHEMA: THETA.schema, NAME: THETA.name },
    VENGA: { SCHEMA: VENGA.schema, NAME: VENGA.name },
  },
  CHARACTERS: {
    // L0
    VANCE_TYRRELL: { SCHEMA: VANCE_TYRRELL.schema, NAME: VANCE_TYRRELL.name },
    MASHA_TALASHOVA: { SCHEMA: MASHA_TALASHOVA.schema, NAME: MASHA_TALASHOVA.name },
    // L1
    IVAN_BARRICK: { SCHEMA: IVAN_BARRICK.schema, NAME: IVAN_BARRICK.name },
    MINTA_HANI: { SCHEMA: MINTA_HANI.schema, NAME: MINTA_HANI.name },
    // L2
    LEXI_CAYNE: { SCHEMA: LEXI_CAYNE.schema, NAME: LEXI_CAYNE.name },
    EVELYN_MOORE: { SCHEMA: EVELYN_MOORE.schema, NAME: EVELYN_MOORE.name },
  },
  PACKS: {
    // L0
    MYTHIC_PACK: { SCHEMA: MYTHIC_PACK.schema, NAME: MYTHIC_PACK.name },
    STARTER_PACK: { SCHEMA: STARTER_PACK.schema, NAME: STARTER_PACK.name },
    // L1
    LEV_1_PACK_COMMON: { SCHEMA: LEV_1_PACK_COMMON.schema, NAME: LEV_1_PACK_COMMON.name },
    LEV_2_PACK_EPIC: { SCHEMA: LEV_2_PACK_EPIC.schema, NAME: LEV_2_PACK_EPIC.name },
    // L2
    LEV_2_PACK_COMMON: { SCHEMA: LEV_2_PACK_COMMON.schema, NAME: LEV_2_PACK_COMMON.name },
    LEV_2_PACK_RARE: { SCHEMA: LEV_2_PACK_RARE.schema, NAME: LEV_2_PACK_RARE.name },
  },
  EARLY_ACCESS: {
    PVP_ALPHA_PASS: { SCHEMA: PVP_ALPHA_PASS.schema, NAME: PVP_ALPHA_PASS.name },
  }
};
window.hiveNftsInfo = HIVE_ITEMS_BY_CATEGORY;
windowSetChecksum({ key: 'hiveNftsInfo', value: HIVE_ITEMS_BY_CATEGORY });

// USAGE: getNftCount({ hiveNFts = [], name: window.hiveNftsInfo.PACKS.MYTHIC_PACK.NAME });
try {
  const original = window.hiveNftsInfo;
  Object.defineProperty(window, 'hiveNftsInfo', { value: original, writable: false });
} catch (_) {}
