import React from "react";
import { Button, Table } from "react-bootstrap";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  countPerLoad,
  LEADERBOARD_ADMINS,
  MAX_USERS_CHART,
} from "../../../config/leaderboardConfig";
import { useState } from "react";
import { useEffect } from "react";
import { PacmanLoader } from "react-spinners";
import { styleOverride } from "../styleOverride";
import { toUpper } from "lodash";


const ProfileLink = ({ name, chain }) => <a
    href={`https://${chain === 'HIVE' ? 'peakd.com/@' : 'wax.bloks.io/account/'}${name}`}
    target="_blank" rel="noreferrer"
  >
    {name}
  </a>;

export default function LeaderboardTable({ loading, dataList = [], title, category='', token='boom' }) {
  const UserState = useSelector((store) => store.user);

  const checkIsAdmin = uname => LEADERBOARD_ADMINS.includes(uname);

  const sliceSize = checkIsAdmin(UserState.name) ? 100000 : countPerLoad;
  const maxUsers = username => checkIsAdmin(username) ? 100000 : MAX_USERS_CHART;
  const isLogged = UserState.isLogged;

  const [showCount, setShowCount] = useState(sliceSize);
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    setShowList(dataList.slice(0, showCount));
  }, [dataList, showCount]);

  const getYourRankings = ({ data, uname }) => {
    const unchartedMsg = (`Not in the first ${maxUsers(uname)}, keep grinding!`);
    const yourRanking =
      data.findIndex((item = {}) => item.account === uname) + 1 || unchartedMsg;
    return {
      yourRanking,
    };
  };

  const { yourRanking } = getYourRankings({
    data: showList,
    uname: UserState.name,
  });

  const handleLoadClick = () => {
    setShowCount(showCount + countPerLoad);
  };

  const getChain = (account) => {
    let isHive = account.includes('hive::');
    return isHive ? 'HIVE' : 'WAX';
  }

  const getAccountName = (account) => {
    let isHive = account.includes('hive::');
    return isHive ? account.replace('hive::','') : account;
  }

  function renderTitle(title) {
    switch (title) {
      case "totalKillsData0":
        return "Kills";
      case "totalKillsData1":
        return "Kills";
      case "totalKillsData2":
        return "Kills";
      case "boomSavingData":
        return "In-game Balance";
      case "marsSavingData":
          return "In-game Balance";
      case "boomClaimedData":
        return "BOOM";
      case "marsClaimedData":
          return "MARS";
      case "bestTimeData":
        return "Best Time [S]";
      case "playedTimeData":
        return "Played Time [HS]";
      case "Recent Stats":
        if (category === 'bestTime') return "Best Time [S]";
        else if(category === 'kills') return 'Kills';
        else if(category === 'staked') return `${toUpper(token)} Staked`;
        break;
      default:
        break;
    }
  }

  function renderValue(title, item = {}) {
    switch (title) {
      case "totalKillsData0":
        return item.amount + " kills";
      case "totalKillsData1":
        return item.amount + " kills";
      case "totalKillsData2":
          return item.amount + " kills";
      case "boomSavingData":
        return item.amount.toFixed(4) + " igBOOM";
      case "marsSavingData":
          return item.amount.toFixed(4) + " igMARS";
      case "boomClaimedData":
        return item.amount.toFixed(4) + " BOOM";
      case "marsClaimedData":
          return item.amount.toFixed(4) + " MARS";
      case "bestTimeData":
        return (item.time/1000).toFixed(2) + " S";
      case "playedTimeData":
        return (item.time/1000).toFixed(2) + " HS";
      case "Recent Stats":
        if (category === 'bestTime') return (item.time/1000).toFixed(2) + " S";
        else if(category === 'kills') return `${item.amount} kills`;
        else if(category === 'staked') return `${item.amount?.toFixed(4)} ${token.toUpperCase()}`;
        break;
      default:
        break;
    }
  }

  return (
    <>
      {isLogged && showList.length > 0 && (
        <p
          className="text-white text-center your-position pad10"
          style={{ fontSize: "18px", minHeight: "30px" }}
        >
          <span
            style={{
              color: "red",
              fontWeight: "bold",
            }}
          >
            Your Position: <span>{yourRanking}</span>
          </span>
        </p>
      )}
      {loading && (
        <div className="col-12">
          <PacmanLoader
            color={"#fff"}
            loading={loading}
            css={styleOverride}
            size={30}
            margin={2}
          />
          {/* {(!loading && !boomSavingData.length) && <h5 className='text-white text-center'>There is no Leaderboard data.</h5>} */}
        </div>
      )}

      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player's Name</th>
            <th>{renderTitle(title)}</th>
            <th>Player's Chain</th>
            <th>Lender <sub>(if any)</sub></th>
          </tr>
        </thead>
        <tbody>
          {
            showList.map((item = {}, index) => {
              const name = getAccountName(item.account);
              const chain = getChain(item.account);
              return (
                <tr key={index} className={classNames(index < 5 && "top-ranked")}>
                  <td>{index + 1}</td>
                  <td><ProfileLink {...{ name, chain }} /></td>
                  <td>{renderValue(title, item)}</td>
                  <td>{chain}</td>
                  <td>{item.teacher ?? ''}</td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
      {showCount < dataList.length && (
        <>
          <div className="col-12 text-center">
            <Button onClick={handleLoadClick}>Load more</Button>
          </div>
        </>
      )}
    </>
  );
}
