
export const OXY_RECHARGE_SECONDS = 60;
export const LIFE_RECHARGE_PC = 50;

export const RESOURCES_COST = {
  AMMO: {
    MARS: 6,
    BOOM: 3, // L2
  },
  OXY: {
    MARS: 4,
    BOOM: 2, // L2
  },
  LIFE: {
    MARS: 4,
    BOOM: 2, // L2
  },
};
