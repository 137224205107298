export const StorageInstructions = () => {
  // styling must be here
  return (
    <div
      id="storage-instructions"
      style={{ border: 'thin solid rgba(227,218,81,0.2)', padding: '10px', fontSize: '16px' }}
    >
      <p>
        CLASSIC: data saved in cache for single browser.
        Saves your Travels and Preferences.
      </p>
      <p>
        PREMIUM: data saved across any browser/machine.<br />
        All the CLASSIC benefits plus Ammo reset of 2nd most powerful weapon before the boss.
      </p>
    </div>
  );
};

export const FullScreenDismisser = ({ setStorageOverlayOpen }) => (
  <div id="full-screen-overlay" onClick={() => setStorageOverlayOpen(false)}></div>
);

export const CloseOverlayBtn = ({ setStorageOverlayOpen }) => {
  return (<div
    onClick={() => setStorageOverlayOpen(false)}
    id="close-storage-overlay"
  >
    X
  </div>);
};
