import { HIVE_TEMPLATES } from "../../../../constants/hiveNfts";

// # HIVE

const MIN_MINTS = {
  // ## NFT Packs
  // 1st level
  [HIVE_TEMPLATES['MYTHIC_PACK'].name]: 0, // amount: 80
  [HIVE_TEMPLATES['STARTER_PACK'].name]: 80, // 300
  // 2nd level
  [HIVE_TEMPLATES['LEV_1_PACK_COMMON'].name]: 380, // 150
  [HIVE_TEMPLATES['LEV_2_PACK_EPIC'].name]: 530, // 50
  // 3rd level
  [HIVE_TEMPLATES['LEV_2_PACK_COMMON'].name]: 580, // 150
  [HIVE_TEMPLATES['LEV_2_PACK_RARE'].name]: 730, // 50
  

  // ## Gaming Assets

  // 1st level
  [HIVE_TEMPLATES.FORCE_FIELD.name]: 0, // amount: 501 // 2nd level
  [HIVE_TEMPLATES.MYTHIC_AMMO.name]: 501, // +80 =
  [HIVE_TEMPLATES.LEGENDARY_AMMO.name]: 581, // 300 +20
  [HIVE_TEMPLATES.BASE_GUN.name]: 901, // 540
  [HIVE_TEMPLATES.LASER_RIFLE.name]: 1441, // 380
  [HIVE_TEMPLATES.LASER_SHOTGUN.name]: 1821, // 380
  [HIVE_TEMPLATES.VANCE_TYRRELL.name]: 2201, // 460
  [HIVE_TEMPLATES.MASHA_TALASHOVA.name]: 2661, // 380
  // NOTE: 3042-3076 taken by broken NFTs
  'BROKEN': 3041, // 34

  // HALLOWEEN SKINS (PVP):
  [HIVE_TEMPLATES.ELEKTRA_L2_SKIN.name]: 3076, // 15 (Muran - common)
  [HIVE_TEMPLATES.JUSTICE_L2_SKIN.name]: 3091, // 10 (Venga - epic)
  [HIVE_TEMPLATES.UTOPIA_L2_SKIN.name]: 3101, // 5 (Theta - rare)

  // 3rd lev shield
  [HIVE_TEMPLATES.FORCE_FIELD_L2.name]: 3106, // 150

  // 2nd level items
  [HIVE_TEMPLATES.SAVAGE.name]: 3256, // 300
  [HIVE_TEMPLATES.TREMOR.name]: 3556, // 250
  [HIVE_TEMPLATES.VORTEX.name]: 3806, // 200
  [HIVE_TEMPLATES.IVAN_BARRICK.name]: 4006, // 400
  [HIVE_TEMPLATES.MINTA_HANI.name]: 4406, // 50

  // 3rd level items
  [HIVE_TEMPLATES.MURAN.name]: 4456, // 300
  [HIVE_TEMPLATES.THETA.name]: 4756, // 250
  [HIVE_TEMPLATES.VENGA.name]: 5006, // 200
  [HIVE_TEMPLATES.LEXI_CAYNE.name]: 5206, // 250
  [HIVE_TEMPLATES.EVELYN_MOORE.name]: 5456, // 200

  // // CYBERGIRLS
  [HIVE_TEMPLATES.KIARA_JANUARY.name]: 5656, // 20
  [HIVE_TEMPLATES.KIARA_FEBRUARY.name]: 5676, // 20
  [HIVE_TEMPLATES.KIARA_MARCH.name]: 5696, // 20
  [HIVE_TEMPLATES.KIARA_APRIL.name]: 5716, // 20
  [HIVE_TEMPLATES.KIARA_MAY.name]: 5736, // 20
  [HIVE_TEMPLATES.KIARA_JUNE.name]: 5756, // 20
  [HIVE_TEMPLATES.KIARA_JULY.name]: 5776, // 20
  [HIVE_TEMPLATES.KIARA_AUGUST.name]: 5796, // 20
  [HIVE_TEMPLATES.KARINA_JANUARY.name]: 5816, // 20
  [HIVE_TEMPLATES.KARINA_FEBRUARY.name]: 5836, // 20
  [HIVE_TEMPLATES.KARINA_MARCH.name]: 5856, // 20
  [HIVE_TEMPLATES.KARINA_APRIL.name]: 5876, // 20
  [HIVE_TEMPLATES.KARINA_MAY.name]: 5896, // 20
  [HIVE_TEMPLATES.KARINA_JUNE.name]: 5916, // 20
  [HIVE_TEMPLATES.KARINA_JULY.name]: 5936, // 20
  [HIVE_TEMPLATES.KARINA_AUGUST.name]: 5956, // 20
  // // Incorrect images:
  // // [HIVE_TEMPLATES.MARTE_JANUARY.name]: 5976, // 20
  // // [HIVE_TEMPLATES.MARTE_FEBRUARY.name]: 5996, // 20
  // // [HIVE_TEMPLATES.MARTE_MARCH.name]: 6016, // 20
  [HIVE_TEMPLATES.MARTE_APRIL.name]: 6036, // 20
  [HIVE_TEMPLATES.MARTE_MAY.name]: 6056, // 20
  [HIVE_TEMPLATES.MARTE_JUNE.name]: 6076, // 20
  [HIVE_TEMPLATES.MARTE_JULY.name]: 6096, // 20
  [HIVE_TEMPLATES.MARTE_AUGUST.name]: 6116, // 20
  // Fixes for broken 3 above:
  [HIVE_TEMPLATES.MARTE_JANUARY.name]: 6136, // 20
  [HIVE_TEMPLATES.MARTE_FEBRUARY.name]: 6156, // 20
  [HIVE_TEMPLATES.MARTE_MARCH.name]: 6176, // 20
  // PS. new cybergirls
  [HIVE_TEMPLATES.KIARA_SEPTEMBER.name]: 6396, // 20
  [HIVE_TEMPLATES.KIARA_OCTOBER.name]: 6416, // 20
  [HIVE_TEMPLATES.KIARA_NOVEMBER.name]: 6436, // 20
  [HIVE_TEMPLATES.KIARA_DECEMBER.name]: 6456, // 20
  [HIVE_TEMPLATES.KARINA_SEPTEMBER.name]: 6476, // 20
  [HIVE_TEMPLATES.KARINA_OCTOBER.name]: 6496, // 20
  [HIVE_TEMPLATES.KARINA_NOVEMBER.name]: 6516, // 20
  [HIVE_TEMPLATES.KARINA_DECEMBER.name]: 6536, // 20
  [HIVE_TEMPLATES.MARTE_SEPTEMBER.name]: 6556, // 20
  [HIVE_TEMPLATES.MARTE_OCTOBER.name]: 6576, // 20
  [HIVE_TEMPLATES.MARTE_NOVEMBER.name]: 6596, // 20
  [HIVE_TEMPLATES.MARTE_DECEMBER.name]: 6616, // 20

  // PVP PASS
  [HIVE_TEMPLATES.PVP_ALPHA_PASS.name]: 6196, // 50++  --> 6396

  [HIVE_TEMPLATES.FORCE_FIELD_L0.name]: 6636, // 500
  [HIVE_TEMPLATES.TORCH.name]: 7136, // 160
};
// NOTE: the MIN MINT is actually +1 from the bottom above

export const fixMintNumber = ({ name, mint }) => {
  const minMint = MIN_MINTS[name];
  if (minMint) return mint - minMint;
  return mint;
};
