import { get } from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import hive from '@hiveio/hive-js';

import { ADMINS, COMMUNITY_MANAGERS, MODS } from '../../config/accounts';
import { isLocal } from '../../constants/envs';
import { nap } from '../../generalUtils/utils';
import { storeAppDispatch } from '../../GlobalState/Store';
import { client } from '../../helpers/client';
import {
  setFailedUsersEndSchool, setFailedUsersRank,
  setUiMessageRank, setUiMessageSchool,
} from '../../reducers/AdminPaneReducer';
import {
  generateChecksum, cleanUserList, cleanTransfersList,
  ENTRIES_SEPARATOR, BOOM_AMOUNT_SEPARATOR, TRANSFER_PARTIES_SEPARATOR,
} from './utils';
import './AdminPane.scss';
import { notifyOfRankReset, notifyOfStakeTransfer } from '../../services/monitoring/discordNotifications';


const sendRankResetRequest = async ({ currentUser, me }) => {
  let player = currentUser.replace('@', '');
  const checksum = generateChecksum({ account: me, player });
  const userChain = currentUser.startsWith('@') ? 'hive' : 'wax';
  const payload = { account: me, player, checksum, userChain };
  isLocal && console.debug('Sending rank reset api request with payload:', payload);
  try {
    const resetResult = await client.post('/api/user/rank-reset', payload);
    isLocal && console.debug('Rank reset result:', resetResult);
    return ({ resetResult });
  } catch (err) {
    console.error('Caught error for rank reset api request:', err);
    return ({ err });
  }
};

const sendSavingsTransferRequest = async ({ account, scholar, teacher, amount, tokenInitial }) => {
  const payload = { account, scholar, teacher, amount, tokenInitial };
  isLocal && console.debug('Sending savings transfer api request with payload:', payload);
  try {
    const transferResult = await client.post('/api/admin/end-of-school', payload);
    isLocal && console.debug('Savings transfer result:', transferResult);
    return ({ transferResult });
  } catch (err) {
    console.error('Caught error for savings transfer api request:', err);
    return ({ err });
  }
};


export const AdminPane = () => {
  const UserState = useSelector((store) => store.user);
  const isSuperAdmin = ADMINS.includes(UserState.name);
  const isTrustedDelegate = COMMUNITY_MANAGERS.includes(UserState.name);
  const isMod = MODS.includes(UserState.name);
  
  const [usersToRankReset, setUsersToRankReset] = useState(''); // input text ranks
  const rankResetUsersArr = cleanUserList(usersToRankReset);
  const [savingsTransfers, setSavingsTransfer] = useState(''); // input text school
  const savingTransfersList = cleanTransfersList(savingsTransfers);
  
  const AdminState = useSelector((store) => store.adminPane);
  const {
    messageUiRank, failedUsersRankArr,
    messageUiSchool, failedUsersEndSchoolArr,
  } = AdminState;
  

  const resetUsers = async () => {
    // validation
    let valid = true;
    rankResetUsersArr.forEach((currentUser = '') => {
      if (currentUser.startsWith('@')) {
        const error = hive.utils.validateAccountName(currentUser.replace('@', ''));
        if (error) {
          storeAppDispatch(setUiMessageRank({ msg: `Incorrect Hive user ${currentUser}: ${error}. Execution stopped.` }));
          valid = false;
        }
      }
      else if (currentUser.length < 5 || currentUser.length > 12) {
        storeAppDispatch(setUiMessageRank({ msg: `Incorrect Wax user ${currentUser}: wrong length. Execution stopped.` }));
        valid = false;
      }
    });
    if (!valid) return;
    // api calls
    const errors = [];
    const toNotify = [];
    for (let id = 0; id < rankResetUsersArr.length; id++) {
      let currentUser = rankResetUsersArr[id];
      isLocal && console.debug(`Resetting rank for ${currentUser}`);
      storeAppDispatch(setUiMessageRank({ msg: `Resetting rank for ${currentUser}` }));
      let err, resp = {};
      try {
        resp = await sendRankResetRequest({ currentUser, me: UserState.name });
        err = resp.err;
        if (!err) toNotify.push(currentUser);
        isLocal && console.debug('Rank reset response:', { resp });
      } catch (caughtErr) {
        console.error('Failed to rank reset:', { caughtErr, resp: resp.resetResult });
        err = `Caught: ${caughtErr}`;
      }
      if (err) {
        const errMsg = JSON.stringify(get(err, 'response.data.error') || err);
        errors.push({ id, currentUser, err: errMsg });
      }
      await nap(1000);
    }
    if (toNotify.length) {
      notifyOfRankReset({ resetter: UserState.name, accountsReset: toNotify });
    }
    if (errors.length) {
      isLocal && console.debug(`Dispatching ${errors.length} errors..`);
      storeAppDispatch(setFailedUsersRank({ failedUsersRankArr: errors }));
    } else storeAppDispatch(setUiMessageRank({ msg: '✔️ ✔️ ✔️' }));
  };

  const sendTransfers = async () => {
    // validation
    let valid = true;
    savingTransfersList.forEach((currentTransfer = '', id) => {
      const { sender, receiver, amount, tokenInitial, error } = currentTransfer;
      if (error) {
        storeAppDispatch(setUiMessageSchool({ msg: error }));
        valid = false;
      }
      const invalidSender = sender.startsWith('@')
        ? hive.utils.validateAccountName(sender.replace('@', ''))
        : (sender.length < 5 || sender.length > 12);
      const invalidReceiver = receiver.startsWith('@')
        ? hive.utils.validateAccountName(receiver.replace('@', ''))
        : (receiver.length < 5 || receiver.length > 12);
      const wrongToken = tokenInitial !== 'B' && tokenInitial !== 'M';
      const wrongAmount = isNaN(amount) || amount <=0;
      const transferToSelf = sender === receiver;
      const isInvalidTransfer = invalidSender || invalidReceiver || transferToSelf || wrongToken || wrongAmount;
      if (isInvalidTransfer) {
        console.error('Invalid transfer:', { invalidSender, invalidReceiver, transferToSelf, wrongToken, wrongAmount });
        storeAppDispatch(setUiMessageSchool({ msg: `Invalid tranfer #${id} - fix the issue to start the batch execution` }));
        valid = false;
      }
    });
    if (!valid) return;
    // api calls
    const errors = [];
    const toNotify = [];
    for (let id = 0; id < savingTransfersList.length; id++) {
      const currentTransfer = savingTransfersList[id];
      const { sender, receiver, amount, tokenInitial } = currentTransfer;
      isLocal && console.debug('Sending transfer request for', { currentTransfer });
      storeAppDispatch(setUiMessageSchool({
        msg: `Transfering savings: ${amount} ${tokenInitial} from ${sender} to ${receiver}`,
      }));
      let err, resp = {};
      try {
        resp = await sendSavingsTransferRequest({
          account: UserState.name, scholar: sender, teacher: receiver, amount, tokenInitial,
        });
        err = resp.err;
        if (!err) toNotify.push(JSON.stringify(currentTransfer));
        isLocal && console.debug('Savings transfer response:', { resp });
      } catch (caughtErr) {
        console.error('Failed to transfer savings:', { caughtErr, resp: resp.transferResult });
        err = `Caught: ${caughtErr}`;
      }
      if (err) {
        const errMsg = JSON.stringify(get(err, 'response.data.error') || err);
        errors.push({ id, currentTransfer, err: errMsg });
      }
      await nap(1000);
    }
    if (toNotify.length) {
      notifyOfStakeTransfer({ transferer: UserState.name, transfers: toNotify });
    }
    if (errors.length) {
      isLocal && console.debug(`Dispatching ${errors.length} errors..`);
      storeAppDispatch(setFailedUsersEndSchool({ failedUsersEndSchoolArr: errors }));
    } else storeAppDispatch(setUiMessageSchool({ msg: '✔️ ✔️ ✔️' }));
  };

  const clearLogs = (type) => {
    if (type === 'rank') {
      if (!messageUiRank.length && !failedUsersRankArr.length) return;
      storeAppDispatch(setUiMessageRank());
      storeAppDispatch(setFailedUsersRank());
    } else if (type === 'school') {
      if (!messageUiSchool.length && !failedUsersEndSchoolArr.length) return;
      storeAppDispatch(setUiMessageSchool());
      storeAppDispatch(setFailedUsersEndSchool());
    }
  }

  return (
    <div className="container admin-panel">
      <div id="username" className="col-12 text-center">
        <p className="text-white">Welcome {isSuperAdmin ? 'super-admin' : 'mod'} {UserState.name}</p>
      </div>
      <div className="row text-white">
        <div className="col-6 pad">
          {
            (isSuperAdmin || isMod) && <div>
              <h2 className="section-title">RANK RESET</h2><br/>
              <div className="row pad">
                <div className="col-12">
                  <label>
                    Enter a list of addresses to Rank Reset and separate them with "{ENTRIES_SEPARATOR}" (comma).
                    <br/>Start Hive accounts with @ (snail symbol).
                  </label>
                  <br/><br/>
                  <input
                    className='form-control'
                    placeholder='Enter addresses separated by comma. Start Hive accounts with @.'
                    value={usersToRankReset}
                    onChange={(e) => setUsersToRankReset(e.target.value)}
                  /><br/>
                  ADDRESSES READY TO RESET: &nbsp; {rankResetUsersArr.length}<br/><br/>
                  <button
                    onClick={resetUsers}
                    className={rankResetUsersArr.length ? undefined : 'disabled'}
                  >
                    <b>START RESET</b>
                  </button> &nbsp; &nbsp; &nbsp;
                  <button
                    onClick={() => clearLogs('rank')}
                    className={messageUiRank.length ? undefined : 'disabled'}
                  >
                      CLEAR LOGS
                  </button><br/><br/>
                  <ul className="ui-message">
                    {messageUiRank.map((msg) => <li key={msg}>{msg}</li>)}
                  </ul><br/>
                  Server errors: {failedUsersRankArr.length && <ul id="rank-resets-failures">
                    {failedUsersRankArr.map(
                      ({ id, currentUser, err } = {}) => <li key={currentUser}>
                          #{id}: {currentUser} - error: {err}
                        </li>
                    )}
                  </ul>}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="col-6 pad">
          {
            (isSuperAdmin || isTrustedDelegate) && <div>
              <h2 className="section-title">STAKE TRANSFER</h2><br/>
              <div className="row pad">
                <div className="col-12">
                  <label>
                    Enter a list of transfers in this format:<br/>
                    <i>0.1_B{BOOM_AMOUNT_SEPARATOR}crypto4shots{TRANSFER_PARTIES_SEPARATOR
                    }crypto5shots,0.02_M{BOOM_AMOUNT_SEPARATOR}crypto5shots{TRANSFER_PARTIES_SEPARATOR}@cribbio</i>
                    <br/><br/>Note: start Hive usernames with @ (snail symbol).
                  </label>
                  <br/><br/>
                  <input
                    className='form-control'
                    placeholder='Enter igToken amount, sender and receiver'
                    value={savingsTransfers}
                    onChange={(e) => setSavingsTransfer(e.target.value)}
                  /><br/>
                  TRANSFERS TO EXECUTE: &nbsp; {savingTransfersList.length}<br/><br/>
                  <button
                    onClick={sendTransfers}
                    className={savingTransfersList.length ? undefined : 'disabled'}
                  >
                    <b>START TRANSFERS</b>
                  </button> &nbsp; &nbsp; &nbsp;
                  <button
                    onClick={() => clearLogs('school')}
                    className={messageUiSchool.length ? undefined : 'disabled'}
                  >
                      CLEAR LOGS
                  </button><br/><br/>
                  <ul className="ui-message">
                    {messageUiSchool.map((msg) => <li key={msg}>{msg}</li>)}
                  </ul><br/>
                  Server errors: {failedUsersEndSchoolArr.length && <ul id="transfers-failures">
                    {failedUsersEndSchoolArr.map(
                      ({ id, currentTransfer = {}, err } = {}) => {
                        const { sender, receiver, amount, tokenInitial } = currentTransfer;
                        return <li key={`${sender}-${receiver}`}>
                          #{id}: {amount} {tokenInitial} savings from {sender} to {receiver} - error: {err}
                        </li>
                      }
                    )}
                  </ul>}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
