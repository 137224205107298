import React, { useEffect } from 'react';
import { useState } from 'react';
import { isLocal } from '../../../constants/envs';
import { getPlayerUsername } from '../../../generalUtils/utils';
import { fetchDoomStakedInPool } from '../../../sagas/helpers/wallet/hiveUtils';
import { InfoIcon } from '../../common/InfoIcon';


const POOL_SYMBOL = 'DOOM:SWAP.HIVE';


export const LiquidityPools = ({ whichActiveChain }) => {
  const [poolPlayerBalance = {}, setPoolPlayerBalance] = useState({});
  const { shares, sharesPc, stakedTokenAmount } = poolPlayerBalance;

  useEffect(() => {
    const fetchPoolBalance = async () => {
      const username = getPlayerUsername();
      const { shares, sharesPc, stakedTokenAmount } = await fetchDoomStakedInPool({ username });
      isLocal && console.log('[] pool data:', { shares, stakedTokenAmount });
      shares > 0 && setPoolPlayerBalance({
        shares: shares.toFixed(4),
        sharesPc: sharesPc.toFixed(1),
        stakedTokenAmount: stakedTokenAmount.toFixed(4),
      });
    };
    fetchPoolBalance();
  });

  return (<>{whichActiveChain === 2
    ? <div className="flashy-box text-white row" style={{ margin: '-20px auto' }}>
        <p>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }} className='text-white'>Liquidity Pool</span>
          &nbsp; - &nbsp; Diesel Pool on Hive Engine
          <a href="https://peakd.com/@achim03/what-are-diesel-pools-and-how-they-could-bring-defi-innovation-to-hive" target="_blank" rel="noreferrer">
            <InfoIcon/>
          </a>
        </p>

        <div className="col-6" id="links" style={{ textAlign: 'left', marginLeft: '5%' }}>
          <p>
            ADD LIQUIDITY:<br/>
            <a href="https://tribaldex.com/dieselpools/add" target="_blank" rel="noreferrer">https://tribaldex.com/dieselpools/add</a>
          </p>
          <p className="up10">
            SWAP TOKENS:<br/>
            <a href="https://tribaldex.com/dieselpools/?from=SWAP.HIVE&to=DOOM" target="_blank" rel="noreferrer">https://tribaldex.com/dieselpools?...</a>
          </p>
        </div>

        <div className="col-5">
          {shares
            ? <p style={{ marginTop: '40px' }}>
                Your {POOL_SYMBOL} Diesel Pool balance:<br/>
                <b style={{ color: 'gold' }}>{stakedTokenAmount} DOOM</b> &nbsp; <span style={{ fontSize: '12px' }}>({shares} shares - {sharesPc}%)</span>
              </p>
            : <a href="https://tribaldex.com/dieselpools/pools" target="_blank" rel="noreferrer">
                <img
                  src="https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23tcLdqxA6cZv2oPWL2DxeKSZ2XAbr1S9hpBG4G6P6UjMfTusgd2wF4LS7KgXAATexX5p.png"
                  width="100%" alt="chart"
                />
              </a>}
        </div>
      </div>
    : <div className="flashy-box" style={{ margin: '-20px auto', width: '300px' }}>
      <span style={{ fontSize: '18px' }} className='text-white'>Liquidity Pool</span>
      &nbsp; - &nbsp;
      <a href="https://wax.alcor.exchange/swap?output=BOOM-csboomcsboom&input=WAX-eosio.token" className="mt-3" target="_blank" rel="noreferrer">
        Alcor Pool
      </a>
    </div>}
  </>);
};
