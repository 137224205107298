import React from 'react';

import { maintenanceReason } from '../../../../config/appRemoteStatus';

export const MaintenanceMessage = () => {
  return (<>
    <div
      id="outage-msg"
      style={{ minWidth: '600px', padding: '15px' }}
    >
      <br />
      <h4>
        We are temporarily under maintenance, please come back later. Pew pew!
      </h4>
      <h5>
        <br />
        <b>Reason</b>: &nbsp; {maintenanceReason() || 'system update or infrastructure change in progress'}
      </h5>
    </div>
  </>);
};
