/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Animated } from "react-animated-css";
import { isUnderMaintenance } from "../../config/appRemoteStatus";
import { getBannersConfig } from './utils/bannerAds';
import { invoke } from 'lodash';
import { setFreeTrial } from './utils/freeTrial';
import { isLocal } from '../../constants/envs';
import { SuggestFirefox } from './subcomp/SuggestBrowser';
import { setGameMode } from '../../reducers/PlayReducer';
import { storeAppDispatch } from '../../GlobalState/Store';
import Swal from 'sweetalert2';
import { LogoLanding } from './subcomp/LogoLanding';
import { LandingBanner } from './subcomp/Banners/LandingBanner';
import { MaintenanceMessage } from './Login/Shared/MaintenanceMessage';
import { P2EarnPane } from './subcomp/P2E/P2EarnPane';
import { F2PlayPane } from './subcomp/F2P/F2PlayPane';
import { ICONS } from './config/config';
import { getNavigationGameMode } from '../../generalUtils/browser/urlParams';
import './LandingPage.scss';

const isFirstLoad = !window.localStorage.getItem('cached');


export const LandingPage = () => {
  const UserState = useSelector((store) => store.user);
  const [didPlayTrial, setDidPlayTrial] = useState(false);
  const [choseP2eMode, setChoseP2eMode] = useState(false);

  const bannersDynConfig = getBannersConfig();

  useEffect(() => {
    // Default to P2E menu expanded (they can still click F2P button)
    setExpandedLoginPane('P2E');
    // Shows specific game mode if url param was present
    const gameMode = getNavigationGameMode();
    if (gameMode) {
      if (gameMode === 'pve') handleP2eGamePVE();
      else if (gameMode === 'pvp') handleP2eGamePVP();
    }
    // NOTE: I could also default to specific game mode based on LS lastModePlayed
    // but prefer not to. Allow them to try F2P a few times without forcing P2E on them.
  }, []);

  useEffect(() => {
    if (!UserState.isLogged) return;
    // Hides Ads displayed in loading page
    isLocal && console.debug('Removing banners...');
    const adsLoadPage = document.getElementById('ad-loading-page'); // web wrapper element
    if (adsLoadPage) adsLoadPage.style.display = 'none';
  }, [UserState.isLogged]);


  const loadUnityGame = ({ level, isTrial }) => {
    localStorage.setItem('lastModePlayed', level); // 0 or 'pvp'
    // TODO: in cs-wrapper default to PVP once the builds are split per level (too heavy now)
    invoke(window, 'loadUnity', { level, isTrial });
    // store last choice so that it pre-loads that next time (in cs-wrapper)
  };

  // ## FREE-TO-PLAY
  const handleGameTrialPVE = () => {
    if (!window.unityLoadCompleted) {
      Swal.fire({ text: 'Please wait a sec, the game is still loading behind the scenes!' })
      return;
    }
    // show game
    invoke(window, 'pageShow', 'game');
    // free trial state
    setFreeTrial({ value: true, from: 'free-btn' });
    setDidPlayTrial(true);
    // gameMode in reducer
    storeAppDispatch(setGameMode({ gameMode: 'pve' }));
    // unity loader (redundant if no switch)
    loadUnityGame({ level: 0, isTrial: true });
  }
  const handleGameTrialPVP = () => {
    if (!window.unityLoadCompleted) {
      Swal.fire({ text: `Please wait a sec, the game is still loading behind the scenes!` })
      return;
    }
    // show game
    invoke(window, 'pageShow', 'game');
    // free trial state
    setFreeTrial({ value: true, from: 'free-btn' });
    setDidPlayTrial(true);
    // gameMode in reducer
    storeAppDispatch(setGameMode({ gameMode: 'pvp' }));
    // unity loader
    loadUnityGame({ level: 'pvp', isTrial: true });
    // set free trial random guest username in cs-wrapper
    document.dispatchEvent(new Event('trialUsername'));
  }

  // ## PLAY-TO-EARN
  const handleP2eGamePVE = () => {
    // login ui local state
    setChoseP2eMode(true);
    // gameMode in reducer
    storeAppDispatch(setGameMode({ gameMode: 'pve' }));
    // unity loader (redundant if no switch)
    loadUnityGame({ level: 0, isTrial: false });
  };
  const handleP2eGamePVP = () => {
    // login ui local state
    setChoseP2eMode(true);
    // gameMode in reducer
    storeAppDispatch(setGameMode({ gameMode: 'pvp' }));
    // unity loader
    loadUnityGame({ level: 'pvp', isTrial: false });
  };

  const [expandedLoginPane, setExpandedLoginPane] = useState(null);


  const PlayToEarn = () => {
    return (<>
      <div
        id="p2e-game"
        className={
          `${UserState.isLogged ? "logged" : "not-logged-pane"} container shade landing${choseP2eMode ? '' : ' waiting-choice'
          }`
        }
        style={expandedLoginPane !== 'P2E' ? { width: '140px', float: 'left', marginLeft: '140px' } : {}}
      >
        {!UserState.isLogged && <img
            src={ICONS.P2E}
            alt="EARN-big-title"
            width="100px"
            style={{ float: 'left', borderRadius: '10px', cursor: expandedLoginPane === 'P2E' ? 'none' : 'pointer' }}
            onClick={() => setExpandedLoginPane('P2E')}
          >
          </img>
        }
        <div className="text-center">
          {isUnderMaintenance() && <MaintenanceMessage />}
        </div>

        {/* Travel Into Game While Loading - Animation and Fetches */}
        {!isUnderMaintenance() && expandedLoginPane === 'P2E'
          && <P2EarnPane {...{ didPlayTrial, choseP2eMode, setChoseP2eMode, handleP2eGamePVE, handleP2eGamePVP }} />}
      </div>
    </>);
  };

  const FreeToPlay = () => {
    return (<>
      <div
        id="try-game-free"
        className="container shade landing"
        style={!expandedLoginPane
          ? { width: '140px', float: 'left', paddingTop: '30px', marginTop: '250px', pointerEvents: 'all' }
          : (expandedLoginPane === 'P2E' ? { marginLeft: '300px', paddingTop: '30px', width: '140px', pointerEvents: 'all' } : {})
        }
      >
        <img
          src={ICONS.F2P}
          alt="FREE-big-title"
          width="100px"
          style={{ float: 'left', borderRadius: '10px', cursor: expandedLoginPane === 'F2P' ? 'none' : 'pointer' }}
          onClick={() => setExpandedLoginPane('F2P')}
        >
        </img>
        {/* // not needed for now
          <div className="text-center">
            {isUnderMaintenance() && <MaintenanceMessage />}
          </div>
        */}
        {expandedLoginPane === 'F2P' && <F2PlayPane {...{ handleGameTrialPVE, handleGameTrialPVP }} />}
      </div>
    </>);
  };

  return (
    <div id="landing">
      <br />
      <div>
        {/* Main Logo */}
        <LogoLanding />
        <br />

        {/* Play-To-Earn Pane */}
        <Animated
          animationIn="fadeIn"
          animationOut="slideOutLeft"
          animationInDuration={500}
          animationInDelay={1000}
          animationOutDuration={0}
          isVisible={true}
        >
          <PlayToEarn />
        </Animated>

        {/* Free-To-Play Pane */}
        {!UserState.isLogged && <>
          <Animated
            animationIn="fadeIn"
            animationOut="slideOutLeft"
            animationInDuration={500}
            animationInDelay={1000}
            animationOutDuration={0}
            isVisible={true}
            style={{ pointerEvents: 'none' }}
          >
            <FreeToPlay />
          </Animated>
        </>
        }
      </div>

      {/* Recommended Browser */}
      <SuggestFirefox />

      {/* Our Banner, Browser Suggestion, and Ads */}
      <LandingBanner {...{ isLogged: UserState.isLogged, isFirstLoad, bannersDynConfig }} />
    </div>
  );
};
