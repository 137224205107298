/* eslint-disable no-useless-concat */
import { get } from 'lodash';
import { isClassicStorage } from '../../../../components/Menu/LeftMenu/storage/utils';
import { WAX_ACCOUNTS } from '../../../../config/accounts';
import { TOKEN_ACCOUNT } from '../../../../config/contractNames';
import { isLocal, isTestnet } from '../../../../constants/envs';
import { travelPaymentMemo } from '../../../../generalUtils/paymentMemo';
import { storeAppDispatch } from '../../../../GlobalState/Store';
import { client } from '../../../../helpers/client';
import { setNewPaidResource } from '../../../../reducers/MarsStorage';
import { flyingSessionLogin, UserService, WaxFlyingSession } from '../../../../services/UserService';
import { checkSessionStillActive } from './checkSession';
import { HOURS, MINUTES, TRAVEL_TX_ID_PREFIX } from './constants';
import { SS_PRICES } from "./travelPrices";
import Swal from 'sweetalert2';


// # TRAVEL DURATION

export const LEVEL_ZERO_TRAVEL_DURATION = 10 * 1000;

export const getTravelDuration = ({ spaceshipId, travellingTo = 1 }) => {
  switch (travellingTo) {
    case 1: {
      switch (spaceshipId) {
        case 0: return 0;
        case 1: return /*isTestnet ? (30 * 1000) :*/ (1 * HOURS);
        case 2: return 15 * MINUTES;
        case 3: return 0;
        default: { throw new Error(`TO1 - Unexpected spaceship id ${spaceshipId} in nap duration calc`); }
      }
    }
    case 2: {
      switch (spaceshipId) {
        case 0: return 0;
        case 1: return /*isTestnet ? (30 * 1000) :*/ (1 * HOURS);
        case 2: return 15 * MINUTES;
        case 3: return 0;
        default: { throw new Error(`TO2 - Unexpected spaceship id ${spaceshipId} in nap duration calc`); }
      }
    }
    case 3: {
      console.error('TO3 - Unexpected travel duration fetch:', { spaceshipId, travellingTo });
      return 120 * MINUTES;
    }
    default: { throw new Error(`Unexpected travel duration fetch: ssId ${spaceshipId} to ${travellingTo}`); }
  }
};


// ## COUNTDOWN

const formatCountdown = millis => new Date(millis).toISOString().slice(11,19);

const timezoneStart = new Date().getTimezoneOffset();

const timezoneCheatCheck = () => {
  if (new Date().getTimezoneOffset() !== timezoneStart) {
    Swal.fire({text: 'Tried to cheat during travel. (warning triggered)'})
    window.location.reload();
    throw new Error('tz' + 'ko');
  }
};

const updateUiCounter = ({ counter, spinner }) => {
  const target = document.getElementById('countdown');
  if (target) {
    if (counter) { target.innerHTML = formatCountdown(counter); }
    if (spinner) { target.innerHTML = '<i>click to update</i>'; }
  }
};

let counter;

export const spaceshipTimer = ({ travelDurationMs, unityLoad = true, isPromo }) => new Promise((resolve) => {
  counter = travelDurationMs > 0 ? travelDurationMs : (11 * 1000);
  const invertedDelay = unityLoad ? (10 * 1000) : 0;
  isTestnet && console.debug('Starting timeship counter:', { counter });
  updateUiCounter({ counter });
  const ts = setInterval(() => {
    counter -= 1000;
    if (counter <= invertedDelay) {
      isTestnet && console.debug(`Resolving ${invertedDelay}s prior to actual clearInterval`, { counter });
      resolve();
    }
    if (counter <= 0) {
      isTestnet && console.debug('Stopping timer internally');
      clearInterval(ts);
    }
    updateUiCounter({ counter });
    Math.random() > 0.9 && timezoneCheatCheck();
  }, 1000);
});

let inactiveStart;
let spaceshipCounterCache;

const inactiveTab = () => {
  inactiveStart = Date.now();
  spaceshipCounterCache = counter;
  updateUiCounter({ spinner: true });
};

const activeTab = () => {
  const inactiveDuration = inactiveStart && (Date.now() - inactiveStart);
  if (!inactiveDuration) return;
  const MINUTE = 60 * 1000;
  if (inactiveDuration > (MINUTE * 10)
    && (spaceshipCounterCache - counter) < (MINUTE * 5)) {
    // tab inactive for more than 10 min and counter update was less than 5 min
    const timeToRemove = inactiveDuration - (spaceshipCounterCache - counter);
    isLocal && console.debug(`Counter stopped more than X seconds. Decreasing counter ${counter}`,
      { timeToRemove, spaceshipCounterCache, counter, inactiveDuration });
    counter -= timeToRemove;
  }
  inactiveStart = null;
  spaceshipCounterCache = null;
};

const enablePageFocusListeners = () => {
  document.addEventListener('visibilitychange', (event) => {
    if (document.visibilityState === 'visible') {
      activeTab();
    } else {
      inactiveTab();
    }
  });
  // window.onfocus = () => console.log('I have focus now');
  // window.onblur = () => console.log('I lost focus now');
};
enablePageFocusListeners(); 


// ## PAYMENTS

export const getFormattedPrice = ({ currency, price, charactersCount, forUI }) => {
  switch (currency) {
    case 'W': return `${price.toFixed(forUI ? 2 : 8)} WAX`;
    case 'B': return `${price.toFixed(forUI ? 2 : 4)} BOOM`;
    // case 'F': return `${price.toFixed(forUI ? 2 : 4)} FOOD`;
    case 'G': return `${(charactersCount * price).toFixed(forUI ? 2 : 4)} GAS`; // All GAS payments double for scholars
    case 'M': return `${price.toFixed(forUI ? 2 : 4)} MARS`;
    default: { throw new Error(`Unexpected currency ${currency} for price ${price}`); }
  }
}

// Used for all spaceship payments. NO FOOD, that uses sendFoodPayment.
export const payForSpaceshipTravel = ({
  spaceshipId, currency, charactersCount = 0, activeLevel, storageType,
}) => new Promise(async (resolve) => {
  const index = spaceshipId + (activeLevel * 3); // L1: 1,2,3 -- L2: 1+3,2+3,3+3 -- L3: 1+6,2+6,3+6
  const price = SS_PRICES[`SS${index}`][currency];
  if (!price) { throw new Error(`Unexpected input for travel payment: ${spaceshipId} ${currency}`); }
  const quantity = getFormattedPrice({ currency, price, charactersCount });
  const contractName = currency === 'W' ? 'eosio.token' : TOKEN_ACCOUNT;
  const destinationLevel = activeLevel + (spaceshipId === 0 ? 0 : 1);
  const targetWallet = WAX_ACCOUNTS.SPACESHIPS[index];
  const memo = travelPaymentMemo({ activeLevel: destinationLevel, spaceshipId, currency, payer: UserService.authName, quantity });
  isLocal && console.debug('spaceship payment:', { charactersCount, price, quantity });


  let waxSession = UserService;
  const isHiveUser = localStorage.getItem('chainLogin') !== 'wax';
  if (isHiveUser && !UserService.authName) {
    isLocal && console.debug('creating side session for wax tokens payment for hive user');
    waxSession = WaxFlyingSession;
    const isLoggedIn = await flyingSessionLogin(waxSession);
    if (!isLoggedIn) return resolve({ err: 'still not logged in for wax tokens payment' });
  }
  isLocal && console.debug('Expected user for payment:', waxSession.authName);

  const { shouldStop } = await checkSessionStillActive();
  if (shouldStop) return;

  waxSession.session.signTransaction(
      {
        actions: [{
          account: contractName,
          name: 'transfer',
          authorization: [{
            actor: waxSession.authName,
            permission: 'active'
          }],
          data: {
            from: waxSession.authName,
            to: targetWallet,
            quantity,
            memo,
          }
        }]
      },
      { blocksBehind: 3, expireSeconds: 30 },
    )
    .then(async (response = { status: '' }) => {
      isLocal && console.log('Transaction outcome:', { response });
      if (response.status !== 'executed') {
        console.error('Spaceship travel payment failed:', JSON.stringify({ response }));
        Swal.fire({text: `Spaceship travel payment failed.\n\n${JSON.stringify(response.status)}`})
        resolve({ err: (response.status || 'ko') });
        return;
      }
      // # UPDATE LOCAL STATE (paid resources counter in storage popup)
      storeAppDispatch(setNewPaidResource({ paidResource: { type: 'travel' } }));
      // ## STORE IN LS
      const txHash = response.transactionId;
      localStorage.setItem(`${TRAVEL_TX_ID_PREFIX}-L${destinationLevel}`, txHash);
      isLocal && console.log('Travel tx put in ls', { payment: txHash, level: destinationLevel });
      // ## STORE IN DB if PREMIUM sub
      if (storageType && !isClassicStorage(storageType)) {
        try {
          const data = await client.post(
            '/api/storage/travels',
            { account: waxSession.authName, travelWaxTx: txHash, level: destinationLevel },
          );
          isLocal && console.log('Store travel result:', { data });
        } catch (err) {
          let errMsg = get(err, 'response.data.error') || 'Unknown error';
          Swal.fire({text: `Uh oh, something went wrong storing your travel in db: ${errMsg}`})
          // resolve({ err }); // dont waste their payment
        }
      }
      resolve({});
    })
    .catch((err) => {
      console.error('Spaceship travel payment went wrong:', JSON.stringify({ err }));
      const errMsg = get(err, 'cause.message');
      const errMsg2 = get(err, 'cause.json.error.details[0].message');
      const errMsg3 = get(err, 'cause.json.message');
      Swal.fire({text: `Spaceship travel payment failed.\n\n${errMsg || errMsg2 || errMsg3 || JSON.stringify(err)}`});
      resolve({ err });
    });
});
