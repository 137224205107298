import { isLocal } from '../../../../../constants/envs';


const cache = {
  cachedResults: {}, // eg. { 'hive,hbd': { ts, data } }
};
const CACHE_DURATION_MINS = 15;


// Free API rate limit 10-30 calls/min
export const coingeckoFetch = async ({ token = '' }) => {
  const tokenLc = token.toLowerCase();
  isLocal && console.log('[gecko] Fetching price of', tokenLc);
  // CACHE CHECK
  const cachedPrice = cache.cachedResults[tokenLc] || {};
  const { data: priceData, ts: lastFetchTs } = cachedPrice;
  if (priceData) {
    const elapsedTime = Date.now() - lastFetchTs;
    if (elapsedTime < (CACHE_DURATION_MINS * 60 * 1000)) {
      isLocal && console.log('[gecko] Returned cached value of', priceData);
      return ({ data: priceData });
    }
    // doing this here so that it returns previous result if new requests come in
    // while this request is still in progress (prevent api throttling)
    cachedPrice.ts = Date.now();
  }
  // REQUEST PREP
  const params = {
    ids: tokenLc,
    vs_currencies: 'usd',
  };
  const tail = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
  isLocal && console.log('[gecko] No cache hit. Sending coingecko price fetch for:', { params });
  // API CALL
  return fetch(
    `https://api.coingecko.com/api/v3/simple/price?${tail}`,
    {
      headers: {
        Accept: 'application/json',
      },
      referrer: 'https://www.coingecko.com/',
    }
  )
    .then(res => res.json())
    .then((data = {}) => {
      isLocal && console.log('[gecko] price data:', { tokenLc, data });
      cache.cachedResults[tokenLc] = { data, ts: Date.now() };
      return ({ data });
    })
    .catch((err) => {
      console.error(`Failed to fetch token price from coingecko: ${err}`);
      return ({ err: 'cg-fetch-ko' });
    });
};


// const test = async () => {
//   const { data } = await coingeckoFetch({ token: 'hive' });
//   console.log('RES:', data.hive.usd);
// };
// const fetch = require('node-fetch');
// test();
