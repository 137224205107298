import countWords from 'count-words';
import { get } from 'lodash';
import { isTestnet } from '../../../constants/envs';
import { ITEMS_BY_CATEGORY } from '../../../constants/templateIds';

import { checkIsNewUser, shouldNewItemBeSkipped } from "./cooldownUtils";


export const groupHotNfts = (hotNfts = []) => {
  const hotNftsStr = hotNfts.map(el => el.replace(/,/g, '').replace(/-/g, '_').replace(/ /g, '_')).join(', ');
  const groupedItems = countWords(hotNftsStr, true); //  { home: 2, sweet: 1 }
  const result = {};
  Object.keys(groupedItems).forEach((item) => {
    result[item.replace(/_/g, ' ')] = groupedItems[item];
  });
  return result;
};

const keywordsOfFaultyNfts = [' cell', ' shield', ' condenser'];
const isDuplicatedName = (name = '') => !!keywordsOfFaultyNfts.find(
  keyword => name.toLowerCase().includes(keyword),
);

export const extractNftsInfo = ({ nftsArr, waxAccountName }) => {
  const isNewUser = checkIsNewUser(waxAccountName);
  const ignoredItems = [];
  const currentAssets = nftsArr.map((item = {}) => {
    const {
      asset_id, name = 'n/a', minted_at_block = -1, template = {}, data = {},
      transferred_at_time, minted_at_time, schema: schemaObj = {},
    } = item;
    if (name === 'n/a') console.debug('==== NO NAME NFT:', { item });
    const { schema_name: schema = '' } = schemaObj;
    const { template_id = -1 } = (template || {});
    const scholarId = ITEMS_BY_CATEGORY.SCHOLARSHIPS.STU.ID;
    const teacherId = ITEMS_BY_CATEGORY.SCHOLARSHIPS.TEA.ID;
    isTestnet && (template_id === scholarId || template_id === teacherId)
      && console.debug('> scholarship metadata:', { item });
    if (shouldNewItemBeSkipped({ asset_id, isNewUser, template_id, transferred_at_time, minted_at_time })) {
      ignoredItems.push(item.name || 'mysterious-nft-with-no-name');
      return null; // then filtered below
    }
    const { img, rarity = 'n/a', customField = '',  } = (data || {});
    const ownersCut = +get(data, 'keeps %') || '';
    const partnersWaxAddr = get(data, 'partner\'s address', '');
    // isLocal && console.debug(`[D].1. ${waxAccountName} NFT found:`, { name, asset_id, template_id });
    const uniqueNameForNft = isDuplicatedName(name) ? `${name} (${rarity})` : name;
    return ({
      asset_id, name: uniqueNameForNft, minted_at_block,
      template_id,
      schema,
      img: img ? `https://gateway.pinata.cloud/ipfs/${img}` : '',
      s3Img: (schema && name)
        ? `https://play.crypto-shots.com/nft-images/wax/${getWaxNftPathS3({ schema, name, template_id })}`
        : '',
      rarity,
      scholarship: partnersWaxAddr && ({ partnersWaxAddr, ownersCut, customField }),
    });
  }).filter(elem => !!elem);
  return ({ currentAssets, ignoredItems });
};


export const getWaxNftPathS3 = ({ schema, name, template_id: tid }) => {
  let fluidName = name;
  // NAME CLASHES WAX:
  // energy cell - eg. Energy_Cell_-_Common.gif
  if (fluidName.includes('Energy Cell')) {
    fluidName = `${fluidName} - ${cellRarity(tid)}`;
  }
  // shield - eg. Energy_Shield_-_Common.gif
  else if (fluidName.includes('Energy Shield')) {
    fluidName = `${fluidName} - ${shieldRarity(tid)}`;
  }
  // condenser - eg. Energy_Condenser_-_Common.gif
  else if (fluidName.includes('Energy Condenser')) {
    fluidName = `${fluidName} - ${condenserdRarity(tid)}`;
  }
  // cyber girls - eg. "KarinaYana_-_GPT_Cybergirl_-_February.gif"
  else if (fluidName.endsWith('GPT Cybergirl')) {
    fluidName = `${fluidName} - ${cyberMonth(tid)}`;
  }
  // SPACES INTO UNDERSCORES
  fluidName = fluidName.replace(/ /g, '_');
  // SPECIAL CHARS : -> _
  fluidName = fluidName.replace(/:/g, '_');
  // , -> _
  fluidName = fluidName.replace(/,/g, '_');
  // % INTO %25
  fluidName = fluidName.replace('%', '%25');
  // # INTO %23
  fluidName = fluidName.replace('#', '%23');
  // ’ into 
  fluidName = fluidName.replace('’', '\'');
  // n/a into undefined
  fluidName = fluidName.replace('n/a', 'undefined');
  // res
  return `${schema}/${fluidName}.gif`;
};


const cellRarity = (tid) => {
  switch (tid) {
    case '403688': return 'Common';
    case '391901': return 'Rare';
    case '403690': return 'Epic';
    default: return '';
  }
};
const shieldRarity = (tid) => {
  switch (tid) {
    case '403685': return 'Common';
    case '403686': return 'Rare';
    case '403687': return 'Epic';
    default: return '';
  }
};
const condenserdRarity = (tid) => {
  switch (tid) {
    case '403681': return 'Common';
    case '403683': return 'Rare';
    case '403684': return 'Epic';
    default: return '';
  }
};


const cyberMonth = (tid) => {
  const { CYBERGIRLS = {} } = ITEMS_BY_CATEGORY;
  const { NAME = '' } = Object.values(CYBERGIRLS)
    .find(({ ID }) => ID === tid) || {};
  const month = NAME.split(' ').pop();
  return month;
};
