import React from 'react';
import { SWAP_RANGES } from './SwapUtils/constants';


export const MinSwapAmounts = ({ chainLogin, amountBoomSwap, amountDoomSwap }) => {
  const isPendingTransfer = !!amountBoomSwap || !!amountDoomSwap;
  if (isPendingTransfer)
    return (<p style={{ lineHeight: '14px', fontSize: '12px', marginTop: '20px' }}>
      Min amount: {SWAP_RANGES[amountBoomSwap ? 'BOOM' : 'DOOM'].MIN} {amountBoomSwap ? 'BOOM' : 'DOOM'}<br />
      Max amount: {SWAP_RANGES[amountBoomSwap ? 'BOOM' : 'DOOM'].MAX} {amountBoomSwap ? 'BOOM' : 'DOOM'}<br />
      Fee: {SWAP_RANGES.FEE * 100} % (burned)
    </p>);

  if (chainLogin === 'hive')
    return (<p style={{ lineHeight: '14px', fontSize: '12px', marginTop: '20px' }}>
      Min amount: {SWAP_RANGES.DOOM.MIN} DOOM<br />
      Max amount: {SWAP_RANGES.DOOM.MAX} DOOM<br />
      Fee: {SWAP_RANGES.FEE * 100} % (burned)
    </p>);

  if (chainLogin === 'wax')
    return (<p style={{ lineHeight: '14px', fontSize: '12px', marginTop: '20px' }}>
      Min amount: {SWAP_RANGES.BOOM.MIN} $BOOM<br />
      Max amount: {SWAP_RANGES.BOOM.MAX} $BOOM<br />
      Fee: {SWAP_RANGES.FEE * 100} % (burned)
    </p>);
};
