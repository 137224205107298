import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { storeAppDispatch } from '../../../../GlobalState/Store';
import { setPlayerLogin, setPlayerLogout } from '../../../../reducers/UserReducer';
import { UserService } from '../../../../services/UserService';
import { useHistory } from 'react-router-dom';
import { verifyAccessAllowed } from '../../../../sagas/helpers/login/verifyAccessAllowed';
import { uiHacks } from '../../../../generalUtils/uiHacks/uiHacks';
import { verifyWaxUser } from '../../../../sagas/helpers/login/verifyUser';
import { processInventory } from '../../../../sagas/helpers/inventory/processInventory';
import { setPlayerInventory } from '../../../../reducers/InventoryReducer';
import { fetchBoomBalance } from '../../../../sagas/helpers/wallet/waxUtils';
import { setPlayerWaxTokensBalances } from '../../../../reducers/WalletReducer';
import { isLocal, isTestnet } from '../../../../constants/envs';
import { blacklistedUsers, multiTabCheckAfterLogin } from '../../../../generalUtils/secure';
import { setFreeTrial } from '../../utils/freeTrial';
import { fetchHiveNfts } from '../../../../sagas/helpers/inventory/hiveNfts';
import { MINIMUM_REQUIREMENTS_INFO } from '../../../../constants/genericConstants';
import { getPlayerUsername } from '../../../../generalUtils/utils';
import { loadStorage } from '../../../../components/Menu/LeftMenu/storage/init/loadStorage';
import { fetchUserDataOnly, setUrlParamApiCalls } from '../../../../helpers/client';
import { LINKS, ICONS } from './config';
import { navigatePostLogin } from '../Shared/utils/navigation';


export const WaxLoginPanel = () => {
  const UserState = useSelector((store) => store.user);
  const InventoryState = useSelector((store) => store.inventory);
  const locationHistory = useHistory();

  // TODO: refactor
  const loginHandler = async () => {
    // # Login status check
    if (!UserService.isLogged()) {
      storeAppDispatch(setPlayerLogout());
      return;
    }

    // # BLACKLIST CHECK
    const { authName } = UserService;
    const blacklistedUserData = blacklistedUsers[authName];
    if (blacklistedUserData) {
      isTestnet && console.log('401 reason: ', blacklistedUserData);
      Swal.fire({ text: `Uh oh, there seems to be a problem with your account.\nPlease reach out to support on Discord.` });
      return;
    } else { isLocal && console.log('u-ok', { blacklistedUsers, u: authName }); }

    // # PUSH LOGGED-IN USER
    storeAppDispatch(setPlayerLogin({ name: authName }));

    // # VERIFY USER IN BACKEND
    const { err: errVerify } = await verifyWaxUser({ username: getPlayerUsername({ UserService, UserState, InventoryState }) });
    if (errVerify) {
      Swal.fire({ text: `Login failure: ${errVerify}` });
      window.location.reload();
      return;
    }

    // # SET CURRENT STATE
    setFreeTrial({ value: false, from: 'login-btn' });
    localStorage.setItem('chainLogin', UserState.chainLogin);

    // # FETCH WAX {INVENTORY}
    isLocal && console.log('{hl} fetching inventory');
    const { err, csNfts, hotNfts, scholarship } = await processInventory({ username: authName });
    if (err) {
      storeAppDispatch(setPlayerLogout());
      return;
    }
    let kitNft;
    const hasNoNfts = csNfts.length === 0 && Object.keys(hotNfts).length === 0;
    if (hasNoNfts) {
      kitNft = { [MINIMUM_REQUIREMENTS_INFO]: '' }; // HACK @@
    }
    // UI next login wait
    localStorage[hasNoNfts ? 'removeItem' : 'setItem']('waxnfts', true);

    // # CROSS-CHAIN {INVENTORY}
    const { linkedAccounts, err: errDataFetch } = await fetchUserDataOnly({ account: authName, chain: UserState.chainLogin });
    isLocal && console.debug('cross-chain account linked:', { linkedAccounts, errDataFetch });
    if (errDataFetch) {
      Swal.fire({ text: `Something went wrong fetching your data. Please try again.` });
      storeAppDispatch(setPlayerLogout());
      return;
    }
    const { hive: linkedHiveAccount, btc: linkedBtcAddress } = linkedAccounts || {};
    let hiveNftsArr = [];
    let teacher = null;
    if (linkedHiveAccount) {
      // FETCH HIVE NFTS
      const inventoryResponse = await fetchHiveNfts({ waxAddr: authName, linkedHiveAccount });
      hiveNftsArr = inventoryResponse.hiveNftsArr;
      teacher = inventoryResponse.teacher;
      // UI next login wait
      localStorage[hiveNftsArr.length ? 'setItem' : 'removeItem']('hivenfts', true);
    }
    const currentState = {
      chainLogin: UserState.chainLogin,
      // wax:
      csAssets: { csNfts, hotNfts: Object.keys(hotNfts).length ? hotNfts : kitNft },
      scholarship,
      // hive:
      hive: {
        nfts: hiveNftsArr, username: linkedHiveAccount, teacher, // linked hive username and nfts
        linkedBtcAddress, // other linked chains (ie. btc only here)
      },
    };
    storeAppDispatch(setPlayerInventory(currentState));

    // # ROUTING
    const hasNfts = csNfts.length + hiveNftsArr.length;
    navigatePostLogin({ locationHistory, hasNfts });

    // # MULTI SESSION CHECKS
    multiTabCheckAfterLogin();

    // # FETCH BALANCES
    isLocal && console.log('{hl} fetching boom balance');
    const boomAmount = await fetchBoomBalance(authName);
    const savingsAmount = await Promise.resolve(0); // TEMP for savings @@ @@ @@ @@
    storeAppDispatch(setPlayerWaxTokensBalances({
      tokens: { boom: boomAmount },
      stakedSavings: { boom: savingsAmount }
    }));

    // # WHITELIST CHECK (TESTNET ONLY)
    isLocal && console.log('{hl} checking whitelist items');
    const missingItem = await verifyAccessAllowed(authName, csNfts); // IMPROVEMENT: just check the NFTs just fetched above !!!
    if (missingItem) {
      const isOk = window.confirm(`${authName} the following item is required to access this version of the game: ${(missingItem || '').replace(/_/g, ' ')}\n\nUnless it's a new mint, keep in mind the 12 hour cooldown.`);
      if (isOk) window.open(LINKS.BUY_PASS, '_blank');
      storeAppDispatch(setPlayerLogout());
      !isLocal && window.location.reload();
    }

    // # LOAD STORAGE 
    loadStorage({ user: authName }); // no await
    isLocal && console.log('{hl} all done');
  };

  const handleLogin = () => {
    uiHacks.chevron();
    UserService.init();
    setUrlParamApiCalls({ chain: UserState.chainLogin });
    UserService.login(loginHandler);
  };

  return (<div>
    <h3 id="login-wrapper" className="text-center">
      <button
        id="login-btn"
        className={`${UserState.acceptedTac ? 'active' : 'disabled'}`}
        disabled={!UserState.acceptedTac}
        onClick={handleLogin}
      >
        <img src={ICONS.LOGIN_DOOR} alt="Loggin" width="24" /> &nbsp;
        LOGIN
      </button>
    </h3>
    <div id="sign-up" className="mt-4 text-center">
      Don't have a WAX account? &nbsp;
      <a
        href={LINKS.WAX_SIGNUP}
        target="_blank"
        rel="noopener noreferrer"
      >
        <sub id="signup-cta">Sign Up</sub>
      </a>
    </div>
  </div>);
};
