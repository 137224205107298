import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMINS } from '../../../config/accounts';
import { isLocal } from '../../../constants/envs';
import { getPlayerUsername } from '../../../generalUtils/utils';
import { linkAccount, unlinkAccount } from '../../../sagas/helpers/login/linking';
import { validateHiveUsername } from '../../../sagas/helpers/wallet/hiveUtils';
import { checkWaxUserExists } from '../../../sagas/helpers/wallet/waxUtils';
import { validateBtcLnAddress } from '../../../sagas/helpers/wallet/btcUtils';
import { linkedBtcAddress } from '../../../reducers/InventoryReducer';
import { storeAppDispatch } from '../../../GlobalState/Store';


const checkValidAddress = async ({ inputAccount, tab: chain }) => {
  if (chain === 'hive') {
    isLocal && console.debug('Validating hive username', inputAccount);
    const { err, caughtErr } = await validateHiveUsername({ username: inputAccount });
    if (err) return ({ invalidReason: err });
    else if (caughtErr) return ({ invalidReason: `Oops, some issues with this Hive node. Try again.` });
  } else if (chain === 'wax') {
    isLocal && console.debug('Validating wax username', inputAccount);
    const exists = await checkWaxUserExists({ account: inputAccount });
    if (!exists) return ({ invalidReason: `the Wax user ${inputAccount} does not exist` });
  } else if (chain === 'btc') {
    isLocal && console.debug('Validating LN address', inputAccount);
    const { valid, reason } = await validateBtcLnAddress({ address: inputAccount });
    return valid ? ({}) : ({ invalidReason: reason });
  }
  else {
    console.error('unexpected chain for username validation');
  }
  return ({});
};


// @@ CHEATS: is this ok??
// @@ 0. doni's concern about people sharing 1 hive account - fixed by blocking re-linking of same account < 36 hs
// @@ 1. player swapping account during the day to double time/rewards (mitigated by multi-acc notification)
// @@ 2. scholarships

export const AccountLinking = ({ tab }) => {
  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;
  const isHiveLogin = chainLogin === 'hive';
  const isHiveUser = tab === 'hive';
  const InventoryState = useSelector((store) => store.inventory);
  const currentAuthUser = getPlayerUsername({ UserState, InventoryState });

  const [actionError, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isSuperAdmin = ADMINS.includes(UserState.name);
  // const isTrustedDelegate = COMMUNITY_MANAGERS.includes(UserState.name);

  const [didSucceed, setDidSucceed] = useState(false);
  const [inputAccount, setInputAccount] = useState('');

  const handleLinking = async () => {
    setIsLoading(true);
    setError('');
    const { invalidReason } = await checkValidAddress({ inputAccount, tab });
    if (invalidReason) {
      setError(invalidReason);
      setIsLoading(false);
      return;
    }

    try {
      const { err } = await linkAccount({ currentAuthUser, inputAccount, tab });
      if (err) {
        setError(typeof err === 'string' ? err : JSON.stringify(err));
        return;
      }
      setDidSucceed(true);
      setInputAccount('');
      // Redux state - NOTE: instead for Wax and Hive we force a re-login to load all NFTs
      if (tab === 'btc') {
        // Set LN Address
        storeAppDispatch(linkedBtcAddress({ lnAddress: inputAccount }));
      }
    } catch (error) {
      console.error('broke:', error);
      setError('Uh oh, something broke.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlinking = async () => {
    setIsLoading(true);
    setError('');
    const { invalidReason } = await checkValidAddress({ inputAccount, tab });
    if (invalidReason) {
      setError(invalidReason);
      setIsLoading(false);
      return;
    }
    try {
      const { err, result, hiveErr } = await unlinkAccount({ currentAuthUser, inputAccount, tab });
      if (err) {
        setError(typeof err === 'string' ? err : JSON.stringify(err));
        return;
      }
      if (result === 'partial') {
        setError(`Dynamo update ok but Hive error: ${hiveErr}`);
        return;
      }
      setDidSucceed(true);
      setInputAccount('');
    } catch (error) {
      console.error('broke:', error);
      setError('Uh oh, something broke.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (ev) => {
    if (actionError) setError('');
    const input = ev.target.value;
    setInputAccount(isHiveUser ? input.replace('@', '') : input);
  }

  return (
    <div className="black-shade instructions-panel" style={{ color: 'white' }}>
      {tab !== 'btc' && <><br /><br /></>}
      <div className="row" style={{ textAlign: 'center' }}>
        {/* TITLE */}
        <div className="col-12"
          style={{ margin: 'auto', marginTop: '20px', fontSize: '24px' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            LINK YOUR {tab.toUpperCase()} {tab === 'btc' ? 'ADDRESS' : 'ACCOUNT'}
            <p style={{ fontSize: '15px', fontStyle: 'italic', marginTop: '10px' }}>(one-time process)</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mt-1 col-12"
          style={{ marginTop: '10px', minHeight: '300px', fontSize: '15px' }}
        >
          <div>
            <div style={{ padding: '20px', margin: 'auto', textAlign: 'center' }}>
              {/* INSTRUCTIONS */}
              <div className="col-12">
                {tab === 'btc'
                  ? <p>LIGHTNING NETWORK address:</p>
                  : <p style={{ fontSize: '18px', marginTop: '20px' }}>
                      Enter your {isHiveUser ? <>Hive username: <sub>&nbsp;(no @)</sub></> : `${tab.toUpperCase()} address:`}
                    </p>
                }
              </div>

              {/* INPUT FIELD */}
              <div className="col-12 mt10">
                <input
                  type="text" style={{ color: 'black', width: tab === 'btc' ? '80%' : '' }}
                  value={inputAccount}
                  onChange={handleInputChange}
                />
              </div>
              {tab !== 'btc' && <><br /><br /></>}

              {/* LINK ACCOUNT */}
              <button
                onClick={handleLinking}
                style={{ float: 'left', padding: '10px', borderRadius: '20px', margin: '20px 35px', color: 'black' }}
              >
                {isLoading
                  ? <i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i>
                  : <>
                      <b>LINK {isHiveUser ? 'HIVE' : tab.toUpperCase()
                        } {tab === 'btc' ? 'address' : 'account'}</b><br />to {isHiveLogin ? '@' : ''}{currentAuthUser}
                    </>}
              </button>

              {/* PUSH SIGN UPs */}
              {tab === 'btc' && <div style={{ float: 'right', margin: '45px 20px', height: '20px', fontWeight: 'bold' }}>
                  <a href="https://lightningaddress.com/#providers" target="_blank" rel="noreferrer">Get a Free<br/>LN Address</a>
                </div>
              }
              {tab === 'hive' && <div style={{ float: 'right', margin: '35px 20px', height: '20px' }}>
                  <a href="https://hiveonboard.com?ref=cryptoshots.nft" target="_blank" rel="noreferrer" style={{ color: 'white', lineHeight: '18px' }}>
                    Create FREE<br />Hive Account
                  </a>
                </div>
              }

              {/* // SUPERADMIN SHOULD ALWAYS TARGET THE MAIN/LOGIN ACCOUNT to reset.
              // - Only crypto4shots can nuke Hive accounts.
              // - Only cryptoshots.nft can nuke Wax accounts. */}
              {(isSuperAdmin && tab !== 'btc') && <button
                  onClick={handleUnlinking}
                  style={{ padding: '10px', borderRadius: '20px', marginTop: '20px', color: 'black' }}
                >
                  {isLoading
                    ? <i class="fa fa-spinner fa-spin" style={{ fontSize: '24px', color: 'red' }}></i>
                    : <>(ADMIN) <b>UNLINK</b> {isHiveUser ? 'HIVE' : tab.toUpperCase()} account</>}
                </button>}
              <div style={{ color: 'yellow', fontWeight: 'bold', float: 'left', margin: 'auto 30px' }}>
                {actionError}
              </div>
              {!isLoading && !actionError && didSucceed
                && <p style={{ color: 'lightgreen', marginTop: '10px', fontWeight: 'bold', float: 'left', margin: 'auto 30px' }}>
                  DONE!! {tab !== 'btc' ? 'Please login again.' : ''}
                </p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
