import { isLocal } from '../../../../../constants/envs';
import { storeAppDispatch } from '../../../../../GlobalState/Store';
import { MEAL_TX_ID_PREFIX, TRAVEL_TX_ID_PREFIX } from '../../../../../pages/Play/subComponents/spaceships/constants';
import { setNewPaidResource, setStorage, setStorageLoading } from '../../../../../reducers/MarsStorage';
import { UserService } from '../../../../../services/UserService';
import { LS_ID_SUBS_TX_HASH } from '../constants';
import { validateSubscription } from './validations/subscriptions';
import { validateResourcePayment } from './validations/resources';
import { getReduxSpaceshipInfo } from '../utils';
import { nap } from '../../../../../generalUtils/utils';
import { client } from '../../../../../helpers/client';
import { get } from 'lodash';
import { windowSetChecksum } from '../../../../../generalUtils/secure/checksums';
import Swal from 'sweetalert2';


export const fetchTxInfo = ({ txHash }) => new Promise(async (resolve) => {
  isLocal && console.debug('Retrieving Tx:', { txHash });
  try {
    const txData = await UserService.session.rpc.history_get_transaction(txHash);
    isLocal && console.debug('Tx data:', { txData });
    const { traces } = txData;
    const infoTransfer = traces.filter((item) => item.act.name === 'transfer');
    const { act = {}, block_time } = infoTransfer[0];
    const { data = {} } = act;
    const { from, to, quantity, memo } = data;
    const [amountTransfered, transferCurrency] = quantity.split(' ');
    const quantityTransfered = parseFloat(amountTransfered);
    const transferTimestamp = `${block_time}Z`;
    resolve({ from, to, memo, transferTimestamp, quantityTransfered, transferCurrency });
  } catch (err) {
    Swal.fire({ text: `Uh oh, something went wrong reading your Wax transactions from the chain in order to activate your storage.\nPlease log in again.\n\n${err}` })
  }
});

const setLocalstorageIfEmpty = (key, value) => {
  const currentValue = localStorage.getItem(key);
  if (!currentValue) {
    localStorage.setItem(key, value);
  }
};

export const writeImmutableStorage = ({ storageType }) => {
  try {
    window.storageType = storageType;
    const original = window.storageType;
    Object.defineProperty(window, 'storageType', { value: original, writable: false });
    // if buys upgrade it will catch below
    windowSetChecksum({ key: 'storageType', value: storageType });
  } catch (_) { }
};

export const loadStorage = async ({ user }) => {
  isLocal && console.log('Checking for existing mars subscriptions..');
  const chainLs = localStorage.getItem('chainLogin');
  if (chainLs === 'hive' || chainLs === 'skl' || chainLs === 'eth') {
    storeAppDispatch(setStorageLoading({ loading: false }));
    return;
    // TEMP -- THEN SUPPORT NEW HIVE TOKENS INSTEAD @@ @@
    // PS. ETH too...
  }
  // FETCH data from DB (was stored only if premium user)
  try {
    const data = await client.get(
      `/api/storage/transactions?account=${user}`,
    );
    isLocal && console.log('={}> Storage transactions:', { data });
    // # Write in LS
    const { subscriptionWaxTx, dailyPurchases = {} } = data?.data?.allTransactions || {};
    const { mealL0WaxTx } = dailyPurchases;
    // Subs
    if (subscriptionWaxTx) setLocalstorageIfEmpty(LS_ID_SUBS_TX_HASH, subscriptionWaxTx);
    // Meals
    if (mealL0WaxTx) setLocalstorageIfEmpty(`${MEAL_TX_ID_PREFIX}-L0`, mealL0WaxTx);
    // Travels
    const travelKeys = Object.keys(dailyPurchases).filter(el => el.startsWith('travelToLev'));
    for (let id = 0; id < travelKeys.length; id++) {
      const currentTravelKey = travelKeys[id];
      const value = dailyPurchases[currentTravelKey];
      const level = currentTravelKey.split('travelToLev')[1];
      setLocalstorageIfEmpty(`${TRAVEL_TX_ID_PREFIX}-L${level}`, value);
    }
  } catch (err) {
    let errMsg = get(err, 'response.data.error') || 'Unknown error';
    console.error(`Uh oh, something went wrong fetching your subs, meals and travels: ${errMsg}`);
    // no return. Eg. new user.
  }

  // # STORAGE SUBSCRIBE TX CHECK
  const txHash = localStorage.getItem(LS_ID_SUBS_TX_HASH);
  if (!txHash) {
    storeAppDispatch(setStorageLoading({ loading: false }));
    return;
  }
  const { invalid, isExpired, storageType, daysLeft } = await validateSubscription({ txHash });
  if (invalid) {
    Swal.fire({ text: `Invalid subscription found! (triggers warning)` })
    storeAppDispatch(setStorageLoading({ loading: false }));
    return;
  } else if (isExpired) {
    storeAppDispatch(setStorage({ storageType: null, expirationInDays: 1 })); // display that it's expired during renewal
    storeAppDispatch(setStorageLoading({ loading: false }));
    localStorage.removeItem(LS_ID_SUBS_TX_HASH);
    return;
  }
  isLocal && console.log('Subscription check completed.');
  storeAppDispatch(setStorage({ storageType, expirationInDays: daysLeft })); // success
  writeImmutableStorage({ storageType });

  // # FOOD PAYMENT CHECK
  const foodTxHash = localStorage.getItem(`${MEAL_TX_ID_PREFIX}-L0`);
  if (foodTxHash) {
    isLocal && console.log('Validating existing food payment');
    const { invalid, isExpired } = await validateResourcePayment({ txHash: foodTxHash });
    if (invalid) Swal.fire({ text: `Invalid food payment found! (triggers warning)` })
    else if (isExpired) localStorage.removeItem(`${MEAL_TX_ID_PREFIX}-L0`);
    else storeAppDispatch(setNewPaidResource({ paidResource: { type: 'meal' } })); // success
  }

  // # SPACESHIP PAYMENTS CHECKS
  const payments = [];
  for (let id = 0; id < 10; id++) {
    const hash = localStorage.getItem(`${TRAVEL_TX_ID_PREFIX}-L${id}`);
    if (hash) payments.push(hash)
  }
  for (let id = 0; id < payments.length; id++) {
    const txHash = payments[id];
    isLocal && console.log('Validating existing travel', id, txHash);
    const { invalid, isExpired, memo, transferTimestamp } = await validateResourcePayment({ txHash });
    if (invalid) Swal.fire({text: `Invalid spaceship travel found! (triggers warning)` })
    else if (isExpired) localStorage.removeItem(`${TRAVEL_TX_ID_PREFIX}-L${id}`);
    else {
      const reduxSpaceshipInfo = getReduxSpaceshipInfo({ memo });
      isLocal && console.debug('Putting spaceship travel in redux:', { reduxSpaceshipInfo });
      storeAppDispatch(setNewPaidResource({
        paidResource: { type: 'travel', transferTimestamp, ...reduxSpaceshipInfo },
      })); // success
    }
    if (id === 0) storeAppDispatch(setStorageLoading({ loading: false })); // can delay others
    await nap(3 * 1000);
  }

  // Reset spinner
  storeAppDispatch(setStorageLoading({ loading: false }));
};
