import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { updateUnityInventory } from '../sagas/helpers/play/unityBridge';

const initialState = {
    // ## WAX
    csAssets: {
      csNfts: [],
      hotNfts: [], // cooldown
    },
    scholarship: {
      // TEACHER
      isTeacher: false,
      scholarName: null,
      teacherCut: 0,
      // SCHOLAR
      isScholar: false,
      teacherName: null,
      scholarCut: 0,
    },
    // ## HIVE
    hive: {
      username: null,
      nfts: [],
      // Scholarship
      teacher: null,
      // Linked Accounts (in theory to import their NFTs)
      linkedWaxAccount: null,
      linkedSkaleAccount: null,
      linkedBtcAddress: null,
    },
    // ## SKALE
    skale: {
      username: null,
      nfs: [],
      // Scholarship
      teacher: null,
      // Linked Accounts (in theory to import their NFTs)
      linkedWaxAccount: null,
      linkedHiveAccount: null,
      linkedBtcAddress: null,
    },
    // ## ETHEREUM
    eth: {
      username: null,
      nfs: [],
      // Scholarship
      teacher: null,
      // Linked Accounts (in theory to import their NFTs)
      linkedWaxAccount: null,
      linkedHiveAccount: null,
      linkedBtcAddress: null,
      linkedSkaleAccount: null,
    },
};

const inventory = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
      setPlayerInventory: (state, action) => {
        const chainLogin = get(action, 'chainLogin');
        // Wax
        const waxPayload = get(action, 'payload.csAssets', {});
        const waxNfts = [...waxPayload.csNfts];
        const shouldRefreshInventory = get(action, 'payload.refresh', false); // here only..
        const scholarship = get(action, 'payload.scholarship', {}); // SCHOLARSHIP !!
        const scholarCut = get(scholarship, 'scholarCut') || undefined;
        // Hive
        const hivePayload = get(action, 'payload.hive', {});
        const hiveNfts = get(hivePayload, 'nfts', []);
        const hasBitcoinAddrLinked = !!hivePayload.linkedBtcAddress;
        // Skale
        const skalePayload = get(action, 'payload.skale', {});
        const skaleNfts = get(skalePayload, 'nfts', []);
        // Ethereum
        const ethPayload = get(action, 'payload.eth', {});
        const ethNfts = get(ethPayload, 'nfts', []);
        // !!!!!!!!!! side effect !!!!!!!!!!!
        updateUnityInventory({
          shouldRefreshInventory,
          csNfts: [waxNfts, hiveNfts, skaleNfts, ethNfts],
          scholarCut,
          chainLogin: hasBitcoinAddrLinked ? 'btc' : chainLogin,
        }); 
        // update redux state
        const newState = { csAssets: waxPayload, hive: hivePayload, skale: skalePayload, eth: ethPayload };
        if (scholarship) { newState.scholarship = scholarship; }
        return ({ ...state, ...newState })
      },
      linkedWaxAccount: (state, action) => {
        const waxAccount = get(action, 'payload.waxAccount', null);
        const csAssets = waxAccount
          ? { ...state.csAssets }
          : { csNfts: [], hotNfts: [] }; // nuke wax NFTs
        return ({
          ...state,
          csAssets,
          hive: { ...state.hive, linkedWaxAccount: waxAccount },
        });
      },
      linkedHiveAccount: (state, action) => {
        const hiveAccount = get(action, 'payload.hiveAccount', null);
        const hive = hiveAccount
          ? { ...state.hive, username: hiveAccount }
          : { ...state.hive, nfts: [], username: null }; // nuke hive NFTs and username
        return ({
          ...state,
          hive,
        });
      },
      linkedBtcAddress: (state, action) => {
        // used to set/update/delete address
        const lnAddress = get(action, 'payload.lnAddress', null);
        return ({
          ...state,
          hive: { ...state.hive, linkedBtcAddress: lnAddress },
        });
      },
    }
});

export const {
  setPlayerInventory,
  linkedWaxAccount,
  linkedHiveAccount,
  linkedBtcAddress,
} = inventory.actions;

export default inventory.reducer;
