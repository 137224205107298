import { isLocal } from "../../../../constants/envs";
import { getWaxTokenPrice } from "./prices/alcor";
import { getHiveEngineTokenPrice } from "./prices/hiveEngine";

const supportedChains = ['HIVE', 'WAX'];

const toUsd = (prices = [], tokenPrice) => prices.map(prize => prize * tokenPrice);


export const calcTileRewards = async ({
  cs = {}, divider = 1, chainPrice = 0,
  sponsor = {}, sponsorSymbol = '',
  chainLogin = '',
}) => {
  isLocal && console.log('Calculating rewards for:', {
    cs, divider, chainPrice, sponsor, sponsorSymbol, chainLogin,
  });
  try {
    const igBoomSponsor = sponsor?.igBoom?.amounts || [];
    const igMarsSponsor = sponsor?.igMars?.amounts || [];
    let igBoomCs = cs?.igBoom?.usdValues || [];
    let igMarsCs = cs?.igMars?.usdValues || [];

    // If there's a sponsor, our rewards are less
    if (igBoomSponsor.length + igMarsSponsor.length) {
      igBoomCs = igBoomCs.map(prize => prize / divider);
      igMarsCs = igMarsCs.map(prize => prize / divider);
    }

    // If a token symbol is provided, fetch its market price. Otherwise default to chainPrice.
    let tokenPrice;
    const isProjectToken = sponsorSymbol && !supportedChains.includes(sponsorSymbol);
    if (isProjectToken) {
      const priceChecker = chainLogin.toLowerCase() === 'hive'
        ? getHiveEngineTokenPrice
        : getWaxTokenPrice;
      const { lastPriceUsd, err } = await priceChecker({ tokenName: sponsorSymbol, chainPrice });
      tokenPrice = err ? 0 : lastPriceUsd;
      isLocal && console.log(`${sponsorSymbol} price: $`, lastPriceUsd);
    } else {
      tokenPrice = chainPrice;
    }

    const tokenValues = [...toUsd(igBoomSponsor, tokenPrice), ...toUsd(igMarsSponsor, tokenPrice)];
    const tokenUsdSum = tokenValues.reduce((acc, cur) => acc + cur, 0);

    // Sum
    const allPrizes = [
      tokenUsdSum, // sponsors
      ...igBoomCs, ...igMarsCs, // ours
    ];
    const usdTotalSum = allPrizes.reduce((acc, cur) => acc + cur, 0);

    // Result
    isLocal && console.log('Tile rewards calc:', { sponsorSymbol, divider, tokenUsdSum, allPrizes, usdTotalSum });
    return { usdTotalSum, tokenUsdSum };
  } catch (err) {
    console.error('broke in calcTileRewards:', err);
    return ({ err: 'rewards-calc-broke' });
  }
};
