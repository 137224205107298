import { ACCESS_CONFIG } from "../../../config/accessConfig";
import { getCurrentEnvName, isLocal, isMainnet, isTestnet } from "../../../constants/envs";
import { ITEMS_BY_CATEGORY } from "../../../constants/templateIds";
import { fetchHiveData } from "../../../generalUtils/hiveData/reader";
import { nap } from "../../../generalUtils/utils";

const isLocalTesting = getCurrentEnvName() === 'local';

const { SNEAK_PEEK, TELEPORT_DEVICE } = ACCESS_CONFIG;
const [sneakName, teleportName] = Object.keys(ACCESS_CONFIG);

const { EARLY_ACCESS, WEAPONS } = ITEMS_BY_CATEGORY;

const ALPHA_TESTERS_WHITELIST_PERMLINK = 're-crypto-shots-r6mbgx';


export const verifyAccessAllowed = async (currentAccount, csNfts) => {
  // # Local testing and mainnet, no early access items checks
  if (isLocalTesting || isMainnet) return null;
  // # Playtest:
  // -- MANUAL MAPPINGS (wam/gm->12) AND FULL WHITELISTED (12)
  const { whitelistUsers = [], mappedSneakers } = await getManualMappingIfAny();
  if (isTestnet && whitelistUsers.includes(currentAccount)) return null; // whitelist only for testnet
  const isMappedUser = !!mappedSneakers[currentAccount];
  const waxAcc = (isTestnet && mappedSneakers[currentAccount]) || currentAccount; // mappings only for testnet
  // -- WHITELIST ITEMS CHECK
  const hasSneak = isMappedUser
    ? !(await isItemMissing({ waxAcc, sneakTemplateId: SNEAK_PEEK.TEMPLATE_ID, name: SNEAK_PEEK.NAME }))
    : csNfts.filter(nft => nft.template_id === EARLY_ACCESS.SPA.ID).length;
    const sneakPassMissing = SNEAK_PEEK.GRANTS_ACCESS && !hasSneak;
    if (sneakPassMissing) {
      if (!TELEPORT_DEVICE.GRANTS_ACCESS) return sneakName;
      isLocal && console.debug('No sneak, teleport grants access too now so checking it...');
      isMappedUser && (await nap(1500));
      const hasTeleport = isMappedUser
        ? !(await isItemMissing({ waxAcc, teleportTemplateId: TELEPORT_DEVICE.TEMPLATE_ID, name: TELEPORT_DEVICE.NAME }))
        : csNfts.filter(nft => nft.template_id === EARLY_ACCESS.TD.ID).length;
      if (!hasTeleport) return teleportName;
    }
  // -- BASE GUN CHECK
  const hasBaseGun = isMappedUser
    ? !(await isItemMissing({ waxAcc, templateId: WEAPONS.BG.ID, name: WEAPONS.BG.NAME }))
    : csNfts.filter(nft => nft.template_id === WEAPONS.BG.ID).length;
  if (!hasBaseGun) return WEAPONS.BG.NAME;
  return null;
};


const getManualMappingIfAny = async () => {
  const { err, whitelistObj } = await fetchHiveData({ permlink: ALPHA_TESTERS_WHITELIST_PERMLINK });
  if (err) return ({ err: 'unable to check.. Network Error' });
  const { whitelistUsers: dirtyWhitelistUsers, mappedSneakers: dirtyMappedSneakers } = whitelistObj;  
  isLocalTesting && console.log('>>>>>>>>>>>>>>> whitelist:', { dirtyWhitelistUsers });
  const whitelistUsers = dirtyWhitelistUsers.map(el => el.split('//')[0]); // ['sdjfksdld//mod', ..]
  const mappedSneakers = {}; // { 'jfsldkfj': 'fsdf.wam//fanZero' }
  Object.keys(dirtyMappedSneakers).forEach((key) => {
    mappedSneakers[key] = dirtyMappedSneakers[key].split('//')[0];
  });
  isLocalTesting && console.log('Users whitelisted on Hive:', { whitelistUsers, mappedSneakers });
  return ({ whitelistUsers, mappedSneakers });
};

// CORS wax.api.aa.atomichub.io -> wax.api.atomicassets.io
const isItemMissing = ({ waxAcc, templateId, name }) => {
  return fetch(
    `https://wax.api.atomicassets.io/atomicassets/v1/assets?owner=${ // WAS STILL HAVING CORS ISSUES, USING SAME HEADERS AS INVENTORY NOW <<<<<<<<<<<<<<<<<<<<<<< still a risk for us
      waxAcc
    }&collection_name=crypto4shots&template_id=${
      templateId
    }&sort=template_mint&order=asc&limit=1`,
    {
      headers: {
        accept: 'application/json',
        'cache-control': 'no-cache',
        pragma: 'no-cache',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'cross-site',
        'sec-gpc': '1',
      },
      referrer: 'https://wax.bloks.io/',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: null,
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    },
  )
    .then(res => res.json())
    .then(async (resp) => {
      if (!resp.success || !resp.data.length) return name;
      return false;
    })
    .catch((err) => {
      console.error(`Error looking for `, waxAcc, templateId, err);
      return 'caught Network Error'; // true
    });
};
