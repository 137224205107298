import stringHash from 'string-hash';
import { newrelic } from "../../services/monitoring/hacksNotify";
import { getPlayerUsername } from "../utils";
import { isLocal } from '../../constants/envs';
import Swal from 'sweetalert2';


// ## CONFIG

const ANTI_CHEAT_POLLING_S = 30 * 1000;


// ## DATA

// SUFFIXES:
// - none
// _obj -> uses stringified obj checksum
// _fun -> stores function name checksum
const windowAttributesCache = {
  // freeShip: true
  // storageType: ''
  // items_obj: '1111111' // obj hash
  // hiveNftsInfo_obj: '1111111'
  // addEventListener_fun: chk // checksum added to obj
  // multiAccCheck_fun: chk
  // setPercentLoaded_fun: chk
  // setWrapperData_fun: chk
  // fetchUserDataOnly_fun: chk
  // isFreeTrial: true, // value
  // payForNextLevel_fun: chk
  // inTravel: true
  // joysticks_obj: '1111111',
  // skippedResourcesPaym: true
  // hasMostlyHiveNfts: true
  // spendTokens_fun: chk
};


// ## UTILS


// {
//   STARTER_KITS: {
//     SK:  { ID: '417605', OG_NAME: '3D game Starter Kit', NAME: '3D game Starter Pack', IMG: 'QmeDPn83PvKUnoN23LuzCyP1ViMAEwc2FjGpxdDabKH8kJ', SCHEMA: 'cs.ahnbpacks' },
//     GXL:  { ID: '445646', OG_NAME: '3D game Golden Pack - XL', NAME: '3D game Golden Pack XL', IMG: 'QmPrvpC2YXixDnqCTXJCSWjdhSLrx1bWis87QFFP6JG7nd', SCHEMA: 'cs.startpack' },
//   },
//   WEAPON_PACKS: {
//     SP: { ID: '392158', OG_NAME: 'Small Box', NAME: 'Weapons Pack S', IMG: 'QmbqdDLvj8eTinW8qduXPyv96SRcfgReez357jBBr5gEd5', SCHEMA: 'cs.ahnbpacks' },
//     LP: { ID: '392182', OG_NAME: 'Large Box', NAME: 'Weapons Pack L', IMG: 'QmbsG4x3p9B9H3tHSdsCoYyvvLYmMvAkwWatrsjpXeG3rz', SCHEMA: 'cs.ahnbpacks' },
//   }, ...
// }
const templateIdsChecksum = (obj) => {
  let checksum = 0;
  for (const category in obj) {
    const items = obj[category];
    for (const item in items) {
      const templateId = items[item].ID;
      const lastChar = templateId.slice(-2);
      checksum += isNaN(+lastChar) ? 0 : parseInt(lastChar, 10);
    }
  }
  isLocal && console.log(':::::> items checksum:', checksum);
  return checksum;
};

const hashObject = (key = '', obj = {}) => {
  try {
    if (key === 'items') {
      return templateIdsChecksum(obj);
    }
    const stringified = JSON.stringify(obj);
    return stringHash(`${stringified.length}`);
  } catch (err) {
    console.error('hash ko:', err);
  }
}

export const windowSetChecksum = ({ key, value }) => {
  if (typeof value === 'object') {
    windowAttributesCache[`${key}_obj`] = hashObject(key, value);
  } else if (typeof value === 'function') {
    const keyHash = stringHash(key)
    windowAttributesCache[`${key}_fun`] = keyHash;
    window[key].chk = keyHash;
  } else {
    windowAttributesCache[key] = value;
  }
};


// ## PERIODIC CHECK

setInterval(async () => {
  isLocal && console.log('Running periodic anti-tampering checks [react]...');
  const attributes = Object.keys(windowAttributesCache);
  for (let id = 0; id < attributes.length; id++) {
    const currentAttr = attributes[id];
    const [key, tail] = currentAttr.split('_');
    const cachedValue = windowAttributesCache[currentAttr];
    let windowValue;
    if (tail === 'obj') {
      windowValue = hashObject(key, window[key]);
    } else if (tail === 'fun') {
      windowValue = window[key]?.chk;
    } else {
      windowValue = window[key];
    }
    if (windowValue !== cachedValue) {
      isLocal && console.debug('[react] FOUND CLASH IMMUTABLE ATTRIBUTES CHECKSUM:', { key, windowValue, cachedValue });
      handleCheat({ key: currentAttr, saved: cachedValue, actual: windowValue });
    }
  }
}, ANTI_CHEAT_POLLING_S);

const handleCheat = async ({ key, saved, actual }) => {
  let user;
  try {
    user = getPlayerUsername();
  } catch (err) { console.error('Unable to get username:', err); }
  try {
    // DISCORD
    await newrelic.notifyHackAttempt({ user, msg: `tried to change frozen attribute: ${key}. Saved: ${saved}, Actual: ${actual}` });
    // ALERT
    const overrideCheatMsg = '263-TA :rorrE .detroper resU .rorre gnirepmat-itnA'.split('').reverse().join('');
    const obfErr = Buffer.from(key).toString('base64').replace(/=/g, '').split('').reverse().join('');
    Swal.fire({text: `${overrideCheatMsg}.${obfErr}`})
  } finally {
    // RELOAD
    !isLocal && window.location.reload();
  }
};
