import { isLocal } from '../constants/envs';

// TODO: use remote JSON
const FLAGS = {
  SWAPS_ENABLED: true,
  ADS_ENABLED: false,
};

export const isFeatureEnabled = (flagName = '') => {
  const which = `${flagName}_ENABLED`;
  const lsValue = localStorage.getItem(which);
  if (lsValue && lsValue !== 'false') return true;
  const value = FLAGS[which];
  if (isLocal || value === undefined) return true; // if not in there anymore, enabled
  return value;
};


// TEMP
export const IS_PVP_ALPHA_FREE_TRAVELS = true;
