/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { ScholarshipPane } from '../ScholarshipPane';
import { areMandNftsMissing, areWhitelistNftsMissing } from '../utils/requiredNfts';
import { MatchResults } from './MatchResults';
import { isPlayerCrossChainSecondary, multiTabCheckBeforePlayAndClaim, updateBlacklists } from '../../../../generalUtils/secure';
import { Penalties } from './Penalties';
import { getNftCount, getPlayerUsername } from '../../../../generalUtils/utils';
import { ITEMS_BY_CATEGORY } from '../../../../constants/templateIds';
import { isTestnet } from '../../../../constants/envs';
import { consumeTourneyConfig } from '../utils/requiredNFts/pvpRemoteConfig';
import Swal from 'sweetalert2';


export const PlayAndMatchOutcome = ({ showResourcesPage, paidResources, shouldAutoPlay, setShouldAutoPlay }) => {
  // PLAY STATE
  const PlayState = useSelector((store) => store.play);
  const activeLevel = PlayState.activeLevel;
  const percentLoaded = PlayState.isPercentLoaded;
  const isPvp = get(PlayState, 'isPvpGameMode');
  // INVENTORY STATE
  const InventoryState = useSelector((store) => store.inventory);
  const waxScholarshipData = get(InventoryState, 'scholarship', {});
  const { isTeacher } = (waxScholarshipData || {});
  const nfts = get(InventoryState, 'csAssets.csNfts', []);
  const hiveData = get(InventoryState, 'hive', {});
  const { nfts: hiveNfts } = hiveData;
  const missingPveNFts = areMandNftsMissing({ nfts, hiveNfts, isPvp });
  // USER
  const UserState = useSelector((store) => store.user);
  const authChain = UserState.chainLogin;


  // shouldAutoPlay
  useEffect(async () => {
    if (shouldAutoPlay) {
      onHandlePlay();
      setShouldAutoPlay(false);
    }
    // Check if pass needed only if he doesnt have it
    const shouldCheckPass = isPvp && areWhitelistNftsMissing({ nfts, hiveNfts });
    if (shouldCheckPass) {
      // cache result for when requested clicking on Play button
      await consumeTourneyConfig();
    }
  }, []);

  const onHandlePlay = async () => {
    // LOADING
    if (!window.unityLoadCompleted) {
      Swal.fire({ text: `The game is still loading in the background: ${window.loadMissingPc}% missing. Try in a bit.` })
      return;
    }
    // SCHOLARSHIPS
    if (isTeacher) {
      Swal.fire({text: `You cannot play with a Teacher account!
         If you want to interrupt the Scholarship talk to your Scholar and burn your Scholar NFT.` })
      return;
    }
    // For Wax account linked to Hive account
    if (authChain === 'hive') {
      const hasWaxTeacherNft = getNftCount({
        nfts,
        id: get(ITEMS_BY_CATEGORY, 'SCHOLARSHIPS.TEA.ID'),
      });
      if (hasWaxTeacherNft) {
        Swal.fire({ text: 'You linked a Wax account with a Teacher NFT. A scholar is already playing with that inventory, so you can\'t.' })
        return;
      }
    }
    // WHITELIST
    const missingWhitelistPvp = isPvp && areWhitelistNftsMissing({ nfts, hiveNfts })
      && (await consumeTourneyConfig()).isPvpPassRequired;
    isTestnet && console.log('::::::: PVP Pass check:', { isPvp, missingWhitelistPvp });
    if (missingWhitelistPvp) {
      Swal.fire({ text: `You need a whitelist NFT to access the PVP ALPHA ${isTestnet ? '(ok on testnet)' : ''}` })
      if (!isTestnet) return;
    }

    // USAGE OF CROSS-CHAIN ACCOUNT CHECK
    const authAccount = getPlayerUsername({ UserState, InventoryState });
    if (isPlayerCrossChainSecondary({ authAccount, authChain })) {
      Swal.fire({ text: 'You cannot play with an account which inventory you already linked to another account of yours!\nAsk support to reset your account if you lost access to it.' })
      return;
    }
    // multi tab check
    multiTabCheckBeforePlayAndClaim();
    // play..
    const playBtn = document.getElementById('play-button');
    if (playBtn?.getAttribute('disabled')) return;
    if (!window.pageShow) {
      Swal.fire({ status: true, type: 'warning', body: 'works only when embedded in the CS-wrapper' })
    } else {
      window.pageShow('game');
      updateBlacklists();
    }
  };


  return (
    <div className="col-12 text-center mt-2 game-result">
      <h2 className="text-white mt-1"><span id="level-number">Level-{activeLevel}</span> &nbsp; {isPvp ? <b>PVP</b> : <><b>PVE</b> &nbsp; MATCH OUTCOME</>}</h2>
      &nbsp;
      <div>
        <div className="container row">
          <div className="col-5 play-btn-wrap">
            {paidResources ?
              <div>
                <p id="newstarter-msg" style={{ display: missingPveNFts ? 'none' : (isPvp ? 'none' : 'block') }}>Pew pew!! 😎</p>
                <button
                  id="play-button" className="btn btn-success btn-lg"
                  style={{ backgroundColor: isTeacher ? '#101010' : '', marginLeft: isPvp ? '200px !important' : 'auto' }}
                  onClick={onHandlePlay} disabled={isTeacher}
                >
                  PLAY <span>{percentLoaded < 100 && <sub><sub>({percentLoaded.toFixed(0)}% loaded)</sub></sub>}</span>
                </button>
              </div>
              : <div id="resources-btn-wrap">
                {!isPvp && missingPveNFts && <h5 id="no-required-nfts" style={{ fontSize: '18px' }}>
                    You won't earn rewards until you have: Base Gun, Base Ammo and 1x level-0 Character. Or a closed 3D Starter/Advanced Pack.
                  </h5>}
                <button
                  id="resources-button" className="btn btn-success btn-lg"
                  onClick={() => showResourcesPage(true)}
                >
                  START GAME
                </button>
              </div>}
            {!isPvp && <>
              <ScholarshipPane/>
              <Penalties/>
            </>}
          </div>
          {!isPvp && <MatchResults />}
        </div>
      </div>
    </div>
  );
};
