/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useState } from 'react';
import {
  claimLimits, BONUS_AMOUNT, HIGHEST_LEVEL, HIGHEST_REWARD, HIGHEST_MARS_CLAIM,
} from '../../../../config/appConfig';
import './ClaimDisclaimer.scss';


const { minBoom } = claimLimits();


export const ClaimDisclaimer = ({
  whichActiveToken,
  boomClaimInfo, activeLevel, totalRewards, scholarCut,
}) => {
  if (whichActiveToken === 'MARS') {
    return (
      <div id="claim-disclaimer-container">
        <div id="rules-and-max-claims">
          <p id="your-max">
            The MAX amount that you can currently claim per day is: &nbsp;
            <b className="your-max-value">{HIGHEST_MARS_CLAIM} <sub>ig</sub>MARS</b>
          </p>
        </div>
      </div>
    );
  }
  else if (whichActiveToken === 'PROX') {
    return (
      <div id="claim-disclaimer-container">
          <div id="rules-and-max-claims">
            <p id="your-max"><i>Not available yet, token for the 5th level.</i></p>
          </div>
      </div>
    );
  }

  const {
    hasMinClaim = false, yourCurrentMaxClaimable = 0, isBelowMaxDaily = true,
    isChampionOverflow, isMostlyUnstaker,
  } = boomClaimInfo;

  const totPendingRewards = scholarCut ? (totalRewards * scholarCut / 100) : totalRewards; 
  const [isExpanded, setExpanded] = useState(false);

  const AboveMaxDaily = ({ isChampionOverflow }) => <p id="uh-oh">
    {isChampionOverflow
      ? <h4>GG CHAMPION!! 😎 🏆</h4>
      : <p>
          {totPendingRewards > 0 && <>
            Uh oh, your pending rewards ({totPendingRewards.toFixed(2)}) went above your daily MAX of {yourCurrentMaxClaimable}.
            {!isMostlyUnstaker && <p>You can claim again after the daily reset. Or... 👇</p>}
          </>}
      </p>
    }
  </p>;

  return (
    <div>
      <div id="claim-disclaimer-container">
        <div id="rules-and-max-claims">
          {(totalRewards > 0 && !hasMinClaim)
            && <p>You need at least {minBoom} <sub>ig</sub>BOOM in your pending rewards before you can claim or unstake.</p>
          }
          {isBelowMaxDaily
            ? <p id="your-max">
                The MAX amount that you can currently claim per day is: &nbsp;
                <b className="your-max-value">{yourCurrentMaxClaimable} <sub>ig</sub>BOOM</b>
              </p>
            : <AboveMaxDaily isChampionOverflow={isChampionOverflow}/>
          }
          <div id="unlock-it">
            <span>NOTE: keep an eye on your rewards!<br/>
            Once they go above your daily max,<br/><u>you won't be able to claim!</u>&nbsp;
            <sub> ( unless you unlock it 👇 )</sub></span>
          </div>
          {!isExpanded && <p
            style={{ fontStyle: 'italic', cursor: 'pointer' }}
            onClick={() => setExpanded(true)}
          >
            More info...
          </p>}
          {isExpanded && <ul style={{ marginLeft: '0px', textAlign: 'left' }}>
            {(activeLevel < HIGHEST_LEVEL)
              && <li className="advice" style={{ marginBottom: '10px', marginTop: '20px',  }}>
                  If you play and win the next level of the game, you will&nbsp;
                  <span className="underline">unlock a higher claim threshold</span>!
                </li>
            }
            {(yourCurrentMaxClaimable < HIGHEST_REWARD)
              && <li className="advice">
                <b style={{ color: 'aqua' }}>CHAMPION</b> staus <span className="underline">unlocks a +{BONUS_AMOUNT}</span> higher threshold per day!&nbsp;
                <span>(see <span style={{ color: 'aqua' }}>requirements</span>)</span>
                <p style={{ marginTop: '5px' }}>Champions can also <u>claim above their max</u> and the excess automatically goes into staking!</p>
              </li>
            }
            </ul>}
        </div>
      </div>
    </div>
  );
};
