import React from "react";
import { Animated } from "react-animated-css";

import { LeaderboardPrizes } from "./Prizes/LeaderboardPrizes";
import { LeaderboardTabs } from "./Tables/LeaderboardTabs";
import "./Leaderboard.scss";

// FEATURE FLAGGED
import { isLocal } from "../../constants/envs";


export const Leaderboard = () => {
  return (
    <div className="container leaderboard">
      <br />
      <Animated
        animationIn="slideInDown"
        animationOut="slideOutLeft"
        animationInDuration={500}
        animationInDelay={50}
        animationOutDuration={3000}
        isVisible={true}
      >
        <div className="row">
          <div className="col-5" style={{ display: isLocal ? 'block' : 'none' }}>
            <LeaderboardPrizes />
          </div>
          <div className="col-7">
            <LeaderboardTabs />
          </div>
        </div>
      </Animated>
    </div>
  );
};
