import Swal from 'sweetalert2';
import { isLocal } from '../../../../constants/envs';


const prepGameResultAndScore = ({ resMatch, score }) => {
  if (!resMatch || !score) return ({});
  let outcomeToDisplay = '';
  let boomToDisplay = 0;
  switch (resMatch) {
    case 'won': {
      outcomeToDisplay = 'YOU WON !!';
      boomToDisplay = score;
      break;
    }
    case 'validTimeout': {
      outcomeToDisplay = 'Timeout - 40% claim';
      boomToDisplay = +score * 40 / 100;
      break;
    }
    case 'invalidTimeout': {
      outcomeToDisplay = 'Timeout - no claim'; // should be 10 % per wp! @@@@@ --> backend change
      boomToDisplay = 0;
      break;
    }
    case 'lost': {
      outcomeToDisplay = 'You lost';
      boomToDisplay = score * 10 / 100;
      break;
    }
    default: { throw new Error(`Unexpected match result to display: ${resMatch}`); }
  }
  return ({ outcomeToDisplay, boomAmountToDisplay: boomToDisplay.toFixed(3) });
};


const roundSum = (...args) => {
  let result = 0;
  args.forEach((num) => { result += num; });
  return +(result).toFixed(2);
};

const formattedRewardsBreakdown = ({ gameData }) => {
  if (!gameData?.rewards) return null;
  const {
    levelKills, boostsTot, stakedNfts, weapons, tekno,
    difficultyAdjustment, taxApplied, finalEarnings,
  } = gameData?.rewards || {};
  if (!finalEarnings || finalEarnings === 0) return '';

  const boostsSum = roundSum(stakedNfts, weapons, tekno);
  const isBoostersBreakdownCorrect = (roundSum(boostsTot) === boostsSum);
  let earningsSum = levelKills + boostsTot + difficultyAdjustment + taxApplied;
  if (window.skippedResourcesPaym) {
    earningsSum /= 10;
  } else if (window.freeShip) {
    earningsSum /= 2;
  }
  const isTotalBreakdownCorrect = (roundSum(finalEarnings) === roundSum(earningsSum));
  if (isLocal && (!isBoostersBreakdownCorrect || !isTotalBreakdownCorrect)) {
    Swal.fire({
      text: `INCORRECT BREAKDOWN: ${JSON.stringify({
        cond1: { isBoostersBreakdownCorrect, boostsTot: roundSum(boostsTot), boostsSum },
        cond2: { isTotalBreakdownCorrect, finalEarnings: roundSum(finalEarnings), earningsSum },
      })}`
    })
  }
  isLocal && console.log('Rewards breakdown:', { rewards: gameData.rewards, boostsSum, earningsSum });

  return (
    <ul style={{ lineHeight: '12px', marginBottom: '-10px' }}>
      <li style={{ marginTop: '10px' }}>
        Level kills: {levelKills.toFixed(3)}
      </li>
      <li style={{ marginTop: '0px' }}>
        Extra rewards: {boostsTot.toFixed(2)}<br />
        <i>
          ( {stakedNfts.toFixed(2)} boosters, {weapons.toFixed(2)
          } weapons, {tekno.toFixed(2)} tekno )
        </i>
      </li>
      <li style={{ marginTop: '0px' }}>
        Difficulty adjustment: {difficultyAdjustment.toFixed(3)}
      </li>
      {window.skippedResourcesPaym
        ? <li style={{ marginTop: '0px' }}>-90 % for skipping $FOOD and $GAS payment</li>
        : (window.freeShip ? <li style={{ marginTop: '0px' }}>-50 % for free spaceship use</li> : null)}
      <li style={{ margin: '0px auto 20px' }}>
        Taxes: {taxApplied.toFixed(3)}
      </li>
    </ul>
  );
};


export const prepLastMatchInfo = ({ sessionData, gameData }) => {
  try {
    const {
      resMatch, score, killBalance, remainingTime, difficulty,
    } = sessionData?.lastMatchInfo || {};
    isLocal && console.debug('Parsing last match data..',
      { resMatch, score, killBalance, remainingTime, difficulty });
    // result and score
    const { outcomeToDisplay, boomAmountToDisplay } = prepGameResultAndScore({ resMatch, score });
    // rewards
    const formattedRewards = formattedRewardsBreakdown({ gameData });
    // difficulty
    const difficultyTxt = +difficulty === 0
      ? 'EASY' : (+difficulty === 1 ? 'MEDIUM' : 'HARD');
    isLocal && console.debug('Updating UI with match result and score..', {
      outcomeToDisplay, boomAmountToDisplay, formattedRewards, difficultyTxt
    });
    // result  
    return ({
      outcomeToDisplay, boomAmountToDisplay,
      killBalance, remainingTime, difficultyTxt, formattedRewards,
    });
  } catch (err) {
    console.error('Error prepping last match info:', err);
  }
};

export const extractPlayedTime = ({ sessionData }) => {
  try {
    const { totTimeSinceMidnight } = sessionData?.lastMatchInfo || {};
    if (!totTimeSinceMidnight) return null;
    const minsPlayed = Math.ceil((+totTimeSinceMidnight / 1000) / 60);
    isLocal && console.debug('Mins played since midnight:', minsPlayed);
    return minsPlayed;
  } catch (err) {
    console.error('Error extracting played time:', err);
  }
};
