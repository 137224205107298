import { get } from 'lodash';
import { WAX_ACCOUNTS } from '../../config/accounts';
import { TOKEN_ACCOUNT } from '../../config/contractNames';
import { CURRENCIES } from '../../constants/currencies';
import { isLocal, isTestnet } from '../../constants/envs';
import { flyingSessionLogin, UserService, WaxFlyingSession } from '../UserService';
import { RESOURCES_COST, OXY_RECHARGE_SECONDS, LIFE_RECHARGE_PC } from './RESOURCES_COST';
import { windowSetChecksum } from '../../generalUtils/secure/checksums';
import Swal from 'sweetalert2';


const startPurchase = ({ cost, tokenName, reason }) => new Promise(async (resolve) => {
  if (!cost || !tokenName || !reason) {
    throw new Error(`Unexpected input for in-game resource purchase: ${cost} ${tokenName} ${reason}`);
  }
  const quantity = isTestnet ? `${(0.1).toFixed(4)} BOOM` : `${cost.toFixed(4)} ${tokenName}`;
  const targetWallet = WAX_ACCOUNTS.IG_RESOURCES_PAYMENTS;
  isLocal && console.debug('ig resouce payment:', { cost, tokenName, reason });

  let waxSession = UserService;
  const isHiveUser = localStorage.getItem('chainLogin') !== 'wax';
  if (window.isFreeTrial || (isHiveUser && !UserService.authName)) {
    isLocal && console.debug('creating side session for wax tokens payment for hive user');
    waxSession = WaxFlyingSession;
    const isLoggedIn = await flyingSessionLogin(waxSession);
    if (!isLoggedIn) return resolve({ err: 'still not logged in for wax tokens payment' });
  }
  isLocal && console.debug('Expected user to sign tx:', waxSession.authName);
  waxSession.session.signTransaction(
    {
      actions: [{
        account: TOKEN_ACCOUNT,
        name: 'transfer',
        authorization: [{
          actor: waxSession.authName,
          permission: 'active'
        }],
        data: {
          from: waxSession.authName,
          to: targetWallet,
          quantity,
          memo: `In-game resource purchase: ${reason}`,
        }
      }]
    },
    { blocksBehind: 3, expireSeconds: 30 }
  )
    .then(async (response = { status: '' }) => {
      isLocal && console.log('Transaction outcome:', response.status);
      if (response.status !== 'executed') {
        console.error('in-game resource payment failed:', JSON.stringify({ response }));
        Swal.fire({text: `Payment failed.\n\n${JSON.stringify(response.status)}`});
        resolve({ err: (response.status || 'payment-ko') });
        return;
      }
      isLocal && console.debug('Tx success.', { response });
      resolve({});
    })
    .catch((err) => {
      console.error('in-game resource payment caught error:', JSON.stringify({ err }));
      const errMsg = get(err, 'cause.message');
      const errMsg2 = get(err, 'cause.json.error.details[0].message');
      const errMsg3 = get(err, 'cause.json.message');
      Swal.fire({text: `Payment failed.\n\n${errMsg || errMsg2 || errMsg3 || JSON.stringify(err)}`})
      resolve({ err });
    });
});


// const { err } = await window.spendTokens({ tokenInitial, reason }); // confirm price + exec
const spendTokens = async ({ tokenInitial, reason }) => {
  let isOk = false;
  const tokenName = CURRENCIES[tokenInitial];
  const cost = RESOURCES_COST[reason][tokenName];
  isLocal && console.debug('Spending:', { cost, tokenName, reason });
  switch (reason) {
    case 'AMMO': {
      isOk = window.confirm(`Spend ${cost} ${tokenName} to 1/3 recharge ALL your weapons?`);
      break;
    }
    case 'OXY': {
      isOk = window.confirm(`Spend ${cost} ${tokenName} to add ${OXY_RECHARGE_SECONDS} of Oxygen to your tank?`);
      break;
    }
    case 'LIFE': {
      isOk = window.confirm(`Spend ${cost} ${tokenName} to recharge ${LIFE_RECHARGE_PC}% of your total health?`);
      break;
    }
    default: {
      Swal.fire({text: `Unexpected purchase reason ${reason} for token $${tokenInitial}`})
      return ({ err: 'unexpected-purchase' });
    }
  }
  if (!isOk) return ({ err: 'user-canceled' });
  isLocal && console.debug('Starting purchase..');
  const { err } = await startPurchase({ cost, tokenName, reason });
  isLocal && console.debug('Payment done.', { err });
  return ({ err, OXY_RECHARGE_SECONDS, LIFE_RECHARGE_PC });
};
window.spendTokens = spendTokens;
windowSetChecksum({ key: 'spendTokens', value: spendTokens });

// FREEZE
const originaValue = window.spendTokens;
try {
  Object.defineProperty(window, 'spendTokens',
    { value: originaValue, writable: false },
  );
} catch (_) { }

// INIT
export const initPaymentsBridge = () => console.log('Payments bridge ready.');
