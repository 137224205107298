// "@hiveio/hive-js": "2.0.4",
import hive from '@hiveio/hive-js';
import stringHash from 'string-hash';
import { get } from 'lodash';
import { isLocal } from '../../../constants/envs';
import { client } from '../../../helpers/client';
import { setRandNode } from '../../../generalUtils/hiveData/reader';
import Swal from 'sweetalert2';


const hiveAmount = isLocal ? (localStorage.getItem('linkPrice') || '0.001') : '1.000';
export const LINKING_COST = `${hiveAmount} HIVE`;
export const LINKING_RECIPIENT = 'cryptoshots.nft';
export const HEAD = '[';
export const TAIL = ':CRYPTO-SHOTS]'; // eg. [5cfb2162f026880e05fe97da8fb018dac0c55d78:CRYPTO-SHOTS]


// ## WAX

const retrieveWaxBio = async ({ waxAddr }) => {
  isLocal && console.log('Checking wax account bio:', waxAddr);
  return fetch('https://wax.pink.gg/v1/chain/get_table_rows', {
    headers: {
      accept: '*/*',
      'accept-language': 'en-GB,en;q=0.9,en-US;q=0.8',
      'content-type': 'text/plain;charset=UTF-8',
      'sec-ch-ua': '"Chromium";v="92", " Not A;Brand";v="99", "Microsoft Edge";v="92"',
      'sec-ch-ua-mobile': '?0',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'cross-site',
    },
    referrer: 'https://wax.atomichub.io/',
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: `{"code":"atomhubtools","table":"acctexts","scope":"${waxAddr
      }","lower_bound":"description","upper_bound":"description","limit":1,"json":true}`,
    method: 'POST',
    mode: 'cors',
    credentials: 'omit',
  })
    .then(res => res.json())
    .then((data) => {
      isLocal && console.log('wax bio data:', data);
      // WAX account existance check
      const { code } = data;
      if (code) {
        const errCode = get(data.error, 'code');
        if (errCode === 3010000) return ({ invalidAcc: true });
      }
      // WAX account found
      const bio = get(data, 'rows[0].value', '');
      const hasHiveTx = bio.includes(TAIL);
      if (!hasHiveTx) return ({});
      const part = bio.split(HEAD)[1];
      const foundTx = part && part.split(TAIL)[0];
      isLocal && console.log('Found tx:', foundTx);
      return ({ foundTx });
    })
    .catch((err) => {
      console.error('>>>>>>>> BIO error:', err);
      return ({ errWaxBio: err });
    });
};

const fetchAtomicProfile = async ({ waxAddr }) => {
    const { invalidAcc, errWaxBio, foundTx } = await retrieveWaxBio({ waxAddr });
    if (invalidAcc) {
      Swal.fire({text: 'It looks like the WAX wallet in use does not exist?'});
      return ({ err: 'account-404' });
    }
    if (errWaxBio) {
      Swal.fire({text: `Oops, unable to read your Hive account. Please try again in a bit.`});
      return ({ err: 'wax-ko' });
    }
    if (foundTx) return ({ foundTx });
    return ({});
};

export const tokenInMemo = (waxAddr) => {
  if (isLocal && !localStorage.getItem('linkPrice')) return 'blahblahtoken';
  const hash = stringHash(waxAddr);
  const expectedToken = `${btoa(hash).replace(/=/g, '')}.${`${+hash * 2}`.substr(0, 3)}`;
  return expectedToken;
};


// ## HIVE

const isInvalidStudent = ({ student, teacher }) => {
  // not same account
  if (student === teacher) {
    Swal.fire({text: `The Scholar has the same username as the Teacher: ${teacher}`});
    return true;
  }
  // check scholar exists
  hive.api.getAccounts([student], (err, result) => {
    if(get(result, '[0].name') !== student) Swal.fire({text: `Incorrect student name: ${student}`});
  });
  // scholar username format validation (dots, lenght, etc)
  const error = hive.utils.validateAccountName(student);
  error && console.error('Invalid scholar name. Reason:', error);
  return error;
};

const findAndValidate = ({ ops = [], expectedToken = '' }) => {
  for (let id = 0; id < ops.length; id++) {
    const [opName, data] = ops[id];
    if (opName === 'transfer') {
      const { from = '', to = '', amount = '0.000 HIVE', memo = '' } = data;
      let isValid = to === LINKING_RECIPIENT && amount === LINKING_COST
        && memo.includes(expectedToken);
      const studentOg = memo.trim().split(':')[1] || '';
      const student = studentOg.replace('@', '');
      isLocal && console.log('hive operations check:', { isValid,
        data, expected: { to: LINKING_RECIPIENT, amount: LINKING_COST, memo: expectedToken },
        scholarship: { value: !!student, teacher: from, scholar: student },
      });
      if (student && isInvalidStudent({ student, teacher: from })) { isValid = false; }
      if (isValid) {
        return !!student
          ? ({ hiveUsername: student, teacher: from })
          : ({ hiveUsername: from });
      }
    }
  }
  return ({});
};

const fetchLinkedIdentity = ({ waxAddr }) => new Promise(async (resolve) => {
  const { foundTx , err } = await fetchAtomicProfile({ waxAddr });
  if (err || !foundTx) {
    resolve({});
    return;
  }
  // look for tx in Hive block from AH profile
  const expectedToken = tokenInMemo(waxAddr);
  const nodeInUse = setRandNode();
  isLocal && console.log('Checking tx data on Hive using api node', { nodeInUse });
  if (foundTx.length > 9) {
    isLocal && console.log('ID found in AH is tx:', { foundTx });
    hive.api.getTransaction(foundTx, (err, result) => {
      isLocal && console.log('HIVE DATA from tx :::::', err, result);
      const ops = get(result, 'operations', []);
      const { hiveUsername, teacher } = findAndValidate({ ops, expectedToken });
      resolve({ hiveUsername, teacher });
    });
  } else {
    const blockNum = foundTx;
    isLocal && console.log('ID found in AH is block:', { blockNum });
    hive.api.getBlock(blockNum, (err, result) => {
      isLocal && console.log('HIVE DATA from block :::::', err, result);
      const transactions = get(result, 'transactions', []);
      for (let id = 0; id < transactions.length; id++) {
        const currentTx = transactions[id];
        const ops = get(currentTx, 'operations', []);
        isLocal && console.log(`Checking HIVE tx ${id} that has ${ops.length} operations`);
        const { hiveUsername, teacher } = findAndValidate({ ops, expectedToken });
        if (hiveUsername) {
          resolve({ hiveUsername, teacher });
          return;
        }
      }
      resolve({});
    });
  }
});

// testnet: 'https://enginetestnet.rishipanthee.com/contracts'
const hiveNftsUrl = () => {
  return 'https://engine.rishipanthee.com/contracts';
};

const fetchUserNfts = ({ hiveUsername, offset = 0 }) => new Promise((resolve) => {
  const symbolNft = 'CSHOTS';
  const payload = [{
    method: 'find',
    jsonrpc: '2.0',
    params: {
      contract: 'nft',
      table: `${symbolNft}instances`,
      query: { account: hiveUsername },
      limit: 1000,
      offset,
      indexes: []
    },
    id: 1,
  }];
  const url = hiveNftsUrl();
  isLocal && console.log('Sending hive nfts fetch request:', { url, payload });
  client.post(
    url,
    payload,
    { fullUrl: true },
  )
    .then(async (res) => {
      let dataArr = get(res, 'data[0].result', []);
      if (offset > 0) {
        return resolve(res); // TEMP, cuts some away @@
      }
      if (!dataArr.length) Swal.fire({text: `No Hive NFTs found for @${hiveUsername}`});
      if (dataArr.length === 1000) {
        isLocal && console.debug('Fetching NFTs again, previous offset:', offset);
        const newRes = await fetchUserNfts({ hiveUsername, offset: 1000 + offset });
        let newDataArr = get(newRes, 'data[0].result', []);
        dataArr = [...dataArr, ...newDataArr];
      }
      const parsedArr = dataArr.map(({ account, _id, previousAccount, properties = {} } = {}) => {
        const { name, schema, img, description } = properties
        if (account === hiveUsername) return ({
          mint: _id,
          previousOwner: previousAccount,
          name, schema, img, description,
        });
      }).filter(e => !!e);
      isLocal && console.log('Hive nfts data:', parsedArr);
      resolve({ hiveNftsArr: parsedArr});
    })
    .catch((err) => {
      console.error('Caught error fetching NFT list:', err);
      Swal.fire({text: 'Uh oh, something went wrong fetching your Hive NFTs. Please try again.'});
      resolve({ hiveNftsArr: [] });
    })
});


export const fetchHiveNfts = async ({
  waxAddr, linkedHiveAccount, // wax login
  hiveUsername, linkedWaxAccount, // hive login
}) => {
  if (waxAddr) {
    // # WAX LOGIN
    const teacher = null; // for now
    // const { hiveUsername, teacher } = await fetchLinkedIdentity({ waxAddr }); // wax
    if (!linkedHiveAccount) return ({ hiveUsername: null, hiveNftsArr: [] });
    const { hiveNftsArr } = await fetchUserNfts({ hiveUsername: teacher || linkedHiveAccount }); // hive
    return ({ hiveNftsArr, teacher });
  } else if (hiveUsername) {
    // # HIVE LOGIN
    const { hiveNftsArr } = await fetchUserNfts({ hiveUsername });
    return ({ hiveNftsArr });
  }
};
