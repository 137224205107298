import React from 'react';
import { PlayerMode } from '../../Login/Shared/PlayerMode';

export const F2PlayPane = ({ handleGameTrialPVE, handleGameTrialPVP }) => {
  return (
    <div className="row" id="free-play-btns-wrapper" style={{ pointerEvents: 'all' }}>
      <PlayerMode
        {...{ singleplayerHandler: handleGameTrialPVE, multiplayerHandler: handleGameTrialPVP, mode: 'free' }}
      />
      <div id="no-login" className="col-12">
        <b>No login required to play</b>. &nbsp;All levels available!
      </div>
    </div>
  );
};
