import React, { Component } from 'react';
import { get } from 'lodash';

import { isLocal } from '../../../constants/envs';
import { ITEMS_BY_CATEGORY } from '../../../constants/templateIds';
import { Tile } from '../Tile/Tile';
import { getItemPrice } from '../../../sagas/helpers/inventory/marketplacePrices';
import './Category.scss';


const getMissingItemsList = ({ userNftsArr = {}, categoryId }) => {
  const userTemplateIdsForCurrentCategory = Object.keys(userNftsArr)
    .map((key) => get(userNftsArr, `${key}.template`));
  const allNftsForCurrentCategory = ITEMS_BY_CATEGORY[categoryId];
  const userMissingItems = Object.values(allNftsForCurrentCategory).map((nftDetails) => {
    if (userTemplateIdsForCurrentCategory.includes(nftDetails?.ID)) return null;
    else return nftDetails;
  }).filter(el => !!el);
  return userMissingItems;
};

const prepRowItems = ({ isOpen, missingItemsList, userNfts }) => {
  if (isOpen) return missingItemsList.map((nftObj) => {
    const key = nftObj.template || nftObj?.ID;
    return <Tile key={key} name={nftObj.NAME} data={nftObj}></Tile>
  });
  else if (Object.keys(userNfts).length === 0) return (
    <div className="col-6 mt-2">
      <sub className="text-white"><i>none</i></sub>
    </div>
  );
  return Object.keys(userNfts).map(
    (item = '') => {
      const data = userNfts[item];
      const key = data.template || data?.ID;
      item.toLowerCase().includes('teacher') && console.log('[D] Teacher:', { key, data }); // @@
      return <Tile {...{ data, key, name: item }}></Tile>
    })
};

const prepSchemas = ({ schemas = [] }) => {
  const allSchemas = schemas.map(({ schema, SCHEMA }) => schema || SCHEMA);
  const noDupes = Array.from(new Set(allSchemas));
  const AH_MARKET_LINK = 'https://wax.atomichub.io/market?collection_name=crypto4shots&order=asc&sort=price&symbol=WAX&schema_name=';
  return (
    <span style={{ textAlign: 'center' }}>
      {noDupes.map((schema = '') => <>
          <a
            href={`${AH_MARKET_LINK}${schema}`}
            target="_blank" rel="noreferrer"
            className="category-schemas"
          >
            {schema}
          </a>&nbsp; &nbsp;
        </>)}
    </span>
  );
};


export class Category extends Component {
  constructor(props) {
    super(props);
    const ownedSchemas = prepSchemas({ schemas: Object.values(this.props.data || {}) });
    this.state = {
      missingItemsOpen: false,
      categorySchemas: ownedSchemas,
    };
  }

  onTabClick = async ({ userNftsArr: data = {}, categoryId }) => {
    // OWNED ITEMS EXPANDED
    if (this.state.missingItemsOpen) {
      isLocal && console.log('owned items:', { data });
      const ownedSchemas = prepSchemas({ schemas: Object.values(data) });
      this.setState((state) => ({
        ...state,
        missingItemsOpen: false,
        missingItemsList: [],
        categorySchemas: ownedSchemas,
      }));
      return;
    }
    // MISSING ITEMS EXPANDED
    const missingItemsList = await getMissingItemsList({ userNftsArr: data, categoryId });
    // ADD PRICE
    for (let id = 0; id < missingItemsList.length; id++) {
      const currentNft = missingItemsList[id];
      const templateId = currentNft.ID;
      const { price } = await getItemPrice({ templateId });
      currentNft.price = price;
    }
    isLocal && console.log('missing items:', { missingItemsList });
    const missingItemsSchemas = prepSchemas({ schemas: missingItemsList });
    this.setState((state) => ({
      ...state,
      missingItemsOpen: true,
      missingItemsList,
      categorySchemas: missingItemsSchemas,
    }));
  };

  render() {
    const { name, data } = this.props;

    const categoryId = name.replace(/ /g, '_');
    const itemsInCategory = ITEMS_BY_CATEGORY[categoryId];
    const maxItems = Object.keys(itemsInCategory).length;
    const userItemsCount = Object.keys(data).length;

    if (name === 'OTHERS' && !userItemsCount) return null; // hide Others category if empty

    const itemsRow = prepRowItems({
      isOpen: this.state.missingItemsOpen,
      missingItemsList: this.state.missingItemsList,
      userNfts: data,
    });
    const missingItemsCount = name === 'OTHERS' ? 0 : Math.max(0, maxItems - userItemsCount);

    return (
      <div className="container mt-1 text-center category-container">
        <div className="row head">
          <div className="col-7">
            <div className="row" style={{ padding: 0 }}>
              <span className="col-6" style={{ fontWeight: 'bold', color: 'black' }}>{name}</span>
              <span className="col-6">{this.state.categorySchemas}</span>
            </div>
          </div>
          <div className="col-5" style={{ padding: '2px' }}>
            {
              // /* TAB */
              missingItemsCount === 0 ?
                (<p>
                  {userItemsCount > 0 ? <b className="you-got-all">You got {
                    maxItems > 1 ? `all ${maxItems}` : 'it'
                  } !! 😎</b> : <>soon!</>}
                </p>) :
                <button
                  className={`missing-items-tab ${this.state.missingItemsOpen ? 'tab-open' : ''}`}
                  onClick={() => this.onTabClick({ userNftsArr: data, categoryId })}
                >
                  {!this.state.missingItemsOpen ?
                    <span className="missing-items-btns">
                      <span>
                        <span className={`${ missingItemsCount > 0 ? "missing-items-count" : ""}`}>
                          {missingItemsCount}
                        </span>
                        &nbsp; items missing</span> &nbsp;
                    </span>
                    : <span style={{ fontWeight: 'bold' }}>
                        Your {userItemsCount > 0 ? userItemsCount : ''} NFTs
                      </span>
                  }
                </button>
            }
          </div>
        </div>
        <div className="row">
          {/* TAB ITEMS */}
          <>{itemsRow}</>
        </div>
      </div>
    );
  }
};
