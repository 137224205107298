import commaNumber from 'comma-number';
import { isTestnet } from '../constants/envs';

export const formatBalance = (balance) => {
  isTestnet && console.log('Wax balance footer:', balance);
  if (balance == undefined) return; // eslint-disable-line
  const rounded = balance.toFixed(4);
  const displayValue = commaNumber(rounded);
  return `${displayValue} WAX`;
};
