import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { UserService } from '../../../services/UserService';
import { fetchBoomBalance } from '../../../sagas/helpers/wallet/waxUtils';
import { TOKEN_ACCOUNT } from '../../../config/contractNames';
import { storeAppDispatch } from '../../../GlobalState/Store';
import { setPlayerWaxTokensBalances } from '../../../reducers/WalletReducer';
import { isLocal, isTestnet } from '../../../constants/envs';
import { MONTHLY_INTEREST_BOOM, MONTHLY_INTEREST_MARS } from '../../../config/appConfig';
import { waxWalletFieldsValidation, displayBalance } from '../utils';
import { DoomBalanceAndSend } from '../HiveTokensPanes';
import { InfoIcon } from '../../common/InfoIcon';
import Swal from 'sweetalert2';


export const BoomPane = ({ wrapperData, scholarshipData, chainLogin }) => {
  const WalletState = useSelector((store) => store.wallet);
  const boomSavingsValue = get(wrapperData, 'sessionData.playerStake', 'n/a');
  const boomSavings = !Number.isNaN(+boomSavingsValue) ? (+boomSavingsValue).toFixed(4) : boomSavingsValue;
  const { isScholar, isTeacher, scholarPreviousBoomStake = 0 } = (scholarshipData || {});

  const [boomReceiver, setBoomReceiver] = useState();
  const [boomSendAmount, setBoomSendAmount] = useState();

  const onHandleSendBOOM = () => {
    try {
      isLocal && console.debug('Sending boom:', { boomSendAmount, boomReceiver });
      const { err, recipient, toSend } = waxWalletFieldsValidation({ boomSendAmount, boomReceiver });
      if (err) {
        console.error('Boom send validation error:', err); return;
      }
      UserService.session.signTransaction(
        {
          actions: [{
            account: TOKEN_ACCOUNT,
            name: 'transfer',
            authorization: [{
              actor: UserService.authName,
              permission: 'active'
            }],
            data: {
              from: UserService.authName,
              to: recipient,
              quantity: `${toSend.slice(0, -4)} BOOM`,
              memo: '$BOOM transfer from Crypto-Shots Web UI',
            },
          }],
        },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      ).then(async (response) => {
        isLocal && console.log('Boom send response status:', { response });
        setBoomReceiver(undefined);
        setBoomSendAmount(undefined);
        if (response.status !== 'executed')
          return;
        const isOk = window.confirm('✅ BOOM Transfer success! Do you want to see the transaction?');
        if (isOk)
          window.open(`https://wax${isTestnet ? '-test' : ''}.bloks.io/transaction/${response.transactionId}`, '_blank');
        const boomAmount = await fetchBoomBalance(UserService.authName);
        const savingsAmount = await Promise.resolve(0); // TEMP for savings @@ @@ @@ @@
        storeAppDispatch(setPlayerWaxTokensBalances({
          tokens: { boom: boomAmount },
          stakedSavings: { boom: savingsAmount }
        }));
      })
        .catch(err => Swal.fire({text: `❌ BOOM transfer failed - ${err}`}));;
    } catch (err) {
      console.error('Caught error reading send boom input fields', err);
    }  
  };

  return (<div className="row mt-4">
    {chainLogin === 'hive'
      ? <DoomBalanceAndSend />
      :  <div className="col-6 flashy-box">
          <h6 className='text-white mt-3'>$BOOM balance: <span id="boom-balance-wallet">{displayBalance(WalletState.tokens.boom, 'B')}</span>
            <p style={{ marginTop: '10px' }}>
              <a className="alcor-buy-boom"
                href="https://wax.alcor.exchange/trade/boom-csboomcsboom_wax-eosio.token"
                target="_blank" rel="noreferrer noopener"
              >
                BUY $BOOM
              </a>
            </p>
            <div className="btn-send">
              <button
                className="btn btn-success btn-lg"
                onClick={onHandleSendBOOM}
                disabled={chainLogin === 'hive'}
              >Send $BOOM</button>
            </div>
            <input
              id="recipient-boom" placeholder='RECIPIENT'
              style={{ width: '180px', fontSize: '14px' }} type='text'
              value={boomReceiver}
              onChange={(e) => setBoomReceiver(e.target.value)}
            /><br />
            <input
              id="amount-boom" placeholder='AMOUNT'
              style={{ width: '180px', fontSize: '14px' }} type='number'
              value={boomSendAmount}
              onChange={(e) => setBoomSendAmount(+e.target.value)}
            />
          </h6>
        </div>}

    <div className="col-6 flashy-box">
      <h4 className='mt-1 text-white'>In-game staked <sub>ig</sub>BOOM: <span id="boom-savings">{boomSavings}</span></h4>
      <p id="pre-ss-stake">{isScholar && <span>Of these, {scholarPreviousBoomStake} <sub>ig</sub>BOOM are your personal stake pre-scholarship.</span>}</p>
      <p>{isTeacher && <span>...plus part of the stake of your Scholar(s).</span>}</p>
      <p className='text-white up10'><i style={{ fontSize: '12px' }}>Withdrawals available daily after playing</i></p>
      <p className='text-white up10'>Current monthly interest: <span>{MONTHLY_INTEREST_BOOM} %</span></p>
      <button className="btn btn-success btn-sm" disabled={true} style={{ marginTop: '15px' }}>
        Burn 500 igB and mint<br />a rare/epic NFT
      </button>
      <InfoIcon/>
    </div>
  </div>);
};

export const MarsPane = ({ wrapperData, scholarshipData, chainLogin }) => {
  const WalletState = useSelector((store) => store.wallet);
  const marsBalance = get(WalletState, 'tokens.mars', 0);

  const marsSavingsValue = get(wrapperData, 'apiResponse.savings.mars.amount', 'n/a');
  const { isScholar, isTeacher, scholarPreviousMarsStake = 0 } = (scholarshipData || {});
  const marsLink = chainLogin === 'mars'
    ? 'https://wax.alcor.exchange/trade/mars-csboomcsboom_boom-csboomcsboom'
    : 'https://hive-engine.com/trade/MARS';

  return (<div className="row mt-4">
    <div className="col-6 flashy-box">
      <h6 className='text-white'>$MARS balance: {marsBalance}</h6>
      <p style={{ marginTop: '10px', fontSize: '14px' }}>
        <a className="alcor-buy-boom"
          href={marsLink}
          target="_blank" rel="noreferrer noopener"
        >
          BUY $MARS
        </a>
      </p>
      <div className="btn-send" style={{ marginTop: '-10px' }}>
        <button disabled className="btn btn-success btn-lg" onClick={() => {}}>Send $MARS</button>
        <br /><input id="recipient-mars" placeholder='RECIPIENT' style={{ width: '180px', fontSize: '14px' }} disabled type='text'></input>
        <br /><input id="amount-mars" placeholder='AMOUNT' style={{ width: '180px', fontSize: '14px' }} disabled type='number'></input>
      </div>
    </div>

    <div className="col-6 flashy-box">
        <h4 className='mt-2 text-white'>In-game staked <sub>ig</sub>MARS: <span id="mars-savings">{marsSavingsValue}</span></h4>
        <p id="pre-ss-stake">{isScholar && <span>Of these, {scholarPreviousMarsStake} <sub>ig</sub>BOOM are your personal stake pre-scholarship.</span>}</p>
        <p>{isTeacher && <span>...plus part of the stake of your Scholar(s).</span>}</p>
        <p className='text-white up10'><i style={{ fontSize: '12px' }}>Withdrawals available daily after playing</i></p>
        <p className='text-white up10'>Current monthly interest: <span>{MONTHLY_INTEREST_MARS} %</span></p>
        <button className="btn btn-success btn-sm" disabled={true} style={{ marginTop: '15px' }}>
          Burn 1000 igM and mint<br/>a rare/epic NFT
        </button>
        <InfoIcon/>
    </div>
  </div>);
};
