import React from 'react';

export const HotAssets = ({ hotNfts = {} }) => {
  const names = Object.keys(hotNfts);
  const values = Object.values(hotNfts);
  return (
    <p style={{ fontSize: '16px', color: '#ffec55' }}>
      {names.map((name, id) => <sub key={`hotnft${id}`}>{values[id]}x <b>{name}</b> &nbsp;</sub>)}
    </p>
  );
};
