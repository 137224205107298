import React from 'react';
import LogoWrite from '../../../images/logos/LogoWrite2.png';

export const LogoLanding = () => {
  return (<>
    <div id="logo-wrapper">
      <img id="logo" src={LogoWrite} alt="LogoWrite" />
      <p
        className="verdana"
        style={{ color: 'rgba(255,255,255,0.2)', fontSize: '24px', marginTop: '-35px' }}
      >
        <span style={{ marginLeft: '110px', color: 'white' }}>
          First Person Shooter
        </span>
      </p>
    </div>
  </>);
};
