import { ADMINS, MODS } from "./accounts";

export const LEADERBOARD_ADMINS = [
  ...ADMINS,
  'boomfungible',
  ...MODS,
  // Partners:
  'xofr2.wam', // ANDREW
];

export const leaderboardInterval = 15 * 60 * 1000;
export const countPerLoad = 15;

export const MAX_USERS_CHART = 50;

export const tokens = ['boom', 'mars'];
export const levels = [0, 1, 2];
export const difficulties = ['easy', 'medium', 'hard'];
export const periods = ['weekly', 'monthly'];
export const properties = ['kills', 'scores', 'bestTime']
export const recentStatsCategories = ['staked', 'kills', 'bestTime']
export const countShowBestTime = 10;
export const countShowPlayedTime = 10;
