/* eslint-disable no-unused-vars */
import hivejs from '@hiveio/hive-js';
import { get } from 'lodash';
import { isLocal } from '../../constants/envs';


const hiveNodes = [
  'https://anyx.io',
  'https://rpc.ecency.com',
  'https://api.hive.blog',
  'https://api.hive.blue',
  // 'https://rpc.ausbit.dev',
];
// 'https://api.openhive.network',
// 'https://hive-api.arcange.eu',
// https://api.c0ff33a.uk
// https://api.pharesim.me
// https://api.hive.blue
// https://hived.emre.sh
// 'https://api.deathwing.me', // cant search by tx

export const pickHiveNode = () => {
  const randId = Math.floor(Math.random() * hiveNodes.length);
  const pickedNode = hiveNodes[randId];
  return pickedNode;
};

export const setRandNode = () => {
  const pickedNode = pickHiveNode();
  hivejs.api.setOptions({ url: pickedNode });
  return pickedNode;
};


const getHiveChainProps = () => new Promise((res, rej) => {
  hivejs.api.getDynamicGlobalProperties((err, result) => {
    if (err) rej(err);
    else res(result);
  });
});

export const readHiveUserBalances = ({ username }) => new Promise((resolve) => {
  setRandNode();
  try {
    hivejs.api.getAccounts([username], (err, result) => {
      isLocal && console.log('Account data:', err, result);
      if (err) {
        console.error('Unable to fetch account balance:', err);
        return resolve({});
      }
      const hiveBalance = get(result, '[0].balance');
      const hbdBalance = get(result, '[0].hbd_balance');
      const vestingShares = get(result, '[0].vesting_shares');
      isLocal && console.debug('Hive liquid balances:', { hiveBalance, hbdBalance });
      getHiveChainProps()
        .then((globals) => {
          try {
            const totalSteem = Number(globals.total_vesting_fund_hive.split(' ')[0]);
            const totalVests = Number(globals.total_vesting_shares.split(' ')[0]);
            const userVests = Number(vestingShares.split(' ')[0]);
            const hivePower = totalSteem * (userVests / totalVests);
            isLocal && console.log('All Hive balances:', { hiveBalance, hbdBalance, hivePower });
            resolve({ hiveBalance, hbdBalance, hivePower });
          } catch (err) {
            console.error('Caught retrieving hive power:', err);
            resolve({ hiveBalance, hbdBalance });
          }                    
        });
    });
  } catch (err) {
    console.error('Caught retrieving hive balances:', err);
    resolve({});
  }
});


// ## WHITELIST stored in Hive

const simpleEncoder = (whitelistObj) => {
  const stringified = JSON.stringify(whitelistObj);
  const urld = encodeURIComponent(stringified);
  const btoed = btoa(urld).replace(/=/g, '');
  const reversed = btoed.split('').reverse().join('');
  const result = btoa(reversed).replace(/=/g, '');
  return result;
};

// const whitelistObj = {
//   whitelistUsers: [
//     '//---MODS',
//     '//---EARLY-TEAM',
//     '//---YOUTUBERS',
//   ],
//   mappedSneakers: {
//     crypto4sh4ts: 'pvbxq.wam//saylormoon',
//   }
// };
// simpleEncoder(whitelistObj);

export const simpleDecoder = (data = '') => {
  const firstChar = data.substr(0, 1);
  if (firstChar === '{' || firstChar === '[') return data;
  const atobed = atob(data);
  isLocal && console.log({ atobed });
  const reversed = atobed.split('').reverse().join('');
  isLocal && console.log({ reversed });
  const decoded = atob(reversed);
  isLocal && console.log({ decoded });
  const deurld = decodeURIComponent(decoded);
  return deurld;
};


// ## READ from given permlink.

export const fetchHiveData = ({ author = 'crypto-shots', permlink }) => new Promise((resolve, reject) => {
  setRandNode();
  isLocal && console.debug('Fetching from hive:', { author, permlink });
  hivejs.api.getContent(author, permlink, (err, data) => {
    if (err) {
      console.error('Error in Hive fetch response', err);
      reject({ err: 'hive-fetch-ko' });
      return;
    }
    if (!data || (data.result && !data.result.length)) {
      console.error('No data in Hive fetch response', err);
      reject({ err: 'res-empty-array-hive' });
      return;
    }
    let json;
    try {
      if (!data.body) {
        console.error(`Unable to read Hive data. Data: ${JSON.stringify(data)}`);
        reject({ err: 'hive-no-body' });
        return;
      }
      json = JSON.parse(simpleDecoder(data.body));
      isLocal && console.debug('Fetched from hive:', { json });
    } catch (err) {
      console.error('Unable to parse into json:', err);
      reject({ err: 'caught-in-hive-fetch' });
      return;
    }
    resolve({ whitelistObj: json, json }); // keep both
  });
});
