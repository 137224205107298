export const ENTRIES_SEPARATOR = ',';

export const BOOM_AMOUNT_SEPARATOR = ':';
export const TOKEN_INITIAL_SEPARATOR = '_';
export const TRANSFER_PARTIES_SEPARATOR = '->';


export const cleanUserList = (usersToRankReset) => {
  const userArray = usersToRankReset.split(ENTRIES_SEPARATOR)
    .filter(e => !!e).map(el => el.trim());
  const withoutDupes = Array.from(new Set(userArray));
  return withoutDupes;
};

const reverseTxt = (txt = '') => txt.split('').reverse().join('');

export const generateChecksum = ({ account, player }) => {
  const reverseAcc = reverseTxt(account);
  const reversePlayer = reverseTxt(player);
  const encoded = btoa(`${reverseAcc}.${reversePlayer}`).replace(/=/g, '');
  return reverseTxt(encoded);
};

export const cleanTransfersList = (savingsTransfers) => {
  const transfersArray = savingsTransfers.split(ENTRIES_SEPARATOR)
    .filter(e => !!e).map(el => el.trim());
  const withoutDupes = Array.from(new Set(transfersArray));
  const result = withoutDupes.map((transfer = '', id) => {
    try {
      const [totalTxt, parties] = transfer.split(BOOM_AMOUNT_SEPARATOR);
      const [amountTxt, tokenInitial = 'B'] = totalTxt.split(TOKEN_INITIAL_SEPARATOR);
      const [sender, receiver] = parties.split(TRANSFER_PARTIES_SEPARATOR);
      return ({ sender, receiver, amount: +amountTxt, tokenInitial });
    } catch (xx) {
      return ({ error: `Something broke parsing transfer #${id}: ${xx}` });
    }
  });
  return result;
};
