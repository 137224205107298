// Removes wam/anchor preference default.
// ( To remove anchor default account as well could also nuke anchor-* )
export const nukeCloudWalletAutoChoice = () => {
  Object.keys(localStorage).forEach(
    key => key.startsWith('ual-') && localStorage.removeItem(key)
  );
};
window.onbeforeunload = nukeCloudWalletAutoChoice;

export const nukeAnchorSavedAccount = () => {
  Object.keys(localStorage).forEach(
    key => key.startsWith('anchor-link-') && localStorage.removeItem(key)
  );
};
