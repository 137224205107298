import React from 'react';

export const SocialLinks = () => {
  return (
    <>
      <a id="twitter" href="https://x.com/cryptoshots_nft" target="_blank" rel="noreferrer">
        <img src="https://files.peakd.com/file/peakd-hive/latino.romano/AKLskaNgx225SY1NDNp17vqdCvhKehV8jwnPxSTbwEXBymCckAauin3ZNPPJGvK.png" alt="twitter-link" />
      </a>
      <a id="discord" href="https://discord.gg/nBXHAHzcQP" target="_blank" rel="noreferrer">
        <img src="https://files.peakd.com/file/peakd-hive/latino.romano/AKF8B7s5RpE2hderg1AeVVoYDxMevayZW9YVqGZSc1q6F6yzfd3vFDt8m26XCGa.png" alt="discord-link" />
      </a>
      <a id="website" href="https://crypto-shots.com" target="_blank" rel="noreferrer">
        <img src="https://files.peakd.com/file/peakd-hive/gaottantacinque/23wC7sNJY1HjeeaRythqGYahjrBgnZhbdLFWZCd4trBwLa7Fz95LUvzW8CUQAYupcqD5C.png" alt="website-link" />
      </a>
    </>
  );
};
