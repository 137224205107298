import React from 'react';

export const Penalties = () => {
  return (<p style={{
    marginTop: '15px', marginBottom: '0px', color: 'red', lineHeight: '18px',
    fontWeight: 'bold', fontSize: '14px',
  }}>
    {!!window.skippedResourcesPaym && <span>-90% rewards<br />[no FOOD/GAS]</span>}
    {!!window.freeShip && <span>-50% rewards<br />[captain]</span>}
  </p>);
};
