import { invoke } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { isTestnet } from '../../../constants/envs';
import { MINIMUM_REQUIREMENTS_INFO } from '../../../constants/genericConstants';
import './WelcomeScreenNewUser.scss';


const WELCOME_LINKS = {
  STARTER_KIT: 'https://wax.atomichub.io/market?collection_name=crypto4shots&order=asc&schema_name=cs.startpack&sort=price&symbol=WAX',
  WEAPONS: 'https://wax.atomichub.io/market?collection_name=crypto4shots&order=asc&schema_name=levelweapons&sort=price&symbol=WAX',
  CHARACTERS: 'https://wax.atomichub.io/market?collection_name=crypto4shots&order=asc&schema_name=cscharacters&sort=price&symbol=WAX',
  ENERGY_CELLS: 'https://wax.atomichub.io/market?collection_name=crypto4shots&match=cell&order=asc&schema_name=cs.gameitems&sort=price&symbol=WAX',
  SHIELDS: 'https://wax.atomichub.io/market?collection_name=crypto4shots&match=shield&order=asc&schema_name=cs.gameitems&sort=price&symbol=WAX',
  SKINS: 'https://wax.atomichub.io/market?collection_name=crypto4shots&match=skin&order=asc&schema_name=skins.cschar&sort=price&symbol=WAX',
  CONDENSERS: 'https://wax.atomichub.io/market?collection_name=crypto4shots&match=condenser&order=asc&schema_name=cs.gameboost&sort=price&symbol=WAX',
  WP: 'https://peakd.com/@cryptoshots.nft/crypto-shots-whitepaper',
};


const HotItemsMsg = ({ hotItemsCount, showBoomInventory }) => (<div className="hot-items"><br/>
  <p className="cooldown-msg">One or more NFTs are getting teleported to your inventory.. ⌛</p><br/>
  <p>There is a <b style={{ color: 'yellow' }}>12 hour cooldown</b> for NFTs unless they are new mints
    <br/><span className="new-mints-msg">(ie. blending, pack opening, purchase via&nbsp;
    <a className="alcor-buy-boom"
      href="https://wax.alcor.exchange/trade/boom-csboomcsboom_wax-eosio.token"
      target="_blank" rel="noreferrer noopener">
      $BOOM
    </a>
    ).</span>
    <br/><sub>The cooldown is in place to prevent players from sharing items during the day.</sub>
  </p>
  <div id="call-to-action">
    <br/>
    <p>In the wait time you can still purchase NFTs with <a id="alcor-buy-boom"
        href="https://wax.alcor.exchange/trade/boom-csboomcsboom_wax-eosio.token"
        target="_blank" rel="noreferrer noopener">
        $BOOM
      </a>.<br/>These are not subject to cooldown.
    </p>
    <button
      id="free-trial" className="btn btn-success btn-lg"
      onClick={showBoomInventory}
    >
      PURCHASE NFTS 
    </button>
  </div>
</div>);

const NewPlayerMsg = ({ showBoomInventory, chainLogin }) => {
  const isWax = chainLogin === 'wax';

  return (<div className="new-player-sell">
    <br/>
    <div id="nft-sales-container">
      <ul id="stuff-list">
        <p>GET a <a
          href={isWax ? WELCOME_LINKS.STARTER_KIT : 'https://hive.crypto-shots.com'}
          target="blank" rel="noreferrer noopener"
        >STARTER KIT</a> to start playing and earn!! <div className="new-line"><br/></div> &nbsp; Pew Pew! &nbsp; 💥</p>
        <br/>
        <li>Want to pick up more GUNS? Get some <a
          href={isWax ? WELCOME_LINKS.WEAPONS : 'https://hive.crypto-shots.com'}
          target="blank" rel="noreferrer noopener"
        >WEAPON NFTs</a></li><br/>
        <li>Want more play TIME? Get some <a
          href={isWax ? WELCOME_LINKS.CHARACTERS : 'https://hive.crypto-shots.com'}
          target="blank" rel="noreferrer noopener"
        >CHARACTERS</a></li><br/>
        <li>Want more AMMO? Get some <a
          href={isWax ? WELCOME_LINKS.ENERGY_CELLS : 'https://hive.crypto-shots.com'}
          target="blank" rel="noreferrer noopener"
        >CELLS</a></li><br/>
        {!isWax && <div style={{ textAlign: 'left', fontSize: '12px' }}>
            (available only on Wax for now)
          </div>}
        <div style={{ border: isWax ? 'none' : 'thin solid lightblue' }}>
          <li>Longer LIFE? Get some <a
            href={WELCOME_LINKS.SHIELDS}
            target="blank" rel="noreferrer noopener"
          >SHIELDS</a> or <a
            href={WELCOME_LINKS.SKINS}
            target="blank" rel="noreferrer noopener"
          >SKILL SKINS</a></li><br/>
          <li>Boost your REWARD$? Get some <a
            href={WELCOME_LINKS.CONDENSERS}
            target="blank" rel="noreferrer noopener"
          >CONDENSERS</a></li>
        </div>
        <br/><br/><br/>
        <p>
          No NFTs?&nbsp;
          Play FOR FREE
          &nbsp;<a href="https://crypto-shots.com/scholarships" target="_blank" rel="noreferrer">borrowing for FREE</a> the assets of another user!
        </p>
        <p>Doubts? Check out our&nbsp;
          <a
            id="wp-link"
            href={WELCOME_LINKS.WP}
            target="blank" rel="noreferrer noopener"
          >
            WHITEPAPER
          </a>
        </p>
      </ul>
      <div id="call-to-action">
        <button
          id="free-trial" className="btn btn-success btn-lg"
          onClick={
            isWax
              ? showBoomInventory
              : () => window.open('https://hive.crypto-shots.com', '_blank')
          }
        >
          PURCHASE NFTS with {
            isWax
              ? <span>$BOOM (no cooldown here!)</span>
              : <span>DOOM</span>
          }
        </button>
      </div>
    </div>
  </div>);
};


export const WelcomeScreenNewUser = ({ name, hotNfts, showBoomInventory }) => {
  isTestnet && console.log('Displaying welcome screen.', hotNfts);

  const UserState = useSelector((store) => store.user);
  const { chainLogin } = UserState;

  const hasMinReqWarning = (Object.keys(hotNfts)[0] || '') === MINIMUM_REQUIREMENTS_INFO;
  const hotItemsCount = Object.keys(hotNfts).length - 1; // remove -1 to test <hotItemsMsg>

  const createUserAndShowInventory = async () => {
    invoke(window, 'fetchUserData');
    showBoomInventory(true);
  };

  return <div className="row welcome">
    <div className="row">
      <div className="col-12 text-center total-nfts">
        <div className="row">
          <div id="litepaper-links">
            {(chainLogin === 'skl' || chainLogin === 'eth') && <><br/><br/><br/><br/></>}
            <h4>WELCOME {name} !! 😎</h4>
            {(chainLogin === 'skl' || chainLogin === 'eth')
              ? <>
                  <Link to="/play">
                    <button className="btn btn-danger" style={{ fontSize: '28px', margin: '60px' }}>PLAY PVP</button>
                  </Link>
                </>
              : <>{(hotItemsCount >= 0 && !hasMinReqWarning)
                    ? <HotItemsMsg {...{ hotItemsCount, showBoomInventory: createUserAndShowInventory }}/>
                    : <NewPlayerMsg {...{ showBoomInventory: createUserAndShowInventory, chainLogin }}/>
                }</>
            }
          </div>
        </div>
      </div>
    </div>
  </div>;
};
