const MY_ID = '898594874419453973';


// ## SWAP ISSUES

export const triggerSwapAlert = (args) => {
  const discordUrl = 'https://discord.com/api/webhooks/1016229102690246727/atfDghvTtlVBcpi7thsb4Nm0Xe6a2T7WEmtqmy7FdslkIp60mDdhm---mAdMo7cG8eKA';
  const errMsg = JSON.stringify(args, null, 2).split('Authorization')[0].slice(0, 1900);
  try {
    fetch(
      discordUrl,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          content: `<@${MY_ID}> <@mods/support>
            Issue with SWAP:
            ${errMsg}
          `,
        }),
      },
    )
      .then((res) => {
        if (res.status < 300) console.log('Discord msg published for swap issue.', { res });
        else console.error('Discord notifications fail.', { res });
      })
      .catch(err => console.error('Something went wrong publishing to discord.', err));
  } catch (err) {
    console.error('Caught error publishing to discord.', err);
  }
};


// ## INVENTORY BUY ISSUE

export const notifyOfInventoryBuyIssue = ({ currentBuy, errMsg }) => {
  const discordUrl = 'https://discord.com/api/webhooks/1036008458593505321/2C3XGZczlHBJ62YOIKFZrohu4cG4vf5t9jro65j6dlmch6sEN8pLG1aTrjhVBrLTT0qV';
  try {
    const body = JSON.stringify({
      content: `<@${MY_ID}> Inventory purchase issue: ${errMsg}\n${JSON.stringify(currentBuy)}`
        .split('Bearer ')[0].slice(0, 1900),
    });
    fetch(
      discordUrl,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      },
    )
      .then((res) => {
        if (res.status < 300) console.log('Discord msg published for inventory buy issue.', { res });
        else console.error('Discord notifications fail.', { res });
      })
      .catch(err => console.error('Something went wrong publishing inventory buy issue msg to discord.', err));
  } catch (err) {
    console.error('Caught error publishing inventory buy issue msg to discord.', err);
  }
};


// ## ADMIN UI

export const notifyOfRankReset = ({ resetter, accountsReset }) => {
  const discordUrl = 'https://discord.com/api/webhooks/1036006986443132979/h2ErWdJClsqTdZb8VkVicNkB_YyBlF1Iynza6PDCuHa3W1E1h11TxAZ7CjbhqDQ2pd7k';
  try {
    fetch(
      discordUrl,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          content: `[] ${resetter} did reset rank for: ${accountsReset.join(', ')}`,
        }),
      },
    )
      .then((res) => {
        if (res.status < 300) console.log('Discord msg published for rank resets.', { res });
        else console.error('Discord notifications fail.', { res });
      })
      .catch(err => console.error('Something went wrong publishing rank reset msg to discord.', err));
  } catch (err) {
    console.error('Caught error publishing rank reset msg to discord.', err);
  }
};

export const notifyOfStakeTransfer = ({ transferer, transfers }) => {
  const discordUrl = 'https://discord.com/api/webhooks/1036007723243942050/-Dq7MAxXuleuj1s8QdIGqyrzqGtrlybDkOQXirHDt4URQ1nF5eJ53JdCkZn2vZfxc9nV';
  try {
    fetch(
      discordUrl,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          content: `[] ${transferer} did transfer stake:\n${transfers.join(', ')}`,
        }),
      },
    )
      .then((res) => {
        if (res.status < 300) console.log('Discord msg published for stake transfers.', { res });
        else console.error('Discord notifications fail.', { res });
      })
      .catch(err => console.error('Something went wrong publishing stake transfer msg to discord.', err));
  } catch (err) {
    console.error('Caught error publishing stake transfer msg to discord.', err);
  }
};
