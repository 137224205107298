
export const calcTotWins = ({ totalWins = {} }) => {
  try {
    return Object.values(totalWins)
      .filter(count => count > 0)
      .reduce((partialSum, num) => partialSum + num, 0);
  } catch (err) {
    console.error('Caught in calcTotWins:', err);
  }
};
