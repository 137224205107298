import React from 'react';
import { useSelector } from 'react-redux';
import { STAKE_TO_CLAIM } from '../../config/appConfig';
import { isLocal } from '../../constants/envs';
import { calcTotWins } from './subComponents/claims/wrapperData/utils';


const showClean = (value) => {
  if (!value) return <sub>n/a</sub>;
  return value?.toFixed ? value.toFixed(3) : value;
}

const toIsoDate = (dateNum) => new Date(dateNum).toISOString().split('T')[0];

const claimedToday = ({ amount, dateOfLastClaim }) => {
  if (!amount) return 0;
  const isSameDay = dateOfLastClaim === toIsoDate(Date.now());
  const result = isSameDay ? amount.toFixed(3) : 0;
  return result;
};

const prepStakeToClaimMsg = ({ rank, savingsBoom }) => {
  try {
    const stakeToClaim = (rank - 1) * STAKE_TO_CLAIM;
    const s2cAmount = stakeToClaim > 0
      ? ((savingsBoom < stakeToClaim) ? <b style={{ color: 'red' }}>{stakeToClaim}</b> : stakeToClaim)
      : showClean(stakeToClaim);
    const s2cMsg = rank < 2
      ? <sub style={{ lineHeight: '12px' }}>
          &nbsp; &nbsp;<p style={{ display: 'inline-block' }}>0 for rank {rank}<br/>players</p>
        </sub>
      : s2cAmount;
    console.log('STYLE FIX:', stakeToClaim);
    const s2cStyle = isNaN(stakeToClaim) ? { marginTop: '-10px' } : { marginTop: '-25px' };
    return ({ s2cMsg, s2cStyle });
  } catch (err) {
    console.error('Caught in prepStakeToClaimMsg:', err);
  }
};


export const PlayerStats = () => {
  const PlayState = useSelector((store) => store.play);
  const { sessionData = {}, gameData = {}, apiResponse = {} } = PlayState.wrapperData;
  isLocal && console.log('SESSION DATA:', { sessionData, apiResponse, gameData });
  const {
    playerLevel: rank, matchCount: sessionMatches, totalKills,
    totalWins, unlockedConsecutiveWins, unlockedFastKills,
  } = sessionData;
  const { taxApplied } = gameData?.rewards || {};
  const { joinDate, savings = {}, transactionStats = {} } = apiResponse;
  const { boom = {}, mars = {} } = savings;
  const { amount: savingsBoom, lastWithdrawTimestamp: lastWithBoom } = boom;
  const { amount: savingsMars, lastWithdrawTimestamp: lastWithMars } = mars;
  const {
    dateOfLastClaim,
    totBoomClaimed, totBoomClaimedForCurrentDay,
    totMarsClaimed, totMarsClaimedForCurrentDay,
  } = transactionStats;

  const totWins = calcTotWins({ totalWins });
  const percentWins = ((totWins / (sessionMatches || 1)) * 100).toFixed(2);
  const { s2cMsg, s2cStyle } = prepStakeToClaimMsg({ rank, savingsBoom });

  return (
    <div className="col-12 text-center mt-2 game-result" id="player-stats">
      <h2 className="text-white mt-1"><b>PVE</b> &nbsp; PLAYER STATS</h2><br />
      <div class="container row" style={{ color: 'white' }}>
        <div className="col-5">
          {/* tech debt, dom api */}
          <div style={{ display: 'none' }} className="flashy-box flashy-blue">
            <p>Total Kills: &nbsp; <span id="total-kills"><sub>n/a</sub></span></p>
            <p>Rank: &nbsp; <span id="player-level"><sub>n/a</sub></span> &nbsp; </p>
          </div>
          <div className="row cointainer">
            <div className="col-2"></div>
            <div className="col-10">
              <div className="flashy-box flashy-blue" style={{ paddingTop: '20px', textAlign: 'left' }}>
                <p className="same-token">Rank: {rank || <sub>n/a</sub>}</p>
                <p className="same-token">Total Kills: {totalKills || <sub>n/a</sub>}</p>
                <p className="same-token">TAX: <span>{taxApplied || 0}%</span></p>
                <p className="same-token" style={{ marginTop: '10px' }}>Joined: {joinDate ? <sub>{joinDate}</sub> : 'OG'}</p>
              </div>
            </div>
            <div className="col-2"></div>
            <div className="col-10" style={{ marginTop: '10px' }}>
              <div className="flashy-box flashy-blue" style={{ paddingTop: '20px', textAlign: 'left' }}>
                <p className="same-token">Session Matches: {sessionMatches || 0}</p>
                <p className="same-token">Session Wins: {totWins} <sub style={{ color: 'aqua' }}>({percentWins} %)</sub></p>
                <p className="same-token">Consecutive L2 Wins: <span style={{ color: 'aqua' }}>{unlockedConsecutiveWins ? 'yes' : 'no'}</span></p>
                <p className="same-token">Fast Kills: <span style={{ color: 'aqua' }}>{unlockedFastKills ? 'yes' : 'no'}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-7" style={{ textAlign: 'left' }}>
          {/* deprecated, dom manipulation.. hidden now */}
          <div className="flashy-box flashy-blue" style={{ display: 'none' }}>
            <p className="same-token gap">Tax on gaming rewards: <span id="tax-on-rewards"><sub>n/a</sub></span> %</p>
            <p className="light-blue same-token"><sub>ig</sub>BOOM currently staked: <span id="boom-staked"><sub>n/a</sub></span></p>
            <p className="light-blue same-token"><sub>ig</sub>BOOM claimed today: <span id="boom-claimed-today"><sub>n/a</sub></span></p>
            <p className="light-blue same-token gap">Total <sub>ig</sub>BOOM withdrawn: <span id="boom-claimed-total"><sub>n/a</sub></span></p>
          </div>

          <div className="flashy-box flashy-blue" style={{ padding: '20px 10px 0px' }}>
            <div>
              <p className="light-blue same-token"><sub>ig</sub><b>BOOM</b> currently staked: {showClean(savingsBoom)}
                <sub style={{ float: 'right' }}>{lastWithBoom && <span style={{ lineHeight: '12px' }}>Last:<br/>{toIsoDate(lastWithBoom)}</span>}</sub>
              </p>
              <p className="light-blue same-token gap"><sub>ig</sub><b>MARS</b> currently staked: {showClean(savingsMars )}
                <sub style={{ float: 'right' }}>{lastWithMars && <span style={{ lineHeight: '12px' }}>Last:<br/>{toIsoDate(lastWithMars)}</span>}</sub>
              </p>
              <p className="light-blue same-token gap" style={s2cStyle}>igBOOM to claim new rewards: {s2cMsg}</p>
            </div>
          </div>
          <div className="flashy-box flashy-blue" style={{ marginTop: '10px', padding: '20px 10px 10px' }}>
            <div>
              <p className="light-blue same-token gap">Last token withdrawal: {showClean(dateOfLastClaim)}</p>
              <p className="light-blue same-token"><sub>ig</sub><b>BOOM</b> claimed today:&nbsp;
                {claimedToday({ amount: totBoomClaimedForCurrentDay, dateOfLastClaim })}
              </p>
              <p className="light-blue same-token gap"><sub>ig</sub><b>BOOM</b> withdrawn total: {showClean(totBoomClaimed)}</p>
              <p className="light-blue same-token"><sub>ig</sub><b>MARS</b> claimed today:&nbsp;
                {claimedToday({ amount: totMarsClaimedForCurrentDay, dateOfLastClaim })}
              </p>
              <p className="light-blue same-token up"><sub>ig</sub><b>MARS</b> withdrawn total: {showClean(totMarsClaimed)}</p>
            </div>
          </div>
        </div>
      </div>
      &nbsp;
    </div>
  );
};
