import React from 'react';
import { UserService } from '../../../services/UserService';
import { processInventory } from '../../../sagas/helpers/inventory/processInventory';
import { fetchBoomBalance } from '../../../sagas/helpers/wallet/waxUtils';
import { setPlayerInventory } from '../../../reducers/InventoryReducer';
import { storeAppDispatch } from '../../../GlobalState/Store';
import { setPlayerWaxTokensBalances } from '../../../reducers/WalletReducer';
import { getCachedPrices, getItemPrice } from '../../../sagas/helpers/inventory/marketplacePrices';
import { isLocal } from '../../../constants/envs';
import { transferAndMint } from '../../../sagas/helpers/inventory/transferAndMint';
import { isInventoryDisabled } from "../../../config/appRemoteStatus";
import { get } from 'lodash';
import { capitalize, isValidNumber, nap } from '../../../generalUtils/utils';
import { isWaxBuyItem, waxPurchItems } from './utils/waxPurchItems';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import './Tile.scss';
import { getWaxNftPathS3 } from '../../../sagas/helpers/inventory/extractNfts';
import { ALL_NFTS_FLAT } from '../../../constants/templateIds';
import Swal from 'sweetalert2';

const NEW_PURCHASE_WAIT_SEC = 60;


const AhLink = ({ tid }) => {
  const AH_TEMPLATE_LINK = `https://wax.atomichub.io/explorer/template/crypto4shots/${tid}`;
  return <a
    href={`${AH_TEMPLATE_LINK}`}
    target="_blank" rel="noreferrer"
    style={{ float: 'right', marginRight: '15px', textDecoration: 'none', fontWeight: 'bold', fontSize: '14px' }}
  >AH</a>;
};

const DisplayPrice = ({ price, nftTemplate }) => {
  if (isWaxBuyItem(nftTemplate)) {
    const cost = get(waxPurchItems, `${nftTemplate}.priceWax`);
    return <div>{cost} WAX</div>
  }
  const hasPriceInChainTable = isValidNumber(price);
  return hasPriceInChainTable
    ? <div style={{ fontSize: '16px', color: 'lightgreen', padding: '5px' }}>{JSON.stringify(price)} BOOM</div>
    : <span style={{ color: 'grey' }}>N/A in $BOOM</span>;
};


export const Tile = ({ name, data }) => {
  const UserState = useSelector((store) => store.user);
  const [lastPurchaseTs, setLastPurchaseTs] = useState(null);

  const fetchUserCsAssets = async () => {
    await nap(5 * 1000); // wait for new NFT to be in inventory
    const { err, csNfts, hotNfts, scholarship } = await processInventory({ username: UserService.authName });
    if (err) return;
    const currentState = {
      csAssets: { csNfts, hotNfts }, refresh: true,
      scholarship,
    };
    storeAppDispatch(setPlayerInventory(currentState));
  };

  const onHandlePurchase = async ({ template }) => {
    setLastPurchaseTs(Date.now());
    // puchase count check
    const { value, MAX_ALLOWED } = get(window, 'totNftsMintsToday', {});
    if (value > MAX_ALLOWED) {
      Swal.fire({ text: `Cannot purchase more items until midnight UTC. You already got ${MAX_ALLOWED} today.`})
      return;
    }
    if (isInventoryDisabled()) {
      Swal.fire({ text: `The Inventory purchases are temporarily disabled.\n\nPlease try again in a bit.`})
      return;
    }
    const isWaxBuy = isWaxBuyItem(template);
    let purchasePrice;
    if (isWaxBuy) {
      purchasePrice = get(waxPurchItems, `${template}.priceWax`);
    } else {
      isLocal && console.debug('Fetching BOOM price..', { template });
      const { price, err } = await getItemPrice({ templateId: template });
      if (err) {
        Swal.fire({text: `Unable to fetch prices, please try in a bit.`})
        return; 
      }
      purchasePrice = price;
    }
    const currency = isWaxBuy ? 'WAX' : 'BOOM';
    const isOk = window.confirm(`🛒 Purchase item ${template} for ${purchasePrice} ${currency}? 🛒`);
    if (!isOk) return;
    if (lastPurchaseTs && ((Date.now() - lastPurchaseTs) < (NEW_PURCHASE_WAIT_SEC * 1000))) {
      Swal.fire({text: `Please leave at least ${NEW_PURCHASE_WAIT_SEC} between purchases.`})
      return;
    }
    isLocal && console.debug('Initiating user transfer and telling BE to mint');
    const purchaseErr = await transferAndMint({
      UserService, UserState,
      template, price: purchasePrice, currency
    });
    if (purchaseErr) return;
    await nap(2 * 1000);
    try {
      isLocal && console.debug('Refreshing inventory..');
      fetchUserCsAssets(); // no await
      isLocal && console.debug('Refreshing balances..');
      const boomAmount = await fetchBoomBalance(UserService.authName);
      const savingsAmount = await Promise.resolve(0); // TEMP for savings @@ @@ @@ @@
      storeAppDispatch(setPlayerWaxTokensBalances({
        tokens: { boom: boomAmount },
        stakedSavings: { boom: savingsAmount }
      }));
      isLocal && console.debug('Post-purchase ops completed.');
    } catch (err) { console.error('Caught in post-purchase:', err); }
  };

  const onPurchaseClick = async ({ nftTemplate, category = '' }) => {
    if (!isWaxBuyItem(nftTemplate)) {
      const availableItems = await getCachedPrices({ idsOnly: true });
      const categoryLc = category.toLowerCase();
      const categoryName = categoryLc.endsWith('ss') ? categoryLc : (categoryLc.endsWith('s') ? categoryLc.slice(0, -1) : categoryLc);
      if (!availableItems.includes(nftTemplate)) {
        const itemName = capitalize(categoryName.replace(/_/g, ' '));
        Swal.fire({text: `${itemName} ${nftTemplate} not available yet ❌`})
        return;
      }
    }
    onHandlePurchase({ template: nftTemplate });
  };

  const {
    template, schema, nftName, img, s3Img, amount = 1, category, price, // user owned item
    ID, NAME, OG_NAME, IMG, SCHEMA, // user missing item
  } = data;
  const nftTemplate = template || ID;
  const nameOverride = ALL_NFTS_FLAT.find(({ ID }) => ID === nftTemplate)?.NAME; // PS. useless now because override applied higher up - cybergirls collapsed into 1
  const nftDisplayName = nameOverride || nftName || NAME;
  // https://ipfs.io/ipfs/, https://ipfs.atomichub.io/ipfs/
  const ipfsImg = (IMG && `https://gateway.pinata.cloud/ipfs/${IMG}`);
  const s3ImgFromConfig = SCHEMA && `https://play.crypto-shots.com/nft-images/wax/${
    getWaxNftPathS3({ schema: SCHEMA, name: OG_NAME || NAME, template_id: ID })}`;
  const nftImage = s3Img || s3ImgFromConfig || img || ipfsImg;
  const shouldDisplayAmount = (!ID && nftImage) && category !== 'OTHERS';
  const displayAmount = shouldDisplayAmount ? amount : '';
  return (
    <div className="col-md-2 mt-2 tile-container">
      <div className="row text-center" style={{ marginBottom: '0px', borderRight: 'thin solid rgba(134, 233, 255, 0.2)' }}>
        <div class="col-8">
        <button id={nftTemplate}
          onClick={() => onPurchaseClick({ nftTemplate, category })}
          style={{ float: 'left', marginLeft: '15px' }}
        >
         <DisplayPrice {...{ price, nftTemplate }} />
        </button>
        </div>
        <div class="col-4">
          <AhLink tid={nftTemplate}/>
        </div>
        <p className='mt-3' style={{ minHeight: '45px', fontSize: '14px' }}>
          {nftDisplayName}
        </p>
        <p className="tile-img-wrapper" style={{ minHeight: '60px', maxHeight: '150px' }}>
          {nftImage &&
            <a
              href={ipfsImg || img}
              target="_blank" rel="noreferrer noopener"
              className="tile-img"
            >
              <img src={nftImage} alt={`${name} - ${ipfsImg || img}`} style={{ height: '130px' }}></img>
            </a>
          }
        </p>
        <p style={{ float: 'right', color: 'lightgreen', fontWeight: 'bolder', marginBottom: '5px' }}>
          {displayAmount}
        </p>
      </div>
    </div>
  );
};
