import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLocal } from '../../constants/envs';
import { ITEMS_BY_CATEGORY } from '../../constants/templateIds';
import './GameUiOverlay.scss';

const { BADGES/*, SCHOLARSHIPS*/ } = ITEMS_BY_CATEGORY;

const getBadgeIcons = ({ waxNfts }) => {
  try {
    const badgeValues = [...Object.values(BADGES)];
    const nftsValues = Object.values(waxNfts);
    const icons = badgeValues.map(({ ID, NAME, IMG }) => {
      const userHasThisNft = !!nftsValues.find(nft => nft.template_id === ID);
      isLocal && console.debug('User has NFT?', { NAME, userHasThisNft });
      if (userHasThisNft) {
        isLocal && console.debug('Found badge:', NAME);
        return ({ ID, IMG, NAME });
      }
      return null;
    }).filter(el => !!el);
    return icons;
  } catch (err) {
    console.error('Broken in getBadgeIcons:', err);
    return [];
  }
};


export const GameUiOverlay = () => {
  const UserState = useSelector((store) => store.user);
  const { isLogged } = UserState;

  const GameOverlayState = useSelector((store) => store.gameOverlay);
  const badges = GameOverlayState.badges;

  const InventoryState = useSelector((store) => store.inventory);

  const waxScholarshipData = get(InventoryState, 'scholarship', {});
  const { isScholar: isWaxScholar } = waxScholarshipData;

  const waxNfts = get(InventoryState, 'csAssets.csNfts', []);
  const hiveData = get(InventoryState, 'hive', {});
  const { nfts: hiveNfts = [], teacher: hiveTeacher } = hiveData;

  if (!isLogged || (!waxNfts.length && !hiveNfts.length)) return null;

  isLocal && console.debug('GameOverlay component props:',
    { badges },
    { waxNfts: waxNfts.length, hiveNfts: hiveNfts?.length, isScholar: !!(isWaxScholar || hiveTeacher) },
  );
  
  const icons = getBadgeIcons({ waxNfts });

  return (
    <div
    id="game-overlays"
    style={{ display: 'none' }}
    >
      {/* ----------- PS. NOW HIDDEN. Would make more sense with game badges. ------- */}
      {/* ----------- REUSE THIS COMPONENT FOR MAINTENANCE FEES STUFF? ------- */}
      <p id="title">
        <a
          href="https://wax.atomichub.io/explorer/schema/crypto4shots/cscommbadges"
          target="_blank"
          rel="noreferrer"
        >
          BADGES
        </a>
      </p>
      {icons.map(({ ID, IMG: hash, NAME }) => <a
          href={`https://wax.atomichub.io/explorer/template/crypto4shots/${ID}`}
          target="_blank" rel="noreferrer"
        >
          <img
            src={`https://gateway.pinata.cloud/ipfs/${hash}`}
            key={hash}
            alt={NAME.substr(0,6)}
          />
        </a>
      )}
    </div>
  );
};
