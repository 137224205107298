import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    wax: 0,
    tokens: { // wax tokens only
      boom: 0,
      food: 0,
      gas: 0,
      mars: 0,
    },
    stakedSavings: { // igBoom - currenty left to 0 here. API hack.
      boom: 0,
    },
    hiveTokens: {
      doom: 0,
    }
};

const wallet = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
      setPlayerWaxBalance: (state, action) => ({ ...state, wax: action.payload.wax }),
      setPlayerWaxTokensBalances: (state, action) => {
        const { tokens: tokensUpdated, stakedSavings } = action.payload;
        return ({
          ...state,
          tokens: { ...state.tokens, ...tokensUpdated },
          stakedSavings: stakedSavings || state.stakedSavings,
        });
      },
      setPlayerHiveTokensBalances: (state, action) => {
        const { hiveTokens: tokensUpdated } = action.payload;
        return ({
          ...state,
          hiveTokens: { ...state.hiveTokens, ...tokensUpdated },
        });
      },
    },
});

export const {
  setPlayerWaxBalance,
  setPlayerWaxTokensBalances,
  setPlayerHiveTokensBalances,
} = wallet.actions;

export default wallet.reducer;
