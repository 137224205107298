/* eslint-disable no-useless-concat */
import { get, invoke } from 'lodash';
import { isLocal, isTestnet } from '../constants/envs';
import { cacheBustParam } from '../helpers/client';
import { UserService } from '../services/UserService';
import { fetchHiveData } from './hiveData/reader';
import { windowSetChecksum } from './secure/checksums';
import Swal from 'sweetalert2';


const originalAel = window.addEventListener;

export const postLoginOverride = async (auth) => {
  var stack = new Error().stack.substring(0, 40);
  if (!stack.includes('debugger') && !stack.includes('anonymous')) return;
  invoke(window, 'newrelic.notify', { auth, ua: navigator.userAgent, ip: '12.232.23.43' }); // todo
  const handleEventListeners = (...args) => {
    console.error(
      '.emirc a si gniod era uoy tahW !deggalf neeb evah tnegA-resU dna SO ,sserdda PI ,tellaw xaw ruoY'.split('').reverse().join(''),
    );
    originalAel(...args);
  };
  window.addEventListener = handleEventListeners;
  windowSetChecksum({ key: 'addEventListener', value: handleEventListeners });
};

export const restorePostLoginOverride = () => {
  window.addEventListener = originalAel;
  windowSetChecksum({ key: 'addEventListener', value: originalAel });
};


// ## ANTI-ABUSE

const multiAccState = {
  currentUsername: null,
};

// ## called after login
export const usernamesChecks = (username = '') => {
  const chain = localStorage.getItem('chainLogin');
  multiAccState.currentUsername = `${chain}__${username}`;
};
// TODO: @@ incorrect chain previous login >> fix with store.getState() @@

let multiAccWhitelist = [];

const checkIsWhitelisted = ({ waxAddr }) => new Promise((resolve) => {
  if (multiAccWhitelist.length) {
    resolve(multiAccWhitelist.includes(waxAddr));
    return;
  }
  try {
    window.fetch(`/json/multiAccWhitelist.json${cacheBustParam()}`)
      .then(res => res.json())
      .then((data = []) => {
        multiAccWhitelist = data;
        resolve(multiAccWhitelist.includes(waxAddr));;
      })
      .catch((err) => {
        console.error('wl.json fetch err:', err);
        resolve(true);
      });
  } catch (err) { console.error('wl-err:', err); }
});

const notifyDiscordMultiAccount = ({ storedUname, msg }) => {
  if (isTestnet || localStorage.getItem('testerZ')) return;
  setTimeout(async () => {
    try {
      const isWl = await checkIsWhitelisted({ waxAddr: storedUname });
      if (isWl) return;
      fetch(
        'https://discord.com/api/webhooks/963379005124390932/ZzLNjKnrUqOlDR5NgQtvfsRa1if2Vuw8r-bAjy2tC5NBZu5JIXjF1APVbfFUPP_eMZPP',
        {
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            content: `MULTI ACC: ${msg}`,
          }),
        },
      )
        .then(res => console.warn('hooked'))
        .catch(err => console.error('hook-ko1', err));
    } catch (err) {
      console.error('hook-ko2', err);
    }
  }, 30 * 1000);
};

// ## called on play
const multiAccCheck = ({ playerStats }) => {
  const storedUname = atob(localStorage.getItem('plyd') || '')
  localStorage.setItem('plyd', btoa(multiAccState.currentUsername).replace(/=/g, ''));
  const storedUnameChain = storedUname.split('__')[0];
  if (storedUname && (multiAccState.currentUsername !== storedUname)) {
    const currentChain = localStorage.getItem('chainLogin');
    if (storedUnameChain !== currentChain) {
      isLocal && console.debug('Not triggering warning, since 2 differnt chains');
      return;
    }
    notifyDiscordMultiAccount({
      storedUname,
      msg: `${storedUname} -> ${
        multiAccState.currentUsername}\n${multiAccState.currentUsername} stats: ${
          JSON.stringify(playerStats, null, 2)}`,
    });
  }
};
window.multiAccCheck = multiAccCheck;
windowSetChecksum({ key: 'multiAccCheck', value: multiAccCheck });


// Example: window.skippedResourcesPaym === true
export const hasImmutableValueOf = ({ target, attribute, matchValue }) => {
  const targetProperties = invoke(Object, 'getOwnPropertyDescriptor', target, attribute);
  const isWritable = get(targetProperties, 'writable');
  if (!isWritable) return false;
  return get(target, attribute) === matchValue;
};


// ## FROZEN ACCOUNTS

export let blacklistedUsers = {
  // 'account.name': 'blaclist reason',
};

export const fetchBlacklistedUsers = () => {
  isTestnet && console.debug('fetching bl..');
  return fetch(`/json/bl.json${cacheBustParam()}`)
    .then(res => res.json())
    .then(async (data = {}) => {
      isTestnet && console.debug('Blacklisted users:', data);
      blacklistedUsers = data;
      try {
        const { json: modsData } = await fetchHiveData({ author: 'cryptoshots.mods', permlink: 'cryptoshots-mods-lists' });
        modsData?.uiBlock.forEach(blUser => { blacklistedUsers[blUser] = 'mods-managed-bl-on-hive'; });
      } catch (err) {
        console.error('Error fetching blacklisted assets.', err);
      }
    })
    .catch(err => console.error('bl fetch err:', err));
};
fetchBlacklistedUsers();


// ## LINKED ACCOUNTS

// { data: [{ loginChain: 'wax', wax: 'kfdgj434jlkJSDF.234', hive: 'jkdsafDSFH32j.352', eth: 'SHD$Kjs3kjJD.393' }] }
export let linkedAccounts = { data: [] };

const obfuscate = address => btoa(btoa(address).split('').reverse('').join('')).replace(/=/g, '')
  + '.' + `${Date.now()}`.slice(-4);

export const isPlayerCrossChainSecondary = ({ authAccount, authChain }) => {
  try {
    if (!linkedAccounts.data.length) {
      setTimeout(fetchLinkedAccounts, 5 * 1000);
    }
    const indexOfTarget = linkedAccounts.data.findIndex(
      (entry = {}) => entry[authChain] && entry[authChain].slice(0, -4) === obfuscate(authAccount).slice(0, -4),
    );
    isLocal && console.debug('cross-chain records:',
      { size: linkedAccounts.data?.length, recordId: indexOfTarget });
    if (indexOfTarget < 0) return false;
    const record = { ...linkedAccounts.data[indexOfTarget] };
    // clear memory
    linkedAccounts.data = [record];
    if (record.loginChain !== authChain) return true;
  } catch (err) {
    console.error('something broke in cross-chain accounts check:', err);
  }
  return false;
};

export const fetchLinkedAccounts = async () => {
  isTestnet && console.debug('fetching cross-chain links..');
  try {
    const { json: linkedAccountsData } = await fetchHiveData({
      author: 'crypto-shots',
      permlink: isTestnet ? 'cross-chain-records-tests' : 'cross-chain-records',
    });
    linkedAccounts = linkedAccountsData;
    isLocal && console.debug('Cross-chain links:', { linkedAccounts });
  } catch (err) {
    console.error('Error fetching cross-chain links:', err);
  }
};
fetchLinkedAccounts();


// ## FROZEN ASSETS

export const blacklistedAssetIds = { data: [] };

const fetchBlacklistedAssets = async () => {
  try {
    const { json: modsData } = await fetchHiveData({ author: 'cryptoshots.mods', permlink: 'cryptoshots-mods-lists' });
    blacklistedAssetIds.data = [...modsData.blacklistedAssets];
    isLocal && console.debug('Bl assets:', { blacklistedAssetIds });
  } catch (err) {
    console.error('Error fetching blacklisted assets.', err);
  }
};
fetchBlacklistedAssets();



// ## Post-login checks

// play
export const updateBlacklists = () => {
  isLocal && console.debug('Updating blacklists');
  try {
    fetchBlacklistedUsers();
    fetchBlacklistedAssets();
  } catch (err) {
    console.error('upd-bl-ko', err);
  }
};

// claim
export const antiCheatersCheck = async ({ waxNfts = [], isWaxUser, isScholar } = {}) => {
  isLocal && console.debug('Checking user before claim...');
  const isCheater = isWaxUser && !isScholar && await UserService.isNewCheaterAccount();
  if (isCheater) {
    Swal.fire({ text: 'Something about your account is suspicious. Please reach out to our mods' })
    throw new Error('claim-ko-usr-bl');
  }
  isLocal && console.debug('Checking assets before claim...');
  for (let id = 0; id < waxNfts.length; id++) {
    const { asset_id } = waxNfts[id] || {};
    if (blacklistedAssetIds.data.includes(asset_id)) {
      Swal.fire({text: 'One/more of your assets is in one/more blacklists. Please reach out to support.' })
      throw new Error('claim-ko-ass-bl');
    }
  }
};


// ---- MULTI TAB CHECK ----

let cachedLoginTimestamp;

// ## Called after login
export const multiTabCheckAfterLogin = () => {
  const lastLogin = localStorage.getItem('mt_ts');
  const TWO_HOURS = 2 * 60 * 60 * 1000;
  const now = Date.now();
  localStorage.setItem('mt_ts', now);
  cachedLoginTimestamp = now;
  // recent login check
  const isRecentLogin = lastLogin && ((Date.now() - +lastLogin) < TWO_HOURS);
  if (isRecentLogin) {
    Swal.fire({ text: 'You cant play in more than one tab! Please log out after playing.' })
    localStorage.clear();
    window.location.reload();
    window.location.href = '/';
    // fallback in case redirects disabled somehow
    Swal.fire({ text: 'Logout and log back in or you wont be able to play or claim' })
  	throw new Error('mt-login');
  }
};

// Called after logout
export const nukeMultiTabTimestamp = () => {
  localStorage.removeItem('mt_ts');
  cachedLoginTimestamp = null;
}
// ...and tentatively right before hard close
window.addEventListener('beforeunload', nukeMultiTabTimestamp);


// Called before play AND before claim
export const multiTabCheckBeforePlayAndClaim = () => {
  const lastLogin = localStorage.getItem('mt_ts');
  const noLastLogin = !lastLogin;
  const loginTsMismatch = (+lastLogin !== cachedLoginTimestamp);
  if (noLastLogin || loginTsMismatch) {
    Swal.fire({ text: `Did you clear your cache while playing? Are you playing from multiple tabs?\n\nAccount under review. For security reasons login again.
    \nReason: ${noLastLogin ? 'nll' : 'ltm'}` })
  	window.location.reload();
    throw new Error('mt-play');
  }
};
