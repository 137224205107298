import { isLocal, isTestnet } from '../../../../constants/envs';
import { ITEMS_BY_CATEGORY } from '../../../../constants/templateIds';
import { freeTrippers } from './constants';


const { BOARDING_PASSES, SPACESHIPS } = ITEMS_BY_CATEGORY;
const {
  SEQ, EXE, FIR, // L1
  GEN: GENP, ART, EXA, // L2
} = BOARDING_PASSES;
const {
  FLW, FRW, FRA, FCR, FFU, FEN, ERA, ELW, ERW, ECR, EFU, EEN, ESE, // L1
  EXRA, EXLW, EXRW, EXCR, EXFU, EXEN, // L2 (Exarch) - ss1
  ARA, ALW, ARW, ACR, AFU, AEN, // L2 (Arthas) - ss2
  GLW, GRW, GFU, GCR, GRA, GEN, // L2 (Genesis) - ss3
} = SPACESHIPS;


let freeTravelsCache = {
  L1: {},
  L2: {},
  L3: { freeSs1: false, freeSs2: false, freeSs3: false },
};

const checkSpaceshipPasses = ({ nfts, travelingTo }) => {
  let hasSs1Pass, hasSs2Pass, hasSs3Pass;
  switch (travelingTo) {
    case 1: {
      hasSs1Pass = nfts.filter(nft => nft.template_id === SEQ.ID).length;
      hasSs2Pass = nfts.filter(nft => nft.template_id === EXE.ID).length;
      hasSs3Pass = isTestnet ? true : nfts.filter(nft => nft.template_id === FIR.ID).length;
      break;
    }
    case 2: {
      hasSs1Pass = nfts.filter(nft => nft.template_id === EXA.ID).length;
      hasSs2Pass = nfts.filter(nft => nft.template_id === ART.ID).length;
      hasSs3Pass = isTestnet ? true : nfts.filter(nft => nft.template_id === GENP.ID).length;
      break;
    }
    case 3: { isLocal && console.debug('Ignored pass check to L3 - button not available anyway'); break; }
    default: { throw new Error(`check pass for unexpected level ${travelingTo}`); }
  }
  isLocal && console.debug('Has passes:', { hasSs1Pass, hasSs2Pass, hasSs3Pass });
  return ({ hasSs1Pass, hasSs2Pass, hasSs3Pass });
};

const checkSpaceshipPartOwnership = ({ nfts, travelingTo }) => {
  let hasSs1FullSpaceship, hasSs1Left, hasSs1Right, hasSs1Radar, hasSs1Contr, hasSs1Fuse, hasSs1Engine,
    hasSs2Right, hasSs2Left, hasSs2Radar, hasSs2Contr, hasSs2Fuse, hasSs2Engine,
    hasSs3Left, hasSs3Right, hasSs3Radar, hasSs3Contr, hasSs3Fuse, hasSs3Engine;
  switch (travelingTo) {
    case 1: {
      hasSs1FullSpaceship = /*isTestnet ? true :*/ nfts.filter(nft => nft.template_id === ESE.ID).length;
      hasSs2Right = nfts.filter(nft => nft.template_id === ERA.ID).length;
      hasSs2Left = nfts.filter(nft => nft.template_id === ELW.ID).length;
      hasSs2Radar = nfts.filter(nft => nft.template_id === ERW.ID).length;
      hasSs2Contr = nfts.filter(nft => nft.template_id === ECR.ID).length;
      hasSs2Fuse = nfts.filter(nft => nft.template_id === EFU.ID).length;
      hasSs2Engine = nfts.filter(nft => nft.template_id === EEN.ID).length;
      hasSs3Left = nfts.filter(nft => nft.template_id === FLW.ID).length;
      hasSs3Right = nfts.filter(nft => nft.template_id === FRW.ID).length;
      hasSs3Radar = nfts.filter(nft => nft.template_id === FRA.ID).length;
      hasSs3Contr = nfts.filter(nft => nft.template_id === FCR.ID).length;
      hasSs3Fuse = nfts.filter(nft => nft.template_id === FFU.ID).length;
      hasSs3Engine = nfts.filter(nft => nft.template_id === FEN.ID).length;
      break;
    }
    case 2: {
      hasSs1Left = nfts.filter(nft => nft.template_id === EXRA.ID).length;
      hasSs1Right = nfts.filter(nft => nft.template_id === EXLW.ID).length;
      hasSs1Radar = nfts.filter(nft => nft.template_id === EXRW.ID).length;
      hasSs1Contr = nfts.filter(nft => nft.template_id === EXCR.ID).length;
      hasSs1Fuse = nfts.filter(nft => nft.template_id === EXFU.ID).length;
      hasSs1Engine = nfts.filter(nft => nft.template_id === EXEN.ID).length;
      hasSs2Right = nfts.filter(nft => nft.template_id === ARA.ID).length;
      hasSs2Left = nfts.filter(nft => nft.template_id === ALW.ID).length;
      hasSs2Radar = nfts.filter(nft => nft.template_id === ARW.ID).length;
      hasSs2Contr = nfts.filter(nft => nft.template_id === ACR.ID).length;
      hasSs2Fuse = nfts.filter(nft => nft.template_id === AFU.ID).length;
      hasSs2Engine = nfts.filter(nft => nft.template_id === AEN.ID).length;
      hasSs3Left = /*isTestnet ? true :*/ nfts.filter(nft => nft.template_id === GLW.ID).length;
      hasSs3Right = nfts.filter(nft => nft.template_id === GRW.ID).length;
      hasSs3Radar = nfts.filter(nft => nft.template_id === GFU.ID).length;
      hasSs3Contr = nfts.filter(nft => nft.template_id === GCR.ID).length;
      hasSs3Fuse = nfts.filter(nft => nft.template_id === GRA.ID).length;
      hasSs3Engine = nfts.filter(nft => nft.template_id === GEN.ID).length;
      break;
    }
    case 3: { isLocal && console.debug('Ignored ownership check for L3 - button not available anyway'); break; }
    default: { throw new Error(`check wwnership for unexpected level ${travelingTo}`); }
  }
  const hasSs1Part = hasSs1FullSpaceship
    || hasSs1Right || hasSs1Left || hasSs1Radar
    || hasSs1Contr || hasSs1Fuse || hasSs1Engine;
  const hasSs2Part = hasSs2Right || hasSs2Left || hasSs2Radar
    || hasSs2Contr || hasSs2Fuse || hasSs2Engine;
  const hasSs3Part = hasSs3Left || hasSs3Right || hasSs3Radar
    || hasSs3Contr || hasSs3Fuse || hasSs3Engine;
  isLocal && console.debug('Spaceships ownership:', { hasSs1Part, hasSs2Part, hasSs3Part });
  return ({ hasSs1Part, hasSs2Part, hasSs3Part });
};

// Used for all levels above 0. The latter uses ResourcesPayment.jsx.
export const checkSpaceshipPassOrOwner = ({ nfts = [], user, travelingTo }) => {
  try {
    // -- use cache
    const levelSpecificTravelCache = freeTravelsCache[`L${travelingTo}`];
    if (Object.keys(levelSpecificTravelCache) > 0) return levelSpecificTravelCache;
    // -- vs calc and fill cache
    let freeSs1, freeSs2, freeSs3;
    // PASSES
    const { hasSs1Pass, hasSs2Pass, hasSs3Pass } = checkSpaceshipPasses({ nfts, travelingTo });
    // OWNERS
    const { hasSs1Part, hasSs2Part, hasSs3Part } = checkSpaceshipPartOwnership({ nfts, travelingTo });
    // checks
    freeSs1 = isLocal ? false : (hasSs1Pass || hasSs1Part);
    freeSs2 = isLocal ? false : (hasSs2Pass || hasSs2Part);
    const isWhitelisted = freeTrippers.includes(user);
    freeSs3 = isLocal ? false : (hasSs3Pass || hasSs3Part || isWhitelisted);
    // cache
    if (freeSs1 || freeSs2 || freeSs3) {
      freeTravelsCache[`L${travelingTo}`] = { freeSs1, freeSs2, freeSs3 };
    }
    isLocal && console.debug('Spaceships free usage:', { freeSs1, freeSs2, freeSs3, isWhitelisted, freeTravelsCache });
    return ({ freeSs1, freeSs2, freeSs3 });
  } catch (err) {
    console.error('Check spaceship pass or owner broke:', err);
    return ({});
  }
};
