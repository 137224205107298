import { client } from '../../../helpers/client';
import { TOKEN_ACCOUNT } from '../../../config/contractNames';
import { isLocal, isTestnet } from '../../../constants/envs';
import { templateReplacement } from './marketplacePrices';
import { get } from 'lodash';
import { WAX_ACCOUNTS } from '../../../config/accounts';
import { getPlayerUsername } from '../../../generalUtils/utils';
import { notifyOfInventoryBuyIssue } from '../../../services/monitoring/discordNotifications';
import { flyingSessionLogin, WaxFlyingSession } from '../../../services/UserService';
import Swal from 'sweetalert2';

let currentBuy;

export const transferAndMint = async ({ UserService, UserState, template, price, currency }) => {
  if (isTestnet) {
    template = templateReplacement({ mainnetTemplate: `${template}` });
  }
  let waxSession = UserService;
  const isHiveUser = localStorage.getItem('chainLogin') !== 'wax';
  if (isHiveUser && !UserService.authName) {
    isLocal && console.debug('creating side wax session for hive user');
    waxSession = WaxFlyingSession
    const isLoggedIn = await flyingSessionLogin(waxSession);
    if (!isLoggedIn) {
      Swal.fire({text: 'You did not successfully log in, please try again.'})
      return;
    }
  }
  isLocal && console.debug('Expected user to sign tx:', waxSession.authName);

  const amount = price && price.toFixed(currency === 'BOOM' ? 4 : 8);
  const account = currency === 'WAX' ? 'eosio.token' : TOKEN_ACCOUNT;
  const playerUsername = getPlayerUsername({ UserService, UserState });
  currentBuy = { template, price, currency, payer: waxSession.authName, playerUsername };
  isTestnet && console.debug('[market] Transfering for purchase:', { template, amount, currency });
  
  return waxSession.session.signTransaction(
    {
      actions: [{
        account,
        name: 'transfer',
        authorization: [{
          actor: waxSession.authName,
          permission: 'active'
        }],
        data: {
          from: waxSession.authName,
          to: WAX_ACCOUNTS.MARKETPLACE,
          quantity: `${amount} ${currency}`,
          memo: JSON.stringify({ template, amount, currency })
        }
      }]
    },
    {
      blocksBehind: 3,
      expireSeconds: 30
    }
  )
    .then(async (response) => {
      if (response.status !== 'executed') {
        Swal.fire({text: `Your transfer to purchase ${template} did not succeed`})
        return 'send-not-exec';
      }
      isTestnet && console.debug('[market] Sending mint request:', { template });
      let detail;
      const payload = {
        account: playerUsername, // can also be hive. For the latter we add waxSideAccount.
        templateIds: [`${template}`],
        purchaseId: response.transactionId,
      };
      if (isHiveUser) {
        payload.waxSideAccount = waxSession.authName;
      }
      isLocal && console.debug('sending purchase request', { playerUsername, payer: waxSession.authName });
      try {
        detail = await client.post(
          '/api/transaction/purchase',
          payload,
        );
      } catch (err) {
        let refundMsg = 'Unknown error';
        const errMsg = get(err, 'response.data', {});
        if (errMsg.error) {
          const refundedUser = get(errMsg, 'resRefundPayment.response.toAccount');
          const refundTx = get(errMsg, 'resRefundPayment.response.transactoin_id');
          refundMsg = refundedUser ? `User ${refundedUser} refunded. Transaction: ${refundTx}` : JSON.stringify(errMsg);
        }
        alertAndNotify(`Error minting your NFT. ${refundMsg}`);
        return 'mint-error';
      }
      isLocal && console.log('Mint result:', { detail });
      const status = get(detail, 'data.resMintNft[0].statusCode', 555);
      const error = get(detail, 'error', '');
      if (status > 300 || error) {
        alertAndNotify(`Uh oh, that did not work\n\n${JSON.stringify(error)}`);
        return (error || status);
      }
      const txId = get(detail, 'data.resMintNft[0].response.transaction_id', '');
      const showMe = txId && window.confirm(`Payment AND NFT minting completed.
      \nDo you want to see the purchase transaction?`);
      if (showMe) window.open(`https://wax${isTestnet
        ? '-test' : ''}.bloks.io/transaction/${txId}`, '_blank');
      return null;
    })
    .catch((err) => {
      const errMsg = `Caught error during transfer: ${err}`;
      console.error(errMsg);
      Swal.fire({text: errMsg});
      return 'transfer-req-error';
    });
};


const alertAndNotify = (msg) => {
  Swal.fire({text: msg});
  notifyOfInventoryBuyIssue({ currentBuy, errMsg: msg });
};
