import Store from '../../../../../GlobalState/Store';

// pvp -> inventory
// pve -> leaderboard
// Exceptions: 
// - dont show leaderboard if no nfts (so it can show welcome page)
// - (will still skip welcome page during f2e tourneys)
export const navigatePostLogin = ({ locationHistory, hasNfts = true }) => {
    const { play } = Store.getState();
    const destination = (play.isPvpGameMode || !hasNfts)
      ? '/inventory'
      : '/leaderboard';
    locationHistory.push(destination);
};
