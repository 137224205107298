import { nap } from "../utils";

export const uiHacks = {
  // the login modal (external component) has the 2 button chevrons out of place
  chevron: () => {
    const ts = setInterval(() => {
      const loginChoices = document.querySelectorAll('.ual-auth-button');
      for (let id = 0; id < loginChoices.length; id++) {
        const currentElem = loginChoices[id];
        if (!currentElem) { console.error('Elem not found for chevron hack'); continue; }
        currentElem.style.width = '100%';
      }
    }, 100);
    setTimeout(() => clearInterval(ts), 2000);
  },
  disableClaimAndPlayBtnsAtFirst: async ({ claimOnly = false } = {}) => {
    await nap(200);
    // const ts = setInterval(() => {
      const claimBtn = document.getElementById('claim-boom');
      const unstakeBtn = document.getElementById('unstake-btn');
      const playBtn = document.getElementById('play-button');
      // claim and unstake
      if (claimBtn) {
        if (claimBtn.getAttribute('been-here')) return; // not pristine
        claimBtn.setAttribute('disabled', 'true');
        claimBtn.setAttribute('bigger', '1.05x');
        claimBtn.setAttribute('been-here', 'ya');
      }
      if (unstakeBtn) {
        if (unstakeBtn.getAttribute('been-here')) return; // not pristine
        unstakeBtn.setAttribute('disabled', 'true');
        unstakeBtn.setAttribute('bigger', '1.05x');
        unstakeBtn.setAttribute('been-here', 'ya');
      }
      // play
      if (claimOnly) return;
      // if (playBtn) {
      //   if (playBtn.getAttribute('been-here')) return; // not pristine
      //   playBtn.setAttribute('disabled', 'true');
      //   playBtn.setAttribute('bigger', '1.05x');
      //   playBtn.setAttribute('been-here', 'ya');
      // }
    // }, 200);
    // setTimeout(() => {
    //   clearInterval(ts);
    //   const claimBtn = document.getElementById('claim-boom');
    //   const unstakeBtn = document.getElementById('unstake-btn');
    //   const playBtn = document.getElementById('play-button');
    //   claimBtn && claimBtn.removeAttribute('been-here');
    //   unstakeBtn && unstakeBtn.removeAttribute('been-here');
    //   playBtn && playBtn.removeAttribute('been-here');
    // }, 500);
  },
};
