import { HIVE_ITEMS_BY_CATEGORY, ITEMS_BY_CATEGORY } from '../../../../constants/templateIds';
import { getNftCount } from '../../../../generalUtils/utils';
import { RATIO_FOR_MOSTLY_HIVE_NFTS } from '../../../../config/appConfig';
import { isLocal } from '../../../../constants/envs';
import { windowSetChecksum } from '../../../../generalUtils/secure/checksums';


// WAX
const { WEAPONS, AMMUNITION, CHARACTERS, STARTER_KITS, EARLY_ACCESS } = ITEMS_BY_CATEGORY;
const { BG } = WEAPONS;
const { BA } = AMMUNITION;
const { VA, MA } = CHARACTERS;
const { SK, AKS, AKM, AKL, GXL } = STARTER_KITS;

// HIVE
const {
  CHARACTERS: hiveCharacters, AMMO: hiveAmmo, WEAPONS: hiveWeapons, SHIELDS, EARLY_ACCESS: earlyAccessHive,
} = HIVE_ITEMS_BY_CATEGORY;


// NFT REQUIREMENTS

let cachedMissingMandNfts = null;

export const areMandNftsMissing = ({ nfts: waxNfts = [], hiveNfts = [], isPvp = false }) => {
  if (cachedMissingMandNfts !== null) return cachedMissingMandNfts;
  if (isPvp) {
    cachedMissingMandNfts = areWhitelistNftsMissing({ nfts: waxNfts, hiveNfts });
  } else {
    const hasCharacter = (getNftCount({ nfts: waxNfts, id: VA.ID }) || getNftCount({ nfts: waxNfts, id: MA.ID })) // wax
      || (getNftCount({ hiveNfts, name: hiveCharacters.VANCE_TYRRELL.NAME })
      || getNftCount({ hiveNfts, name: hiveCharacters.MASHA_TALASHOVA.NAME })); // hive
    const hasBaseGun = getNftCount({ nfts: waxNfts, id: BG.ID }) // wax
      || getNftCount({ hiveNfts, name: hiveWeapons.BASE_GUN.NAME }); // hive
    const hasBaseAmmo = getNftCount({ nfts: waxNfts, id: BA.ID }) // wax
      || getNftCount({ hiveNfts, name: hiveAmmo.MYTHIC_AMMO.NAME })
      || getNftCount({ hiveNfts, name: hiveAmmo.LEGENDARY_AMMO.NAME }); // hive
    const hasThreeBaseItems = hasBaseGun && hasBaseAmmo && hasCharacter;
    const hasKit = getNftCount({ nfts: waxNfts, id: SK.ID }) || getNftCount({ nfts: waxNfts, id: AKS.ID })
      || getNftCount({ nfts: waxNfts, id: AKM.ID }) || getNftCount({ nfts: waxNfts, id: AKL.ID })
      || getNftCount({ nfts: waxNfts, id: GXL.ID });
    cachedMissingMandNfts = !hasThreeBaseItems && !hasKit;
    isLocal && console.debug('Are NFTs missing?',
      {
        hasCharacter, hasBaseGun, hasBaseAmmo,
        hasThreeBaseItems, hasKit, cachedMissingMandNfts,
      }
    );
  }
  return cachedMissingMandNfts;
};


let cachedValueWlNfts = null;

export const areWhitelistNftsMissing = ({ nfts: waxNfts = [], hiveNfts = [] }) => {
  if (cachedValueWlNfts !== null) return cachedValueWlNfts;
  // HIVE
  const hasL1Shield = hasHiveShield({ hiveNfts, level: 1 });
  const hasL2Shield = hasHiveShield({ hiveNfts, level: 2 });
  if (hasL1Shield && hasL2Shield) {
    cachedValueWlNfts = false;
    return false;
  }
  const hasPvpPassHive = getNftCount({ hiveNfts, name: earlyAccessHive.PVP_ALPHA_PASS.NAME });
  if (hasPvpPassHive) {
    cachedValueWlNfts = false;
    return false;
  }
  // WAX
  const hasPvpPass = getNftCount({ nfts: waxNfts, id: EARLY_ACCESS.PAA.ID });
  if (hasPvpPass) {
    cachedValueWlNfts = false;
    return false;
  }
  cachedValueWlNfts = true;
  return true;
};


// NFTS ON WHICH CHAIN 

let mostlyHiveNfts = null;

export const hasMostlyHiveNfts = ({ hiveNfts = [], waxNfts = [] } = {}) => {
  if (mostlyHiveNfts !== null) return mostlyHiveNfts;
  if (!hiveNfts.length && !waxNfts.length) {
    return !!localStorage.getItem('chainLogin') === 'hive';
  }
  mostlyHiveNfts = (hiveNfts.length / (waxNfts.length + hiveNfts.length)) > RATIO_FOR_MOSTLY_HIVE_NFTS;
  return mostlyHiveNfts;
};
window.hasMostlyHiveNfts = hasMostlyHiveNfts;
windowSetChecksum({ key: 'hasMostlyHiveNfts', value: hasMostlyHiveNfts });
try {
  const original = window.hasMostlyHiveNfts;
  Object.defineProperty(window, 'hasMostlyHiveNfts', { value: original, writable: false });
} catch (_) {}

export const hasHiveShield = ({ hiveNfts = [], level = 1 }) => {
  const shieldCategory = `L${level}_SHIELD`;
  return !!getNftCount({ hiveNfts, name: SHIELDS[shieldCategory].NAME });
};
