
export const LOST_BLOCKS_THRESHOLD = 5;

export const SWAP_RANGES = {
  BOOM: {
    MIN: 10,
    MAX: 10000,
  },
  DOOM: {
    MIN: 20,
    MAX: 20000,
  },
  FEE: 0.05,
  BD_RATIO: 2,
};
