import { consumeTourneyConfig } from "../../pages/Play/subComponents/utils/requiredNFts/pvpRemoteConfig";

const NO_BOTHER_MS = 10 * 60 * 1000; // 10 minutes in milliseconds


const canAskToJoinPvP = () => {
  const lastTimeAsked = localStorage.getItem('askedPvePlayerJoinF2e');
  if (!lastTimeAsked) {
    return true;
  }
  if ((Date.now() - parseInt(lastTimeAsked, 10)) >= NO_BOTHER_MS) {
    return true;
  }
  return false;
};

export const askPvePlayerJoinTourney = async ({ isPvp }) => {
  const { isPvpPassRequired = true } = await consumeTourneyConfig() || {};
  if (!isPvp && !isPvpPassRequired) {
    const canAsk = canAskToJoinPvP();
    if (canAsk) {
      localStorage.setItem('askedPvePlayerJoinF2e', Date.now());
      const ok = window.confirm('Free-To-Earn PVP Tournament in progress! Want to navigate to PVP to join?');
      if (ok) window.location.replace('/?pvp');
    }
  }
};
