import { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

import { storeAppDispatch } from '../../../../GlobalState/Store';
import { setStorage } from '../../../../reducers/MarsStorage';
import { FullScreenDismisser, StorageInstructions, CloseOverlayBtn } from './subComponents';
import { getSubDuration } from './utils';
import { SUBS_PRICES, SUB_TYPES } from './constants';
import { purchaseSubscription } from './purchaseSubs';
import { LoadingSpinner } from '../../../../images/gifs/LoadingSpinner';

import ScifiStorage2 from '../images/ScifiStorage2.gif';
import { isLocal } from '../../../../constants/envs';
import './StoragePane.scss';
import Swal from 'sweetalert2';

const StorageOverlay = ({ storageType, expirationInDays, setStorageOverlayOpen, resourcesPaid }) => {
  const [wantsUpgrade, setWantsChangePlan] = useState(false);
  isLocal && console.debug('Resouces Paid:', { resourcesPaid });

  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;
  const isHiveUser = chainLogin === 'hive';

  const handleSub = async (storageType) => {
    const duration = getSubDuration(storageType);
    setWantsChangePlan(false);
    setStorageOverlayOpen(false);
    const { err } = await purchaseSubscription({ storageType, isHiveUser });
    if (err) return;
    storeAppDispatch(setStorage({ storageType, expirationInDays: duration }));
    setStorageOverlayOpen(true);
  };

  return (
    <>
      <FullScreenDismisser setStorageOverlayOpen={setStorageOverlayOpen}/>
      <div id="storage-overlay" className="flashy-box">
        <div className="row container text-white mt-1">
          <div className="col-6">
            <p id="storage-title">STORAGE SUBSCRIPTIONS</p>
          </div>
          <div className="col-2">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="tooltip-storage"><StorageInstructions/></Tooltip>}
            >
              <InfoCircleFill className="tooltip-info" />
            </OverlayTrigger>
          </div>
          <div className="col-4">
            <div id="buy-mars" className="col-12">
              <a href="https://wax.alcor.exchange/trade/mars-csboomcsboom_boom-csboomcsboom" target="_blank" rel="noreferrer">
                BUY $MARS
              </a>
            </div>
          </div>
        </div>
        {(!!storageType && !wantsUpgrade)
          ? <>
              <div className="row container text-white mt-1">
                <div className="col-1"></div>
                <div className="col-5 pad10 nested-box row">
                  <div className="row">
                    Your Storage Type:<br/>
                    <b>{storageType}</b>
                  </div>
                  <div className="up15">
                    <span id="upgrade-btn" onClick={() => setWantsChangePlan(true)}>
                      Upgrade
                    </span>
                  </div>
                </div>
                <div className="col-1"></div>
                <div className="col-5 pad10 nested-box">
                  Subscription Expiration:<br/>
                  <i>in {expirationInDays} days</i>
                </div>
              </div>
              <div id="paid-resources" className="row container flashy-box mt-2">
                <span>Meals and Travels active until Midnight UTC: <span className="boldo">&nbsp;
                  {resourcesPaid.length}
                </span></span>
              </div>
            </>
          : <div id="purchase-options">
              <div className="row container text-white mt-1">
                <div className="col-2"></div>
                <div className="col-4">CLASSIC (week)</div>
                <div className="col-6">
                  {(storageType === SUB_TYPES.CLASSIC_WEEK)
                    ? <p className="using">in use</p>
                    : (!!storageType ? <></> : <button className="buy-sub btn-success" onClick={() => handleSub(SUB_TYPES.CLASSIC_WEEK)}>
                        <p className="purchase-for">Purchase for {SUBS_PRICES.v1[SUB_TYPES.CLASSIC_WEEK]} $MARS</p>
                      </button>)
                    }
                </div>
              </div>
              <div className="row container text-white mt-1">
                <div className="col-2"></div>
                <div className="col-4">CLASSIC (month)</div>
                <div className="col-6">
                  {storageType === SUB_TYPES.CLASSIC_MONTH
                    ? <p className="using">in use</p>
                    : (storageType !== SUB_TYPES.PREMIUM && <button className="buy-sub btn-success" onClick={() => handleSub(SUB_TYPES.CLASSIC_MONTH)}>
                        <p className="purchase-for">Purchase for {SUBS_PRICES.v1[SUB_TYPES.CLASSIC_MONTH]} $MARS</p>
                      </button>)
                    }
                </div>
              </div>
              <div className="row container text-white mt-1">
                <div className="col-2"></div>
                <div className="col-4">PREMIUM</div>
                <div className="col-6">
                {storageType === SUB_TYPES.PREMIUM ?
                  <p className="using">in use</p>
                  : <button className="buy-sub btn-success" onClick={() => handleSub(SUB_TYPES.PREMIUM)}>
                      <p className="purchase-for">Purchase for {SUBS_PRICES.v1[SUB_TYPES.PREMIUM]} $MARS</p>
                    </button>
                  }
                </div>
              </div>
              <div className="row container text-white mt-1">
                {(!storageType && !!expirationInDays) && <p id="expired-warn">Previous subscription expired.</p>}
              </div>
            </div>
        }
        <CloseOverlayBtn setStorageOverlayOpen={setStorageOverlayOpen}/>
      </div>
    </>
  );
};

export const StoragePane = () => {
  const [storageOverlayOpen, setStorageOverlayOpen] = useState(false);

  const StorageState = useSelector((store) => store.marsStorage);
  const { loading, storageType, expirationInDays, resourcesPaid } = StorageState;

  const UserState = useSelector((store) => store.user);
  const isHive = UserState.chainLogin !== 'wax';

  const handleStorageClick = () => {
    if (loading) return;
    if (isHive) {
      Swal.fire({text: 'On-chain storage not supported yet on Hive!'})
      return;
    }
    setStorageOverlayOpen(true)
  };

  return (
    <div id="storage-panel">
      {storageOverlayOpen
        && <StorageOverlay
            {...{ storageType, expirationInDays, setStorageOverlayOpen, resourcesPaid }}
          />
      }
      <div
        id="storage-menu-button"
        onClick={handleStorageClick}
      >
        {loading
          ? <LoadingSpinner style={{ width: '38px', marginTop: '15px', border: 'none' }}/>
          : <>
              <img id="scifi-storage" src={ScifiStorage2} alt="Storage" />;
              <div id="color-bar" style={{ backgroundColor: !!storageType ? 'darkgreen' : 'red' }}></div>
            </>
        }
      </div>
    </div>
  );
};
