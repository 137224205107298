import React from 'react';
import { isLocal, isTestnet } from '../../../../constants/envs';

const formatNumber = (num) => !isNaN(num) ? num.toFixed(4) : ''; // num;
const shorterTxt = (txt = '') => txt.substr(0, 35);


export const ClaimResult = ({ scholarshipData, claimResult = {} }) => {
  isLocal && console.debug('Data received in ClaimResult component:', { scholarshipData, claimResult });
  const { mainUser = {}, teacher = {} } = claimResult;
  if (!mainUser) return null;
  const {
    id: playerTxId, amount: playerClaimedAmount, playerAddr, stakedAmount,
    doomPlayer: doomClaimMsg = '', playerDoomTxLink = '',
    marsTx, marsAmount, marsStakedAmount, // MARS
  } = mainUser;
  const {
    id: teacherTxId, amount: teacherClaimedAmount, teacherAddr = '',
    doomTeacher = '', teacherDoomTxLink = '',
    marsTxTeacher, marsAmountTeacher, // MARS
  } = teacher;

  const linkBaseTx = `https://wax${isTestnet ? '-test' : ''}.bloks.io/transaction/`;
  const linkBaseAcc = `https://wax.bloks.io/account/`;
  const { scholarName, teacherName } = (scholarshipData || {});

  return (<div id="claim-result">
    {(playerTxId || teacherDoomTxLink || doomClaimMsg || marsTx) && <p style={{ fontSize: '12px' }}>
      {(playerTxId || marsTx) && <>
        Claimed: &nbsp;
          <span>
            {!!(+playerClaimedAmount) && <>{formatNumber(playerClaimedAmount)} $BOOM&nbsp;</>}
            {teacherTxId && <a href={`${linkBaseAcc}${scholarName}`} target="_blank" rel="noreferrer">
                {scholarName || playerAddr}<br/>
              </a>}
            {playerTxId && <span id="tx-id">[<a href={`${linkBaseTx}${playerTxId}`} target="_blank" rel="noreferrer">{
                shorterTxt(playerTxId)
              }</a>]</span>}
          </span>
          <span>
            {!!(+marsAmount) && <><br/>{formatNumber(marsAmount)} $MARS&nbsp;</>}
            {marsTxTeacher && <a href={`${linkBaseAcc}${scholarName}`} target="_blank" rel="noreferrer">
                {scholarName || playerAddr}<br/>
              </a>}
            {marsTx && <span id="tx-id">[<a href={`${linkBaseTx}${marsTx}`} target="_blank" rel="noreferrer">
                {shorterTxt(marsTx)}
              </a>]</span>}
          </span>
        </>
      }
      {!!(stakedAmount > 0 || marsStakedAmount > 0) && 
        <span>
          Staked because over max:
          {stakedAmount > 0 && <sub style={{ fontSize: '12px' }}><br/>{formatNumber(stakedAmount)} igBOOM</sub>}
          {marsStakedAmount > 0 && <sub style={{ fontSize: '12px' }}><br/>{formatNumber(marsStakedAmount)} igMARS</sub>}
        </span>
      }
      {(doomClaimMsg || playerDoomTxLink) && <sub style={{ fontSize: '12px' }}><br/><br/>
          {doomClaimMsg}&nbsp; - &nbsp;
          <a href={`https://he.dtools.dev/tx/${playerDoomTxLink}`} target="_blank" rel="noreferrer">Hive tx</a>
        </sub>}
    </p>}
    {(teacherTxId || teacherDoomTxLink || marsTxTeacher) && <div>
      <p>
        To Teacher <a href={`${linkBaseAcc}${teacherAddr}`} target="_blank" rel="noreferrer">{teacherAddr}</a>: &nbsp;
        {!!+teacherClaimedAmount && <>{formatNumber(teacherClaimedAmount)} $BOOM&nbsp;</>}
        {!!+marsAmountTeacher && <><br/>{formatNumber(marsAmountTeacher)} $MARS&nbsp;</>}
        {(teacherTxId || marsTxTeacher) && <sub style={{ fontSize: '10px' }}>
          {teacherTxId && <span id="tx-id-b"><br/>[<a href={`${linkBaseTx}${teacherTxId}`} target="_blank" rel="noreferrer">
              {shorterTxt(teacherTxId)
            }</a>]</span>}
          {marsTxTeacher && <span id="tx-id-m"><br/>[<a href={`${linkBaseTx}${marsTxTeacher}`} target="_blank" rel="noreferrer">
              {shorterTxt(marsTxTeacher)}
            </a>]</span>}
        </sub>}
      </p>
      {doomTeacher && <sub style={{ fontSize: '12px' }}><br/><br/>
        {doomTeacher}&nbsp; - &nbsp;
        <a href={teacherDoomTxLink} target="_blank" rel="noreferrer">Hive tx</a>
      </sub>}
    </div>}
  </div>);
};
