import React from 'react';
import CheckBox from 'rc-checkbox';
import { invoke } from 'lodash';
import { useSelector } from 'react-redux';
import { client } from '../../../../../helpers/client';
import { UserService } from '../../../../../services/UserService';
import { isLocal } from '../../../../../constants/envs';
import { getPlayerUsername } from '../../../../../generalUtils/utils';
import { getPendingRewardToClaim } from '../wrapperData/extractors';
import { LoadingSpinner } from '../../../../../images/gifs/LoadingSpinner';
import { getUnstakeOptionsStatus } from './utils';
import { calcTotWins } from '../wrapperData/utils';
import Swal from 'sweetalert2';


export const BoomRewardsActions = ({
  whichActiveToken, actions,
}) => {
  // USER STATE
  const UserState = useSelector((store) => store.user);

  // INVENTORY STATE
  const InventoryState = useSelector((store) => store.inventory);

  // PLAY STATE
  const PlayState = useSelector((store) => store.play);
  const { wrapperData = {} } = PlayState;
  const { apiResponse: apiInfo = {}, sessionData = {} } = wrapperData;
  const totalBoomRewards = +(getPendingRewardToClaim(apiInfo.boomRewardsToClaim)).toFixed(3);

  // PARENT STATE
  const {
    withdrawLots, setWithdrawLots,
    claimInProgress,
    stakeInProgress, setStakeInProgress,
    unstakeInProgress, setUnstakeInProgress,
  } = actions;
  const totalWins = calcTotWins({ totalWins: sessionData?.totalWins });
  const isUnstakeDisabled = !totalBoomRewards || !totalWins || unstakeInProgress || claimInProgress || stakeInProgress;

  // LOCAL STATE
  const tokenInitial = whichActiveToken.slice(0, 1);
  const {
    unstakingAmount, withdrawOption, percentUnstake
  } = getUnstakeOptionsStatus({ token: 'boom', apiInfo, sessionData, withdrawLots });


  const onHandleStake = async () => {
    if (document.getElementById('stake-button').getAttribute('disabled')) {
      return;
    }
    setStakeInProgress(true);
    const isOk = window.confirm('Do you confirm that you want to stake all your igBOOM pending rewards?\n\nYou do accrue interest but there are specific rules to withdraw.');
    if (!isOk) {
      setStakeInProgress(false);
      return;
    }
    try {
      isLocal && console.log('Sending API request to stake funds');
      const payload = { account: getPlayerUsername({ UserService, UserState, InventoryState }) };
      // it gets all pending BOOM claims transferred to their BOOM saving balance.
      // It can be done whenever the request is called.
      const detail = await client.post(`/api/user/stake/boom`, payload);
      console.log('Stake result:', { detail });
      invoke(window, 'fetchUserData');
    } catch (err) {
      console.error('caught handling stake action:', err);
    } finally {
      setStakeInProgress(false);
    }
  };

  const onHandleWithdraw = async () => {
    if (document.getElementById('unstaking-options').getAttribute('bigger')) return; // anti-cheat
    setUnstakeInProgress(true);
    // validation and confirmation
    const msElapsed = Date.now() - +localStorage.getItem('lastBoomUnstakeTs');
    if (msElapsed < (18 * 60 * 60 * 1000)) { // 18 hours // validated server side too
      Swal.fire({text: `You already unstaked in the past 18 hours (${(msElapsed / (60 * 1000)).toFixed(0)} mins ago). Come back later.`});
      setUnstakeInProgress(false);
      return;
    }
    try {
      if (!unstakingAmount) {
        Swal.fire({text: `Cannot unstake, you have: ${unstakingAmount}`});
        setUnstakeInProgress(false);
        return;
      }
      const msg = `Do you confirm that you want to get ${unstakingAmount.toFixed(4)} igBOOM, that is ${percentUnstake}% of your stake/savings${withdrawLots ? ' minus the 35% rush fee' : ''}?`;
      const isOk = window.confirm(msg);
      if (!isOk) {
        setUnstakeInProgress(false);
        return;
      }
      // api call
      isLocal && console.log('Sending API request to unstake funds with withdraw option:', withdrawOption);
      const payload = { account: getPlayerUsername({ UserService, UserState, InventoryState }), withdrawOption };
      const detail = await client.post('/api/user/withdraw/boom', payload);
      console.log('Unstake result:', { detail });
      localStorage.setItem('lastBoomUnstakeTs', Date.now());
      Swal.fire({text: 'Unstake successful. Press Claim to get it into your wallet.'});
      // - update claimable balance on UI
      isLocal && console.log('Updating UI to show unstaked balance that can be claimed now');
      invoke(window, 'fetchUserData');
      // - disable claim btn
      const claimBtn = document.getElementById('claim-boom');
      claimBtn.setAttribute('disabled', 'true');
    } catch (err) {
      console.error('caught in unstake action:', err);
    } finally {
      setUnstakeInProgress(false);
    }
  };

  const toggleUnstakeLots = (event) => {
    setWithdrawLots(event.target.checked);
  };

  return (
    <>
      <div className="col-12">
        <button
          id="stake-button"
          className="btn btn-success btn-lg"
          onClick={onHandleStake}
          disabled={!totalBoomRewards || stakeInProgress || claimInProgress}
        >
          Stake all your pending ig{whichActiveToken}<br /><sub>({totalBoomRewards} <sub>ig</sub>{tokenInitial})</sub>
        </button>
        {stakeInProgress && <LoadingSpinner />}
      </div>
      <div id="unstaking-options">
        <div className="col-12">
          <button
            id="unstake-btn"
            className="btn btn-success btn-lg"
            onClick={onHandleWithdraw}
            disabled={isUnstakeDisabled}
          >
            Unstake <span style={{ display: withdrawLots ? 'inline-block' : 'none' }}>20</span><span
              id="unstake-percent"
              style={{ display: withdrawLots ? 'none' : 'inline-block' }}
            >{percentUnstake}</span> %
            <br />of your staked <sub>ig</sub>{whichActiveToken}
          </button>
          {unstakeInProgress
            ? <LoadingSpinner />
            : <div class="text-left row">
              <div className="col-1"></div>
              <div className="col-2">
                <CheckBox
                  onChange={toggleUnstakeLots}
                  className={`${withdrawLots ? "active" : ""} check-box`}
                  checked={withdrawLots}
                  id="unstake-amount-chk"
                ></CheckBox>
              </div>
              <div className="col-7">
                <p id="unstake-more">
                  Increase unstaking to 20% and burn 35% of the withdrawn amount
                </p>
              </div>
              <div className="col-12" id="unstake-notes">
                <ul id="unstake-rules">
                  <li>You can unstake funds only once per day, and first you need to win at least one match.</li>
                  <li>Keep in mind your max daily claim!</li>
                </ul>
              </div>
            </div>}
        </div>
      </div>
    </>
  );
};
