import { configureStore } from '@reduxjs/toolkit';
import { getCurrentEnvName } from '../constants/envs';
import { rootReducer as reducer } from './reducers';

const env = getCurrentEnvName();
const devToolsEnabled = env === 'dev'; // DEV ONLY. postMessage script disabled there.


const Store = configureStore({
    reducer,
    devTools: devToolsEnabled,
});

export const storeAppDispatch = Store.dispatch;
export default Store;


// ...
// https://geekrodion.com/blog/redux-saga-cra


// import { createStore, applyMiddleware } from 'redux';
// import createSagaMiddleware from 'redux-saga';
// import "regenerator-runtime/runtime";

// function* exampleSaga() {
//   console.log("Example saga reached");
// }

// const sagaMiddleware = createSagaMiddleware();
// export const store = createStore(
//   countReducer,
//   applyMiddleware(sagaMiddleware),
// );

// sagaMiddleware.run(exampleSaga);
