import React from 'react';

export const BoomStakingForMars = () => {
  return (<div className="row mt-3">
    <div className="col-4">
      <p><sub style={{ color: 'orange', marginBottom: '5px' }}>UNDER CONSTRUCTION<br /></sub></p>
      <button className="btn btn-success btn-lg" disabled={true} style={{ fontSize: '16px !important' }}>
        Stake $BOOM
      </button>
      <input type="number" style={{ width: '100px' }}></input>
    </div>
    <div className="col-1"></div>
    <div className="row col-7 flashy-box">
      <div className="col-6 mt-2">
        <button style={{ width: '100px' }}>Unstake $BOOM</button>
        <input type="number" style={{ width: '100px' }}></input>
      </div>
      <div className="col-6 mt-2">
        <button style={{ marginLeft: '15px' }}>Cancel Unstake</button>
      </div>
      <br /><br />
      <div className="col-12">
        <button style={{ width: '160px' }}>Claim $BOOM</button>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-4">
        <button className="btn btn-success btn-lg" disabled={true} style={{ fontSize: '16px !important' }}>
          Claim $MARS
        </button>
      </div>
      <div className="col-1"></div>
      <div className="row col-7 flashy-box" style={{ marginLeft: '5px' }}>
        <div className="col-6">
          <button style={{ width: '100px' }}>Unlock $MARS</button>
          <input type="number" style={{ width: '100px' }}></input>
        </div>
        <div className="col-6">
          <button style={{ marginLeft: '15px' }}>Cancel Unlock</button>
        </div>
      </div>
    </div>
  </div>);
};
export const MarsStakingForGas = () => {
  return (<div className="row mt-3">
    <div className="col-4">
      <p><sub style={{ color: 'orange', marginBottom: '5px' }}>UNDER CONSTRUCTION<br /></sub></p>
      <button className="btn btn-success btn-lg" disabled={true} style={{ fontSize: '16px !important' }}>
        Stake $MARS
      </button>
      <input type="number" style={{ width: '100px' }}></input>
    </div>
    <div className="col-1"></div>
    <div className="row col-7 flashy-box">
      <div className="col-6 mt-2">
        <button style={{ width: '100px' }}>Unstake $MARS</button>
        <input type="number" style={{ width: '100px' }}></input>
      </div>
      <div className="col-6 mt-2">
        <button style={{ marginLeft: '15px' }}>Cancel Unstake</button>
      </div>
      <br /><br />
      <div className="col-12">
        <button style={{ width: '160px' }}>Claim $MARS</button>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-4">
        <button className="btn btn-success btn-lg" disabled={true} style={{ fontSize: '16px !important' }}>
          Claim $GAS
        </button>
      </div>
      <div className="col-1"></div>
      <div className="row col-7 flashy-box" style={{ marginLeft: '5px' }}>
        <div className="col-6">
          <button style={{ width: '100px' }}>Unlock<br />$GAS</button>
          <input type="number" style={{ width: '100px' }}></input>
        </div>
        <div className="col-6">
          <button style={{ marginLeft: '15px' }}>Cancel Unlock</button>
        </div>
      </div>
    </div>
  </div>);
};
