import React from 'react';
import { useSelector } from 'react-redux';
import { displayBalance } from '../utils';

export const WaxBalance = () => {
  const WalletState = useSelector((store) => store.wallet);

  return (<div className="col-5" style={{ marginLeft: '-20px' }}>
    <div className="col-12">
      <p className='text-white mt-1' style={{ fontSize: '20px' }}>WAXP</p>
        <p className="text-white" style={{ fontSize: '18px', marginTop: '-10px' }}>
          Balance: <span id="wax-balance-wallet">{displayBalance(WalletState.wax, 'W')}</span>
        </p>
        <p>
          <a className="alcor-buy-boom"
            href="https://www.moonpay.com/buy/wax"
            target="_blank" rel="noreferrer noopener">
            BUY WAX
          </a>
        </p>
    </div>
  </div>);
};
