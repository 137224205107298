import { isLocal } from '../../../../constants/envs';
import { CURRENCIES } from '../../../../constants/currencies';
import { windowSetChecksum } from '../../../../generalUtils/secure/checksums';


// ## STORAGE TYPE

export const getSubDuration = (storageType) => {
  switch (storageType) {
    case 'CLASSIC-7d': return 7;
    case 'CLASSIC-30d': return 30;
    case 'PREMIUM': return 30;
    default: throw new Error(`Unexpected storage type ${storageType}`);
  }
};

export const isClassicStorage = storageType => storageType?.includes('CLASSIC');


// ## SPACESHIP INFO

export const getReduxSpaceshipInfo = ({ memo }) => {
  const [body] = memo.split('.');
  const [resourceType, level, shipAndCurrency] = body.split(':');
  const toLevel = +level.split('-')[1];
  const shipAndCurrencyNoPrefix = shipAndCurrency.split('-')[1];
  const spaceshipId = +shipAndCurrencyNoPrefix.slice(0, -1);
  const currency = CURRENCIES[shipAndCurrencyNoPrefix.slice(-1)];
  const result = { toLevel, spaceshipId, currency };
  isLocal && console.debug('Spaceship travel info for redux:', { result });
  return result;
};

export const setFreeSpaceshipUsage = () => {
  window.freeShip = true;
  windowSetChecksum({ key: 'freeShip', value: true });
  const originaValue = window.freeShip;
  try { // freeze:
    Object.defineProperty(window, 'freeShip', { value: originaValue, writable: false });
  } catch (_) { }
};
