import { createSlice } from '@reduxjs/toolkit';
import { restorePostLoginOverride } from '../generalUtils/secure';

const acceptedTacLs = localStorage.getItem('acceptedTac');

const initialState = {
  name: '',
  isLogged: false,
  acceptedTac: !!acceptedTacLs,
  chainLogin: localStorage.getItem('chainLogin') || null,
  // TODO: THIS SHOULD BE MOVED INTO A LeaderboardReducer.js
  leaderboardCache: {
    kills: {},
    savings: {
      dataMarsSaving: [],
      dataBoomSaving: [],
    },
    claims: {
      dataTotBoomClaimed: [],
      dataTotMarsClaimed: []
    },
    bestTimes: {
      easy: {},
      medium: {},
      hard: {}
    },
    playedTimes: {
      easy: {},
      medium: {},
      hard: {}
    },
    report: {
      weekly: [],
      monthly: []
    },
    weeklyRecentStats: {},
    monthlyRecentStats: {},
    lastUpdateTs: null,
  }
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPlayerData: (state, action) => ({ ...state, ...action.payload }),
    setPlayerLogout: (_1, _2) => {
      restorePostLoginOverride();
      return initialState;
    },
    setPlayerLogin: (state, action) => ({ ...state, isLogged: true, name: action.payload.name }),
    setPlayerTac: (state, action) => ({ ...state, acceptedTac: action.payload.acceptedTac }),
    setChainLogin: (state, action) => ({ ...state, chainLogin: action.payload.chainLogin }),
    setLeaderboardData: (state, action) => {
      return {
        ...state,
        leaderboardCache: {
          kills: {
            ...state.leaderboardCache.kills,
            ...action.payload.kills,
          },
          savings: {
            ...state.leaderboardCache.savings,
            ...action.payload.savings,
          },
          claims: {
            ...state.leaderboardCache.claims,
            ...action.payload.claims,
          },
          bestTimes: {
            ...state.leaderboardCache.bestTimes,
            ...action.payload.bestTimes
          },
          playedTimes: {
            ...state.leaderboardCache.playedTimes,
            ...action.payload.playedTimes
          },
          report: {
            ...state.leaderboardCache.report,
            ...action.payload.report,
          },
          monthlyRecentStats: {
            ...state.leaderboardCache.monthlyRecentStats,
            ...action.payload.monthlyRecentStats,
          },
          weeklyRecentStats: {
            ...state.leaderboardCache.weeklyRecentStats,
            ...action.payload.weeklyRecentStats,
          },
          lastUpdateTs: action.payload.lastUpdateTs,
        },
      }
    },
  }
});

export const {
  setPlayerData,
  setPlayerLogout,
  setPlayerLogin,
  setPlayerTac,
  setChainLogin,
  setLeaderboardData,
} = user.actions;

export default user.reducer;
