import { get } from "lodash";
import { WAX_ACCOUNTS } from "../../../../../../config/accounts";
import { isLocal, isTestnet } from "../../../../../../constants/envs";
import { DAYS } from "../../../../../../generalUtils/constants";
import { UserService } from "../../../../../../services/UserService";
import { SUBS_PRICES, SUB_TYPES } from "../../constants";
import { getSubDuration } from "../../utils";
import { fetchTxInfo } from "../loadStorage";



const validateTransaction = ({ from, to, memo, quantityTransfered, transferCurrency }) => {
  if (from !== UserService.authName) {
    console.error('Invalid sender!', { actual: from });
    return false;
  }
  if (to !== WAX_ACCOUNTS.SUBS_PAYMENTS) {
    console.error('Invalid recipient!', { actual: to });
    return false;
  }
  const storageType = memo?.trim();
  if (!Object.values(SUB_TYPES).includes(storageType)) {
    console.error('Invalid memo!', { actual: memo });
    return false;
  }
  const expectedPrice1 = SUBS_PRICES.v1[storageType];
  const expectedPrice2 = get(SUBS_PRICES, `v2.${storageType}`, 100000);
  const foundMatch = expectedPrice1 === quantityTransfered || expectedPrice2 === quantityTransfered;
  if (!isTestnet && !foundMatch) {
    console.error('Invalid amount!', { actual: quantityTransfered });
    return false;
  }
  if (transferCurrency !== 'MARS' && !isTestnet) {
    console.error('Invalid currency!', { actual: transferCurrency });
    return false;
  }
  return true;
};

const calcDaysLeft = ({ storageType, transferTimestamp }) => {
  const subDuration = getSubDuration(storageType);
  const daysLeftMillis = (new Date(transferTimestamp).getTime() + (subDuration * DAYS)) - Date.now();
  if (daysLeftMillis < 0) {
    isLocal && console.log('Subscription expired!', { storageType, txTime: transferTimestamp, subDuration });
    return ({ isExpired: true });
  }
  const daysLeft = Math.ceil(daysLeftMillis / DAYS);
  isLocal && console.debug('Sub has days left:', daysLeft);
  return ({ daysLeft });
};

export const validateSubscription = async ({ txHash }) => {
  // retrieve: sender, amount, time, memo
  const { from, to, memo, transferTimestamp, quantityTransfered, transferCurrency } = await fetchTxInfo({ txHash });

  // validate sender, amount matching memo -- invalid
  isLocal && console.log('Validating subscription tx data:', { from, to, memo, transferTimestamp, quantityTransfered, transferCurrency });
  const isValid = validateTransaction({ from, to, memo, transferTimestamp, quantityTransfered, transferCurrency });
  if (!isValid) return ({ invalid: true });

  // check how many days left (or expired)
  const storageType = memo.trim();
  const { isExpired, daysLeft } = calcDaysLeft({ storageType, transferTimestamp });
  if (isExpired) return ({ isExpired: true });

  // result
  return ({ storageType, daysLeft });
};
