import React, { useState } from 'react';
import { Animated } from "react-animated-css";
import { useSelector } from 'react-redux';
import { LevelChoice } from './overlays/LevelChoice';
import { ResourcesPayment } from './overlays/Resources/ResourcesPayment';
import { TravelSection } from './subComponents/spaceships/components/SpaceTravelPane';
import { uiHacks } from '../../generalUtils/uiHacks/uiHacks';
import { PlayerStats } from './PlayerStats';
import { PlayAndMatchOutcome } from './subComponents/launchPodAndResults/PlayAndMatchOutcome';
import { RightPaneRewards } from './RightPaneRewards';
import { windowSetChecksum } from '../../generalUtils/secure/checksums';
import './Play.scss';


export const Play = () => {
  const PlayState = useSelector((store) => store.play);
  const activeLevel = PlayState.activeLevel;

  // TRAVEL
  const inTravel = PlayState.isInTravel;
  window.inTravel = inTravel; // to prevent cheats on the wrapper side
  windowSetChecksum({ key: 'inTravel', value: inTravel });
  const inTravelToZero = PlayState.isTravellingToZero;

  const [travelPage, setShowTravelPage] = useState(false);
  const [whichBtnExpanded, setExpandedBtn] = useState(activeLevel);

  const resetLevelChoice = (targetLevel) => {
    uiHacks.disableClaimAndPlayBtnsAtFirst({ claimOnly: true });
    setShowTravelPage(false);
    setExpandedBtn(targetLevel || activeLevel);
  };

  // For RESOURCES page
  const [shouldShowResourcesPage, showResourcesPage] = useState(false);
  const paidResources = PlayState.paidResources;
  const isPvp = PlayState.isPvpGameMode;
  const autoPlayDefault = isPvp ? true : false; // TEMP until spacehips travels @@ @@
  const [shouldAutoPlay, setShouldAutoPlay] = useState(autoPlayDefault);
  
  const PlayAreaLanding = ({ paidResources }) => {
    return (
      <div
        className="row inner-container"
        style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="col-md-6">
          <br/>
          <PlayAndMatchOutcome {...{ showResourcesPage, paidResources, shouldAutoPlay, setShouldAutoPlay }} />
          <PlayerStats />
        </div>
        <RightPaneRewards />
      </div>
    );
  };

  return (
    <div className="container play">
      <br/>
      <Animated
        animationIn="slideInDown" animationOut="slideOutLeft" animationInDuration={500}
        animationInDelay={50} animationOutDuration={3000} isVisible={true}
      >
        {/* The 3 buttons to navigate game levels */}
        {(shouldShowResourcesPage || inTravelToZero) 
          ? <ResourcesPayment {...{ showResourcesPage, setShouldAutoPlay }} />
          : <>
              {/* The 3 buttons to navigate game levels */}
              <LevelChoice
                {...{ whichBtnExpanded, setExpandedBtn, inTravel, activeLevel, travelPage, setShowTravelPage }}
              />
              {/* Travel page / Play section */}
              {(travelPage || inTravel)
                ? <TravelSection {...{ travelPage, resetLevelChoice, inTravel }} />
                : <PlayAreaLanding {...{ paidResources, shouldAutoPlay }} />
              }
          </>
        }
      </Animated>
    </div>
  );
};
