import React, { useState } from 'react';


export const SuggestFirefox = () => {
  const [shouldDisplay, setShouldDisplay] = useState(true);

  const handleClose = () => {
    localStorage.setItem('dismissFox', 1);
    setShouldDisplay(false);
  };

  const isFox = navigator.userAgent && navigator.userAgent.includes('Firefox');
  const choseIgnoreFox = !!localStorage.getItem('dismissFox');
  if (isFox || choseIgnoreFox || !shouldDisplay) return null;

  return <center
      style={{
        color: 'rgb(224, 224, 104)', margin: '40px auto 0px', backgroundColor: 'rgba(20,20,20,0.95)',
        padding: '8px', maxWidth: '900px',
      }}
      id="use-fox"
    >
    <h4 style={{ fontSize: '16px' }}>
    You are not using&nbsp;
      <a style={{ color: 'orange' }} href="https://www.mozilla.org/en-US/firefox/new" target="_blank" rel="noreferrer">
        Firefox
      </a>. We recommend it, it's&nbsp;
      <a style={{ color: 'orange' }} href="https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23u6Yr396uj4cW64xexkA1vbHdaAgA56J9KVoe1MA3zKKY8QENEfv5PgvXvteCVk88yRo.png" target="_blank" rel="noreferrer">
        10x faster
      </a> for 3D games!<br/>Also make sure that you don't have other heavy Apps running. And disable shadows if you experience lags.
      &nbsp; &nbsp; &nbsp; <button
        onClick={handleClose}
        style={{ color: 'brown' }}
      >
        X
      </button>
    </h4>
  </center>;
};
