import { get } from 'lodash';
import { TOKEN_ACCOUNT } from '../../../../config/contractNames';
import { WAX_ACCOUNTS } from '../../../../config/accounts';
import { isLocal, isTestnet } from '../../../../constants/envs';
import { flyingSessionLogin, UserService, WaxFlyingSession } from '../../../../services/UserService';
import { isClassicStorage } from './utils';
import { LS_ID_SUBS_TX_HASH, SUBS_PRICES } from './constants';
import { client } from '../../../../helpers/client';
import { writeImmutableStorage } from './init/loadStorage';
import Swal from 'sweetalert2';


export const purchaseSubscription = ({ storageType, isHiveUser }) => new Promise(async (resolve) => {
  const price = SUBS_PRICES.v1[storageType];
  if (!price) { throw new Error(`Unexpected input for sub payment: ${storageType} ${price}`); }
  const quantity = isTestnet ? `${(0.1).toFixed(4)} BOOM` : `${price.toFixed(4)} MARS`;
  const targetWallet = WAX_ACCOUNTS.SUBS_PAYMENTS;
  isLocal && console.debug('MARS payment:', { storageType, price, quantity });

  let waxSession = UserService;
  if (isHiveUser && !UserService.authName) {
    isLocal && console.debug('creating side session for mars payment for hive user');
    waxSession = WaxFlyingSession;
    const isLoggedIn = await flyingSessionLogin(waxSession);
    if (!isLoggedIn) return resolve({ err: 'still not logged in for mars transfer' });
  }

  waxSession.session.signTransaction(
    {
      actions: [{
        account: TOKEN_ACCOUNT,
        name: 'transfer',
        authorization: [{
          actor: waxSession.authName,
          permission: 'active'
        }],
        data: {
          from: waxSession.authName,
          to: targetWallet,
          quantity,
          memo: storageType,
        }
      }]
    },
    { blocksBehind: 3, expireSeconds: 30 }
  )
    .then(async (response = { status: '' }) => {
      isLocal && console.log('Transaction outcome:', response.status);
      if (response.status !== 'executed') {
        console.error('Mars Sub payment failed:', JSON.stringify({ response }));
        Swal.fire({ text: `$MARS subscription payment failed.\n\n${JSON.stringify(response.status)}` })
        resolve({ err: (response.status || 'payment-ko') });
        return;
      }
      // ## STORE IN LS and window for Unity
      const txHash = response.transactionId;
      console.log({ txHash }); // @@
      localStorage.setItem(LS_ID_SUBS_TX_HASH, txHash);
      writeImmutableStorage({ storageType });
      // ## STORE IN DB if PREMIUM sub
      if (!isClassicStorage(storageType)) {
        try {
          const data = await client.post(
            '/api/storage/subscriptions',
            { account: waxSession.authName, subscriptionWaxTx: txHash },
          );
          isLocal && console.log('Store subscription result:', { data });
        } catch (err) {
          let errMsg = get(err, 'response.data.error') || 'Unknown error';
          Swal.fire({ text: `Uh oh, something went wrong saving your premium subscription: ${errMsg}` })
          resolve({ err });
        }
      }
      resolve({});
    })
    .catch((err) => {
      console.error('Mars Sub payment went wrong:', JSON.stringify({ err }));
      const errMsg = get(err, 'cause.message');
      const errMsg2 = get(err, 'cause.json.error.details[0].message');
      const errMsg3 = get(err, 'cause.json.message');
      Swal.fire({ text: `Mars Sub payment failed.\n\n${errMsg || errMsg2 || errMsg3 || JSON.stringify(err)}` })      
      resolve({ err });
    });
});
