import React from 'react';
import { UserService, WaxFlyingSession } from '../../services/UserService';
import { isTestnet } from '../../constants/envs';
import { updateDoomBalance, validateHiveUsername } from '../../sagas/helpers/wallet/hiveUtils';
import Swal from 'sweetalert2';


export const displayBalance = (balance, type) => {
  isTestnet && console.log('Balance wallet:', balance, type);
  return (balance && !isNaN(balance) && balance !== 0)
    ? (balance).toFixed(4)
    : <span>n/a <sub><a href={`https://wax${isTestnet ? '-test' : ''}.bloks.io/account/${
      UserService.authName}#tokens`} target="_blank" rel="noreferrer">( see bloks )</a></sub></span>;
};


// ## SEND WAX - utils

export const waxWalletFieldsValidation = ({
  amountElem, recipientElement, memoElement, // input fields
  boomSendAmount, boomReceiver, // values
}) => {
  const MIN_TO_SEND = 0.1;
  const MAX_TO_SEND = 500;
  
  const recipient = boomReceiver || recipientElement.value;
  const amountToSend = boomSendAmount || +amountElem.value;
  const memo = (memoElement && memoElement.value) || '';
  if (!boomSendAmount && (!amountElem || !amountToSend || !recipientElement || !recipient || !memoElement)) {
    Swal.fire({text: 'Please enter recipient and amount'});
    return ({ err: 'no-fields' });
  }
  console.log('Validating:', { recipient, amountToSend });
  // recipient
  if (!recipient.match(/^[0-9a-z.*]+$/)) {
    Swal.fire({text: 'Not a valid wax address, only characters and dots allowed'});
    return ({ err: 'not valid wax addr' });
  }
  // amount
  if (isNaN(amountToSend)) {
    Swal.fire({text: 'Enter a valid amount to send'});
    return ({ err: 'invalid amount' });
  }
  if (amountToSend < MIN_TO_SEND) {
    Swal.fire({text: `Min amount to send: ${MIN_TO_SEND}`});
    return ({ err: `Amount too low, min ${MIN_TO_SEND}` });
  } else if (amountToSend > MAX_TO_SEND) {
    Swal.fire({text: `Max amount to send: ${MAX_TO_SEND}`});
    return ({ err: `Amount too high, max ${MAX_TO_SEND}` });
  }
  const toSendFormatted = amountToSend && amountToSend.toFixed(8);
  // result
  return ({ recipient, toSend: toSendFormatted, memo });
};

export const hiveWalletFieldsValidation = async ({ amountElem, recipientElement, memoElement, symbol }) => {
  const MIN_TO_SEND = 0.1;
  const MAX_TO_SEND = 500;

  const recipient = recipientElement.value;
  const amountToSend = +amountElem.value;
  const memo = memoElement?.value || '';
  if (!amountElem || !amountToSend || !recipientElement || !recipient || memo == null) {
    console.error('Please enter recipient and amount');
    return ({ err: 'One of the required fields is missing' });
  }
  console.log('Validating:', { recipient, amountToSend });
  // recipient
  if (!recipient.match(/^[0-9a-z.-]+$/)) {
    console.error('Not a valid hive address, only characters and dots allowed');
    return ({ err: 'Not a valid Hive username' });
  }
  const { err } = await validateHiveUsername({ username: recipient });
  if (err) {
    console.error('Invalid recipient:', err);
    return ({ err: `Invalid account: ${err}` });
  }
  // amount
  if (isNaN(amountToSend)) {
    console.error('Enter a valid amount to send');
    return ({ err: 'Invalid transfer amount' });
  }
  if (amountToSend < MIN_TO_SEND) {
    console.error(`Min amount to send: ${MIN_TO_SEND}`);
    return ({ err: `Amount too small, min ${MIN_TO_SEND}` });
  } else if (amountToSend > MAX_TO_SEND) {
    console.error(`Max amount to send: ${MAX_TO_SEND}`);
    return ({ err: `Amount too big, max ${MAX_TO_SEND}` });
  }
  const toSendFormatted = amountToSend
    && amountToSend.toFixed(symbol === 'DOOM' ? 4 : 3);
  // result
  return ({ recipient, toSend: toSendFormatted, memo });
};

export const clearFields = ({ amountElem, recipientElement }) => {
  amountElem.value = '';
  recipientElement.value = '';
  // plus dispatches // nah, temp hack anyway
};


// ## FETCH ALL BALANCES (all chains)

export const updateAllBalances = ({ hiveUsername }) => {
  WaxFlyingSession.session && WaxFlyingSession.fetchWaxTokens();
  UserService.session && UserService.fetchWaxTokens();
  hiveUsername && updateDoomBalance({ hiveUsername });
};
