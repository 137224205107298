import React from 'react';
import { ALL_NFTS_FLAT, ITEMS_BY_CATEGORY } from '../../constants/templateIds';
import { Category } from '../../components/Inventory/Category/Category';


export const ActiveInventory = ({ csNfts }) => {
  let result = {};
  // init categories
  const allCategories = Object.keys(ITEMS_BY_CATEGORY);
  allCategories.forEach(category => { result[category] = {}; });
  // add category and price to user items
  csNfts.forEach((nft = {}) => {
    const { name, category, img, s3Img, template_id, schema, price } = nft;
    const fixedName = ALL_NFTS_FLAT.find(nft => nft.ID === template_id)?.NAME || name;
    if (result[category][fixedName]) {
      result[category][fixedName].amount++;
    } else {
      result[category][fixedName] = {
        nftName: fixedName, img, s3Img, amount: 1, template: template_id, schema,
        category, price: price || 'n/a', // THE FIELDS ADDED HERE
      };
    }
  });
  // Put empty categories at the bottom
  const categoriesWithItems = {};
  const emptyCategories = {};
  Object.keys(result).forEach((category) => {
    if (Object.keys(result[category]).length > 0) {
      categoriesWithItems[category] = result[category];
    } else {
      emptyCategories[category] = result[category];
    }
  });
  result = { ...categoriesWithItems, ...emptyCategories };
  return (<>{allCategories.map((categoryName, index) => {
    return (
      <Category
        name={categoryName.replace(/_/g, ' ')}
        data={result[categoryName]}
        key={`category${index}`}
      ></Category>);
  })}</>);
};
