import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoIcon from '../../../images/logos/cs-logo.jpg';
import { isMainnet } from '../../../constants/envs';
import { formatBalance } from '../../../generalUtils/formatBalance';
import { ChainIcon } from '../../../pages/LandingPage/Login/Shared/ChainIcon';
import { getPlayerUsername } from '../../../generalUtils/utils';
import { coingeckoFetch } from '../../../pages/Leaderboard/Prizes/utils/prices/coingecko';
import './NameAndBalance.scss';


const getUsernameLink = (chain) => {
  const uname = getPlayerUsername();
  switch (chain) {
    case 'wax': return isMainnet
      ? `https://wax.atomichub.io/profile/${uname}`
      : `https://wax-test.atomichub.io/profile/${uname}`;
    case 'hive':
      return `https://peakd.com/@${uname}`;
    case 'skl':
    case 'eth':
      return `https://etherscan.io/address/${uname}#multichain-portfolio`;
    default: console.error('unexpected chain for username link:', uname, chain);
  }
};

const formatUsername = ({ username, chain }) => {
  if (chain === 'skl' || chain === 'eth') return `${username.slice(0, 6)}...${username.slice(-4)}`;
  if (chain === 'hive') return `@${username}`;
  return username;
};

const CryptoBalance = ({ chain = '' }) => {
  const WalletState = useSelector((store) => store.wallet);

  switch (chain) {
    case 'wax': return (
      <Link to="/wallet">
        <span>{formatBalance(WalletState.wax)}</span>
      </Link>
    );
    case 'hive':
    case 'skl':
    case 'eth':
      return (
        <span style={{ fontSize: '14px' }}> &nbsp; &nbsp; n/a <span className="bold">
            {chain.toUpperCase()}
          </span>
        </span>
        // <Link to="/wallet">
        //   <span style={{ fontSize: '14px' }}> &nbsp; &nbsp; n/a ${chain.toUpperCase()}</span>
        //   {/* TODO */}
        // </Link>
      );
    default: return null;
  }
};

export const NameAndBalance = () => {
  const UserState = useSelector((store) => store.user);
  const chain = UserState.chainLogin;

  const [chainPrice, setChainPrice] = useState(0);

  useEffect(() => {
    if (!chain) return;
    // Fetch chain price
    const fetchChainPrice = async () => {
      const { data = {}, err } = await coingeckoFetch({ token: chain });
      const usdPrice = data[chain]?.usd;
      !err && usdPrice && setChainPrice(usdPrice.toFixed(2));
    };
    fetchChainPrice();
  }, [chain]);

  if (!UserState.isLogged) return null;

  return (<div className="left-menu row container">
    {/* LOGO */}
    <div className="col-4">
      <img id="cs-logo" src={LogoIcon} alt="LogoIcon" width="42" />
    </div>

    {/* BALANCE AND USERNAME */}
    <div id="name-and-balance" className="col-6 text-white">
      <div id="username">
        <a href={getUsernameLink(chain)} target="_blank" rel="noreferrer nopener">
          {formatUsername({ username: UserState.name, chain })}
        </a>
      </div>
      <div id="wallet-balance">&nbsp; &nbsp;
        <CryptoBalance chain={chain} />
      </div>
    </div>

    {/* CHAIN ICON */}
    <div id="which-chain" className="col-1">
      <ChainIcon chain={chain} />
      <p style={{ fontSize: '10px', color: 'white', margin: '-33px auto auto 3px' }}>
        {chainPrice}
      </p>
    </div>
  </div>
  );
};
