import { isLocal } from '../../../../../constants/envs';


const cache = {
  cachedResults: {}, // eg. { 'BOOM:csboomcsboom': { lastPriceUsd, ts } }
  marketNumbers: {}, // eg. { 'BOOM:csboomcsboom': 368 }
};
const CACHE_DURATION_MINS = 10;


const alcorApiPrice = marketNumber => `https://wax.alcor.exchange/api/markets/${marketNumber}/deals`;
const alcorApiMarkets = `https://wax.alcor.exchange/api/markets`;


export const getWaxTokenPrice = async ({ tokenName = '', chainPrice = 0 }) => {
  if (!tokenName) return;
  try {
    // CACHE CHECK
    const cached = cache.cachedResults[tokenName] || {};
    const { lastPriceUsd: cachedPrice, ts: lastFetchTs } = cached;
    if (cachedPrice) {
      const elapsedTime = Date.now() - lastFetchTs;
      if (elapsedTime < (CACHE_DURATION_MINS * 60 * 1000)) {
        isLocal && console.log('[alcor-price] Returned cached value of', cachedPrice);
        return ({ lastPriceUsd: cachedPrice });
      }
      // doing this here so that it returns previous result if new requests come in
      // while this request is still in progress (prevent api throttling)
      cached.ts = Date.now();
    }

    // FETCH MARKET NUMBER (and cached)
    let marketNumCache = cache.marketNumbers[tokenName];
    if (!marketNumCache) {
      isLocal && console.log('[alcor-price] fetching market for token', tokenName);
      const markets = await fetch(alcorApiMarkets).then(response => response.json())
      const [symbol, contract] = tokenName.split(':');
      const marketNumber = markets.find(
        market => (market.base_symbol || market.quote_token?.symbol?.name) === symbol
        && (market.contract || market.quote_token?.contract) === contract,
      );
      if (!marketNumber?.id) {
        console.error(`[alcor-price] market not found for ${tokenName}. Response:`, markets[0]);
        return ({ err: 'market-not-found' });
      }
      marketNumCache = (cache.marketNumbers[tokenName] = marketNumber?.id);
      isLocal && console.log('[alcor-price] market match:', marketNumCache);
    }
    // FETCH TOKEN PRICE
    isLocal && console.log('[alcor-price] fetching token price for', tokenName);
    const priceData = await fetch(alcorApiPrice(marketNumCache)).then(res => res.json());
    const lastPrice = priceData[0]?.unit_price;
    if (!lastPrice) {
      console.error('[alcor-price] price data missing:', { priceData });
      return ({ err: 'price-not-found' });
    }
    isLocal && console.log('[alcor-price] token price in Wax:', lastPrice);

    // CONVERT INTO USD
    const lastPriceUsd = +lastPrice * chainPrice;
    // RESULT
    isLocal && console.log('[alcor-price] result:', { lastPrice, lastPriceUsd });
    cache.cachedResults[tokenName] = { lastPriceUsd, ts: Date.now() };
    return ({ lastPriceUsd });
  } catch (err) {
    console.error(`[alcor-price] Something broke fetching token price from HE: ${err}`);
    return ({ err });
  }
};

// // TEST
// const fetch = require('node-fetch');
// const test = async () => {
//   const { lastPriceUsd, err } = await getFungibleTokenPrice({ tokenName: 'BOOM:csboomcsboom', chainPrice: 0.062 });
// };
// test();
