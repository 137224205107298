import { combineReducers } from 'redux';
import user from '../reducers/UserReducer';
import inventory from '../reducers/InventoryReducer';
import wallet from '../reducers/WalletReducer';
import play from '../reducers/PlayReducer';
import adminPane from '../reducers/AdminPaneReducer';
import gameOverlay from '../reducers/GameUiReducer';
import marsStorage from '../reducers/MarsStorage';

// import { enableMapSet } from 'immer'
// enableMapSet();

export const rootReducer = combineReducers({
  user,
  inventory,
  wallet,
  play,
  adminPane,
  gameOverlay,
  marsStorage,
});
