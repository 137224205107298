import axios from 'axios';
import { get } from 'lodash';
import { getCurrentApiEndpoint, getCurrentEnvName, isLocal } from '../constants/envs';
import { windowSetChecksum } from '../generalUtils/secure/checksums';
// import { handleDeprecatedClient } from '../pages/Wallet/subComponents/SwapUtils/utils';


// For auth: axios.defaults.headers.common['Authorization'] = 'X';
export function client(
    endpoint,
    {
        method, body,
        contentType = 'application/json',
        customHeaders,
        ...customConfig
    } = {},
) {
    const url = customConfig.fullUrl
      ? endpoint
      : getCurrentApiEndpoint() + endpoint; // process.env.REACT_APP_API_URL
    const urlParamSymbol = url.includes('?') ? '&' : '?';
    const urlToUse = `${url}${client.chain
      ? `${urlParamSymbol}chain=${client.chain}` : ''}`;
    isLocal && console.log('[x] client request:', { url, customConfig });
    const options = {
        url: urlToUse,
        // url: process.env.REACT_APP_API_URL + endpoint,
        method,
        ...customConfig,
        data: body,
        headers: {
          'Content-Type': contentType,
          // plus all the axios.defaults.headers.common in verifyUser.js
          ...customHeaders,
         },
    }
    if (getCurrentEnvName() !== 'prod') {
      console.debug('-- Sending api request:', options);
    }
    const response = axios(options)
        // .catch(handleDeprecatedClient);
    isLocal && console.debug('Returning axios response', { response });
    return response;
}

client.get = function(endpoint, customConfig = {}) {
    return client(endpoint, {...customConfig, method: 'GET' })
}

client.post = function(endpoint, body, customConfig = {}, customHeaders = {}) {
    return client(endpoint, {...customConfig, method: 'POST', body, customHeaders })
}

client.postForm = function(endpoint, body, customConfig = {}) {
    return client(endpoint, { method: 'POST', body, contentType: false })
}

client.put = function(endpoint, body, customConfig = {}) {
    return client(endpoint, {...customConfig, method: 'PUT', body })
}

client.delete = function(endpoint, body, customConfig = {}) {
    return client(endpoint, {...customConfig, method: 'DELETE', body })
}


export const cacheBustParam = () => `?v=${new Date().toISOString().replace(/\D/g, '').slice(0, -6)}`;

// Appends '?chain=hive' if the chain is hive
export const setUrlParamApiCalls = ({ chain = '' }) => {
    client.chain = chain;
};


// ---- UTILS

export const fetchUserDataOnly = async ({ account, chain }) => {
  isLocal && console.debug('Fetching user data only - no side effects!', { account, chain });
  const clientVersion = get(window, 'version.GAME_VERSION');
  let userDataResponse;
  try {
      userDataResponse = await client.post('/api/user/get',
        { account }, // body
        {}, // customConfig
        { 'X-FE-VERSION': `R_v${clientVersion}`, } // headers
      );
      const { data } = userDataResponse;
      isLocal && console.debug('[data-only]', { account, chain, data });
      return data;
    } catch (err) {
      console.error('user data-only fetch fail', { err });
      return ({ err: 'error-getting-nonce' });
    }
};
window.fetchUserDataOnly = fetchUserDataOnly; // used by React UI
windowSetChecksum({ key: 'fetchUserDataOnly', value: fetchUserDataOnly });
