import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { isMainnet, isTestnet } from '../../../constants/envs';


const getUsernameLink = (username, chain) => {
  if (chain === 'hive') return `https://peakd.com/@${username}`;
  return isMainnet
    ? `https://wax.atomichub.io/profile/${username}`
    : `https://wax-test.atomichub.io/profile/${username}`;
};


export const ScholarshipPane = () => {
  const InventoryState = useSelector((store) => store.inventory);
  const waxScholarshipData = get(InventoryState, 'scholarship', {});
  const { hive = {}, csAssets = {} } = InventoryState;
  const { csNfts: waxNFts = [] } = csAssets;
  const { nfts: hiveNFts = [], username: hiveUsername, teacher: hiveTeacher } = hive;

  isTestnet && console.debug('Data received in ScholarshipPane component:', { waxScholarshipData, hiveUsername, hiveTeacher });
  const {
    isTeacher, scholarName, teacherCut, isScholar, teacherName, scholarCut,
  } = (waxScholarshipData || {});
  if (!isTeacher && !isScholar && !hiveTeacher) {
    return <p className="pane-under-play-btn" style={{ background: 'none', marginTop: '15px' }}>
      <i>Not a <a href="https://crypto-shots.com/scholarships" target="_blank" rel="noreferrer">Scholarship</a> account</i>
    </p>;
  }
  const teacherPercentage = teacherCut || (100 - scholarCut);
  const scholarPercentage = scholarCut || (100 - teacherCut);
  return (<p className="pane-under-play-btn">
    {isTeacher && <span>Only your scholar <a href={getUsernameLink(scholarName, 'wax')} target="_blank" rel="noreferrer">{
      scholarName}</a> can play with your inventory. You get {teacherPercentage}%.</span>}
    {isScholar && <span>You are playing with <a href={getUsernameLink(teacherName, 'wax')} target="_blank" rel="noreferrer">{
      teacherName}</a>'s <b>{waxNFts.length}</b> NFTs and keeping <b>{scholarPercentage}%</b> of all the rewards.</span>}
    {hiveTeacher && <div className="text-left pad5">
      <b>Hive Scholarship</b> &nbsp;  ({hiveNFts.length} NFTs)
      <br/><span>Scholar: @<a href={getUsernameLink(scholarName, 'hive')} target="_blank" rel="noreferrer">{hiveUsername}</a></span>
      <br/><span>Teacher: @<a href={getUsernameLink(scholarName, 'hive')} target="_blank" rel="noreferrer">{hiveTeacher}</a></span>
      </div>}
  </p>);
};
