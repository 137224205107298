
const bech32 = require('bech32');


const LN_ADDRESS_PREFIX = 'ln';


// The address decodes correctly using the Bech32 library.
// The decoded data has the expected format.
const isValidLightningNetworkAddress = ({ address }) => {
  try {
    // Decode the address using Bech32
    const decoded = bech32.decode(address);
    // Check if the decoded address has the expected human-readable part (prefix)
    const expectedPrefix = LN_ADDRESS_PREFIX;
    if (decoded.prefix !== expectedPrefix) return false;
    // Additional checks based on the Lightning Network address format
    // ...
    return true;
  } catch (error) {
    console.error('An error occurred during decoding');
    return true; // best effort
  }
}


export const validateBtcLnAddress = async ({ address = '' }) => {
  // ADDRESS SIZE
  const tooShort = address.length < 8;
  const tooLong = address.length > 90;
  if (tooShort || tooLong) return ({ valid: false, reason: `Incorrect size: ${tooShort ? 'too short' : 'too long'}` });

  // BECH32 ENCODING check
  // eg. lnbc1pwr5u7hpp5cx4k6t4szgt0jh5tkj8d4y7mrpw2h
  if (address.startsWith(LN_ADDRESS_PREFIX)) { // Lightning Network prefix for Bech32 encoding
    const valid = isValidLightningNetworkAddress({ address });
    return valid ? ({ valid: true }) : ({ valid: false, reason: 'Failed Bech32 decoding' });
  }

  // EMAIL FORMAT check
  // eg. sniper@vipsats.app
  const [username, tail = ''] = address.split('@');
  const [service, suffix] = tail.split('.');
  if (!username || !service || !suffix) return ({ valid: false, reason: 'No "@" and "." found' });
  if (username.length < 3 || service.length < 2 || tail.length < 2) return ({ valid: false, reason: 'Not a valid address: parts too short' });

  return ({ valid: true });
};
