
const CURRENT_HOSTNAME = window.location.hostname;

const CS_DOMAIN = 'crypto-shots.com';

// ----
const NGROK_ID = 'fc08-2601-348-400-85f0-a009-5cd0-fbd0-e1bc';
const NGROK_DOMAIN = `${NGROK_ID}.ngrok-free.app`;
const IS_NGROK_TEST = false;
// ----

const HOSTNAMES = {
  LOCAL: 'localhost',
  DEV: `playdev.${CS_DOMAIN}`,
  TEST: `playtest.${CS_DOMAIN}`,
  DEMO: `playdemo.${CS_DOMAIN}`,
  PROD: `play.${CS_DOMAIN}`,
};
if (IS_NGROK_TEST) {
  HOSTNAMES[NGROK_DOMAIN] = 'localhost';
}

const getEnvUrls = () => Object.values(HOSTNAMES);
const getEnvNames = () => Object.keys(HOSTNAMES);

// NOTE: do NOT expose this in the global scope
export const getCurrentEnvName = () => {
  const index = CURRENT_HOSTNAME === NGROK_DOMAIN
    ? 1 : getEnvUrls().indexOf(CURRENT_HOSTNAME);
  return getEnvNames()[index].toLowerCase();
};

const checkMainnet = () => {
  const envName = getCurrentEnvName();
  return envName === 'demo' || envName === 'prod';
};
const checkTestnet = () => {
  const envName = getCurrentEnvName();
  return envName !== 'demo' && envName !== 'prod';
};

export const isMainnet = checkMainnet();
export const isTestnet = checkTestnet();
export const isLocal = getCurrentEnvName() === 'local' || localStorage.getItem('isLocal');


export const getCurrentEnvHost = () => {
  const ucName = getCurrentEnvName().toUpperCase();
  return HOSTNAMES[ucName];
};


const LOCAL = {
  LOCALHOST_OVERRIDE: `http://localhost:9090`,
  WEBSERVER_PORT: 9090,
  // `https://tt4s0nifne.execute-api.us-east-2.amazonaws.com/dev`,
  // Jay's: 'https://djn9wuvu83.execute-api.us-east-2.amazonaws.com/dev',
  // Jay's NEW: https://tt4s0nifne.execute-api.us-east-2.amazonaws.com/dev
};

export const isReactLayer = ['3000', '3001'].includes(window.location.port);

const localUrl = () => {
  if (LOCAL.LOCALHOST_OVERRIDE) return LOCAL.LOCALHOST_OVERRIDE;
  if (isLocal && isReactLayer) return `https://apitest.${CS_DOMAIN}`;
  return `http://localhost:${LOCAL.WEBSERVER_PORT}`;
};

const API_DOMAINS = {
  LOCAL: localUrl(),
  DEV: window.location.hostname === 'localhost'
    ? `http://localhost:${LOCAL.WEBSERVER_PORT}` : `https://apidev.${CS_DOMAIN}`,
  TEST: `https://apitest.${CS_DOMAIN}`,
    //'https://djn9wuvu83.execute-api.us-east-2.amazonaws.com/dev',
  DEMO: `https://apidemo.${CS_DOMAIN}`,
  PROD: `https://api.${CS_DOMAIN}`,
};

export const getCurrentApiEndpoint = () => {
  const ucName = getCurrentEnvName().toUpperCase();
  const currentEndpoint = API_DOMAINS[ucName];
  isLocal && console.log('API endpoint:', currentEndpoint);
  return currentEndpoint;
};
