import { isLocal } from "../../../../../constants/envs";


const cache = {
  cachedResults: {}, // eg. { 'doom': { lastPriceUsd, ts } }
};
const CACHE_DURATION_MINS = 10;


const tokenInfoUrl = `https://api.hive-engine.com/rpc/contracts`;

const prepTokenInfoBody = tokenName => ({
  jsonrpc: '2.0',
  method: 'find',
  params: {
      contract: 'market',
      table: 'metrics',
      query: { 'symbol': tokenName },
      limit: 1,
      offset: 0,
      indexes: [],
  },
  id: 1,
});

export const getHiveEngineTokenPrice = async ({ tokenName = '', chainPrice = 0 }) => {
  if (!tokenName) return;
  const priceFetchStartTs = Date.now();
  try {
    // CACHE CHECK
    const cached = cache.cachedResults[tokenName] || {};
    const { lastPriceUsd: cachedPrice, ts: lastFetchTs } = cached;
    if (cachedPrice) {
      const elapsedTime = Date.now() - lastFetchTs;
      if (elapsedTime < (CACHE_DURATION_MINS * 60 * 1000)) {
        isLocal && console.log('[he-price] Returned cached value of', cachedPrice);
        return ({ lastPriceUsd: cachedPrice });
      }
      // doing this here so that it returns previous result if new requests come in
      // while this request is still in progress (prevent api throttling)
      cached.ts = Date.now();
    }
    // API CALL
    const tokenInfoBody = prepTokenInfoBody(tokenName);
    const tokenInfoData = await fetch(tokenInfoUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(tokenInfoBody),
    })
      .then(res => res.json());
    const { lastPrice } = tokenInfoData?.result[0];
    if (!lastPrice) {
      console.error('[he-price] price data missing:', { tokenInfoData });
      return ({ err: 'price-not-found' });
    }
    // CONVERT INTO USD
    const lastPriceUsd = +lastPrice * chainPrice;
    // RESULT
    isLocal && console.log('[he-price] result:', { lastPrice, lastPriceUsd });
    cache.cachedResults[tokenName] = { lastPriceUsd, ts: Date.now() };
    return ({ lastPriceUsd });
  } catch (err) {
    const durationMs = Date.now() - priceFetchStartTs;
    console.error(`[he-price] Something broke fetching token price from HE: [durationMs:${durationMs}] ${err}`);
    return ({ err });
  }
};
