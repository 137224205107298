import { HIVE_TEMPLATES } from "../../../../constants/hiveNfts";
import { ITEMS_BY_CATEGORY } from "../../../../constants/templateIds";
import { getNftCount } from "../../../../generalUtils/utils";

const { CHARACTERS } = ITEMS_BY_CATEGORY;


const PRICE_MULTIPLIER_SCHOLARS = 2;
const MAX_CHARS_SCHOLARS = 19; // 20 including captain
const MAX_CHARS_NONSCHOLARS = 9; // 10 including captain


const charactersCountNfts = ({ waxNfts, hiveNfts }) => {
  const waxCount = 0
    // L0
    + getNftCount({ nfts: waxNfts, id: CHARACTERS.VA.ID })
    + getNftCount({ nfts: waxNfts, id: CHARACTERS.MA.ID })
    // L1
    + getNftCount({ nfts: waxNfts, id: CHARACTERS.IV.ID })
    + getNftCount({ nfts: waxNfts, id: CHARACTERS.MI.ID })
    // L2
    + getNftCount({ nfts: waxNfts, id: CHARACTERS.LEX.ID })
    + getNftCount({ nfts: waxNfts, id: CHARACTERS.EVE.ID });
  const hiveCount = 0
    // L0
    + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.VANCE_TYRRELL.name })
    + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.MASHA_TALASHOVA.name })
    // L1
    + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.IVAN_BARRICK.name })
    + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.MINTA_HANI.name })
    // L2
    + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.LEXI_CAYNE.name })
    + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.EVELYN_MOORE.name })
  return waxCount + hiveCount;
};


export const countCharsToPayFor = ({ isScholar, waxNfts = [], hiveNfts = [] }) => {
  let result;
  const charsCount = charactersCountNfts({ waxNfts, hiveNfts });
  if (isScholar) {
    result = Math.min(MAX_CHARS_SCHOLARS, charsCount * PRICE_MULTIPLIER_SCHOLARS);
  } else {
    result = Math.min(MAX_CHARS_NONSCHOLARS, charsCount);
  }
  return result + 1; // +1 = captain
};
