import { isLocal, isTestnet } from '../../../constants/envs';
import { nap } from '../../../generalUtils/utils';


// SECURITY: allow only in mobile
export const isSmartphoneOrTablet = isTestnet || (window.innerWidth < 800);

// canvas getter cached and mocked
let canvas;
const getCanvas = () => {
  if (canvas) return canvas;
  canvas = document.getElementById('unity-canvas');
  if (!canvas && isLocal) return ({ dispatchEvent: (ee) => console.log('Fake dispatch of:', ee) }); // mock
  return canvas;
}


// ## MOVE AROUND (WASD)
const moveEventDetails = ({ letter }) => {
  const eventDetails = {
    bubbles: true,
    shiftKey: false,
    ctrlKey: false,
    metaKey: false,
  };
  switch (letter) {
    case 'W': {
      eventDetails.key = 'w';
      eventDetails.keyCode = eventDetails.which = 87;
      eventDetails.code = 'KeyW';
      break;
    }
    case 'A': {
      eventDetails.key = 'a';
      eventDetails.keyCode = eventDetails.which = 65;
      eventDetails.code = 'KeyA';
      break;
    }
    case 'S': {
      eventDetails.key = 's';
      eventDetails.keyCode = eventDetails.which = 83;
      eventDetails.code = 'KeyS';
      break;
    }
    case 'D': {
      eventDetails.key = 'd';
      eventDetails.keyCode = eventDetails.which = 68;
      eventDetails.code = 'KeyD';
      break;
    }
    default: { throw new Error(`Unexpected letter ${letter}`); }
  }
  return eventDetails;
};

export const startPressingKey = ({ letter }) => {
  if (!isSmartphoneOrTablet) return;
  getCanvas().dispatchEvent(new window.KeyboardEvent('keydown', moveEventDetails({ letter })));
};

export const stopPressingKey = ({ letter }) => {
  if (!isSmartphoneOrTablet) return;
  getCanvas().dispatchEvent(new window.KeyboardEvent('keyup', moveEventDetails({ letter })));
};


// ## SHOOT
const shootStart = () => {
  if (!isSmartphoneOrTablet) return;
  getCanvas().dispatchEvent(
    new MouseEvent(
      'mousedown',
      {
        clientX: window.innerWidth / 2,
        clientY: window.innerHeight / 2,
        bubbles: true,
      },
    ),
  );
};
const shootEnd = () => {
  if (!isSmartphoneOrTablet) return;
  getCanvas().dispatchEvent(
    new MouseEvent(
      'mouseup',
      {
        clientX: window.innerWidth / 2,
        clientY: window.innerHeight / 2,
        bubbles: true,
      },
    ),
  );
};


// ## CHANGE WAPON (Q and E)
const changeWeapon = async () => {
  if (!isSmartphoneOrTablet) return;
  const weaponConfig = {
    key: 'e',
    bubbles: true,
    keyCode: 69,
    which: 69,
    code: 'KeyE',
    shiftKey: false,
    ctrlKey: false,
    metaKey: false,
  };
  getCanvas().dispatchEvent(new window.KeyboardEvent('keydown', weaponConfig));
  await nap(200);
  getCanvas().dispatchEvent(new window.KeyboardEvent('keyup', weaponConfig));
};

// ## JUMP
const jump = async () => {
  if (!isSmartphoneOrTablet) return;
  const jumpConfig = {
    key: ' ',
    bubbles: true,
    keyCode: 32,
    which: 32,
    code: 'Space',
    shiftKey: false,
    ctrlKey: false,
    metaKey: false,
  };
  getCanvas().dispatchEvent(new window.KeyboardEvent('keydown', jumpConfig));
  await nap(200);
  getCanvas().dispatchEvent(new window.KeyboardEvent('keyup', jumpConfig));
};


// ## MOVE CAMERA
// ??
