import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { storeAppDispatch } from '../../../../GlobalState/Store';
import { setChainLogin } from '../../../../reducers/UserReducer';
import { isUnderMaintenance } from "../../../../config/appRemoteStatus";
import { isLocal } from '../../../../constants/envs';
import { ChainIcon } from './ChainIcon';
import { getSingleChainLogin } from '../../../../generalUtils/browser/urlParams';
import { BitcoinLnInstructions } from './BitcoinLnInstructions';
import { useSelector } from 'react-redux';


export const ChainChoicePanel = () => {
  const PlayState = useSelector((store) => store.play);
  const isPvp = PlayState.isPvpGameMode;

  const [btcInfoMsg, setBtcInfoMsg] = useState('');

  useEffect(() => {
    const urlParamChain = getSingleChainLogin();
    if (urlParamChain === 'btc') showBtcInstructions();
  }, []);


  const handleChainChoice = (chain) => {
    if (isUnderMaintenance()) {
      Swal.fire({ text: `We are currently in maintenance mode. Please try later.` });
      return;
    }
    if (chain === 'eth-choice' && !isPvp) {
      Swal.fire({ text: `For now only Multiplayer mode is supported on Ethereum` });
      return;
    }
    isLocal && console.debug('Setting game chain to:', chain);
    storeAppDispatch(setChainLogin({ chainLogin: chain }));
  };

  const showBtcInstructions = () => {
    setBtcInfoMsg(<BitcoinLnInstructions />);
  };

  return (
    <div
      className="button-choices-wrapper row container"
      style={{ marginTop: '20px', marginLeft: '0px' }}
    >
      <div className="col-3 logo-wrapper">
        <button className="login-options-btns btn btn-success btn-lg"
          onClick={() => handleChainChoice('hive')}
        >
          Hive
        </button>
        <ChainIcon chain="hive" />
      </div>
      <div className="col-3 logo-wrapper">
        <button className="login-options-btns btn btn-success btn-lg"
          onClick={() => handleChainChoice('wax')}
        >
          Wax
        </button>
        <br />
        <ChainIcon chain="wax" />
      </div>
      <div className="col-3 logo-wrapper">
        <button className="login-options-btns btn btn-success btn-lg"
          onClick={showBtcInstructions}
        >
          Bitcoin
        </button>
        {btcInfoMsg
          ? <div style={{ minWidth: '240px', marginTop: '15px' }}>{btcInfoMsg}</div>
          : <ChainIcon chain="btc" />}
      </div>
      <div className="col-3 logo-wrapper">
        <button className="login-options-btns btn btn-success btn-lg"
          onClick={() => handleChainChoice('eth-choice')}
        >
          Ethereum
        </button>
        {!btcInfoMsg && <ChainIcon chain="eth" />}
      </div>
    </div>
  );
};
