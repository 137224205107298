import Swal from 'sweetalert2';
import { isLocal } from '../../../../constants/envs';
import { getPlayerUsername } from '../../../../generalUtils/utils';
import { client } from '../../../../helpers/client';


export const checkSessionStillActive = async () => {
  const account = getPlayerUsername();
  const resSessionCheck = await client.post('/api/auth/check-auth', { account });
  isLocal && console.debug('check session response:', { resSessionCheck });
  if (resSessionCheck.status !== 200) {
    Swal.fire({text: 'You probably don\'t want to make a payment, your authentication is expired so you would not be able to play.\nPlease log in again.'})
    return ({ shouldStop: true });
  }
  return ({ shouldStop: false });
};
