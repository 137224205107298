import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';
import Swal from 'sweetalert2';

import ExitIcon from '../../images/icons/exit.png';
import { UserService } from '../../services/UserService';
import { setPlayerLogout } from '../../reducers/UserReducer';
import { storeAppDispatch } from '../../GlobalState/Store';
import { fetchBoomBalance } from '../../sagas/helpers/wallet/waxUtils';
import { isLocal } from '../../constants/envs';
import { setPlayerWaxTokensBalances } from '../../reducers/WalletReducer';
import { uiHacks } from '../../generalUtils/uiHacks/uiHacks';
import { get, invoke } from 'lodash';
import { isPriviledgedUser } from '../../routing/authUtils';
import { NameAndBalance } from './LeftMenu/NameAndBalance';
import { nukeMultiTabTimestamp } from '../../generalUtils/secure';
import { AnonAdSquare } from '../../pages/LandingPage/subcomp/Banners/AnonAd';
import { REACT_UI_VERSION } from '../../config/version.json';
import { consumeTourneyConfig } from '../../pages/Play/subComponents/utils/requiredNFts/pvpRemoteConfig';
import { ActiveTourneyMsg } from './children/ActiveTourneyMsg';
import { StoragePane } from './LeftMenu/storage/StoragePane';
import { SocialLinks } from './LeftMenu/SocialLinks';
import './Menu.scss';


export const Menu = () => {
  const UserState = useSelector((store) => store.user);
  const { isLogged = false } = UserState;
  const InventoryState = useSelector((store) => store.inventory);
  const [mobileMenuIsActive, setMobileMenuActive] = useState(false);

  const [isActiveTourney, setIsActiveTourney] = useState(false);
  const [canBeFullyUsed, setCanBeFullyUsed] = useState(false);

  
  useEffect(() => {
    const fetchTourneyConfig = async () => {
      const { activeTourney, isPvpPassRequired = true } = await consumeTourneyConfig() || {};
      setIsActiveTourney(activeTourney === true);
      const waxAssets = InventoryState.csAssets;
      const hiveAssets = InventoryState.hive?.nfts;
      const canBeUsed = isLogged && (waxAssets?.csNfts?.length || hiveAssets.length);
      if (canBeUsed || (isLogged && !isPvpPassRequired)) setCanBeFullyUsed(true);
    };
    fetchTourneyConfig();
  }, [isLogged, InventoryState]);

  const onWalletMenuClick = async () => {
    setMobileMenuActive(false);
    isLocal && console.debug('Fetching balances..');
    if (!canBeFullyUsed) return;
    if (UserState.chainLogin !== 'wax') return;
    UserService.getBalance(); // wax
    const boomAmount = await fetchBoomBalance(UserService.authName);
    const savingsAmount = await Promise.resolve(0); // TEMP for savings @@ @@ @@ @@
    storeAppDispatch(setPlayerWaxTokensBalances({
      tokens: { boom: boomAmount },
      stakedSavings: { boom: savingsAmount }
    }));
    invoke(window, 'fetchUserData');
  };

  const onInventoryMenuClick = async () => {
    setMobileMenuActive(false);
    isLocal && console.log('Inventory clicked');
  };

  const onLeaderboardMenuClick = () => {
    if (!canBeFullyUsed) return;
    setMobileMenuActive(false);
  }

  const onPlayMenuClick = () => {
    setMobileMenuActive(false);
    isLocal && console.log('page show game clicked');
    if (!canBeFullyUsed) return;
    if (!window.pageShow) Swal.fire({ text: 'works only when embedded in the CS-wrapper' })
    invoke(window, 'fetchUserData');
    uiHacks.disableClaimAndPlayBtnsAtFirst();
  };

  const onLogoutMenuClick = () => {
    const confirmed = window.confirm('Are you sure that you want to log out?');
    if (!confirmed) return;
    setMobileMenuActive(false);
    if (UserState.chainLogin === 'wax') UserService.logout();
    // Stored on page load on the wrapper side, allows correct page reload
    var previousSession = localStorage.getItem('csUrlParams');
    if (previousSession) {
      const { testParam, devParam, authParamValue } = JSON.parse(previousSession);
      window.location.href = window.readOnlyEnv === 'prod'
      ? '/'
      : `/?${testParam ? 'test' : 'dev'}=${testParam || devParam}&auth=${authParamValue}`;
    }
    localStorage.removeItem('isLogged');
    nukeMultiTabTimestamp();
    storeAppDispatch(setPlayerLogout());
    if (UserState.chainLogin === 'hive') window.location.reload();
  }

  const appVersion = () => {
    // - REACT UI version
    isLocal && console.log('React UI version:', REACT_UI_VERSION);
    // - 4 APIs version (http://localhost:9090/api/health/version)
    //   API_CORE_VERSION	"1.19.12"
    //   API_DYNAMOCONNECTOR_VERSION	"1.19.12"
    //   API_WAXCHAINCONNECTOR_VERSION	"1.19.12"
    //   API_HIVECONNECTOR_VERSION	"1.19.12"
    // - Unity game version (PS. Application.unityVersion in postMessage)
    //   https://discussions.unity.com/t/difference-between-application-version-and-playersettings-bundleversion/230170
    // TODO: add all version in onClick panel @@ @@ and use in monitoring alerts
    return `v${get(window, 'version.GAME_VERSION', '0.0.0a')}`; // WEB WRAPPER
  };

  return (
    <nav id="menu" className="row" style={{ margin: 'auto' }}>
      {
        !UserState.isLogged && <Redirect to="/" />
      }

      {/* SOCIALS */}
      <div id="our-socials" className="col-2">
        <SocialLinks />
      </div>


      {/* LOGO, CHAIN, USERNAME */}
      <div className="col-3">
        <NameAndBalance />
      </div>

      {/* STORAGE */}
      {UserState.isLogged
        && <div className="col-1">
          <StoragePane />
        </div>
      }

      <div id="navigation-tabs" className="col-5">
        {canBeFullyUsed
          && <div className="row">
              <Link
                id="inventory" to="/inventory"
                onClick={onInventoryMenuClick}
                className={`${UserState.isLogged ? '' : 'disabled'} btn-item top-menu bottom-menu-buttons col-1`}
              >
                INVENTORY
              </Link>
              <Link
                id="wallet" to="/wallet"
                className={`${canBeFullyUsed ? '' : 'disabled'} btn-item top-menu bottom-menu-buttons col-1`}
                onClick={onWalletMenuClick}
              >
                TOKENS
              </Link>
              <Link
                  id="leaderboard" to="/leaderboard"
                  className={`${canBeFullyUsed ? '' : 'disabled'} btn-item top-menu bottom-menu-buttons col-1`}
                  onClick={onLeaderboardMenuClick}
                >
                  LEADERBOARD
              </Link>
              <Link
                id="play-btn-react" to="/play"
                onClick={onPlayMenuClick}
                className={`${canBeFullyUsed ? '' : 'disabled'} btn-item top-menu bottom-menu-buttons col-1`}
              >
                PLAY
              </Link>
              {
                (isPriviledgedUser(UserState.name) || isLocal)
                  && <Link
                    id="admin-panel" to="/admin"
                    onClick={() => {}}
                    className={`${canBeFullyUsed ? '' : 'disabled'} btn-item top-menu bottom-menu-buttons col-1`}
                  >
                    MOD
                  </Link>
              }
          </div>
        }
      </div>

      {UserState.isLogged &&
        <div id="logout-tab" className="col-1 row">
          {/* VERSION */}
          <div className="d-flex col-6" id="app-version">
            {appVersion()} <sub style={{ fontSize: '8px' }}>beta</sub>
          </div>

          {/* LOGOUT */}
          <div className="col-6">
            <Link
              id="logout-btn-react" to="/"
              onClick={onLogoutMenuClick}
            >&nbsp;
              <img src={ExitIcon} alt="Exit" width="20" /> &nbsp; &nbsp;
            </Link>
          </div>

          <div className="menu-icon">
            <HamburgerMenu
              isOpen={mobileMenuIsActive}
              menuClicked={() => { setMobileMenuActive(!mobileMenuIsActive); }}
              width={27}
              height={23}
              strokeWidth={3}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
        </div>
      }

      {/* // MOBILE */}
      <div className={`${mobileMenuIsActive ? "active" : ""} mobile-menu col-6`}>
        {canBeFullyUsed
          && <>
            <Link
              id="inventory" to="/inventory"
              onClick={onInventoryMenuClick}
              className={`${UserState.isLogged ? '' : 'disabled'} menu-item`}
            >
              INVENTORY
            </Link>
            <Link
              id="wallet" to="/wallet"
              className={`${canBeFullyUsed ? '' : 'disabled'} menu-item`}
              onClick={onWalletMenuClick}
            >
              WALLET
            </Link>
            <Link
                id="leaderboard" to="/leaderboard"
                className={`${canBeFullyUsed ? '' : 'disabled'} menu-item`}
                onClick={onLeaderboardMenuClick}
              >
                LEADERBOARD
            </Link>
            <Link
              id="play-btn-react" to="/play"
              onClick={onPlayMenuClick}
              className={`${canBeFullyUsed ? '' : 'disabled'} menu-item`}
            >
              PLAY
            </Link>
          </>
        }

        {
          UserState.isLogged && <>
            <Link
              id="logout-btn-react" to="/"
              className="menu-item"
              onClick={onLogoutMenuClick}
            >
              LOG OUT
            </Link>
          </>
        }
      </div>

      {UserState.isLogged && <AnonAdSquare />}
      {!UserState.isLogged && isActiveTourney && <ActiveTourneyMsg />}
    </nav>
  );
}
