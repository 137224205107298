import { get } from "lodash";
import { isTestnet } from "../../../constants/envs";
import { ITEMS_BY_CATEGORY } from "../../../constants/templateIds";
import { blacklistedAssetIds } from "../../../generalUtils/secure";
import { COOLDOWN_RANGE, INVENTORY_COOLDOWN_TS_KEY, SPACESHIP_PASS_COOLDOWN } from "./constants";


const storedTs = localStorage.getItem(INVENTORY_COOLDOWN_TS_KEY) || '';
const _getRoot = () => `${new Date().getDay() * new Date().getDay() * 1234}`;

export const newUserTs = (waxAcc) => {
  if (storedTs) return;
  const root = _getRoot();
  const ts = btoa(`${Math.floor(Math.random() * 9)}${waxAcc}${root}`).replace(/=/g, '');
  localStorage.setItem(INVENTORY_COOLDOWN_TS_KEY, ts);
};

// TODO: replace with API Call @@
export const checkIsNewUser = (waxAcc) => {
  var atobed = atob(storedTs).slice(1);
  return atobed === `${waxAcc}${_getRoot()}`;
};


const { EARLY_ACCESS, WEAPONS, SCHOLARSHIPS, BOARDING_PASSES } = ITEMS_BY_CATEGORY;
const { TD, SPA, PAA } = EARLY_ACCESS;
const {
  COR, FD5, FD9,
  FIR, SEQ, EXE,
  GEN, ART, EXA,
} = BOARDING_PASSES;

const PASSES_IDS = [
  COR.ID, FD5.ID, FD9.ID,
  FIR.ID, SEQ.ID, EXE.ID,
  GEN.ID, ART.ID, EXA.ID,
];
export const isSpaceShipPass = (templateId) => PASSES_IDS.includes(templateId);

export const shouldNewItemBeSkipped = ({ asset_id, isNewUser, template_id, transferred_at_time, minted_at_time }) => {
  // PLAYTEST
  if (isTestnet) return false;
  // SCHOHLARSHIP
  if (template_id === get(SCHOLARSHIPS, 'STU.ID')) return false;
  if (template_id === get(SCHOLARSHIPS, 'TEA.ID')) return false;
  // EARLY ACCESS ITEMS
  if (template_id === PAA.ID) return false;
  // if (template_id === TD.ID || template_id === SPA.ID) return false;
  // if (template_id === WEAPONS.BG.ID) return false; // base gun
  // NEW USER
  // if (isNewUser) return false;
  // NEW MINTS
  if (transferred_at_time === minted_at_time) return false;
  // LAST TRANSFER CHECK
  if (isSpaceShipPass(template_id) && ((Date.now() - +transferred_at_time) < SPACESHIP_PASS_COOLDOWN)) return true;
  if (transferred_at_time && ((Date.now() - +transferred_at_time) < COOLDOWN_RANGE)) return true;
  // CHEATER
  if (blacklistedAssetIds.data.includes(asset_id)) return true;
  // DEFAULT
  return false;
};
