import React from 'react';
import { isLocal } from '../../../constants/envs';
import { calcPercentageDoom } from '../subComponents/claims/claim';

export const WhichChains = ({ hiveNfts = [], waxNfts = [] }) => {
  const hiveNftsCount = hiveNfts.length;
  const waxNftsCount = waxNfts.length;
  isLocal && console.debug('NFT counts: ', { hiveNftsCount, waxNftsCount });
  if (!hiveNftsCount && waxNftsCount)
    return null; // wax only account
  if (hiveNftsCount && !waxNftsCount)
    return null; // hive only account
  const pcDoom = calcPercentageDoom({ waxNfts, hiveNfts });
  if (!pcDoom)
    return null;
  const pcWaxTokens = 100 - pcDoom;
  isLocal && console.debug('percentages: ', { pcDoom, pcWaxTokens });
  return <p className="pane-under-claim-btn text-white" style={{ fontSize: '14px', paddingBottom: '0px' }}>
    <p style={{  }}>Wax tokens rewards: &nbsp;<b>{pcWaxTokens}%</b><br/><sub>({waxNftsCount} Wax NFTs)</sub></p><br />
    <p style={{ marginTop: '-25px' }}>DOOM (Hive) rewards: &nbsp;<b>{pcDoom}%</b><br/><sub>({hiveNftsCount} Hive NFTs)</sub></p><br />
    <p style={{ marginTop: '-25px' }}>ETH token rewards: &nbsp;<b>{0}%</b><br/><sub>({0} Eth-L2 NFTs)</sub></p>
  </p>;
};
