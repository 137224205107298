import React from 'react';
import { isLocal } from '../../../../constants/envs';
import { getPendingRewardToClaim } from './wrapperData/extractors';
import './PendingRewards.scss';


const MatchEarnings = ({ rewards = {} } = {}) => {
  const allResults = Object.entries(rewards);
  if (!allResults.length) return null;
  const [first, ...rest] = allResults;
  const reordered = [...rest, first];
  const capitalize = str => str[0].toUpperCase() + str.slice(1);
  try {
    return reordered
      .map(([resultCategory, matches]) => <li key={resultCategory}>
          <b>{capitalize(resultCategory)}</b>: {matches.length
            ? matches.map(
              ({ amount, boomAmount, marsAmount } = {}) => (amount || boomAmount || marsAmount || 0).toFixed(2)
              ).join(', ')
            : <sub>none</sub>}
        </li>);
  } catch (err) {
    console.error('Match Earnings component broke:', err);
    return null;
  }
};

export const PendingRewards = ({
  name, rewards, shouldLockNavigation,
  whichActiveToken, setWhichActiveToken,
}) => {
  const totalRewards = getPendingRewardToClaim(rewards);
  isLocal && console.debug('Rewards:', { name, rewards, totalRewards });
  const isActiveTab = whichActiveToken === name;
  return (
    <div
      className="pending-rewards flashy-box"
      style={isActiveTab
        ? {
          boxShadow: '0 0 3px 4px rgba(255,255,255,0.8)',
          backgroundColor: 'rgba(1, 1, 1, 0.7)', color: 'white'
        }
        : { cursor: 'pointer', backgroundColor: '#33629c', color: 'white' }
      }
      onClick={() => !shouldLockNavigation && setWhichActiveToken(name)}
    >
      <p className="pen-rew-title">{name}</p>
      {(totalRewards > 0)
        ? <div style={{ marginBottom: '-10px' }}>
            <button
              id="expand-token"
              style={isActiveTab ? { color: 'grey' } : {}}
            >
              <span className="glyphicon glyphicon-chevron-right"></span>
            </button>
            <ul style={{ textAlign: 'left' }}>
              <MatchEarnings rewards={rewards} />
            </ul>
          </div>
        : <i style={{ fontSize: '12px' }}>
            No pending ig{name} rewards
          </i>}
    </div>
  );
};
