import React from 'react';
import { useSelector } from 'react-redux';
import Checkbox from 'rc-checkbox';
import { storeAppDispatch } from '../../../../GlobalState/Store';
import { setChainLogin, setPlayerTac } from '../../../../reducers/UserReducer';


export const TermsConditions = ({ urlParamChain }) => {
  const UserState = useSelector((store) => store.user);
  const { acceptedTac } = UserState;

  const termsToggle = (event) => {
    const newState = { acceptedTac: event.target.checked };
    storeAppDispatch(setPlayerTac(newState));
    if (event.target.checked) {
      localStorage.setItem('acceptedTac', Date.now());
    } else {
      localStorage.removeItem('acceptedTac');
    }
  };

  const ChainChoiceBtn = () => {
    return (<div
      id="choice-close-btn"
      className="col-3"
      onClick={() => storeAppDispatch(setChainLogin({ chainLogin: null }))}
    >
      Other Chains &gt;
    </div>
    );
  };

  return (<>
    <div id="accept-terms" className="row">
      <div className="col-9"></div>
      {!urlParamChain && <ChainChoiceBtn />}
      <div className="row col-12" id="terms-wrapper">
        <div
          id="tac-checkbox-wrap"
          className="col-3"
          style={{ marginLeft: '-50px', marginTop: '-15px', marginBottom: '20px' }}
        >
          <Checkbox
            id="tac-checkbox"
            onChange={termsToggle}
            className={`${acceptedTac ? "active" : "disabled"}`}
            checked={acceptedTac}
          ></Checkbox> &nbsp;
        </div>
        <div className="col-6" id="terms-text">
          <sub className="text-white">I have read and accept the &nbsp;</sub><br />
          <a
            href="https://crypto-shots.com/t-and-c-s"
            target="_blank"
            rel="noreferrer noopener"
          >
            <sub>
              Terms & Conditions (v1)
            </sub>
          </a>
        </div>
      </div>
    </div>
  </>);
};
