import React, { useEffect } from 'react';
import { isLocal } from '../../../../constants/envs';
import { useSelector } from 'react-redux';
import { prepLastMatchInfo } from './prepLastMatchInfo';
import { PlayTime } from './PlayTime';
import { getPlayerUsername } from '../../../../generalUtils/utils';
import Swal from 'sweetalert2';
import { askPvePlayerJoinTourney } from '../../../../services/notifications/joinTourney';

const getGamingTokenName = ({ activeLevel }) => {
  switch (activeLevel) {
    case 0:
    case 1: return 'BOOM';
    case 2:
    case 3: return 'MARS';
    case 4: return 'PROX';
    default: break;
  }
};

// GAME ID to verify screenshots during contests.
const antiCheatId = ({ lastMatch = {}, uname }) => {
  const {
    // startTime, endTime, gameLevel, matchID,
    difficulty = 0, finalScore = {}, monthDay = '',
  } = lastMatch;
  isLocal && console.debug('calculating match uuid:', { lastMatch, uname });
  if (!monthDay) return '';
  try {
    const uuid = window.btoa(
      `${difficulty}-${finalScore?.reward.slice(0, 3)}-${
        uname.slice(0, 2)}-${monthDay}`
        .split('').reverse().join(''),
    ).replace(/=/g, '');
    return uuid;
  } catch (err) {
    console.error('broke generating anti-cheat match id', err);
  }
};


export const MatchResults = () => {
  const UserState = useSelector((store) => store.user);
  const InventoryState = useSelector((store) => store.inventory);
  const PlayState = useSelector((store) => store.play);

  const activeLevel = PlayState.activeLevel;
  const { sessionData = {}, gameData = {}, apiResponse = {} } = PlayState.wrapperData;
  const { breakdownPerKill = {} } = gameData?.rewards || {};
  const { lastMatch = {} } = apiResponse;
  const {
    outcomeToDisplay, boomAmountToDisplay, killBalance, remainingTime, difficultyTxt, formattedRewards,
    // minsPlayed,
  } = prepLastMatchInfo({ sessionData, gameData });
  const rewardToken = getGamingTokenName({ activeLevel });
  const uname = getPlayerUsername({ UserState, InventoryState });
  const encodedMatchId = outcomeToDisplay && antiCheatId({ lastMatch, uname });

  const isPvp = PlayState.isPvpGameMode;

  useEffect(() => {
    // ask PVE player to join active F2E pvp tourney
    askPvePlayerJoinTourney({ isPvp });
  }, []);


  const showKillsInfo = () => {
    if (!breakdownPerKill.length) return;
    try {
      const toShow = breakdownPerKill.map(
        ({
          enemyName, weaponUsed, percentageBoost, enemyReward,
          immortalReward, killTotReward
        }, id) => `${id + 1}. ${enemyName} killed with ${weaponUsed}. Enemy reward ${
          enemyReward} * weapon reward ${percentageBoost}% = ${
            (killTotReward).toFixed(4)} (+ tekno reward? ${!!immortalReward ? 'Y' : 'N'})`
      ).join('\n\n');
      Swal.fire({ text: `KILL WEAPON rewards:\n\n${toShow}` })
    } catch (err) {
      console.error('Unable to show weapon kills popup', err);
    }
  };

  return (
    <div className="col-6 game-outcome" style={{ marginLeft: '25px', paddingTop: '0px', paddingBottom: 0 }}>
      <div className="flashy-box flashy-lightblue" style={{ padding: '20px' }}>
        <div>
          <b className="entry-title">Result:</b> &nbsp;
          <span id="game-outcome">
            {!!outcomeToDisplay
              ? <b>{outcomeToDisplay}</b>
              : <i style={{ fontSize: '14px' }}>not played yet</i>}
          </span>
        </div>
        <div style={{ marginTop: '15px' }}>
          <span className="entry-title">Earned:</span> &nbsp;
          <span id="win-amount">{boomAmountToDisplay
            ? <span><b>{boomAmountToDisplay}</b> <i><sub>ig</sub>{rewardToken}</i></span>
            : <span className="na">n/a</span>}
          </span>
        </div>
        <div>
          <sub><i><span id="rewards-breakdown">
            {formattedRewards}
          </span></i></sub>
        </div>
      </div>
      <div
        className="row flashy-box flashy-lightblue"
        style={{ marginTop: '10px', marginBottom: '-5px', lineHeight: '14px', paddingBottom: 0 }}
      >
        <p className="col-4">
          <span className="entry-title">Kills:</span>
          <p id="session-kills" className="stats-value">{(killBalance !== undefined)
            ? <span
                id="kills-details"
                style={{ color: breakdownPerKill.length ? 'rgb(234, 234, 110)' : 'white' }}
                onClick={showKillsInfo}
              >
                {killBalance}*
              </span>
            : <sub>n/a</sub>}</p> &nbsp;
        </p>
        <p className="col-4">
          <span className="entry-title">Difficulty:</span>
          <p id="difficulty-level" className="stats-value">{!!outcomeToDisplay
            ? <span>{difficultyTxt}</span>
            : <sub>n/a</sub>}</p>
        </p>
        <p className="col-4">
          <span className="entry-title">Time left:</span>
          <p id="remaining-time" className="stats-value">{!!remainingTime
            ? <span>{remainingTime}</span>
            : <sub>n/a</sub>}</p>
        </p>
        {<i className="col-12 match-id-ui" style={{ marginTop: '-20px', paddingBottom: '0px' }}>
          <sub>Match ID: <span id="game-id-ui">{encodedMatchId || <span>&nbsp;n/a</span>}</span></sub>
        </i>}
      </div>
      <PlayTime />
    </div>
  );
};
