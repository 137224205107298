import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { isLocal } from '../constants/envs';
import { isPriviledgedUser } from './authUtils';


const ProtectedRoute = ({ component: Component, ...rest }) => {
    const UserState = useSelector((store) => store.user);
    const InventoryState = useSelector((store) => store.inventory);
    const { csAssets: waxAssets = {}, hive = {}, skale = {}, eth = {} } = InventoryState;
    const { nfts: hiveNfts = [], username: hiveUsername } = hive;
    const { nfts: skaleNfts = [], username: skaleUsername } = skale;
    const { nfts: ethNfts = [], username: ethUsername } = eth;
    // NFTs counting
    const hasWaxNfts = (waxAssets?.csNfts.length + Object.keys(waxAssets?.hotNfts || {}).length) > 0;
    const hasHiveNfts = hiveNfts.length > 0;
    const hasSkaleNfts = skaleNfts.length > 0;
    const hasEthNfts = ethNfts.length > 0;
    const hasNfts = hasWaxNfts || hasHiveNfts || hasSkaleNfts || hasEthNfts;
    // username
    const hasUsername = hiveUsername || skaleUsername || ethUsername;
    // Can be used: login, nfts, username
    const canBeFullyUsed = UserState.isLogged && (hasNfts || hasUsername);
    const canUseAdminPanel = (props) => get(props, 'location.pathname') === 'admin'
        && isPriviledgedUser(UserState.name);

    return (
        <Route {...rest} render={
            (props) => {
                isLocal && console.debug('Rendering component for:',
                  {
                    locationObj: props.location,
                    user: UserState.name,
                    isAdmin: isPriviledgedUser(UserState.name),
                  }
                );
                if (canUseAdminPanel(props)) {
                    return <Component {...rest} {...props} />
                } if (canBeFullyUsed) {
                    return <Component {...rest} {...props} />
                } else {
                    isLocal && console.error('{cant be fully used}');
                    return <Redirect to={
                        {
                            pathname: '/',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
}

export default ProtectedRoute;
