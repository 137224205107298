import React from 'react';


export const ProxRewardsActions = ({ whichActiveToken, isClaimBtnDisabled }) => {
  return (
    <>
      <div
        className="col-12 mt-4" id="claim-prox-container"
        style={{ paddingTop: '50px', paddingBottom: '50px' }}
      >
        <button
          className="btn btn-success btn-lg" id="claim-prox"
          onClick={() => {}}
          disabled={isClaimBtnDisabled}
        >
          Stake all your pending ig{whichActiveToken}
        </button>
      </div>
      <div
        className="col-12 mt-4" id="claim-prox-container"
        style={{ paddingTop: '50px', paddingBottom: '50px' }}
      >
        <button
          className="btn btn-success btn-lg" id="claim-prox"
          onClick={() => {}}
          disabled={isClaimBtnDisabled}
        >
          Unstake 5% of your ig{whichActiveToken}
        </button>
      </div>
    </>
  );
};
