
export const LS_ID_SUBS_TX_HASH = 'subHash';

export const SUB_TYPES = {
  CLASSIC_WEEK : 'CLASSIC-7d',
  CLASSIC_MONTH: 'CLASSIC-30d',
  PREMIUM: 'PREMIUM',
}

export const SUBS_PRICES = {
  v1: {
    [SUB_TYPES.CLASSIC_WEEK]: 600,
    [SUB_TYPES.CLASSIC_MONTH]: 2000,
    [SUB_TYPES.PREMIUM]: 3000,
  },
};
