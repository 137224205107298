import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { storeAppDispatch } from '../../../GlobalState/Store';
import { setChainLogin } from '../../../reducers/UserReducer';
import { getSingleChainLogin } from '../../../generalUtils/browser/urlParams';
import { TermsConditions } from './Shared/TermsConditions';
import { ChainChoicePanel } from './Shared/ChainChoicePanel';
import { ChainPromo } from './Shared/ChainIcon';
import { GameModesBtn } from './Shared/GameModesBtn';
import { HiveLoginPanel } from './Hive/HiveLoginPanel';
import { WaxLoginPanel } from './Wax/WaxLoginPanel';
import { BitcoinLoginPanel } from './Bitcoin/BitcoinLoginPanel';
import { SkaleLoginPanel } from './Ethereum/SkaleChain/SkaleLoginPanel';
import { EthNetworkChoice } from './Ethereum/EthNetworkChoice';
import { EthLoginPanel } from './Ethereum/EthChain/EthLoginPanel';
import './LoginOptions.scss';


export const LoginChoice = ({ backBtn: backToModeChoice } = {}) => {
  const UserState = useSelector((store) => store.user);
  const { chainLogin } = UserState;
  const urlParamChain = getSingleChainLogin();
  const chain = urlParamChain || chainLogin;
  
  const isIntermediateScreen = chain === 'eth-choice';
  const shouldDisplayTc = !isIntermediateScreen && urlParamChain !== 'btc';

  useEffect(() => {
    if (urlParamChain && !chainLogin) {
      storeAppDispatch(setChainLogin({ chainLogin: urlParamChain }));
    }
  }, []);

  const LoginPanel = ({ chain }) => {
    switch (chain) {
      case 'wax': return <WaxLoginPanel />;
      case 'hive': return <HiveLoginPanel />;
      case 'btc': return <BitcoinLoginPanel />;

      // ETH CHAINS
      case 'eth-choice': return <EthNetworkChoice/>;
      case 'skl': return <SkaleLoginPanel />;
      case 'eth': return <EthLoginPanel />;

      default: return null;
    }
  };

  return (
    <div id="login-choice-container" className="text-white">
      {(urlParamChain || !chainLogin) && <GameModesBtn {...{ backToModeChoice }}/>}
      {chain && <ChainPromo chainName={chain} />}
      {(!chainLogin && !urlParamChain)
        ? <ChainChoicePanel />
        : <>{shouldDisplayTc && <TermsConditions {...{ urlParamChain }} />}</>
      }
      <LoginPanel chain={chain} />
    </div>
  );
};
