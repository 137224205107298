import { get } from 'lodash';
import Store from '../GlobalState/Store';
import { UserService as US } from '../services/UserService';
import { isLocal } from '../constants/envs';

export const nap = ms => new Promise(res => setTimeout(res, ms));

export const isValidNumber = price => !Number.isNaN(+price);

export const isObjectEmpty = (obj = {}) => Object.keys(obj).length === 0;

export const capitalize = (ss = '') => {
  if (!ss || !ss[0]) return ss;
  return ss[0].toUpperCase() + ss.slice(1).toLowerCase();
}


// ## NFTs

// export const getNftCount = ({ nfts = [], id = '' }) => {
//   if (typeof id !== 'string') throw new Error(`Unexpected ID in counting helper: ${id}`);
//   return nfts.filter(nft => nft.template_id === id).length
// };
export const getNftCount = ({
  nfts = [], id: templateId = '',
  hiveNfts = [], name = '',
}) => {
  if (!templateId && !name) throw new Error(`Unexpected ID in counting helper: ${templateId || name}`);
  if (nfts && templateId) {
    return nfts.filter(nft => nft.template_id === templateId).length;
  }
  if (hiveNfts && name) {
    return hiveNfts.filter(nft => nft.name === name).length;
  }
  throw new Error(`Unexpected input to getNftCount: ${
    JSON.stringify({ wax: { nfts, templateId }, hive: { hiveNfts, name } })
  }`);
};


// ## USER DETAILS

export const getPlayerUsername = ({
  UserService = US, UserState, InventoryState,
  shorten = false,
} = {}) => {
  let userState = UserState
  let inventoryState = InventoryState;
  if (!UserState || !InventoryState) {
    const { user, inventory } = Store.getState();
    userState = user;
    inventoryState = inventory;
  }
  // HIVE
  const hiveData = get(inventoryState, 'hive', {});
  const { username: hiveUsername } = hiveData;
  // SKL
  const sklData = get(inventoryState, 'skale', {});
  const { username: sklUsername } = sklData;
  // ETH
  const ethData = get(inventoryState, 'eth', {});
  const { username: ethUsername } = ethData;

  const uname = UserService.authName || userState.name || hiveUsername || sklUsername || ethUsername;
  isLocal && console.log('getPlayerUsername found:',
    { userService: UserService.authName, userState: userState.name, hiveUsername, sklUsername, ethUsername });

  if (shorten) return `${uname.slice(0, 5)}..${uname.slice(-3)}`;
  if (uname) return uname;

  // FALLBACK
  const fallbackUname = localStorage.getItem('hiveUsername')
    || localStorage.getItem('sklUsername')
    || localStorage.getItem('waxUsername')
    || localStorage.getItem('ethUsername')
    || atob(localStorage.getItem('plyd') || '').split('__')[1];
  return fallbackUname;
};
