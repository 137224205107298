import { invoke } from 'lodash';
import { isLocal } from '../../../constants/envs';
import { payForNextLevel } from '../../../sagas/helpers/wallet/payAfterTrial/payForNextLevel';
import { windowSetChecksum } from '../../../generalUtils/secure/checksums';


// INIT FREE TRIAL
export const setFreeTrial = ({ value, from }) => {
  isLocal && console.debug('Free UI:', value);
  if (window.isFreeTrial === undefined) {
    window.isFreeTrial = value;
    windowSetChecksum({ key: 'isFreeTrial', value });
  }
  if (from === 'login-btn') { // login sets it to false and freezes it
    isLocal && console.debug('After login set freeTrial to', value);
  } else if (from === 'free-btn') {
    isLocal && console.debug('After F2P set freeTrial to', value);
    const originaValue = window.isFreeTrial; // cant login after F2P anyway
    try {
      Object.defineProperty(window, 'isFreeTrial', { value: originaValue, writable: false });
    } catch (_) {}
    invoke(window, 'initFreeTrial');
  }
};
window.payForNextLevel = payForNextLevel;
windowSetChecksum({ key: 'payForNextLevel', value: payForNextLevel });

// PAYMENT TO UNLOCK LEVELS ABOVE 2nd
try {
  const original = window.payForNextLevel;
  Object.defineProperty(window, 'payForNextLevel', { value: original, writable: false });
} catch (_) { }
