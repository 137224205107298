import { RESOURCES_PRICES } from './resourcesPrices';
import { TOKEN_ACCOUNT } from '../../../../config/contractNames';
import { flyingSessionLogin, UserService, WaxFlyingSession } from '../../../../services/UserService';
import { isLocal } from '../../../../constants/envs';
import { get } from 'lodash';
import { WAX_ACCOUNTS } from '../../../../config/accounts';
import { MEAL_TX_ID_PREFIX } from '../../subComponents/spaceships/constants';
import { mealPaymentMemo } from '../../../../generalUtils/paymentMemo';
import { isClassicStorage } from '../../../../components/Menu/LeftMenu/storage/utils';
import { client } from '../../../../helpers/client';
import { checkSessionStillActive } from '../../subComponents/spaceships/checkSession';
import Swal from 'sweetalert2';


export const sendFoodPayment = ({ charactersToPay, activeLevel, storageType }) => new Promise(async (resolve) => {
  const price = RESOURCES_PRICES.FOOD * charactersToPay;
  if (!price) { throw new Error(`Unexpected input for food payment: ${charactersToPay} x ${price}`); }
  const quantity = `${price.toFixed(4)} FOOD`;
  const contractName = TOKEN_ACCOUNT;
  const targetWallet = WAX_ACCOUNTS.FOOD_CANTEEN;

  let waxSession = UserService;
  const isHiveUser = localStorage.getItem('chainLogin') !== 'wax';
  if (isHiveUser && !UserService.authName) {
    isLocal && console.debug('creating side session for wax tokens payment for hive user');
    waxSession = WaxFlyingSession;
    const isLoggedIn = await flyingSessionLogin(waxSession);
    if (!isLoggedIn) return resolve({ err: 'still not logged in for wax tokens payment' });
  }
  isLocal && console.debug('Expected user for payment:', waxSession.authName);

  const memo = mealPaymentMemo({ activeLevel, payer: waxSession.authName, quantity });
  isLocal && console.debug('FOOD payment:', { charactersToPay, price });

  const { shouldStop } = await checkSessionStillActive();
  if (shouldStop) return;

  waxSession.session.signTransaction(
    {
      actions: [{
        account: contractName,
        name: 'transfer',
        authorization: [{
          actor: waxSession.authName,
          permission: 'active'
        }],
        data: {
          from: waxSession.authName,
          to: targetWallet,
          quantity,
          memo,
        }
      }]
    },
    { blocksBehind: 3, expireSeconds: 30 },
  )
    .then(async (response = { status: '' }) => {
      isLocal && console.log('Transaction outcome:', response.status);
      if (response.status !== 'executed') {
        console.error('Food payment failed:', JSON.stringify({ response }));
        Swal.fire({ text: `Food payment failed.\n\n${JSON.stringify(response.status)}` })
        resolve({ err: (response.status || 'ko') });
        return;
      }
      isLocal && console.debug('Resources payment response:', { response });

      const txHash = response.transactionId;
      localStorage.setItem(`${MEAL_TX_ID_PREFIX}-L${activeLevel}`, txHash);
      isLocal && console.log('Meal tx put in ls', { payment: txHash, level: activeLevel });

      // ## STORE IN DB if PREMIUM sub
      if (storageType && !isClassicStorage(storageType)) {
        try {
          const data = await client.post(
            '/api/storage/meals',
            { account: waxSession.authName, mealL0WaxTx: txHash },
          );
          isLocal && console.log('Store meal result:', { data });
        } catch (err) {
          let errMsg = get(err, 'response.data.error') || 'Unknown error';
          Swal.fire({ text: `Uh oh, something went wrong storing your meal in db: ${errMsg}` })
          // resolve({ err }); // dont waste their payment
        }
      }
      resolve({});
    })
    .catch((err) => {
      console.error('Food payment went wrong:', JSON.stringify({ err }));
      const errMsg = get(err, 'cause.message');
      const errMsg2 = get(err, 'cause.json.error.details[0].message');
      const errMsg3 = get(err, 'cause.json.message');
      Swal.fire({ text: `Food payment failed.\n\n${errMsg || errMsg2 || errMsg3 || JSON.stringify(err)}` })
      resolve({ err });
    });
});
