import React from 'react';
import { useSelector } from 'react-redux';
import { AccountLinking } from './AccountLinking';


export const BtcInventory = () => {
  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;

  if (chainLogin === 'hive') {
    return (<div className="mt-3 inventory-top-panel">
      <div className="black-shade" style={{ marginLeft: '30px', width: '100%' }}>
        <AccountLinking tab="btc"/>
      </div>
    </div>);
  }

  return (
    <div className="row mt-3">
      YOUR ORDINALS:
    </div>
  );
};
