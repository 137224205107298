import React from 'react';

export const InfoIcon = () => {
  return (<span
    style={{
      border: '1px solid grey', padding: '0px 5px 5px', borderRadius: '10px',
      height: '8px', marginLeft: '10px', color: 'white', cursor: 'pointer',
      textDecoration: 'none',
    }}
  >
    {/* <sub className="glyphicon glyphicon-info-sign">i</sub> */}
    <i class="fa-solid fa-circle-info">i</i>
  </span>);
};
