import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { UserService } from '../../../services/UserService';
import { COOLDOWN_HOURS } from '../../../sagas/helpers/inventory/constants';
import { ActiveInventory } from '../ActiveInventory';
import { HotAssets } from '../HotAssets';
import { isTestnet } from '../../../constants/envs';
import { AccountLinking } from './AccountLinking';
import { getPlayerUsername } from '../../../generalUtils/utils';
import { storeAppDispatch } from '../../../GlobalState/Store';
import { linkedWaxAccount as linkedWaxAccountData } from '../../../reducers/InventoryReducer';
import { unlinkAccount } from '../../../sagas/helpers/login/linking';
import { useState } from 'react';


export const WaxInventory = () => {
  const InventoryState = useSelector((store) => store.inventory);
  const coolDownCount = get(InventoryState, 'csAssets.hotNfts')
    ? Object.keys(InventoryState.csAssets.hotNfts).length
    : 0;
  const waxNfts = get(InventoryState, 'csAssets.csNfts', []);
  const waxHotNfts = get(InventoryState, 'csAssets.hotNfts', {});
  
  const linkedWaxAccount = get(InventoryState, 'hive.linkedWaxAccount');
  const bloksLink = linkedWaxAccount && <><a
      href={`https://wax.atomichub.io/profile/${linkedWaxAccount}#inventory`}
      target="_blank" rel="noreferrer"
    >
      {linkedWaxAccount}
    </a><p style={{ display: 'inline-block', fontSize: '16px', margin: '-10px 5px auto' }}>'s</p></>

  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;

  const WalletState = useSelector((store) => store.wallet);
  const boomTot = +WalletState.tokens.boom;
  const boomDisplayValue = boomTot ? <span>{boomTot.toFixed(4)} $BOOM</span>
    : <span>n/a <sub><a href={`https://wax${isTestnet ? '-test' : ''}.bloks.io/account/${
        UserService.authName || 'crypto4shots'}#tokens`} target="_blank" rel="noreferrer">( see bloks )</a></sub></span>;


  const [unlinkWip, setUnlinkWip] = useState(false);

  const unlinkChain = async () => {
    setUnlinkWip(true);
    try {
      const currentAuthUser = getPlayerUsername({ UserState, InventoryState });
      const ok = window.confirm(`Are you sure?\n\nUnlink your WAX account ${linkedWaxAccount
        }\nfrom your ${chainLogin.toUpperCase()} account ${
          chainLogin === 'hive' ? '@' : ''}${currentAuthUser}?`);
      if (!ok) return;

      const { result, err, hiveErr } = await unlinkAccount({
        currentAuthUser, inputAccount: linkedWaxAccount, tab: 'wax',
      });
      // Unlink Failure
      if (err) alert(`Failed to unlink Hive account ${linkedWaxAccount}: ${err}`);
      // Unlink Success
      else {
        storeAppDispatch(linkedWaxAccountData({ waxAccount: null }));
        // Hive DB Failure
        if (hiveErr) alert('Wax account unlinked in our Database but not on Hive, please contact support.');
        // Hive DB Success
        else console.log('Wax account unlink success - Hive tx:', result);
      }
    } catch (err) {
      console.error('Something broke unlinking chain:', err);
      alert('Uh oh, something broke..');
    } finally {
      setUnlinkWip(false);
    }
  };

  // ACCOUNT LINKING
  if (chainLogin === 'hive' && (waxNfts.length + Object.keys(waxHotNfts).length) === 0) {
    return (<div className="mt-3">
      <div style={{ marginLeft: '30px', width: '380px', margin: 'auto' }}>
        <AccountLinking tab="wax"/>
      </div>
    </div>);
  }

  return (
    <div className="row mt-3">
      <div className="row inventory-top-panel">
        <div className="col-6 text-center total-nfts">
          <div className="row">
            <p id="tot-nfts">
              <b><span>{linkedWaxAccount ? bloksLink : 'TOTAL'}</span> NFTs: &nbsp;</b>{waxNfts.length}
            </p>
          </div>
          <div className="row">
            <div className="row">
              <p style={{ marginTop: '5px', fontSize: '17px', fontWeight: 'bold' }}>
                On their way:&nbsp; {coolDownCount} &nbsp; <sub>({`${COOLDOWN_HOURS}hs cooldown`})</sub>
              </p>
            </div>
            <div className="row" id="hot-nfts">
              <HotAssets hotNfts={waxHotNfts} />
            </div>
            <button
              style={{
                backgroundColor: 'white', color: 'red', width: '160px',
                margin: 'auto', marginTop: '-20px', fontSize: '14px', fontWeight: 'bold',
                display: chainLogin !== 'wax' ? 'block' : 'none', minHeight: '25px',
              }}
              onClick={unlinkChain}
            >
              {unlinkWip
                ? <i class="fa fa-spinner fa-spin" style={{ fontSize: '20px', minHeight: '35px', color: 'red' }}></i>
                : <span>UNLINK ACCOUNT</span>}
            </button>
          </div>
        </div>
        <div className="col-6 boom-for-purchases">
          <p id="ah-link">
            <a
              href={`https://wax.atomichub.io/profile/${UserService.authName || 'crypto4shots'}#inventory`}
              target="_blank" rel="noreferrer noopener">
              Your AtomicHub
            </a>
          </p>
          <div>
            <b id="boom-purchases-title">Available for purchases:</b>
            <p style={{ fontSize: '16px' }}>{boomDisplayValue}</p>
            <i>
              <a className="alcor-buy-boom"
                href="https://wax.alcor.exchange/trade/boom-csboomcsboom_wax-eosio.token"
                target="_blank" rel="noreferrer noopener">
                BUY $BOOM
              </a>
            </i>
          </div>
        </div>
      </div>
      <div>
        <ActiveInventory csNfts={waxNfts} />
      </div>
    </div>
  );
};
