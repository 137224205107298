import React from 'react';

export const KeychainSwapWidget = ({ hiveUsername }) => {
  const iframeUrl = `https://swapwidget.hive-keychain.com/?username=${hiveUsername}&partnerUsername=cryptoshots.nft&partnerFee=0.5&from=DOOM&to=HIVE&slippage=1`;
  return (
    <iframe
      id="swapWidgetkeychain"
      title="Swap Tokens with Keychain"
      src={iframeUrl}
      allow="clipboard-write"
      style={{
        width: '330px', height: '400px', margin: 'auto', border: 'none', marginTop: '-55px'
      }}
    >
    </iframe>
  );
};
