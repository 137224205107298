import Swal from "sweetalert2";
import { DEFAULT_CUT_SCHOLAR, DEFAULT_CUT_TEACHER } from "../../../config/appConfig";
import { isTestnet } from "../../../constants/envs";
import { CATEGORIES_BY_ITEM, ITEMS_BY_CATEGORY } from "../../../constants/templateIds";
import { blacklistedUsers } from "../../../generalUtils/secure";
import { nap } from "../../../generalUtils/utils";
import { fetchInventory } from "./fetchInventory";
import { getItemPrice } from "./marketplacePrices";


const { SCHOLARSHIPS } = ITEMS_BY_CATEGORY;
const msInOneDay = 24 * 60 * 60 * 1000;

const getInfoCustomField = ({ customField }) => {
  if (customField.length < 3) return ({});
  try {
    const parsedJson = JSON.parse(customField.trim());
    const {
      scholarKeepsNfts = false, scholarPreviousBoomStake = 0, expiration = {},
    } = parsedJson;
    const { startTs = 0, durationDays = 0 } = expiration;
    const expirationTs = startTs + (durationDays * msInOneDay);
    const expiresInMs = expirationTs && (expirationTs - Date.now());
    isTestnet && console.debug('Scholarship custom fields:', { scholarKeepsNfts, scholarPreviousBoomStake, expiresInMs });
    return ({ scholarKeepsNfts, scholarPreviousBoomStake, expiresInMs });
  } catch (err) {
    console.error('Something broke extracting info from custom field:', err);
    return ({});
  }
};


const hasOneScholarNft = ({ csNfts: firstRoundNfts }) => {
  const scholarNfts = firstRoundNfts.filter(nft => nft.template_id === SCHOLARSHIPS.STU.ID);
  if (scholarNfts.length > 1) {
    Swal.fire({text: 'CAN ONLY HAVE ONE SCHOLAR NFT'}); // plus webhook
    throw new Error('more than one scholar nft');
  }
  const scholarNft = scholarNfts[0];
  if (!scholarNft) return ({});
  const { partnersWaxAddr, ownersCut, customField } = scholarNft.scholarship || {};
  const { scholarKeepsNfts, scholarPreviousBoomStake, expiresInMs } = getInfoCustomField({ customField });
  if (expiresInMs && expiresInMs < 0) {
    Swal.fire({text: 'This scholarship account is expired, please burn your Scholarship NFT'});
    throw new Error('expired-scholarship');
  }
  isTestnet && console.debug('Scholarship custom fields:', { scholarNft });
  const scholarCut = Math.max(DEFAULT_CUT_SCHOLAR, ownersCut || DEFAULT_CUT_SCHOLAR); // no 50/50 old scholarships
  return ({
    teacherName: partnersWaxAddr.trim(), scholarCut,
    scholarKeepsNfts, scholarPreviousBoomStake,
  });
};

const hasOneTeacherNft = ({ csNfts: teacherNfts }) => {
  const teachNfts = teacherNfts.filter(nft => nft.template_id === SCHOLARSHIPS.TEA.ID);
  if (teachNfts.length > 1) {
    Swal.fire({text: 'CAN ONLY HAVE ONE TEACHER NFT'}); // plus webhook
    throw new Error('more than one teacher nft');
  }
  const teacherNft = teachNfts[0];
  if (!teacherNft) return ({});
  const { partnersWaxAddr, ownersCut, customField } = teacherNft.scholarship || {};
  const { scholarKeepsNfts, scholarPreviousBoomStake, expiresInMs } = getInfoCustomField({ customField });
  if (expiresInMs && expiresInMs < 0) {
    Swal.fire({text: 'This scholarship account is expired, please burn your Scholarship NFT'});
    throw new Error('expired-scholarship');
  }
  isTestnet && console.debug('Scholarship custom fields:', { teacherNft });
  const teacherCut = Math.min(DEFAULT_CUT_TEACHER, ownersCut || DEFAULT_CUT_TEACHER); // no 50/50 old scholarships
  return ({
    scholarName: partnersWaxAddr.trim(), teacherCut,
    scholarKeepsNfts, scholarPreviousBoomStake,
  });
};


export const processInventory = async ({ username }) => {
  let scholarship = null;
  let teacherNameLs = localStorage.getItem('b055');
  // ## Fetch inventory
  isTestnet && console.log(`Fetching NFTs for ${username}. Is teacher (from LS) -> ${!!teacherNameLs}`);
  if (blacklistedUsers[teacherNameLs]) { teacherNameLs = ''; }
  let { csNfts, hotNfts, err } = await fetchInventory(teacherNameLs || username);
  if (err) {
    console.error('Inventory fetch:', err);
    Swal.fire({text: 'Inventory check failed, please try again.\nIf this persists try changing your IP address.\nIf all still fails it may be a temporary issue with Wax. Hang in there!'});
    return ({ err: 'inventory-check-fail' }); // TODO: analytics
  }
  // ## Anti cheat - in case they put it in the LS
  if (teacherNameLs) {
    const { scholarName } = hasOneTeacherNft({ csNfts });
    if (scholarName !== username) {
      console.error('Account reported - LS teacher but no student nft', { scholarName, username });
      localStorage.removeItem('b055');
      const isWaxLogin = localStorage.getItem('chainLogin') === 'wax';
      isWaxLogin && Swal.fire({text: 'Stale data from your Scholar account, please try again.'});
      return ({ err: 'ls-teacher-inventory-but-no-student' }); // TODO: discord notification
    }
  }
  // ## Check if he's a Teacher -----------------------------------
  isTestnet && console.log('Checking if it has TEACHER NFT');
  const {
    scholarName, teacherCut,
    scholarKeepsNfts: skn1, scholarPreviousBoomStake: spbs1,
  } = hasOneTeacherNft({ csNfts });
  if (scholarName) {
    isTestnet && console.log('YEP, HAS TEACHER NFT.', { scholarName, teacherCut });
    scholarship = { isTeacher: true, scholarName, teacherCut };
    if (skn1) { scholarship.scholarKeepsNfts = !!skn1 }
    if (spbs1) { scholarship.scholarPreviousBoomStake = +spbs1 }
  }
  // ## Check if he's Scholar ----------------------------------------
  // Teacher not in LS -> it could be scholar first time or new browser
  isTestnet && console.log('Checking if it has SCHOLAR NFT');
  const {
    teacherName: teacherNameNft, scholarCut,
    scholarKeepsNfts: skn2, scholarPreviousBoomStake: spbs2,
  } = hasOneScholarNft({ csNfts });
  const isTeacherBlacklisted = blacklistedUsers[teacherNameLs];
  if (isTeacherBlacklisted) {
    Swal.fire({text: 'Your teacher is blacklisted. Burn your scholar NFT.'});
    return;
  }
  if (teacherNameNft) {
    isTestnet && console.log('YEP, HAS SCHOLAR NFT. Re-fetching.', { teacherNameNft, scholarCut });
    await nap(3000);
    const {
      csNfts: teachersNfts, hotNfts: teacherHotNfts,
      err: teacherErr,
    } = await fetchInventory(teacherNameNft);
    if (teacherErr) {
      console.error('Teacher inventory fetch:', err);
      Swal.fire({text: 'Teacher inventory check failed, please try again.'});
      return ({ err: 'teacher-inventory-check-fail' }); // TODO: analytics
    }
    // verify teacher still has this scholar
    isTestnet && console.log('Verifying that TEACHER still has this SCHOLAR..');
    const { scholarName } = hasOneTeacherNft({ csNfts: teachersNfts });
    if (!scholarName) {
      console.error('Account reported - The TEACHER account does not have this SCHOLAR..');
      return ({ err: 'using-teacher-inventory-but-no-student' }); // TODO: warning hook
    } else if (scholarName !== username) {
      console.error('Account reported - The TEACHER has a different SCHOLAR', { scholarName, username });
      return ({ err: 'using-teacher-inventory-but-different-student' }); // TODO: warning hook
    }
    csNfts = teachersNfts;
    hotNfts = teacherHotNfts;
    localStorage.setItem('b055', teacherNameNft);
    isTestnet && console.log(`Done. Using ${csNfts.length} teacher NFTs.`);
  }
  const teacher = teacherNameLs || teacherNameNft;
  if (teacher) {
    isTestnet && console.log('Using TEACHER NFTs.', { teacher, teacherCut });
    scholarship = {
      isScholar: true, teacherName: teacher,
      scholarCut: scholarCut || (100 - teacherCut),
    };
    if (skn2) { scholarship.scholarKeepsNfts = !!skn2 }
    if (spbs2) { scholarship.scholarPreviousBoomStake = +spbs2 }
  }

  // ## PRICES AND CATEGORIES
  isTestnet && console.log('Getting prices and categories..');
  for (let id = 0; id < csNfts.length; id++) {
    const currentNft = csNfts[id];
    const { template_id: template } = currentNft;
    // Insert price
    const { price } = await getItemPrice({ templateId: template });
    currentNft.price = price;
    // Insert category
    const currentCategory = CATEGORIES_BY_ITEM[template];
    if (!currentCategory) {
      console.log('Found item without category :::::::::::::::::::--+>', currentNft);
    }
    currentNft.category = currentCategory || 'OTHERS';
  }

  // TEST @@@@@@@@@@@@@@@
  // scholarship = {
    // // SCHOLAR
    // isScholar: true, teacherName: 'csteknopolly',
    // scholarCut: 0.4 || (100 - teacherCut),
    // // TEACHER
    // isTeacher: true, scholarName: 'crypto5shots',
    // teacherCut: 0.6,
  // };
  // TEST @@@@@@@@@@@@@@@
  isTestnet && console.log('Scholarship state for redux -_-_-_-_-_-_:', { scholarship });

  // Result
  return ({ csNfts, hotNfts, scholarship });
};
