import { isLocal } from '../../constants/envs';

const interceptChainChoice = ({ urlParams }) => {
  const isHiveOnlyLogin = urlParams.has('hive');
  if (isHiveOnlyLogin) {
    sessionStorage.setItem('exclusiveLogin', 'hive');
    return;
  }
  const isWaxOnlyLogin = urlParams.has('wax') || urlParams.has('waxp');
  if (isWaxOnlyLogin) {
    sessionStorage.setItem('exclusiveLogin', 'wax');
    return;
  }
  const isBtcOnlyLogin = urlParams.has('btc') || urlParams.has('bitcoin');
  if (isBtcOnlyLogin) {
    sessionStorage.setItem('exclusiveLogin', 'btc');
    return;
  }
  const isEthOnlyLogin = urlParams.has('eth') || urlParams.has('ethereum');
  if (isEthOnlyLogin) {
    sessionStorage.setItem('exclusiveLogin', 'eth-choice');
    return;
  }
  const isSklOnlyLogin = urlParams.has('skl') || urlParams.has('skale');
  if (isSklOnlyLogin) {
    sessionStorage.setItem('exclusiveLogin', 'skl');
    return;
  }
  sessionStorage.removeItem('exclusiveLogin');
};

const interceptGamemodeChoice = ({ urlParams }) => {
  const wantsPve = urlParams.has('pve') || urlParams.has('singleplayer');
  if (wantsPve) {
    sessionStorage.setItem('urlGameMode', 'pve');
    return;
  }
  const wantsPvp = urlParams.has('pvp') || urlParams.has('multiplayer');
  if (wantsPvp) {
    sessionStorage.setItem('urlGameMode', 'pvp');
    return;
  }
  sessionStorage.removeItem('urlGameMode');
};

export const detectUrlParams = () => {
  const params = window.location.search;
  isLocal && console.log('[urlParams]', { params });
  const urlParams = new URLSearchParams(params);

  // CHAIN CHOICE
  interceptChainChoice({ urlParams });

  // GAME MODE CHOICE
  interceptGamemodeChoice({ urlParams });
};


export const getSingleChainLogin = () => {
  return sessionStorage.getItem('exclusiveLogin');
};

export const getNavigationGameMode = () => {
  return sessionStorage.getItem('urlGameMode');
};
