/* eslint-disable no-useless-concat */

// # RANK and S2C rule
export const STAKE_TO_CLAIM = 80;
export const KILLS_PER_LEVEL = 1000;

// # CHAMPIONS
export const BONUS_AMOUNT = 15;
export const PERCENT_WINS = 0.95;

// # NEWBIES
export const FREE_RESOURCES_FOR_KILLS = 500;

// # CLAIM LIMITS
const MIN_CLAIMABLE_PER_DAY = 0.5;
const MAX_CLAIMABLE_PER_DAY_L0 = 62;
const MAX_CLAIMABLE_PER_DAY_L1 = 90;
const MAX_CLAIMABLE_PER_DAY_L2 = 200;
const MAX_CLAIMABLE_PER_DAY_L3 = 250;

export const claimLimits = () => ({
  minBoom: MIN_CLAIMABLE_PER_DAY,
  maxBoomL0: MAX_CLAIMABLE_PER_DAY_L0,
  maxBoomL1: MAX_CLAIMABLE_PER_DAY_L1,
  maxMarsL2: MAX_CLAIMABLE_PER_DAY_L2,
  maxMarsL3: MAX_CLAIMABLE_PER_DAY_L3,
});

// # RESOURCES PAYMENT
export const RATIO_FOR_MOSTLY_HIVE_NFTS = 0.8;


// @@ @@ -- update every time a new level is launched..
// PS. NO. Updated only the champion rule to be unlocked only in L2  <<<<<<<<<<<<<<<<<<<<
export const HIGHEST_LEVEL = 1;
export const LOWEST_THRESHOLD = claimLimits().maxBoomL0;
export const HIGHEST_REWARD = claimLimits()[`maxBoomL${HIGHEST_LEVEL}`] + BONUS_AMOUNT;
export const HIGHEST_MARS_CLAIM = claimLimits()['maxMarsL2']; // <<<<

// # YIELD
export const MONTHLY_INTEREST_BOOM = 2.5; // 3.5
export const MONTHLY_INTEREST_MARS = 5.0; // 5.5

// # SCHOLARSHIPS
export const DEFAULT_CUT_SCHOLAR = 60;
export const DEFAULT_CUT_TEACHER = 100 - DEFAULT_CUT_SCHOLAR;

// # LOCALSTORAGE BYPASSES
export const BYPASS_OUTAGE_CODE = 'k4G-jh6' + '98d7.fg9';
export const BYPASS_INVENTORY_CODE = '4j3k-jj' + '2.4.fr1';
