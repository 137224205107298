import { WAX_ACCOUNTS } from "../../../../../../config/accounts";
import { isLocal, isTestnet } from "../../../../../../constants/envs";
import { getMemoChecksum, getPriceHash } from "../../../../../../generalUtils/paymentMemo";
import { UserService } from "../../../../../../services/UserService";
import { fetchTxInfo } from "../loadStorage";
import { getMonthDay } from "./utils";

// Invalid memo or price! 
// { 
//   memo: "TRAVEL-FARE:level-1:ship-3W.3198463836-4085106150"
//   quantity: "6.65000000 WAX"
//   reason: { 1: true, 2: true }
// }
// Invalid memo or price! 
// { 
//   memo: "TRAVEL-FARE:level-2:ship-3W.2517275042-3627327500"
//   quantity: "7.60000000 WAX"
//   reason: { 1: true, 2: true }
// }
const validateResourceTx = ({ from, to, memo, quantityTransfered, transferCurrency }) => {
  isLocal && console.debug('Validating resource payment tx:',
    { from, to, memo, quantityTransfered, transferCurrency },
  );
  // Validate parties
  if (from !== UserService.authName) {
    console.error('Invalid sender!', { actual: from });
    return false;
  }
  if (to !== WAX_ACCOUNTS.FOOD_CANTEEN && !WAX_ACCOUNTS.SPACESHIPS.includes(to)) {
    console.error('Invalid recipient!', { actual: to });
    return false;
  }
  // Validate checksums
  const [body, checksumFull] = memo.split('.');
  const [part1, part2] = checksumFull.split('-');
  const expectedChecksum = getMemoChecksum({ body, payer: from });
  const quantity = `${quantityTransfered.toFixed(transferCurrency === 'WAX' ? 8 : 4)} ${transferCurrency}`;
  const expectedPriceHash = getPriceHash({ quantity, checksum: part1 });
  const amountTooSmall = !isTestnet && quantityTransfered < 10;
  if (amountTooSmall || +part1 !== expectedChecksum || +part2 !== expectedPriceHash) {
    console.error('Invalid memo or price!', {
      memo, quantity, reason: { 1: part1 !== expectedChecksum, 2: part2 !== expectedPriceHash },
    });
    return false;
  }
  // Valid tx
  return true;
};

export const validateResourcePayment = async ({ txHash }) => {
  // retrieve: sender, amount, time, memo
  const { from, to, memo, transferTimestamp, quantityTransfered, transferCurrency } = await fetchTxInfo({ txHash });

  // validate sender, amount matching memo -- invalid
  isLocal && console.log('Validating meal tx data:', { from, to, memo, transferTimestamp, quantityTransfered, transferCurrency });
  const isValid = validateResourceTx({ from, to, memo, transferTimestamp, quantityTransfered, transferCurrency });
  if (!isValid) return ({ invalid: true });

  // check how many days left (or expired)
  const currentDate = getMonthDay({ ts: Date.now() });
  const txDate = getMonthDay({ ts: new Date(transferTimestamp).getTime() });
  const isExpired = currentDate !== txDate;

  // result
  return ({ invalid: !isValid, isExpired, memo, transferTimestamp });
};
