import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { readHiveUserBalances } from '../../generalUtils/hiveData/reader';
import { isLocal } from '../../constants/envs';
import { hiveWalletFieldsValidation } from './utils';
import { updateDoomBalance } from '../../sagas/helpers/wallet/hiveUtils';
import { chainId } from '../../constants/genericConstants';
import Swal from 'sweetalert2';


export const HiveBalanceAndSend = () => {
  const InventoryState = useSelector((store) => store.inventory);
  const hiveData = get(InventoryState, 'hive', {});
  const { username: hiveUsername } = hiveData;

  const [hiveBalance, setHiveBalance] = useState('0 HIVE');
  const [hivePowerBalance, setHivePowerBalance] = useState('0 HP');
  const [hbdBalance, setHbdBalance] = useState('0 HBD');

  const [hiveTransferResult, setHiveTransferMsg] = useState({ msg: '', tx: '' });

  useEffect(
    () => {
      if (!hiveUsername) return;
      const getHiveBalances = async () => {
        const { hiveBalance, hbdBalance, hivePower } = await readHiveUserBalances({ username: hiveUsername });
        setHiveBalance(hiveBalance);
        setHbdBalance(hbdBalance);
        setHivePowerBalance(hivePower ? hivePower.toFixed(2) : 0);
      };
      getHiveBalances();
    },
    []
  );
  
  const sendHive = async () => {
    const amountHiveElem = document.getElementById('amount-hive');
    const recipientHiveElem = document.getElementById('recipient-hive');
    const memoHiveElem = document.getElementById('memo-hive');
    const { err, recipient, toSend, memo } = await hiveWalletFieldsValidation({
      amountElem: amountHiveElem, recipientElement: recipientHiveElem,
      memoElement: memoHiveElem, symbol: 'HIVE',
    });
    if (err) {
      console.error('Hive send validation error:', err);
      setHiveTransferMsg({
        msg: `ERROR: transfer failed. ${err}`,
      });
      return;
    }
    const keychain = window.hive_keychain
    isLocal && console.debug('Starting hive keychain handshake');
    keychain.requestHandshake(() => {
        isLocal && console.debug('handshake success');
        keychain.requestTransfer(
          hiveUsername,
          recipient,
          toSend,
          memo || 'HIVE transfer from the Crypto Shots Web UI',
          'HIVE',
          (response) => {
            isLocal && console.debug('Hive transfer resp:', response);
            if (!response.success) {
              setHiveTransferMsg({
                msg: `ERROR: transfer failed. ${response.message || ''}`,
              });
            } else {
              setHiveTransferMsg({
                msg: `${response.message || ''}`,
                tx: get(response, 'result.id'),
              });
            }
          },
          true, // enforce
        );
      },
    );
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    isLocal && console.debug('Input changed', { name, value, target: e.target });
    setHiveTransferMsg({});
  }

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-6 text-white" style={{ marginTop: '-20px' }}>
            <br/>
            HIVE&nbsp;
            <a
              href={`https://peakd.com/@${hiveUsername}/wallet`}
              target="_blank" rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
            FUNDS
            </a><br/>
            <div className="mt-2 text-left pad5" style={{ marginLeft: '40px' }}>
              <p><b>HIVE</b>: &nbsp; {hiveBalance}</p>
              <p>+ <b>HIVE DOLLAR</b> (stablecoin)<br/> &nbsp; &nbsp; {hbdBalance}</p>
              <p>+ <b>STAKED HIVE</b> (Hive Power)<br/> &nbsp; &nbsp; {hivePowerBalance}</p>
            </div>
        </div>
        <div className="col-6 flashy-box" style={{ maxHeight: '180px' }}>
          <div className="btn-send">
            <button className="btn btn-success btn-lg" onClick={sendHive}>SEND HIVE</button>
          </div>
          <input
            id="recipient-hive" placeholder='RECIPIENT' type='text'
            onChange={handleChange}
            style={{ width: '180px', fontSize: '14px' }}
            ></input><br />
          <input
            id="amount-hive" placeholder='AMOUNT' type='number'
            onChange={handleChange}
            style={{ width: '180px', fontSize: '14px' }}
            ></input>
          <input
            id="memo-hive" placeholder='MEMO (optional)' type='text'
            onChange={handleChange}
            style={{ width: '180px', fontSize: '14px' }}
            ></input>
          <p className="pad10 text-white">
            <sub style={{ color: hiveTransferResult.msg?.includes('ERROR') ? '#ffec85' : '#57f557' }}>
            {hiveTransferResult.msg} <br/>
            {hiveTransferResult.tx && <a
              href={`https://hiveblocks.com/tx/${hiveTransferResult.tx}`}
              target="_blank" rel="noreferrer">
              TX
            </a>}
            </sub>
          </p>
          <div className="text-white" style={{ marginTop: '-20px' }}>
            <a
              href="https://peakd.com/@cryptoshots.nft/wallet/exchanges"
              target="_blank" rel="noreferrer"
            >
              BUY HIVE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};


export const DoomBalanceAndSend = () => {
  const WalletState = useSelector((store) => store.wallet);
  const doomBalance = WalletState.hiveTokens.doom;

  const InventoryState = useSelector((store) => store.inventory);
  const hiveData = get(InventoryState, 'hive', {});
  const { username: hiveUsername } = hiveData;

  const [doomTransferResult, setDoomTransferMsg] = useState({ msg: '', tx: '' });

  useEffect(
    () => {
      if (!hiveUsername) return;
      updateDoomBalance({ hiveUsername });
    },
    []
  );

  const sendDoom = async () => {
    if (!window.hive_keychain) {
      Swal.fire({text: 'Hive Keychain extension not found'});
      return;
    }
    const amountDoomElem = document.getElementById('amount-doom');
    const recipientDoomElem = document.getElementById('recipient-doom');
    const memoDoomElem = document.getElementById('memo-doom');
    const { err, recipient, toSend } = await hiveWalletFieldsValidation({
      amountElem: amountDoomElem, recipientElement: recipientDoomElem,
      memoElement: memoDoomElem, symbol: 'DOOM',
    });
    if (err) {
      console.error('Doom send validation error:', err);
      setDoomTransferMsg({
        msg: `ERROR: ${err}`,
      });
      return;
    }
    const payload = {
      contractName: 'tokens',
      contractAction: 'transfer',
      contractPayload: {
        symbol: 'DOOM',
        to: recipient,
        quantity: toSend,
        memo: 'DOOM transfer from Crypto Shots Web UI',
      },
    };
    const customJsonData = {
      required_auths: [hiveUsername],
      required_posting_auths: [],
      id: chainId,
      json: JSON.stringify(payload),
    };
    const keychain = window.hive_keychain
    isLocal && console.debug('Starting hive keychain handshake');
    keychain.requestCustomJson(
      hiveUsername, chainId, "Active", JSON.stringify(customJsonData), "DOOM TRANSFER",
      (response) => {
        isLocal && console.debug('DOOM transfer result:', response);
        if (response.success && response.result) {
            setDoomTransferMsg({
              msg: `${response.message || ''}`,
              tx: get(response, 'result.id'),
            });
        } else {
          setDoomTransferMsg({
            msg: `ERROR: ${response.message}`,
          });
        }
      },
    );
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    isLocal && console.debug('Input changed', { name, value, target: e.target });
    setDoomTransferMsg({});
  }

  return (<>
    <div className="col-6 flashy-box" style={{ margin: 'auto', paddingBottom: '0px', maxHeight: '200px' }}>
      <p className='md-2' style={{ color: 'white', fontSize: '18px' }}>
        $DOOM balance:&nbsp;
        <a
          href={`https://peakd.com/@${hiveUsername}/wallet/hive-engine`}
          target="_blank" rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          {doomBalance}
        </a>
      </p>
      <div className="text-white" style={{ fontSize: '14px', marginTop: '-10px' }}>
        <a
          href="https://hive-engine.com/trade/DOOM"
          target="_blank" rel="noreferrer"
        >
          BUY DOOM
        </a>
      </div>

      <div className="mt-2 btn-send">
        <button
          className="btn btn-success btn-lg"
          onClick={sendDoom}
        >SEND DOOM</button>
      </div>
      <input
        id="recipient-doom" placeholder='RECIPIENT' type='text'
        onChange={handleChange}
        style={{ width: '180px', fontSize: '14px', color: 'black' }}
        ></input><br />
      <input
        id="amount-doom" placeholder='AMOUNT' type='number'
        onChange={handleChange}
        style={{ width: '180px', fontSize: '14px', color: 'black' }}
        ></input>
      <p className="text-white">
        <sub style={{ color: doomTransferResult.msg?.includes('ERROR') ? '#ffec85' : '#57f557' }}>
          {doomTransferResult.msg} <br/>
          {doomTransferResult.tx && <a
            href={`https://he.dtools.dev/tx/${doomTransferResult.tx}`}
            target="_blank" rel="noreferrer">
            TX
          </a>}
        </sub>
      </p>
    </div>
  </>);
};
