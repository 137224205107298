import React from 'react';
import WaxLogo from '../../../../images/logos/WaxLogo.png';
import HiveLogo from '../../../../images/logos/HiveLogo.png';
import EthLogo from '../../../../images/logos/EthLogo.png';
import BtcLogo from '../../../../images/logos/BtcLogo.png';
import SklLogo from '../../../../images/logos/SklLogo.png';
import { ChainProps } from './ChainProps';


const ICONS = {
  WIDTH: {
    BTC: '65',
    WAX: '120',
    HIVE: '',
    ETH: '70',
    ETH_CHOICE: '70',
    SKL: '',
  },
  GAP_TOP: {
    BTC: '0',
    HIVE: '-10',
    WAX: '-20',
    ETH: '10',
    ETH_CHOICE: '10',
    SKL: '-10',
  },
};

export const ChainPromo = ({ chainName = '' }) => {
  const chain = chainName.toUpperCase().replace(/-/g, '_');
  return (<>
    <div id="right-icon"
      style={{
        float: 'left', margin: `${ICONS.GAP_TOP[chain] || '10'}px -50px auto 70px`,
        paddingTop: chainName === 'wax' ? '20px' : ''
      }}
    >
      <ChainIcon chain={chainName} width={ICONS.WIDTH[chain] || '90'} />
      <ChainProps chain={chainName} />
    </div>
  </>);
};

export const ChainIcon = ({ chain, width }) => {
  switch (chain) {
    case 'wax':
      return <img id="WaxLogo" src={WaxLogo} alt="WaxLogo" width={width || '78'}
        style={{ marginTop: '30px' }}
      />;
    case 'hive':
      return <img id="HiveLogo" src={HiveLogo} alt="HiveLogo" width={width || '48'} />;
    case 'eth-choice':
    case 'eth':
      return <img id="EthLogo" src={EthLogo} alt="EthLogo" width={width || '28'} />;
    case 'btc':
      return <img id="BtcLogo" src={BtcLogo} alt="BtcLogo" width={width || '38'} />;
    case 'skl':
      return <img id="SklLogo" src={SklLogo} alt="SklLogo" width={width || '38'} />;
    default: {
      console.error(`Unexpected chain ${chain}`);
      return null;
    }
  }
};
