import { ITEMS_BY_CATEGORY } from '../constants/templateIds';

export const ACCESS_CONFIG = {
  SNEAK_PEEK: {
    GRANTS_ACCESS: true,
    TEMPLATE_ID: ITEMS_BY_CATEGORY.EARLY_ACCESS.SPA.ID,
    NAME: ITEMS_BY_CATEGORY.EARLY_ACCESS.SPA.NAME,
  },
  TELEPORT_DEVICE: {
    GRANTS_ACCESS: false, // is check enabled
    TEMPLATE_ID: ITEMS_BY_CATEGORY.EARLY_ACCESS.TD.ID,
    NAME: ITEMS_BY_CATEGORY.EARLY_ACCESS.TD.NAME,
  },
};
