import React from 'react';
import { HiveLoginPanel } from '../Hive/HiveLoginPanel';
import { BitcoinLnInstructions } from '../Shared/BitcoinLnInstructions';

export const BitcoinLoginPanel = () => {
  return (
    <div className="row">
      <div className="row col-12">
        <div className="col-6" style={{ margin: '-25px auto 0px 40px' }}>
          <BitcoinLnInstructions />
        </div>
      </div>
      <HiveLoginPanel />
    </div>
  );
};
