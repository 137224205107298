import React, { useState } from 'react';
import { get } from 'lodash';
import { useEffect } from 'react';
import { Animated } from 'react-animated-css';
import { useSelector } from 'react-redux';

import { storeAppDispatch } from '../../../../GlobalState/Store';
import { resetLastMatch, setFirstLevTravel, setPaidResources } from '../../../../reducers/PlayReducer';
import { RESOURCES_PRICES } from './resourcesPrices';
import { LEVEL_ZERO_TRAVEL_DURATION, payForSpaceshipTravel, spaceshipTimer } from '../../subComponents/spaceships/travels';
import { sendFoodPayment } from './paymentUtils';
import { mapSrc } from '../../subComponents/spaceships/ui';
import { getNftCount, nap } from '../../../../generalUtils/utils';
import { isLocal, isTestnet } from '../../../../constants/envs';
import { ITEMS_BY_CATEGORY } from '../../../../constants/templateIds';
import { uiHacks } from '../../../../generalUtils/uiHacks/uiHacks';
import { FREE_RESOURCES_FOR_KILLS, RATIO_FOR_MOSTLY_HIVE_NFTS } from '../../../../config/appConfig';
import { hasMostlyHiveNfts } from '../../subComponents/utils/requiredNfts';
import { countCharsToPayFor } from '../../subComponents/utils/countChars';
import './ResourcesPayment.scss';
import { setNewPaidResource } from '../../../../reducers/MarsStorage';
import { windowSetChecksum } from '../../../../generalUtils/secure/checksums';
import { IS_PVP_ALPHA_FREE_TRAVELS } from '../../../../config/featureFlags';

const { SPACESHIPS, BOARDING_PASSES } = ITEMS_BY_CATEGORY;

const checkResources = ({ type, waxNfts = [] }) => {
  let shouldPay = true && !(isLocal && window.skipPayment);
  let discount;
  if (type === 'food') {
    const has50foodDiscount = getNftCount({ nfts: waxNfts, id: BOARDING_PASSES.FD5.ID });
    if (has50foodDiscount) {
      discount = 0.5; // 50 % discount
    }
    const has90foodDiscount = getNftCount({ nfts: waxNfts, id: BOARDING_PASSES.FD9.ID });
    if (has90foodDiscount) {
      discount = 0.1; // 90 % discount
    }
  }
  return ({ shouldPay, discount });
}


const defaultStatsNoCheat = { levelX: { totalKills: FREE_RESOURCES_FOR_KILLS + 1 } };

const getKillsSum = ({ gameStats = defaultStatsNoCheat }) => {
  try {
    const totPlayerKills = Object.values(gameStats).map(el => el.totalKills)
      .filter(count => count > 0)
      .reduce((partialSum, num) => partialSum + num, 0);
    return totPlayerKills;
  } catch (err) {
    console.error('Caught in getKillsSum:', err);
    return FREE_RESOURCES_FOR_KILLS + 1;
  }
};


const checkTravelRequirements = ({
  waxNfts = [], hiveNfts = [], gameStats /*no-default*/, canSkipPaymentsPvpAlpha,
}) => {
  const kills = getKillsSum({ gameStats });
  const isNewbie = kills < FREE_RESOURCES_FOR_KILLS;

  const mostlyHiveNfts = hasMostlyHiveNfts({ hiveNfts, waxNfts });

  const isGasSpaceshipOwner = getNftCount({ nfts: waxNfts, id: SPACESHIPS.COR.ID });
  const hasGasPass = getNftCount({ nfts: waxNfts, id: BOARDING_PASSES.COR.ID });
  const canSkipGas = isNewbie || isGasSpaceshipOwner || hasGasPass || mostlyHiveNfts || canSkipPaymentsPvpAlpha;
  
  const isFoodMachineOwner = false; // @@ ADD FOOD MACHINE OWNER
  const canSkipFood = isNewbie || isFoodMachineOwner || mostlyHiveNfts || canSkipPaymentsPvpAlpha;

  const has50foodDiscount = getNftCount({ nfts: waxNfts, id: BOARDING_PASSES.FD5.ID });
  const has90foodDiscount = getNftCount({ nfts: waxNfts, id: BOARDING_PASSES.FD9.ID });
  const hasFoodDiscount = has50foodDiscount || has90foodDiscount;

  isLocal && console.debug('Can skip resources paym?', {
    canSkipGas: { value: canSkipGas, isNewbie, isGasSpaceshipOwner, hasGasPass, mostlyHiveNfts },
    canSkipFood: { value: canSkipFood, isNewbie, isFoodMachineOwner, mostlyHiveNfts },
    newbie: { kills, gameStats },
  });
  return ({ canSkipGas, canSkipFood, hasFoodDiscount, mostlyHiveNfts });
};

export const ResourcesPayment = ({ showResourcesPage, setShouldAutoPlay }) => {
  const PlayState = useSelector((store) => store.play);
  const WalletState = useSelector((store) => store.wallet);
  const foodBalance = get(WalletState, 'tokens.food');
  const gasBalance = get(WalletState, 'tokens.gas');

  const StorageState = useSelector((store) => store.marsStorage);
  const { resourcesPaid = [], storageType } = StorageState;
  const subAlreadyPaidFood = !!resourcesPaid.find(
    paidObj => paidObj.type === 'meal',
  );
  const subAlreadyPaidGas = !!resourcesPaid.find(
    paidObj => paidObj.type === 'travel' && paidObj.toLevel === 0,
  );

  const InventoryState = useSelector((store) => store.inventory);
  const waxNfts = get(InventoryState, 'csAssets.csNfts', []);
  const hiveNfts = get(InventoryState, 'hive.nfts', []);
  const isScholar = get(InventoryState, 'scholarship.isScholar', false);
  const gameStats = get(PlayState, 'wrapperData.apiResponse.gameStats');

  const isPvp = get(PlayState, 'isPvpGameMode');
  const canSkipPaymentsPvpAlpha = IS_PVP_ALPHA_FREE_TRAVELS && isPvp;

  const charactersToPay = countCharsToPayFor({ isScholar, waxNfts, hiveNfts });

  const inTravelToZero = PlayState.isTravellingToZero;
  const activeLevel = PlayState.activeLevel;

  const { canSkipGas, canSkipFood, hasFoodDiscount, mostlyHiveNfts } = checkTravelRequirements({
    waxNfts, hiveNfts, gameStats, canSkipPaymentsPvpAlpha,
  });
  const showMustPayWarning = waxNfts.length > 0 && hiveNfts.length > 0 && !mostlyHiveNfts;

  const [paidFood, setPaidFood] = useState(false);
  const [paidFuel, setPaidFuel] = useState(false);

  const closeAfterPayment = () => {
    isTestnet && console.log('============== Closing overlay after payment or trip');
    try {
      storeAppDispatch(setPaidResources({ paidResources: true }));
      uiHacks.disableClaimAndPlayBtnsAtFirst({ claimOnly: true });
      storeAppDispatch(resetLastMatch());
    } catch (err) { console.error('caught-res-paym:', err); }
    showResourcesPage(false);
  };

  const showShortTravelPage = async () => {
    storeAppDispatch(setFirstLevTravel({ isTravellingToZero: true }));
    await spaceshipTimer({
      travelDurationMs: isTestnet ? (10 * 1000) : LEVEL_ZERO_TRAVEL_DURATION,
      unityLoad: false,
    });
    storeAppDispatch(setFirstLevTravel({ isTravellingToZero: false }));
    setShouldAutoPlay(true);
  };

  const skipPayments = async () => {
    // adjust rewards
    window.skippedResourcesPaym = true;
    windowSetChecksum({ key: 'skippedResourcesPaym', value: true });
    const originaValue = window.skippedResourcesPaym; // freeze:
    try {
      Object.defineProperty(window, 'skippedResourcesPaym', { value: originaValue, writable: false });
    } catch (_) {}
    await showShortTravelPage();    
    closeAfterPayment();
  };

  useEffect( () => {
    const dismissIfResourcesPaid = async () => {
      if (paidFuel && paidFood) {
        isLocal && console.debug('Both food and gas paid (or both skipped). Dismissing after delay.');
        await showShortTravelPage();    
        closeAfterPayment();
      }
    };
    dismissIfResourcesPaid();
  }, [paidFuel, paidFood]);

  const payFood = async () => {
    const { shouldPay, discount } = checkResources({ type: 'food', waxNfts });
    if (!canSkipFood && !subAlreadyPaidFood && shouldPay) {
      const characters = discount ? (charactersToPay * discount) : charactersToPay;
      const { err } = await sendFoodPayment({ charactersToPay: characters, activeLevel, storageType });
      if (err) return;
      storeAppDispatch(setNewPaidResource({ paidResource: { type: 'meal' } }));
    }
    setPaidFood(true);
  };

  const payGas = async () => {
    const { shouldPay } = checkResources({ type: 'gas' });
    if (!canSkipGas && !subAlreadyPaidGas && shouldPay) {
      const { err } = await payForSpaceshipTravel({
        spaceshipId: 0, currency: 'G', charactersCount: charactersToPay, activeLevel
      });
      if (err) return;
    }
    setPaidFuel(true);
  };

  return (
    <div className="container resources-payment"><br />
      <div className="row inner-container" id="resources">
        {inTravelToZero
          ? <div>
              <Animated animationIn="slideInDown" animationOut="slideOutLeft" animationInDuration={3000} animationInDelay={500} animationOutDuration={3000} isVisible={true}>
                <img id="loading-map-0" src={mapSrc(isPvp ? 2 : 0)} alt="map" /><br/><br/>
              </Animated>
              <p id="time-to-destination">Time to your destination: <span id="countdown">00:00:00</span></p>
          </div>
          : <div>
              <button
                onClick={() => showResourcesPage(false)}
                style={{
                  color: 'white', padding: '3px', backgroundColor: 'rgba(100,100,100,0.8)',
                  zIndex: 2000, float: 'left',
                  marginLeft: '-30px', marginTop: '5px',
                  width: '40px', height: '47px', outline: 'none', border: 'none',
                }}
              >X</button>
              <div class="row" style={{ textAlign: 'center' }}>
                <div className="col-12" style={{
                  color: 'white', padding: '10px', textAlign: 'center', marginTop: '5px',
                  backgroundColor: 'rgba(122,32,192,0.7)', fontSize: '18px', fontWeight: 'bolder',
                }}>
                  CREW PREP and TRAVEL TO THE 1st LEVEL
                </div>
                <div className="col-5" id="food-column" style={{
                  margin: 'auto', paddingLeft: '40px', textAlign: 'center',
                }}>
                    <div style={{ height: '400px' }}>
                      <div style={{
                          height: '270px', backgroundColor: 'black', padding: '0px',
                          marginTop: '10px', width: '220px',
                          margin: 'auto', textAlign: 'center',
                        }}>
                        <img
                          src="https://files.peakd.com/file/peakd-hive/cryptoshots.play/AJiuKcRf9FxuhdV6rTCerawu77dZ9fajqT9J3QW5BuX8ABvp3auLvqLq7hJrPPQ.gif"
                          style={{ margin: '20px auto 60px', width: '220px' }} alt="FOOD coin"
                        ></img>
                        <div class="row" style={{
                          minWidth: '240px', lineHeight: 'normal', color: 'white',
                          fontSize: '18px', marginTop: '-20px',
                        }}>
                          <p className="aqua spaceship-name">CREW <a
                            href="https://wax.atomichub.io/explorer/asset/1099827089623"
                            target="_blank" rel="noreferrer"
                            className="aqua"
                          >
                            MEAL
                          </a></p>
                        </div>
                        <p className="wait-time text-white">
                          Meal cost per character: {RESOURCES_PRICES.FOOD}
                        </p>
                      </div>
                    </div>
                  <p className="flashy-box" id="characters-feed" style={{
                      maxWidth: '400px', margin: '-38px auto auto', color: '#81b4e5'
                    }}>
                    Characters to feed: {charactersToPay} &nbsp;
                    {isScholar && <sub style={{ fontWeight: '500' }} className="text-white">(double for scholars)</sub>}
                    <br />Payment due: <u>{charactersToPay * RESOURCES_PRICES.FOOD} $FOOD</u>{
                      hasFoodDiscount ? <sub className="text-white"> &nbsp; *minus <u>DISCOUNT</u>!</sub> : ''
                    }
                  </p>
                  {paidFood
                    ? <p style={{ fontSize: '16px', color: '#dbdb10', fontWeight: 'bold' }}>Yummy! Ready to pew pew!</p>
                    : <button
                    style={{
                      width: '135px', height: '35px', outline: 'none', border: 'none', marginLeft: '10px',
                      fontSize: '12px', fontWeight: 'bold',
                    }}
                    onClick={payFood}
                    >
                      <span>
                        {(!canSkipFood && !subAlreadyPaidFood)
                          ? <span>FEED TEAM</span>
                          : <span>FREE FOR YOU!</span>
                        }</span>
                    </button>
                  }
                  {foodBalance !== undefined && <p className="text-white"><br/>Your balance: <b>{foodBalance}</b> $FOOD</p>}
                  {(!canSkipFood && !subAlreadyPaidFood) && <p className="buy-alcor">
                    <a href="https://wax.alcor.exchange/trade/food-csboomcsboom_boom-csboomcsboom" target="_blank" rel="noreferrer">BUY FOOD on Alcor</a>
                    <br />with $BOOM
                  </p>}
                </div>
                <div
                  className="col-2"
                  style={{ color: 'black', fontSize: '45px' }}
                >
                  <p style={{ marginTop: '100px' }}>+</p>
                </div>
                <div className="col-5" id="spaceship-column">
                  <div>
                    <img
                      src="https://files.peakd.com/file/peakd-hive/cryptoshots.play/EoEhkdqskmV8SZkBJhmKF6VXJUn6vWChjZMpxoJ9sQRcbFTa7uDEJ8CnMystq18Hdtc.gif"
                      style={{ marginTop: '10px', width: '200px' }} alt="Free Spaceship L0"
                    ></img>
                    <div class="row" style={{
                       minWidth: '240px', lineHeight: 'normal', color: 'white', marginTop: '10px', fontSize: '18px',
                      }}>
                      <p className="aqua spaceship-name">Cordoba,&nbsp;
                        <a
                          href="https://wax.atomichub.io/market?collection_name=crypto4shots&order=desc&schema_name=csspaceships&sort=created&symbol=WAX"
                          target="_blank" rel="noreferrer"
                          className="aqua"
                        >
                          Level-0 Spaceship
                        </a>
                      </p>
                    </div>
                    <p className="travel-type text-white" stye={{ fontSize: '13px !important' }}>
                      Travel cost per character: {RESOURCES_PRICES.GAS}
                    </p>
                  </div>
                  <p className="flashy-box" style={{
                    color: '#81b4e5', fontSize: '14px', fontWeight: 'bold',
                    marginTop: '-45px', maxWidth: '400px', margin: 'auto',
                  }}>
                    Characters to carry: {charactersToPay} &nbsp;
                    {isScholar && <sub style={{ fontWeight: '500' }} className="text-white">(double for scholars)</sub>}
                    <br />Payment due: <u>{charactersToPay * RESOURCES_PRICES.GAS} $GAS</u>
                  </p>
                  {paidFuel
                    ? <p style={{ fontSize: '16px', color: '#dbdb10', fontWeight: 'bold' }}>To the moon!!</p>
                    : <button
                    style={{
                      width: '135px', height: '35px', outline: 'none', border: 'none', marginLeft: '10px',
                      fontSize: '12px', fontWeight: 'bold',
                        }}
                        onClick={payGas}
                        >
                        <span>{(!canSkipGas && !subAlreadyPaidGas)
                          ? <span>FILL TANK</span>
                          : <span>FREE FOR YOU!</span>
                        }</span>
                      </button>
                  }
                  {gasBalance !== undefined && <p className="text-white buy-alcor"><br/>Your balance: <b>{gasBalance}</b> $GAS</p>}
                  {(!canSkipGas && !subAlreadyPaidGas) && <p className="buy-alcor">
                    <a href="https://wax.alcor.exchange/trade/gas-csboomcsboom_boom-csboomcsboom" target="_blank" rel="noreferrer">BUY GAS on Alcor</a>
                    <br />with $BOOM
                  </p>}
                </div>
                <div className="col-1"></div>
                {showMustPayWarning && <div className="col-12">
                    <p style={{ backgroundColor: 'black', color: 'yellow', fontSize: '13px' }}>
                      You need at least {
                      RATIO_FOR_MOSTLY_HIVE_NFTS * 100
                      }% Hive NFTs to skip spaceship fees - <i>offer valid until spaceships are live on Hive too</i>
                    </p>
                  </div>}
                <div id="bottom-pane" className="col-12" style={{ backgroundColor: 'rgba(122,32,192,0.65)', color: 'white' }}>
                  {(paidFuel && paidFood)
                    ? <h3 id="lightspeed-travel" style={{ display: 'none' }}>(( HYPERDRIVE IN PROGRESS... ))</h3>
                    : <>
                      {!(canSkipFood && canSkipGas) && <div className="row container">
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <div className="col-3"></div>
                        <div className="col-4">
                          <span style={{ fontSize: '22px' }}>OR.. &nbsp;</span>
                          <button
                            style={{
                              width: '235px', height: '65px', outline: 'none', border: 'none',
                              margin: '5px', color: 'black', fontSize: '16px',
                            }}
                            onClick={skipPayments}
                          >
                            SKIP PAYMENTS and earn<br/>only 10% <sub>ig</sub>BOOM
                          </button>
                        </div>
                        <div className="col-2 mt-2">
                          <p style={{
                            color: 'white', padding: '5px',
                            fontSize: '13px', marginLeft: '-120px',
                          }}>
                            TRAVEL TIME: <b>no wait</b><br/>
                            <b>FREE TRAVEL TO ALL LEVELS</b><br/>
                            MAX REWARD: 62 igBOOM
                          </p>
                        </div>
                        <div className="col-2" style={{ fontSize: '14px' }}>
                        </div>
                      </div>}
                    </>
                  }
                </div>
              </div>
            </div>
          }
      </div>
    </div>
  );
};
