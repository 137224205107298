import { setBridgeVanillaToRedux } from '../../GlobalState/extReduxBridge';
import { isSmartphoneOrTablet } from '../../pages/Play/joysticks/messages';
import { initMobileControllers } from '../../pages/Play/joysticks/joysticks';
import { toast } from 'react-toastify';
import { isLocal, isMainnet, isTestnet } from '../../constants/envs';
import { initPaymentsBridge } from '../../services/microPayments/buyResources';


// ---- BROWSER ----

export const browserCustomize = () => {
  // ## REDUX BRIDGE between the 2 JS layers
  isLocal && console.debug('Setting up Redux bridge..');
  setBridgeVanillaToRedux();

  // ## MOBILE CONTROLLERS
  isLocal && console.debug('Setting up Mobile controllers..');
  // NOTE: PLAYTEST ONLY for now, and only on mobile
  isTestnet && isSmartphoneOrTablet && initMobileControllers();
  
  // ## LOGGING
  isLocal && console.debug('Setting up Logging..');
  if (isMainnet) {
    console.log = console.debug = () => { };
  } else {
    // const originalConsoleLog = console.log;
    // console.log = (...args) => {
    //   const url = args[0];
    //   if (!url || url.includes('PHANTOM DEBUG')) return;
    //   originalConsoleLog(...args);
    // };
  }

  // ## Toast config
  toast.configure({
    autoClose: 2500,
  });

  // ## BRIDGE MICRO PAYMENTS
  initPaymentsBridge();
};
