import React from 'react';
import { useSelector } from 'react-redux';
import { AccountLinking } from '../../Inventory/chains/AccountLinking';
import get from 'lodash/get';
import { storeAppDispatch } from '../../../GlobalState/Store';
import { linkedBtcAddress as linkedBtcAddressData } from '../../../reducers/InventoryReducer';
import { useState } from 'react';
import { getPlayerUsername } from '../../../generalUtils/utils';
import { unlinkAccount } from '../../../sagas/helpers/login/linking';


export const BtcLinkPane = () => {
  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;

  const InventoryState = useSelector((store) => store.inventory);
  const linkedBtcAddress = get(InventoryState, 'hive.linkedBtcAddress');
  const hiveUsername = get(InventoryState, 'hive.username');

  const [unlinkWip, setUnlinkWip] = useState(false);
  
  
  const unlinkLnAddress = async () => {
    setUnlinkWip(true);
    try {
      const currentAuthUser = getPlayerUsername({ UserState, InventoryState });
      const ok = window.confirm(`Are you sure?\n\nUnlink your BTC address ${linkedBtcAddress
        }\nfrom your ${chainLogin.toUpperCase()} account ${
          chainLogin === 'hive' ? '@' : ''}${currentAuthUser}?`);
      if (!ok) return;

      const { result, err } = await unlinkAccount({
        currentAuthUser, inputAccount: linkedBtcAddress, tab: 'btc',
      });
      // Unlink Failure
      if (err) alert(`Failed to unlink BTC address ${linkedBtcAddress}: ${err}`);
      // Unlink Success
      else {
        console.log('BTC address unlink success - api result:', result);
        storeAppDispatch(linkedBtcAddressData({ lnAddress: null }));
      }
    } catch (err) {
      console.error('Something broke unlinking chain:', err);
      alert('Uh oh, something broke..');
    } finally {
      setUnlinkWip(false);
    }
  };

  if (chainLogin !== 'hive') {
    return (
      <div style={{
          backgroundColor: 'black', color: 'white', width: '35%',
          margin: 'auto', padding: '30px', minHeight: '270px', textAlign: 'center',
        }}
      >
        <p>PLEASE LOG IN WITH A <a
            href="https://hiveonboard.com?ref=cryptoshots.nft" target="_blank" rel="noreferrer"
            style={{ color: 'white', lineHeight: '18px', textDecoration: 'none' }}
          >
            <span className="bold hive">HIVE ACCOUNT</span>
          </a><br/>IF YOU WANT TO LINK A <span className="bitcoin">BITCOIN ADDRESS</span>
          <br/>and earn BTC during our Weekly Tournaments.</p>
        <br/><p>You can link this Wax account to your Hive account too.</p>

        <div style={{ height: '20px', margin: 'auto' }}>
          <br/>
          <a
            href="https://hiveonboard.com?ref=cryptoshots.nft" target="_blank" rel="noreferrer"
            style={{ color: 'white', lineHeight: '18px', textDecoration: 'none' }}
          >
            <span className="bold hive">Create FREE Hive Account</span>
          </a>
        </div>
      </div>
    );
  }

  if (!linkedBtcAddress) {
    return (
    <div className="mt-3">
      <div style={{ width: '380px', height: '330px', backgroundColor: 'black', margin: 'auto' }}>
        <AccountLinking tab="btc"/>
      </div>
    </div>);
  }

  return (
    <div
      className="row mt-3"
      style={{
        backgroundColor: 'black', width: '650px', margin: '0px auto', color: 'white',
        padding: '30px', textAlign: 'center',
      }}
    >
      <div id="unlink-btc-btn-wrapper"
        className="row"
        style={{ marginTop: '20px', margin: 'auto' }}
      >
        <div className="col-12">
          <p style={{ fontSize: '28px' }}>YOUR LIGHTNING NETWORK ADDRESS:</p>
        </div>
        <div className="col-12">
          <div className="row">
          <div className="col-8 bitcoin" style={{ fontSize: '18px', marginTop: '20px' }}>{linkedBtcAddress}</div>
          <div className="col-4" style={{ float: 'right' }}>
            <div className="row">
              <div className="col-12 mt-2">
                <button
                  style={{
                    backgroundColor: 'white', color: 'red', minHeight: '25px',
                  }}
                  onClick={unlinkLnAddress}
                >
                  {unlinkWip
                    ? <i class="fa fa-spinner fa-spin" style={{ fontSize: '20px', minHeight: '35px', color: 'red' }}></i>
                    : <span>UNLINK</span>}
                </button>
              </div>
              <div className="col-12 mt-3">
                <a
                  href="https://github.com/theDavidCoen/LightningExchanges#lets-keep-track-of-the-exchanges-that-support-lightning-network"
                  target="_blank" rel="noreferrer"
                  style={{ fontSize: '14px' }}
                >
                  Convert your BTC to FIAT
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '50px', padding: '0px 20px' }}>
        <p>At the end of each <span className="bold">PVP TOURNAMENT</span>, if your total reward is at least
        <span className="bold hive"> 2 Hive</span>, you will automatically receive its $ worth
        <i> (minus the LN channel fee) at your linked Bitcoin address</i>.</p>
        <div style={{ padding: '10px', marginTop: '20px' }}>
          <p>Tournament earnings below 2 Hive are<br/>automatically sent in Hive to your account:<br/>
            <a
              href={`https://peakd.com/@${hiveUsername}/wallet`} target="_blank" rel="noreferrer"
            >
              <span className="bold hive" style={{ textDecoration: 'none' }}>@{hiveUsername}</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
