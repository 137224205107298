import React from 'react';
import { timeToUTCMidnight } from '../utils/time';
import { useSelector } from 'react-redux';
import { extractPlayedTime } from './prepLastMatchInfo';


export const PlayTime = () => {
  const PlayState = useSelector((store) => store.play);
  const { sessionData = {} } = PlayState.wrapperData;
  const minsPlayed = extractPlayedTime({ sessionData });
  const timeReset = timeToUTCMidnight();

  // if (!sessionData.matchCount) return null; // @@ @@
  return (
    <div className="col-12 mt-4 flashy-box flashy-lightblue" id="results-wrapper">
      <span className="light-blue entry-title" id="time-used">Played since midnight UTC:&nbsp;</span>
      <span className="light-blue"><span id="tot-time-midnight">{!!minsPlayed
        ? <span>{minsPlayed} minutes</span>
        : <sub className="na">n/a</sub>}
      </span><span id="max-time-char"></span>
      </span>
      {/* remove completely from DOM too @@ */}
      <p
        className="light-blue"
        style={{ display: 'none' }}
      >
        - Weapons: &nbsp; <span id="weapons-time-midnight"><sub>n/a</sub></span><span id="max-time-weap"></span>
      </p>
      <p className="light-blue" id="time-reset" style={{ marginBottom: '5px' }}>
        <span>Time resets in: {timeReset}</span>
      </p>
    </div>
  );
};
