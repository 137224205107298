
// ## FOR TESTS and maybe also PLAYER PREFERENCES
// localStorage.setItem('joyOverrides', JSON.stringify({
//   joystickCheckRate: 400,
// });
const joyOverrides = () => {
  const joyOverridesFromLs = localStorage.getItem('joyOverrides') || '{}';
  let resultJson = {};
  try {
    resultJson = JSON.parse(joyOverridesFromLs);
  } catch (err) {
    console.error('Unable to parse joy overrides:', err);
  }
  return resultJson;
};

export const JOY_CONFIG = {
  internalFillColor: 'rgba(100,100,20,0.3)',
  internalStrokeColor: 'blue',
  externalStrokeColor: 'blue',
  externalLineWidth: 5,
};
export const JOYSTICK_CHECK_RATE = joyOverrides().joystickCheckRate || 300;
