import { get } from 'lodash';
import { isLocal } from '../../../../constants/envs';
import { flyingSessionLogin, WaxFlyingSession } from '../../../../services/UserService';
import { TRIAL_PAYMENTS_ACCOUNT_HIVE, WAX_ACCOUNTS } from '../../../../config/accounts';
import { cheapTrials } from '../../../../config/appRemoteStatus';
import Swal from 'sweetalert2';


const isFreeTrialWhitelistedUser = ({ username }) => cheapTrials.whitelist?.includes(username);

// const { err } = window.payForNextLevel({ username, amount, currency });
export const payForNextLevel = async ({ username, amount, currency }) => {
  let finalAmount = isFreeTrialWhitelistedUser({ username }) ? 0.001 : amount;
  if (currency === 'HIVE') {
    const { err } = await hiveTrialPayment({ username, amount: finalAmount });
    return ({ err });
  } else if (currency === 'WAX') {
    const { err } = await waxTrialPayment({ username, amount: finalAmount });
    return ({ err });
  }
  return ({ err: `Unexpected input: ${amount} ${currency}` });
};


// ## HIVE

const hiveTrialPayment = ({ username, amount }) => new Promise((resolve) => {
  isLocal && console.debug('Starting hive keychain handshake..');
  try {
    window.hive_keychain.requestHandshake(() => {
      isLocal && console.debug('handshake success');
      window.hive_keychain.requestTransfer(
        username,
        TRIAL_PAYMENTS_ACCOUNT_HIVE,
        (+amount).toFixed(3),
        'Trial payment in Free to Play mode',
        'HIVE',
        (response) => {
          isLocal && console.debug('Hive transfer resp:', response);
          if (!response.success) {
            resolve({
              err: `ERROR: transfer failed. ${response.message || ''}`,
            });
            return;
          }
          console.log('Payment BROADCASTED.', {
            msg: `${response.message || ''}`,
            tx: get(response, 'result.id'),
          });
          resolve({});
        },
        true
      );
    }
    );
  } catch (err) { console.error('Caught during hive payment:', err); resolve({ err }); }
});


// ## WAX

const waxTrialPayment = async ({ username: waxSender, amount: waxAmount }) => {
  isLocal && console.debug('Creating side session for wax chain');
  const waxSession = WaxFlyingSession;
  if (isLocal) { window.flyingSession = waxSession; } // DEBUG @@
  const isLoggedIn = await flyingSessionLogin(waxSession);
  if (!isLoggedIn || waxSession.authName !== waxSender) {
    Swal.fire({text: `To unlock the new level you have to sign-in with ${waxSender}`})
  }
  isLocal && console.debug('Initiating Wax transfer:', { waxSender, waxAmount });
  try {
    return waxSession.session.signTransaction(
      {
        actions: [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: waxSender,
            permission: 'active'
          }],
          data: {
            from: waxSender,
            to: WAX_ACCOUNTS.TRIAL_PAYMENTS,
            quantity: `${waxAmount.toFixed(8)} WAX`,
            memo: 'Trial payment in Free to Play mode',
          },
        }],
      },
      {
        blocksBehind: 3,
        expireSeconds: 30
      }
    )
      .then((response = {}) => {
        isLocal && console.log('Wax send response status:', { waxSender, waxAmount, response });
        if (response.status !== 'executed') {
          console.error('Transfer not executed:', { data: JSON.stringify(response) });
          return ({ err: JSON.stringify(response) });
        }
        return ({});
      })
      .catch((err) => {
        console.error('Caught error during Wax transfer:', err);
        return ({ err });
      });
  } catch (err) {
    console.error('Caught error during trial payment', err);
    return ({ err });
  }
};
