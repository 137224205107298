import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Animated } from "react-animated-css";

import RefreshIcon from '../../images/icons/refresh.png';
import { UserService } from '../../services/UserService';
import { processInventory } from '../../sagas/helpers/inventory/processInventory';
import { setPlayerInventory } from '../../reducers/InventoryReducer';
import { storeAppDispatch } from '../../GlobalState/Store';
import { WelcomeScreenNewUser } from './welcome/WelcomeScreenNewUser';
import { ScholarshipPane } from './Scholarships/ScholarshipPane';
import { isReactLayer, isTestnet } from '../../constants/envs';
import { HiveInventory } from './chains/HiveInventory';
import { WaxInventory } from './chains/WaxInventory';
import { BtcInventory } from './chains/BtcInventory';
import { getPlayerUsername } from '../../generalUtils/utils';
import { askPvePlayerJoinTourney } from '../../services/notifications/joinTourney';
import './Inventory.scss';


const ChainChoice = ({ choseChain, activeBtn = 1 }) => {
  const [whichActiveBtn, setActiveBtn] = useState(activeBtn);
  const InventoryState = useSelector((store) => store.inventory);
  const waxNftsCount = get(InventoryState, 'csAssets.csNfts.length', 0);
  const hiveNftsCount = get(InventoryState, 'hive.nfts.length', 0);

  const ethBtnStyle = {
    width: whichActiveBtn === 4 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 4 ? 'bold' : 'auto',
  };
  const btcBtnStyle = {
    width: whichActiveBtn === 3 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 3 ? 'bold' : 'auto',
  };
  const hiveBtnStyle = {
    width: whichActiveBtn === 2 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 2 ? 'bold' : 'auto',
  };
  const waxBtnStyle = {
    width: whichActiveBtn === 1 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 1 ? 'bold' : 'auto',
  };

  const handleEthClick = () => {
    choseChain(4);
    setActiveBtn(4);
  }
  const handleBtcClick = () => {
    choseChain(3);
    setActiveBtn(3);
  }
  const handleHiveClick = () => {
    choseChain(2);
    setActiveBtn(2);
  }
  const handleWaxClick = () => {
    choseChain(1);
    setActiveBtn(1);
  }

  return (
    <div
      id="chain-choice"
      style={{ left: isReactLayer ? '20px' : '-150px' }}
    >
      <button
        id="btc-chain-btn"
        className="btn btn-lg" onClick={handleBtcClick}
        style={btcBtnStyle}
        disabled={true}
      >
        BTC <sub>({0})</sub>
      </button><br />
      <button
        id="hive-chain-btn"
        className="btn btn-lg" onClick={handleHiveClick}
        style={hiveBtnStyle}
      >
        HIVE <sub>({hiveNftsCount})</sub>
      </button><br />
      <button
        id="wax-chain-btn"
        className="btn btn-lg" onClick={handleWaxClick}
        style={waxBtnStyle}
      >
        WAX <sub>({waxNftsCount})</sub>
      </button><br />
      <button
        id="wax-chain-btn"
        className="btn btn-lg" onClick={handleEthClick}
        style={ethBtnStyle}
        disabled={true}
      >
        ETH <sub>({0})</sub>
      </button>
    </div>
  );
};

export const Inventory = () => {
  const UserState = useSelector((store) => store.user);
  const InventoryState = useSelector((store) => store.inventory);
  const [shouldShowInventory, showBoomInventory] = useState(false);
  const chainLogin = UserState.chainLogin;
  const isWaxLogin = chainLogin === 'wax';
  const [whichActiveChain, setActiveChain] = useState(isWaxLogin ? 1 : 2);
  
  const canBeFullyUsed = UserState.isLogged && get(InventoryState, 'csAssets.csNfts.length');
  const scholarshipData = get(InventoryState, 'scholarship', {});

  const PlayState = useSelector((store) => store.play);
  const isPvp = PlayState.isPvpGameMode;

  useEffect(() => {
    // ask PVE player to join active F2E pvp tourney
    askPvePlayerJoinTourney({ isPvp });
  }, []);


  let previousRefresh = Date.now();

  const fetchUserCsAssets = async () => {
    if (!canBeFullyUsed) return;
    if ((Date.now() - previousRefresh) < 5 * 1000) return;
    previousRefresh = Date.now();
    const { err, csNfts, hotNfts, scholarship } = await processInventory({ username: UserService.authName });
    if (err) return;
    isTestnet && console.debug(`Fetched ${csNfts.length} NFTs. Dispatching..`);
    const currentState = {
      csAssets: { csNfts, hotNfts }, refresh: true,
      scholarship,
    };
    storeAppDispatch(setPlayerInventory(currentState));

    // TODO:
    // ADD HIVE @@@
    // @@@ and re-display it
  };

  if (!UserState.isLogged) return <h4 className="loading">?!</h4>;
  const hasNfts = get(InventoryState, 'csAssets.csNfts.length', 0) > 0 || get(InventoryState, 'hive.nfts.length', 0);
  const shouldDisplayWelcome = !hasNfts && !shouldShowInventory;

  return (
    <div className="container inventory-container">
      <Animated animationIn="slideInDown" animationOut="slideOutLeft" animationInDuration={500} animationInDelay={50} animationOutDuration={3000} isVisible={true}>
        {!shouldDisplayWelcome && <ChainChoice choseChain={setActiveChain} activeBtn={whichActiveChain} />}
        <div className="row section" id="refresh-section">
          <div className="mt-4">
            {(whichActiveChain === 1 && isWaxLogin) && <button
              id="refresh"
              style={{ display: hasNfts ? 'block' : 'none', opacity: canBeFullyUsed ? 1 : 0.5 }}
              onClick={fetchUserCsAssets}
            >
              <img src={RefreshIcon} alt="Refresh" width="24" />
            </button>}
          </div>
        </div>
        <div
          className="row section inner-container"
          style={{ height: !shouldDisplayWelcome ? '85vh !important' : '80vh' }}
        >
          {scholarshipData && <ScholarshipPane data={scholarshipData}/>}
          <div
            className="col-12 mt-4 text-white some-name-wrapper"
            style={{ height: !shouldDisplayWelcome ? '82vh' : '80vh' }}
          >
            {shouldDisplayWelcome
              ? <WelcomeScreenNewUser
                  hotNfts={get(InventoryState, 'csAssets.hotNfts', {})}
                  showBoomInventory={showBoomInventory}
                  name={getPlayerUsername({ shorten: chainLogin === 'skl' || chainLogin === 'eth' })}
                />
              : <>
                  {whichActiveChain === 1 && <WaxInventory />}
                  {whichActiveChain === 2 && <HiveInventory />}
                  {whichActiveChain === 3 && <BtcInventory />}
                </>
            }
          </div>
        </div>
      </Animated>
    </div>
  );
}
