import stringHash from 'string-hash';
import { isLocal } from '../constants/envs';


export const getMemoChecksum = ({ body, payer }) => stringHash(btoa(`${body}_${payer}`));

export const getPriceHash = ({ quantity, checksum }) => stringHash(btoa(`${quantity}_${checksum}`)); // eg. '1.0000 MARS'


// MEAL:level-0:F
export const mealPaymentMemo = ({ activeLevel, payer, quantity }) => {
  const body = `MEAL:level-${activeLevel}:0F`;
  const checksum = getMemoChecksum({ body, payer });
  const priceHash = getPriceHash({ quantity, checksum });
  isLocal && console.debug(`Generated meal memo:`, { body, checksum });
  return `${body}.${checksum}-${priceHash}`;
};

// TRAVEL-FARE:level-0:ship-0F, TRAVEL-FARE:level-0:ship-0G, TRAVEL-FARE:level-0:ship-1B
export const travelPaymentMemo = ({
    activeLevel, spaceshipId, currency, payer, quantity,
}) => {
  const body = `TRAVEL-FARE:level-${activeLevel}:ship-${spaceshipId}${currency}`;
  const checksum = getMemoChecksum({ body, payer });
  const priceHash = getPriceHash({ quantity, checksum });
  isLocal && console.debug(`Generated travel memo:`, { body, checksum });
  return `${body}.${checksum}-${priceHash}`;
};
