import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    badges: [],
};

// NOT IN USE @@
const gameUiOverlay = createSlice({
    name: 'gameUiOverlay',
    initialState,
    reducers: {
      setBadges: (state, action) => ({ ...state, badges: action.payload.badges }),
    }
});

export const {
  setBadges,
} = gameUiOverlay.actions;

export default gameUiOverlay.reducer;
