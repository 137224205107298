import { isTestnet } from '../../../../../constants/envs';
import { testDataTournament } from './localTournamentData';


const TOURNAMENT_TIMES_ENDPOINT = 'https://play.crypto-shots.com/json/tournament-times.json';
const TOURNAMENT_TIMES_CACHE_DURATION_MS = 2 * 60 * 1000;

let lastCheckTourneyStatusTs;
let cachedResponse;


// API call to check if it's tourney time
export const consumeTourneyConfig = async () => {
    isTestnet && console.log('~~~~~~~~~~~~ Checking if a tourney is active...........');
    // CACHE
    if (lastCheckTourneyStatusTs && cachedResponse !== undefined) {
      const elapsedTime = Date.now() - lastCheckTourneyStatusTs;
      if (elapsedTime < TOURNAMENT_TIMES_CACHE_DURATION_MS) {
        isTestnet && console.debug('Already checked recently, returning previous status.',
          { lastCheckTourneyStatusTs, cachedResponse });
        return ({ isPvpPassRequired: cachedResponse });
      }
    }
    try {
      const data = isTestnet // TEST
        ? await Promise.resolve(testDataTournament)
        : await fetch(TOURNAMENT_TIMES_ENDPOINT).then(res => res.json());
      // ## GENERAL TOURNEY CONFIGS
      // tournament.rewards.extraRewardsTourneyOnly = data.extraRewardsTourneyOnly || true;
      // tournament.sponsoredRewards.avgPlayersSingleSponsoredTourney = data.sponsoredRewards?.avgPlayersSingleSponsoredTourney || 8;
      // tournament.sponsoredRewards.howManySponsoredTourneysPerWeek = data.sponsoredRewards?.howManySponsoredTourneysPerWeek || 2;
  
      // ## TIME BOUNDARIES CHECK
      lastCheckTourneyStatusTs = Date.now(); // activate cache

      const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' }); // eg. 'Saturday'
      const currentTime = new Date().toISOString().substr(11, 8); // eg. '06:00:00'
      for (const tourneyEntry of data.tournaments) {
        if (tourneyEntry.day === currentDay) {
          const startTime = tourneyEntry.start;
          const endTime = tourneyEntry.end;
          // const isTestTournament = tourneyEntry.isTestnet;
          // if (isTestTournament && !isTestnet) continue;
          isTestnet && console.debug('Tournament entry being checked:', { startTime, endTime }, JSON.stringify({ tourneyEntry }));
          if (isTimeBetween(startTime, endTime, currentTime)) {
            // ## FOUND ACTIVE TOURNEY
            isTestnet && console.log(`Tournament active: ${tourneyEntry.day} from ${startTime} to ${endTime
            }. Reward multiplier: ${tourneyEntry.rewardsMultiplier}. Note: ${tourneyEntry.NOTE}`);
            // const { streamer = {}, tournamentRegionOverride, autoRewardsOnTourneyEnd, sponsor } = tourneyEntry;
            // ## SPECIFIC TOURNEY CONFIGS
            // - "STATUS" - mark tournament as within active ranges (Different from active = enabled by streamer)
            // tournamentStatus.isInTourneyTimeRange = true;
            // - "T.PROPS" - (instead "T.CACHE" and "ATTENDANTS" updated by single matches)
            // tournament.props.rewardsMultiplier = tourneyEntry.rewardMultiplier || 1;
            // const { displayName, streamUrl } = streamer;
            // tournament.props.streamer = { name: displayName, url: streamUrl };
            // tournament.props.tournamentRegion = tournamentRegionOverride;
            // tournament.props.autoRewardsOnTourneyEnd = autoRewardsOnTourneyEnd;
            // // { sponsorSymbol, sponsorTokenInitialBalance, tokenPrecision, sendStaked, projectName, ownerDiscordId, sponsorDiscordInvite }
            // tournament.props.sponsor = sponsor;
            const isPvpPassRequired = !tourneyEntry?.skipPvpPassCheck;
            cachedResponse = isPvpPassRequired;                  
            return ({ activeTourney: true, isPvpPassRequired }); // false (not required if the skip flag is true)
          }
        }
      }
      isTestnet && console.log('Tourney is not active at the moment');
      // tournamentStatus.isInTourneyTimeRange = false;
      return ({ isPvpPassRequired: true });
    } catch (error) {
      console.error('Error fetching tournament data:', error);
      // tournamentStatus.isInTourneyTimeRange = false;
      return ({ isPvpPassRequired: true });
    }
  };
  
  
  // UTILS
  
  const WIGGLE_MINUTES_START = 10; // ie. counts match if started max X min early
  const WIGGLE_MINUTES_END = 10; // let them still start a new match X mins after tourney end
  
  // For tournament start time checks it counts matches ended -WIGGLE_MINUTES_START mins before official time.
  // For tournament end, if tourney not ended in bot, adds WIGGLE_MINUTES_END mins more.
  // (but be aware of the TOURNAMENT_TIMES_CACHE_DURATION_MS mins cache for these checks)
  const isTimeBetween = (startTime, endTime, currentTime) => {
    const startDate = new Date(
      new Date('1970-01-01 ' + startTime).getTime() - (WIGGLE_MINUTES_START * 60 * 1000)
    );
    const endDate = new Date(
      new Date('1970-01-01 ' + endTime).getTime() + (WIGGLE_MINUTES_END * 60 * 1000)
    );
    const currentDate = new Date('1970-01-01 ' + currentTime);
    return startDate <= currentDate && currentDate <= endDate;
  };
