import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    storageType: null,
    expirationInDays: null,
    // [{ type: meal}, { type: travel, toLevel, spaceshipId, currency }]
    resourcesPaid: [],
};

const marsStorage = createSlice({
    name: 'marsStorage',
    initialState,
    reducers: {
      setStorage: (state, action) => ({
        ...state,
        storageType: action.payload.storageType,
        expirationInDays: action.payload.expirationInDays,
      }),
      setStorageLoading: (state, action) => ({
        ...state,
        loading: action.payload.loading,
      }),
      setNewPaidResource: (state, action) => ({
        ...state,
        resourcesPaid: [
          ...state.resourcesPaid,
          action.payload.paidResource,
        ],
      }),
    }
});

export const {
  setStorage,
  setStorageLoading,
  setNewPaidResource,
} = marsStorage.actions;

export default marsStorage.reducer;
