import { isTestnet } from "../constants/envs";


// ### WAX

export const WAX_ACCOUNTS = {
  SPACESHIPS: [
    // L0
    'spaceshipzer',
    // L1
    'csspaceship1',
    'csspaceship2',
    'csspaceship3',
    // L2
    'csspaceshipa',
    'csspaceshipb',
    'csspaceshipc',
  ],
  FOOD_CANTEEN: 'foodcanteen1',
  MARKETPLACE: 'csgamemarket',
  TRIAL_PAYMENTS: 'trycshotsfps',
  SUBS_PAYMENTS: 'csmarsburner',
  IG_RESOURCES_PAYMENTS: 'csigresource',
};

export const MODS = [
  'csmoderators',
  'nwv.u.c.wam', // thor,
  'mkske.c.wam', // pierre,
  'spphy.wam', // zayne
  'iavhs.wam', // silverblue
  '2bbzc.wam', // asako
  // testing:
  'crypto5shots',
];

export const ADMINS = [
  // Wax:
  'crypto4shots',
  'crypto5shots',
  // Hive:
  'crypto-shots',
  'cryptoshots.nft',
];

export const COMMUNITY_MANAGERS = [
  // 'nwv.u.c.wam', // Thor
  // 'mkske.c.wam', // pierre,
  'iavhs.wam', // silverblue (doggo)
  'spphy.wam', // zayne
];

export const SWAP_WL = [
  ...ADMINS,
  'xofr2.wam', // andrew
];


// ### HIVE

export const DOOM_SWAPS_RECEIVER = 'cryptoshots.burn';
export const BOOM_SWAPS_RECEIVER = isTestnet ? 'crypshotsnft' : 'cstokensburn';

export const TRIAL_PAYMENTS_ACCOUNT_HIVE = 'cryptoshots.try';
