import React from 'react';
import { isMainnet, isTestnet } from '../../../constants/envs';


const getUsernameLink = (waxAddr) => isMainnet
  ? `https://wax.atomichub.io/profile/${waxAddr}`
  : `https://wax-test.atomichub.io/profile/${waxAddr}`;

export const ScholarshipPane = ({ data }) => {
  const {
    isTeacher = false, scholarName = '', teacherCut = 0,
    isScholar = false, teacherName = '', scholarCut = 0,
    scholarKeepsNfts, scholarPreviousBoomStake,
  } = (data || {});
  isTestnet && console.log('Inventory Scholarship panel - data:', { data });
  if (!isTeacher && !isScholar) return null;
  return (
    <div className="scholarships-pane">
      <p>
        {isTeacher
          && <span><b>Welcome Teacher!</b> &nbsp; <a href={getUsernameLink(scholarName)} target="_blank" rel="noreferrer">{scholarName
              }</a> is your SCHOLAR, they are playing for you and you keep <b>{teacherCut
              }%</b> of the <sub>ig</sub>BOOM rewards{
              scholarKeepsNfts ? <span>. They keep the NFTs</span> : <span>&nbsp;and the NFTs</span>}.
          </span>
        }
      </p>
      <p>
        {isScholar
          && <span><b>Welcome Scholar!</b> &nbsp; <a href={getUsernameLink(teacherName)} target="_blank" rel="noreferrer">{teacherName
            }</a> is your TEACHER, you play with their inventory (below) and keep <b>{scholarCut
            }%</b> of the <sub>ig</sub>BOOM rewards{
            scholarKeepsNfts ? <span>&nbsp;and the NFTs</span> : <span>. They keep the NFTs</span>}.
          </span>
        }
      </p>
    </div>
  );
};
