import React from 'react';
import { useSelector } from 'react-redux';
import { Animated } from "react-animated-css";
import { LoginChoice } from '../../Login/LoginOptions';
import { isLocal } from '../../../../constants/envs';
import { PlayerMode } from '../../Login/Shared/PlayerMode';


const nftScanMsgs = ({ isHiveLogin }) => {
  const hadWaxNfts = localStorage.getItem('waxnfts');
  const hadHiveNfts = localStorage.getItem('hivenfts');
  const steps = [
    <Animated
      animationIn="fadeIn" animationOut="slideOutLeft" animationInDuration={500} animationInDelay={1000}
      animationOutDuration={3000} isVisible={true}
    >
      <p><br />- Finding your NFTs on the <span className="boldo">{isHiveLogin ? 'Hive' : 'Wax'}</span> blockchain</p>
    </Animated>,
    <Animated
      animationIn="fadeIn" animationOut="slideOutLeft" animationInDuration={500} animationInDelay={2000}
      animationOutDuration={3000} isVisible={true}
    >
      <p><br />- Finding your NFTs on the <span className="boldo">{isHiveLogin ? 'Wax' : 'Hive'}</span> blockchain</p>
    </Animated>
  ];
  const oneChainOnly = (isHiveLogin && !hadWaxNfts) || (!isHiveLogin && !hadHiveNfts);
  isLocal && console.debug('UI login msg:', { isHiveLogin, oneChainOnly, why: { hadWaxNfts, hadHiveNfts } });
  if (oneChainOnly) return [steps[0]];
  return steps;
};

const PostLoginLoading = ({ isHiveLogin }) => {
  return (
    <div id="login-wait-panel">
      <div className="text-center text-white">
        <h5>
          {!isHiveLogin && <p>- Logging you in <i>(2 signatures)</i></p>}
          {[...nftScanMsgs({ isHiveLogin })]}
        </h5>
      </div>
    </div>
  );
};

const ForcedReloadForP2E = () => {
  return (
    <p id="please-reload">Please&nbsp;
      <span className="reload" onClick={() => window.location.reload()}>reload</span> the page to log in.
    </p>
  );
};


export const P2EarnPane = ({ didPlayTrial, choseP2eMode, setChoseP2eMode, handleP2eGamePVE, handleP2eGamePVP }) => {
  const UserState = useSelector((store) => store.user);
  const isHiveLogin = UserState.chainLogin === 'hive';

  return (
    UserState.isLogged
    ? <PostLoginLoading {...{ isHiveLogin }} />
    : <>
      {didPlayTrial
        ? <ForcedReloadForP2E />
        : <>
          {choseP2eMode
            ? <LoginChoice backBtn={() => setChoseP2eMode(false)} />
            : <div className="row" id="p2e-game-play">
                <PlayerMode
                  {...{ singleplayerHandler: handleP2eGamePVE, multiplayerHandler: handleP2eGamePVP, mode: 'p2e' }}
                />
                <div id="no-rewards-p2e-pvp" className="col-12" style={{ marginTop: '14px' }}>
                  <i><b style={{ fontSize: '14px' }}><u>ALPHA</u></b> &nbsp; with initial auto-rewards system</i>
                </div>
              </div>
          }
          </>
      }
      </>
  );
};
