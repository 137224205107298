import { isLocal } from '../../constants/envs';
import { checkTokenBalance } from '../../sagas/helpers/wallet/waxUtils2';
import { readHiveUserBalances } from '../hiveData/reader';


export const unsafeBalanceRead = async ({ cryptoSymbol, senderAccount }) => {
  isLocal && console.log('Checking balance:', { cryptoSymbol, senderAccount });
  if (cryptoSymbol === 'HIVE') {
    return await readHiveUserBalances({ username: senderAccount });
  } else if (cryptoSymbol === 'WAX') {
    return await checkTokenBalance({ username: senderAccount, symbol: cryptoSymbol });
  }
  return 0;
};
window.unsafeBalanceRead = unsafeBalanceRead;
