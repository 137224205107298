import React from 'react';
import { isReactLayer } from '../../../constants/envs';


export const LevelChoice = ({ whichBtnExpanded, setExpandedBtn, inTravel, activeLevel, setShowTravelPage }) => {
  if (inTravel) return null;

  // Make button larger when clicked. But can be clicked only to travel
  // or see travel requirements for the very next level.
  const levelZeroBtnStyle = () => ({
    cursor: 'auto',
    fontWeight: (whichBtnExpanded === 0) ? 'bold' : 'inherit',
    width: (whichBtnExpanded === 0) ? '150px' : '120px',
  });
  const levelOneBtnStyle = () => ({
    cursor: (activeLevel === 0 && whichBtnExpanded !== 1) ? 'pointer' : 'auto',
    fontWeight: (whichBtnExpanded === 1) ? 'bold' : 'inherit',
    width: (whichBtnExpanded === 1) ? '150px' : '120px'
  });
  const levelTwoBtnStyle = () => ({
    cursor: (activeLevel === 1 && whichBtnExpanded !== 2) ? 'pointer' : 'auto',
    fontWeight: (whichBtnExpanded === 2) ? 'bold' : 'inherit',
    width: (whichBtnExpanded === 2) ? '150px' : '120px'
  });
  
  // Button can be clicked only if player is on previous level
  const level1BtnHandler = () => {
    if (activeLevel !== 0) return; // cant travel to lower level
    setExpandedBtn(1);
    setShowTravelPage(1);
  };
  const level2BtnHandler = () => {
    if (activeLevel !== 1) return; // cant travel to lower level
    setExpandedBtn(2);
    setShowTravelPage(2);
  };

  return (
    <div
      id="level-choice"
      style={{ left: isReactLayer ? '20px' : '-150px' }}
    >
      <button
        id="level-1-btn"
        className="btn btn-lg" onClick={() => {}}
        style={levelZeroBtnStyle()}
      >
        LEVEL 0
      </button><br />
      <button
        id="level-2-btn"
        className="btn btn-lg" onClick={level1BtnHandler}
        style={levelOneBtnStyle()}
      >
        LEVEL 1
      </button><br/>
      <button
        id="level-3-btn"
        className="btn btn-lg" onClick={level2BtnHandler}
        style={levelTwoBtnStyle()}
      >
        LEVEL 2
      </button>
    </div>
  );
};
