import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isLocal } from '../../../constants/envs';
import { LDB_BANNER_PRICE, PRIZES_FILENAME, REMOTE_JSON_PATH } from './config';
import { DISCORD_INVITE } from '../../../constants/links';
import { capitalize } from '../../../generalUtils/utils';
import { calcTileRewards } from './utils/calcTileRewards';
import { coingeckoFetch } from './utils/prices/coingecko';
import { PrizesCurrency, isEmbedded } from '../StandaloneAds/Shared/PrizesCurrency';
import './LeaderboardPrizes.scss';


const formatAmounts = ({ igToken, symbol, rewardDivider }) => {
  const values = igToken?.usdValues || igToken?.amounts;
  if (!values) return ['n/a'];
  return values.map(value => symbol ? `${value} ${symbol}` : `$ ${value / rewardDivider}`);
}

const CsPrizesPanel = ({ prizes = {}, chainLogin, hasSponsor, rewardDivider = 1 }) => {
  const { igBoom, igMars } = prizes.cs || {};
  const playerGoalLow = hasSponsor ? 'Highest TOTAL KILLS' : 'Top igBoom balance';
  const playerGoalTop = hasSponsor ? '3rd level BEST TIME' : 'Top igMars balance';

  return <div className="paragraphs-wrapper">
    <p>{playerGoalLow}: &nbsp;<span className="usd-prize"><br/>
      &nbsp; {formatAmounts({ igToken: igBoom, rewardDivider }).join(', ')}
    </span>&nbsp; in <PrizesCurrency chainLogin={chainLogin}/></p>
    <p className="bottom">{playerGoalTop}: &nbsp;<span className="usd-prize"><br/>
      &nbsp; {formatAmounts({ igToken: igMars, rewardDivider }).join(', ')}
    </span>&nbsp; in <PrizesCurrency chainLogin={chainLogin}/></p>
  </div>;
};

const LeaderboardBanner = ({
  placeholder, period = '', // "buy this ad space" banner
  sponsor = {}, // sponsor banner
  chainLogin,
}) => {
  const { projectName, banner, sponsorDiscordInvite } = sponsor;
  const periodUc = period.toUpperCase();

  if (!projectName) return (
    <div className="placeholder-wrapper">
      <a href={DISCORD_INVITE} target="_blank" rel="noreferrer">
        <img src={placeholder} alt="sponsor" style={{ width: '100%', objectFit: 'cover' }} />
      </a>
      <div className="ad-price-label">
        1 {periodUc} for <p className="ad-price">$ {LDB_BANNER_PRICE[periodUc]
          }</p> in <PrizesCurrency chainLogin={chainLogin}/>{period === 'week' ? <p className="token-accepted"><br/>...or your {isEmbedded ? 'project' : capitalize(chainLogin)} token</p> : ''}
      </div>
    </div>
  );
  return (
    <>
      <a href={sponsorDiscordInvite} target="_blank" rel="noreferrer">
        <img src={banner.primaryImg} alt="sponsor" style={{ width: '100%', objectFit: 'cover', maxHeight: 200 }} />
      </a>
    </>
  );
};

const SponsorPrizesPanel = ({ placeholder, sponsor = {}, period = '', chainLogin, tokenUsdSum = 0, showBanner = true }) => {
  const { projectName, symbol = '', igBoom, igMars, sponsorDiscordInvite } = sponsor;
  const cleanSymbol = symbol.split(':')[0];

  const tokenValue = tokenUsdSum > 0 ? tokenUsdSum.toFixed(0) : '...';

  // MISSING SPONSOR, sell spot!
  if (!projectName) return (
    <div className="SponsorPrizesPanel">
      <br/>
      <span className="sponsored-by-label">...PLUS prizes sponsored by: &nbsp;<i>❌</i></span>
      <div>
        <span className="bottom">Advertise your project and reward our players!</span>
        <span className="bottom">&nbsp; Chat with us&nbsp;
          <a href={DISCORD_INVITE} target="_blank" rel="noreferrer">HERE</a>
        </span>
      </div>

      {showBanner && <LeaderboardBanner {...{ placeholder, period, chainLogin }}/>}
    </div>
  );

  // SPONSOR, show prizes and their ad
  return (
    <div className="SponsorPrizesPanel">
      <p className="sponsor-name">
        <span className="sponsored-by-label">...PLUS $ {tokenValue} sponsored by: &nbsp;</span>
        <span className="bold">
          <a href={sponsorDiscordInvite} target="_blank" rel="noreferrer">
            {projectName}
          </a>
        </span>
      </p>
      <div className="paragraphs-wrapper">
        <p>- <span className="underline">{capitalize(chainLogin)
          } user</span> with most igBoom: &nbsp;<span className="usd-prize">
            {formatAmounts({ igToken: igBoom, symbol: cleanSymbol || chainLogin }).join(', ')}
          </span>
        </p>
        <p className="bottom">- <span className="underline">{capitalize(chainLogin)
          } user</span> with most igMars: &nbsp;<span className="usd-prize">
            {formatAmounts({ igToken: igMars, symbol: cleanSymbol || chainLogin }).join(', ')}
          </span>
        </p>
      </div>

      {showBanner && <LeaderboardBanner {...{ sponsor, chainLogin }}/>}
    </div>
  );
};

const PrizeTile = ({ period = '', leaderboardConfig = {}, chainData = {} }) => {
  // Configs Prep
  const { chainLogin, chainPrice } = chainData;
  const chainLc = chainLogin.toLowerCase();
  const { prizes = {}, bannerPlaceholder = {} } = leaderboardConfig;
  const { additionalProperties = {} } = prizes[chainLc] || {};
  const placeholder = bannerPlaceholder.primaryImg;
  const chainPrizes = prizes[chainLc] || {};
  const { cs, sponsor } = chainPrizes[period] || {};
  const { dividerCsRewardsWhenSponsored: divider = 1 } = additionalProperties;
  
  // Local State
  const [tileRewards, setTileRewards] = useState(<sub className="loading">...</sub>);
  const [tokenUsdSum, setTokenUsdSum] = useState(0);
  
  // Rewards Calc
  useEffect(() => {
    if (!chainPrice) return;
    const calcTileRewardsIncludingTokens = async () => {
      const { usdTotalSum = 0, tokenUsdSum = 0 } = await calcTileRewards({
        cs, divider, chainPrice,
        sponsor, sponsorSymbol: sponsor?.symbol,
        chainLogin,
      });
      const usdTotalSumFormatted = `$ ${usdTotalSum.toFixed(0)}`;
      if (usdTotalSum > 0) setTileRewards(usdTotalSumFormatted);
      if (tokenUsdSum > 0) setTokenUsdSum(tokenUsdSum);
    };
    calcTileRewardsIncludingTokens();
  }, [chainPrice, chainLogin, cs, divider, sponsor]);


  // UI
  const title = `${period.toUpperCase()}${period === 'year' ? ` ${new Date().getFullYear()}` : 'LY'} PRIZES`;
  const rewardDivider = sponsor?.symbol ? divider : 1;
  const hasSponsor = sponsor?.projectName;

  return (
    <div className="prize-tile">
      <div className="prize-category">
        {title}
        <p className="tile-prize-sum">{tileRewards}</p>
      </div>
      {period === 'year'
        ? <div className="row">
            <div style={{ float: 'left', width: '45%' }}>
              <CsPrizesPanel {...{ prizes: chainPrizes[period], chainLogin, hasSponsor, rewardDivider }} />
            </div>
            <div style={{ float: 'right', width: '51%', margin: '-35px 0px -10px 0px', padding: 0 }}>
              <SponsorPrizesPanel {...{ placeholder, sponsor, period, chainLogin, tokenUsdSum, showBanner: false }} />
            </div>
            <div id={hasSponsor ? "sponsor-big-banner-wrapper" : "big-banner-wrapper"}>
              <LeaderboardBanner {...{ placeholder, period, sponsor, chainLogin }}/>
            </div>
        </div>
        : <>
          <CsPrizesPanel {...{ prizes: chainPrizes[period], chainLogin, hasSponsor, rewardDivider }} />
          <SponsorPrizesPanel {...{ placeholder, sponsor, period, chainLogin, tokenUsdSum }} />
        </>
      }
    </div>
  );
};

export const LeaderboardPrizes = () => {
  const UserState = useSelector((store) => store.user);
  const chainLogin = localStorage.getItem('chainParam') || UserState.chainLogin;
  
  const [leaderboardConfig, setLeaderboardConfig] = useState({});
  const [chainData, setChainData] = useState({ chainLogin: chainLogin.toUpperCase() });

  useEffect(() => {
    // Fetch Prizes Config
    const fetchPvePrizes = async () => {
      const url = isLocal
        ? `./${PRIZES_FILENAME}` // uses package.json > proxy
        : `${REMOTE_JSON_PATH}/${PRIZES_FILENAME}`;
      try {
        const json = await fetch(url).then(res => res.json());
        setLeaderboardConfig(json);
      } catch (err) { console.error(`Unable to fetch pve prizes: ${err}`); }
    };
    fetchPvePrizes();
    // Fetch chain price
    const fetchChainPrice = async () => {
      const { data = {}, err } = await coingeckoFetch({ token: chainLogin });
      !err && setChainData({ ...chainData, chainPrice: data[chainLogin]?.usd });
    };
    fetchChainPrice();
  }, []);

  return (
    <div className="LeaderboardPrizes row">
      <p id="prizes-main-title">Automated <span className="bold">PVE</span> CRYPTO PRIZES, on top of our tokens</p>
      <div id="week-and-month-prizes" className="col-12 row">
        <div id="week-prizes" className="col-5 prize-tile-wrapper">
          <PrizeTile {...{ period: 'week', leaderboardConfig, chainData }} />
        </div>
        <div className="col-7" style={{ paddingLeft: '10px', paddingRight: '0px' }}>
          <div id="month-prizes" className="prize-tile-wrapper">
            <PrizeTile {...{ period: 'month', leaderboardConfig, chainData }} />
          </div>
        </div>
      </div>
      <div id="year-prizes" className="col-12 prize-tile-wrapper">
        <PrizeTile {...{ period: 'year', leaderboardConfig, chainData }} />
      </div>
    </div>
  );
};
