import { get } from 'lodash';

export const getUnstakeOptionsStatus = ({ token, apiInfo, sessionData, withdrawLots }) => {
  let withdrawOption, percentUnstake;
  if (withdrawLots) {
    withdrawOption = 3;
    percentUnstake = 20;
  } else if (!sessionData.totalWins) {
    withdrawOption = 2;
    percentUnstake = 3;
  } else {
    withdrawOption = 1;
    percentUnstake = 5;
  }
  const boomSavings = get(apiInfo, `savings.${token}.amount`); // eg. 1203.23
  let unstakingAmount = boomSavings * percentUnstake / 100;
  if (withdrawLots) {
    unstakingAmount -= (unstakingAmount * 35 / 100); // burn fee
  }
  return ({ unstakingAmount, withdrawOption, percentUnstake });
};
