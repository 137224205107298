import { get } from "lodash";
import { WaxAuthClient } from 'wax-auth-client';

import { isLocal } from '../../../constants/envs';
import { client } from '../../../helpers/client';
import { flyingSessionLogin, WaxFlyingSession } from "../../../services/UserService";


const authClient = new WaxAuthClient(); 


// ## SIGN TX TO PROVE ACCOUNT OWNERSHIP

const signHiveTx = async ({ inputAccount }) => new Promise((resolve) => {
  window.hive_keychain.requestHandshake(() => {
    try {
      isLocal && console.debug('handshake success!');
      const message = `${inputAccount}_${Date.now()}`;
      window.hive_keychain.requestSignBuffer(
        inputAccount,
        message,
        'Posting',
        (response) => {
          isLocal && console.debug('Keychain requestSignBuffer:', response);
          if (response.error) {
            const errMsg = 'Keychain signing failed';
            console.error(errMsg, response.error);
            return resolve({ err: errMsg });
          }
          const username = get(response, 'data.username');
          isLocal && console.debug('signing response data', response.data);
          if (username !== inputAccount) {
            console.error('Username mismatch:', response.error)
            return resolve({ err: `expected signature for ${inputAccount} but got ${username}` });
          }
          resolve({ message, signed_message: response.result });
        }
      );
    } catch (err) {
      console.error('Sign hive Tx caught error:', err);
      throw new Error('sign-hive-caught-error');
    }
  });
});

const signWaxTx = async ({ inputAccount }) => {
  // ## NONCE
  let nonceResponse;
  try {
    isLocal && console.debug('[cross-chainlink-waxSign] getting nonce for', inputAccount);
    nonceResponse = await client.post(`/api/auth/getNonce`, { waxAddress: inputAccount });
  } catch (err) {
    console.error('nonce ko:', err);
    return ({ err: 'getNonce-ko' });
  }
  const nonce = nonceResponse.data.nonce;
  isLocal && console.debug('[cross-chainlink-waxSign] getting tx data:', { nonce, inputAccount });
  const tx = authClient.getTransactionData(nonce, inputAccount);
  let result;
  // ## SIGNATURE
  try {
    isLocal && console.debug('Creating side session for wax chain');
    const waxSession = WaxFlyingSession;
    const isLoggedIn = await flyingSessionLogin(waxSession);
    if (!isLoggedIn) {
      return ({ err: 'login failed' });
    } else if (waxSession.authName !== inputAccount) {
      return({ err: `signed with ${waxSession.authName} instead of ${inputAccount}` });
    }
    isLocal && console.debug('Signing Wax tx:', { inputAccount, tx });
    result = await waxSession.session.signTransaction(tx.data, tx.options);
  } catch (err) {
    console.error('[cross-chainlink-waxSign] Error signing:', { err, nonce, tx });
    return ({ err: 'error signing tx' });
  }
  // ## RESULT
  const proof = {
    serializedTransaction: result.transaction.serializedTransaction,
    signatures: result.transaction.signatures
  };
  return ({ proof, nonce });
};


// ## API CALLS

// - Account linking:
//   Hive: send sig
//   Wax: ask nonce and verify
//   POST: api/auth/link
//   {
//       account, chainToLink /* tab */,
//       hiveUsername, message, signed_message, // hive
//       waxAddress, proof, nonce, // wax
//   }
export const linkAccount = async ({ currentAuthUser, inputAccount, tab }) => {
  let payload = {
    account: currentAuthUser,
    chainToLink: tab,
  };
  
  // ## HIVE
  if (tab === 'hive') {
    if (!window.hive_keychain) {
      const isConfirmed = window.confirm('You need the Hive keychain extension to link your Hive account.\nWant me to open the installation page?');
      if (isConfirmed) {
        window.open('https://chrome.google.com/webstore/detail/hive-keychain/jcacnejopjdphbnjgfaaobbfafkihpep?hl=en', '_blank');
      }      
      return ({ err: 'Hive Keychain missing' });
    }
    const { message, signed_message, err } = await signHiveTx({ inputAccount });
    if (err) {
      return ({ err });
    }
    payload = {
      ...payload,
      hiveUsername: inputAccount,
      message, signed_message, // proof of ownership
    };

    // ## WAX
  } else if (tab === 'wax') {
    const { proof, nonce, err } = await signWaxTx({ inputAccount });
    if (err) {
      return ({ err });
    }
    payload = {
      ...payload,
      waxAddress: inputAccount,
      proof, nonce, // proof of ownership
    };

  // ## BTC
  } else if (tab === 'btc') { 
    payload = {
      ...payload,
      btcAddress: inputAccount,
      // no proof of ownership for LN Address
    };
  } else {
    throw new Error(`Unexpected tab type ${tab}`);
  }
  try {
    const resCrossChainLink = await client.post('/api/auth/link', payload);
    const respError = resCrossChainLink.data?.err || resCrossChainLink.data?.error;
    if (resCrossChainLink.status !== 200 || respError) {
      console.error('linking failed:', resCrossChainLink);
      return ({ err: `linking error: ${respError}` });
    }
    isLocal && console.debug('api call result:', { resCrossChainLink });
  } catch (caughtErr) {
    console.error('Caught error linking account', { caughtErr, currentAuthUser, inputAccount });
    throw new Error({ err: caughtErr.error || caughtErr });
  }
  return ({});
};


// - Account unlinking:
//   POST: api/auth/unlink
//     {account, accountToUnlink, chainOfAccountToUnlink /* tab */ }
export const unlinkAccount = async ({ currentAuthUser, inputAccount, tab }) => {
  const payload = {
    account: currentAuthUser,
    accountToUnlink: inputAccount, 
    chainOfAccountToUnlink: tab,
  };
  let resCrossChainLink;
  try {
    resCrossChainLink = await client.post('/api/auth/unlink', payload);
    const respError = resCrossChainLink.data?.err || resCrossChainLink.data?.error;
    if (resCrossChainLink.status !== 200 || respError) {
      console.error('unlinking failed:', resCrossChainLink);
      return ({ err: `unlinking failed: ${respError}` });
    }
    isLocal && console.debug('api call result:', { resCrossChainLink });
  } catch (caughtErr) {
    console.error('Caught error ulinking account', { caughtErr, currentAuthUser, inputAccount });
    throw new Error({ err: caughtErr.error || caughtErr });
  }
  return resCrossChainLink.data;
};


// WIP:
// - test:
// swapping owned accounts linked - V
// linking another chain AND btc - []
// - build playtest & check: spinner issue in wax "unlink" and btc pane - works on hive tab
// - release prod
// - tourney e2e tests btc

// N blog posts
// caesars MR and then add shit (UTs and manual tests)
