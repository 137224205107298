import { useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./ReportChart.scss";

import {
  difficulties,
  levels,
  periods,
  properties,
} from "../../config/leaderboardConfig";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ReportChart({ dataReport, loading }) {
  // const [periodSelected, setPeriodSelected] = useState(periods[0]);
  const [propertySelected, setPropertySelected] = useState(properties[0]);
  const [levelForChartSelected, setLevelForChartSelected] = useState(levels[0]);
  const [difficultyForChartSelected, setDifficultyForChartSelected] = useState(
    difficulties[0]
  );
  const [options, setOptions] = useState(null);
  const [data, setData] = useState(null);

  // dataChart
  function getDifficultyNum(difficulty) {
    if (difficulty === "easy") return 0;
    else if (difficulty === "normal") return 1;
    else if (difficulty === "hard") return 2;
    else return null;
  }

  useEffect(() => {
    // let selectedChartData = dataReport[periodSelected];
    let selectedChartData = dataReport;
    let dataForChart = {};
    let chartTitle = "";
    if (selectedChartData) {
      // console.log("before", selectedChartData, Object.keys(selectedChartData));
      switch (propertySelected) {
        case "kills":
          chartTitle = "Chart of Kills";
          dataForChart = {};
          Object.keys(selectedChartData).map((item) => {
            let kills = 0;
            if (selectedChartData[item].length > 0) {
              selectedChartData[item].forEach((element) => {
                kills += element.kills.length;
              });
            }
            dataForChart[item] = kills;
            return kills;
          });
          break;
        case "scores":
          chartTitle = "Chart of Scores";
          dataForChart = {};
          Object.keys(selectedChartData).map((item) => {
            let scores = 0;
            if (selectedChartData[item].length > 0) {
              selectedChartData[item].forEach((element) => {
                scores += element.score;
              });
            }
            dataForChart[item] = scores;
            return scores;
          });
          break;
        case "bestTime":
          chartTitle = `Chart of Best Time on level${levelForChartSelected} for ${difficultyForChartSelected}`;
          dataForChart = {};
          Object.keys(selectedChartData).map((item) => {
            let bestTime = 999999000;
            if (selectedChartData[item].length > 0) {
              selectedChartData[item].forEach((element) => {
                const gameLevel = element.gameLevel ?? 0;
                if (
                  element.difficulty ===
                    getDifficultyNum(difficultyForChartSelected) &&
                  element.resMatch === "won" &&
                  gameLevel === levelForChartSelected &&
                  element.endTime - element.startTime < bestTime
                ) {
                  bestTime = element.endTime - element.startTime;
                }
              });
            }
            if (bestTime === 999999000) bestTime = 0;
            bestTime = bestTime / 1000; // convert to second
            dataForChart[item] = bestTime;
            return bestTime;
          });

          break;
        default:
          break;
      }
      // console.log("dataForChart", dataForChart);

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top", // as const,
          },
          title: {
            display: true,
            text: chartTitle,
          },
        },
      };
      setOptions(options);

      const labels = Object.keys(dataForChart);
      const data = {
        labels,
        datasets: [
          {
            label: propertySelected,
            data: labels.map((item) => dataForChart[item]),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      };
      setData(data);
      // console.log("chart data: ", options, data);
    }
  }, [
    // periodSelected,
    propertySelected,
    difficultyForChartSelected,
    levelForChartSelected,
    dataReport,
  ]);

  return (
    <>
      <div className="text-center">
        {/* <ButtonGroup className="m-2">
          {periods.map((period) => (
            <Button
              variant={period === periodSelected ? "warning" : "secondary"}
              key={period}
              onClick={() => setPeriodSelected(period)}
            >
              {period}
            </Button>
          ))}
        </ButtonGroup> */}
        <ButtonGroup className="m-2">
          {properties.map((property, index) => (
            <Button
              variant={property === propertySelected ? "warning" : "secondary"}
              key={index}
              onClick={() => setPropertySelected(property)}
            >
              {property}
            </Button>
          ))}
        </ButtonGroup>
        {propertySelected === properties[2] && (
          <>
            <ButtonGroup className="m-2">
              {levels.map((level) => (
                <Button
                  variant={
                    level === levelForChartSelected ? "warning" : "primary"
                  }
                  key={level}
                  onClick={() => setLevelForChartSelected(level)}
                >
                  Level {level}
                </Button>
              ))}
            </ButtonGroup>
            <ButtonGroup className="m-2">
              {difficulties.map((difficulty, index) => (
                <Button
                  variant={
                    difficulty === difficultyForChartSelected
                      ? "warning"
                      : "primary"
                  }
                  key={index}
                  onClick={() => setDifficultyForChartSelected(difficulty)}
                >
                  {difficulty}
                </Button>
              ))}
            </ButtonGroup>
          </>
        )}
      </div>

      {!loading && data && options && (
        <div className="chart-container mt-4">
          <Bar options={options} data={data} />;
        </div>
      )}
    </>
  );
}
