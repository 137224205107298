import { LeaderboardPrizes } from '../Prizes/LeaderboardPrizes';
import { PvpPrizes } from './PVP/PvpPrizes';
import './StandaloneAds.scss';


export const StandaloneAds = () => {
    return (
      <div id="StandaloneAds" className="row">
        <div className="col-6 pad30">
          <LeaderboardPrizes/>
        </div>
        <div className="col-6 pad30">
          <PvpPrizes/>
        </div>
      </div>
    );
};