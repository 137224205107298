import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { getCurrentEnvName } from './constants/envs';
import { browserCustomize } from './generalUtils/browser/browser';
import Store from './GlobalState/Store';
import reportWebVitals from './reportWebVitals';
import { detectUrlParams } from './generalUtils/browser/urlParams';


detectUrlParams();

// ## REACT INIT
ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('react-app'),
);

// ## ENV
export const envName = getCurrentEnvName();
console.log('Env:', envName)

// ## DEBUG
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
envName === 'local' && reportWebVitals();

// ## GLOBAL ERROR HANDLER
const errHandler = (type) => (err) => {
  console.error(`Caught ${type} error:`, err, '\n Redirecting back home..');
  setTimeout(() => {
    // dangerous for their gaming session and balances
    const okRedirect = window.confirm(`Oops, a ${type} error occurred. Do you want to refresh the page?`);
    if (okRedirect) {
      localStorage.clear();
      window.location.href = '/';
    }
  }, 1 * 1000);
}
document.addEventListener('error', errHandler('global'));
document.addEventListener('login-error', errHandler('login'));

// Customizations
browserCustomize();
