import { isLocal } from '../../../../constants/envs';
import { cacheBustParam } from '../../../../helpers/client';

// Why not working??
export const waxPurchItems = {
  // 493725: { name: 'L1 pack S', priceWax: 380 },
};

const fetchWaxPrices = () => {
  isLocal && console.debug('fetching wax prices..');
  if (isLocal) return;
  fetch(`/json/waxPr.json${cacheBustParam()}`)
    .then(res => res.json())
    .then((data = {}) => {
      isLocal && console.debug('Wax prices:', data);
      Object.entries(data).forEach(([key, value]) => { waxPurchItems[key] = value; });
    })
    .catch(err => console.error('wax prices fetch err:', err));
};
fetchWaxPrices();


export const isWaxBuyItem = template => Object.keys(waxPurchItems).includes(template);
