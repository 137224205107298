import axios from 'axios';
import stringHash from 'string-hash';

import { WaxAuthClient } from 'wax-auth-client';
import { storeAppDispatch } from '../../../GlobalState/Store';
import { setPlayerLogout } from '../../../reducers/UserReducer';
import { client } from '../../../helpers/client';
import { UserService } from '../../../services/UserService';
import { setBridgeToken } from '../play/unityBridge';
import { isLocal, isTestnet } from '../../../constants/envs';
import { nukeAnchorSavedAccount } from '../../../services/autoLogin';
import { get } from 'lodash';
import Swal from 'sweetalert2';

const authClient = new WaxAuthClient();
sessionStorage.removeItem('verified');


// --------- utils

const failureHandler = ({ reason, err }) => {
  Swal.fire({text: `Verification error. Please try again`})
  console.error(`Verification failure details:\n-Reason: ${reason}\n-Error: ${err}`);
  storeAppDispatch(setPlayerLogout());
  window.location.reload();
  throw new Error('verify-ko');
};


// ADD USER-AGENT HASH anti-cheat. Multi tab/window check.
// - send it and store it in login request
// - send it in all claim/stake/unstake requests
// - check it srv side and 400 if mismatch
const addDefaultHeaders = () => {
  // FE version
  const version = get(window, 'version.GAME_VERSION');
  axios.defaults.headers.common['X-FE-VERSION'] = `R_v${version}`;
  // User Agent
  const uaHash = stringHash(navigator.userAgent);
  axios.defaults.headers.common['X-Client-ID'] = uaHash;
};


// --------- WAX

export const verifyWaxUser = async ({ username }) => {
  console.debug('[vwu] Starting verification for', username);
  // ## NONCE
  let nonceResponse;
  try {
    isTestnet && console.debug('[vwu] getting nonce for', username);
    nonceResponse = await client.post(`/api/auth/getNonce`, { waxAddress: username });
  } catch (err) {
    failureHandler({ reason: 'caught-getnonce', err});
    return ({ err: 'error-getting-nonce' });
  }
  const nonce = nonceResponse.data.nonce;
  isLocal && console.debug('[vwu] getting tx data:', { nonce, username });
  const tx = authClient.getTransactionData(nonce, username);
  let result;
  // ## SIGNATURE 1
  try {
    result = await UserService.session.signTransaction(tx.data, tx.options);
  } catch (err) {
    console.error('[vwu] Error signing:', { nonce, tx, err });
    nukeAnchorSavedAccount();
    return ({ err: 'error-signing-tx' });
  }
  // ## VERIFY (BACKEND)
  const proof = {
    serializedTransaction: result.transaction.serializedTransaction,
    signatures: result.transaction.signatures
  };
  const payload = { proof, waxAddress: username, nonce };
  let verificationResult;
  try {
    isTestnet && console.debug('<><> verifying sig');
    addDefaultHeaders(); // applied to all API calls from now on
    verificationResult = await client.post(`/api/auth/verify`, payload);
  } catch (err) {
    failureHandler({ reason: 'caught-verify', err});
    return ({ err: 'error-verify-call' });
  }
  isLocal && console.debug('[vu] Verification result', verificationResult);
  if (verificationResult.data.result !== 'success') {
    failureHandler({ reason: '401-result', err: 'verification response is not success' });
    return ({ err: 'verify-call-failed' });
  }
  // ## LOGIN SUCCESS ACTIONS
  const headerValue = `Bearer ${verificationResult.data.token}`;
  axios.defaults.headers.common['Authorization'] = headerValue;
  setBridgeToken({ headerValue, username });
  sessionStorage.setItem('verified', Date.now());
  isLocal && console.log('Wax user correctly authenticated!'); // toast.success
  return ({});
};


// ----------- Used for HIVE, SKL, ETH. For Wax double signature crap.

export const verifyUserIdentity = async ({ username, message, signed_message, chainLogin }) => {
  // ## VERIFY (message already signed)
  addDefaultHeaders(); // applied to all API calls from now on
  const resUserSigVerify = await client.post('/api/auth/verify',
    { username, message, signed_message },
  );
  if (resUserSigVerify.status !== 200 || resUserSigVerify.data.result !== 'success') {
    failureHandler({ reason: 'verify-ko', err: resUserSigVerify });
  }
  // ## LOGIN SUCCESS ACTIONS
  // - Axios Auth header
  const headerValue = `Bearer ${resUserSigVerify.data.token}`;
  axios.defaults.headers.common['Authorization'] = headerValue;
  // - Unity
  setBridgeToken({ headerValue, username });
  // - Browser Storage
  sessionStorage.setItem('verified', Date.now());
  localStorage.setItem(`${chainLogin}Username`, username);

  isLocal && console.log(`${chainLogin} user correctly authenticated!`); // toast.success
  return true;
};
