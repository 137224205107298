import React from 'react';

export const BitcoinLnInstructions = () => {
  return (
    <span style={{ fontSize: '16px' }}>
      Login with a <span className="bold hive">Hive
      </span> account and link your <span className="bold bitcoin">
        Lightning Network</span> address in the <span className="bold" style={{ color: '#005cb0' }}>
        Tokens</span> section!
    </span>
  );
};
