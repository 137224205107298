import { BOOM_SWAPS_RECEIVER } from "../../../../config/accounts";
import { TOKEN_ACCOUNT } from "../../../../config/contractNames";
import { isLocal } from "../../../../constants/envs";
import { flyingSessionLogin, UserService, WaxFlyingSession } from "../../../../services/UserService";


export const transferBoomWithSession = async ({ amountBoomSwap, boomSenderSwap }) => {
  let waxSession = UserService;
  // If logged in with Hive, override waxSession with temporary one.
  // (Not needed if logged in with Wax already)
  if (!UserService.authName || (UserService.authName !== boomSenderSwap)) {
    isLocal && console.debug('creating side session for wax chain');
    waxSession = WaxFlyingSession;
    if (isLocal) { window.flyingSession = waxSession; } // DEBUG @@
    const isLoggedIn = await flyingSessionLogin(waxSession);
    if (!isLoggedIn || waxSession.authName !== boomSenderSwap) {
      return ({ err: `To proceed with the swap you have to sign in with ${boomSenderSwap}` });
    }
  } else {
    isLocal && console.debug('using the login session for wax chain');
  }
  isLocal && console.debug('Initiating Boom transfer:', { amountBoomSwap, boomSenderSwap });
  return waxSession.session.signTransaction(
    {
      actions: [{
        account: TOKEN_ACCOUNT,
        name: 'transfer',
        authorization: [{
          actor: waxSession.authName,
          permission: 'active'
        }],
        data: {
          from: waxSession.authName,
          to: BOOM_SWAPS_RECEIVER,
          quantity: `${amountBoomSwap.toFixed(4)} BOOM`,
          memo: 'BOOM swap',
        },
      }],
    },
    {
      blocksBehind: 3,
      expireSeconds: 30
    }
  )
    .then((response) => {
      isLocal && console.log('Boom send response status:', { amountBoomSwap, boomSenderSwap, response });
      if (response.status !== 'executed') {
        console.error('Transfer not executed:', { response });
        return ({ errDetails: JSON.stringify(response) });
      }
      return ({ tx: response.transactionId });
    })
    .catch((err) => {
      console.error('Caught error during BOOM transfer:', err);
      return ({ errDetails: err });
    });
};
