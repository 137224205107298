import React, { useState } from 'react';
import { isReactLayer } from '../../../constants/envs';

export const ChainChoice = ({ choseChain, activeBtn = 1 }) => {
  const [whichActiveBtn, setActiveBtn] = useState(activeBtn);

  const ethBtnStyle = {
    width: whichActiveBtn === 4 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 4 ? 'bold' : 'auto',
  };
  const btcBtnStyle = {
    width: whichActiveBtn === 3 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 3 ? 'bold' : 'auto',
  };
  const hiveBtnStyle = {
    width: whichActiveBtn === 2 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 2 ? 'bold' : 'auto',
  };
  const waxBtnStyle = {
    width: whichActiveBtn === 1 ? '170px' : '150px',
    fontWeight: whichActiveBtn === 1 ? 'bold' : 'auto',
  };

  const handleEthClick = () => {
    choseChain(4);
    setActiveBtn(4);
  };
  const handleBtcClick = () => {
    choseChain(3);
    setActiveBtn(3);
  };
  const handleHiveClick = () => {
    choseChain(2);
    setActiveBtn(2);
  };  
  const handleWaxClick = () => {
    choseChain(1);
    setActiveBtn(1);
  };

  return (
    <div
      id="chain-choice"
      style={{ left: isReactLayer ? '20px' : '-150px' }}
    >
      <button
        id="btc-chain-btn"
        className="btn btn-lg" onClick={handleBtcClick}
        style={btcBtnStyle}
      >
        BTC
      </button><br />
      <button
        id="hive-chain-btn"
        className="btn btn-lg" onClick={handleHiveClick}
        style={hiveBtnStyle}
      >
        HIVE
      </button><br />
      <button
        id="wax-chain-btn"
        className="btn btn-lg" onClick={handleWaxClick}
        style={waxBtnStyle}
      >
        WAX
      </button><br/>
      <button
        id="wax-chain-btn"
        className="btn btn-lg" onClick={() => {}}
        style={ethBtnStyle}
        disabled={true}
      >
        ETH
      </button>
    </div>
  );
};
