import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLocal } from '../../../../../constants/envs';
import { hasMostlyHiveNfts } from '../../utils/requiredNfts';
import { SS_PRICES } from "../travelPrices";
import { getFormattedPrice } from '../travels';
import { SS_INFO } from './spaceshipsInfo';


const getSpaceshipInfo = ({ spaceshipId, destinationLevel, charactersCount }) => {
  try {
    isLocal && console.debug('Getting spaceship info:', { spaceshipId, destinationLevel, charactersCount, SS_INFO });
    const currentSsInfo = SS_INFO[`TO_L${destinationLevel}`][spaceshipId - 1];
    if (!currentSsInfo) return ({});
    const { SRC: imgSrc, NAME: spaceshipName, DURATION: travelDuration, ENGINE: engineType } = currentSsInfo;
    
    const index = spaceshipId + ((destinationLevel - 1) * 3); // L1: 1,2,3 -- L2: 1+3,2+3,3+3 -- L3: 1+6,2+6,3+6
    const ssCurrencies = SS_PRICES[`SS${index}`]; // . B/F/M/G, W
    const { W: waxAmount, ...rest } = ssCurrencies;
    const [abbr, ourToken] = Object.entries(rest)[0]; // { inWax: 1, abbr: 'H', ourToken: 2 }
    const waxFormattedPrice = getFormattedPrice({ currency: 'W', price: waxAmount, forUI: true });
    const ourTokenFormattedPrice = getFormattedPrice({ currency: abbr, price: ourToken, charactersCount, forUI: true });
    return ({
      imgSrc, spaceshipName, travelDuration, engineType,
      travelCost: { ourTokenFormattedPrice, ourTokenInitial: abbr, waxFormattedPrice },
    });
  } catch (err) {
    console.error('Get spaceship info broke:', err);
    return ({});
  }
};

const SpaceshipListing = ({
  spaceshipId, destinationLevel,
  canUnlockNextLevel, charactersCount, freebies, bookTravel,
} = {}) => {
  const InventoryState = useSelector((store) => store.inventory);
  const waxNfts = get(InventoryState, 'csAssets.csNfts', []);
  const hiveNfts = get(InventoryState, 'hive.nfts', []);
  const isMostlyHive = hasMostlyHiveNfts({ hiveNfts, waxNfts });
  
  const isFree = freebies[`freeSs${spaceshipId}`] || isMostlyHive;
  const {
    spaceshipName, imgSrc, travelDuration, engineType, travelCost = {},
  } = getSpaceshipInfo({ spaceshipId, destinationLevel, charactersCount });
  if (!spaceshipName) return null;

  return (
    <div className="col-3 spaceship-pane">
      <img src={imgSrc} alt={`${spaceshipName} Spaceship`}></img>
      <div class="row">
        <p className="spaceship-name">{spaceshipName} &nbsp;
          <a href="https://wax.atomichub.io/market?collection_name=crypto4shots&order=desc&schema_name=csspaceships&search=part&sort=created&symbol=WAX" target="_blank" rel="noreferrer">
            <span className="parts-ownership">(parts owned by players)</span>
          </a></p>
        <p className="wait-time">Travel duration: {travelDuration}</p>
        <p className="engine-type">{engineType}</p>
      </div>
      {(!canUnlockNextLevel || !!window.freeShip)
        ? null
        : <>
            {!isFree && <button
                style={{ fontWeight: 'bold' }}
                onClick={() => bookTravel({ spaceshipId, currency: travelCost.ourTokenInitial })}
              >
                {travelCost.ourTokenFormattedPrice}
              </button>
            }
            <button onClick={() => bookTravel({ spaceshipId, currency: 'W', isMostlyHive })}>
              {isFree ? <span>FREE FOR YOU!</span> : <span>{travelCost.waxFormattedPrice}</span>}
            </button>
            {(!isFree && travelCost.ourTokenInitial === 'G') && <div style={{ textAlign: 'left', height: '5px', margin: '0px auto 40px 40px' }}>
                &nbsp; &nbsp; &nbsp; <sub><b>[ {charactersCount}</b> characters ]</sub>
              </div>}
            {!isFree && <p className="sell-pass">or GET A <a href="https://wax.atomichub.io/market?collection_name=crypto4shots&order=desc&schema_name=csspaceships&search=pass&sort=created&symbol=WAX" target="_blank" rel="noreferrer">LIFETIME PASS NFT</a></p>}
          </>
      }
    </div>
  );
};

export const ThreeSpaceships = ({
  bookTravel, canUnlockNextLevel,
  charactersCount, freeSs1, freeSs2, freeSs3
}) => {
  const PlayState = useSelector((store) => store.play);
  const destinationLevel = PlayState.activeLevel + 1;
  if (!destinationLevel) return null;
  const freebies = { freeSs1, freeSs2, freeSs3 };
  const allSpaceshipsProps = { destinationLevel, canUnlockNextLevel, charactersCount, freebies, bookTravel };

  return (
    <>
      <SpaceshipListing spaceshipId={1} {...allSpaceshipsProps} />
      <SpaceshipListing spaceshipId={2} {...allSpaceshipsProps} />
      <SpaceshipListing spaceshipId={3} {...allSpaceshipsProps} />
    </>
  );
};
