import { MINTER_ACCOUNT } from '../../../config/contractNames';
import { WAX_NODES } from '../../../config/endpoints';
import { isLocal, isTestnet } from '../../../constants/envs';
import { ITEMS_BY_CATEGORY } from '../../../constants/templateIds';


const PRICES_CACHED_MINS = 15;
const PRICES_CACHE_DURATION_MS = PRICES_CACHED_MINS * 60 * 1000;

let fetchEndpoint = WAX_NODES.DEFAULT;
const fallbackEndpoints = WAX_NODES.ALTS;


const pricesCache = {
  lastFetch: Date.now(),
  rowsObj: {
    // '365485': 30, // template_id, boom_price
    // '385499': 100, // template_id, boom_price
  },
};

// ---- FOR MAINNET ONLY -----
// !!!! -- here due to CORS issues --
// const hardcodedPrices = {
//   '365485': 30, // Base Gun
//   '385499': 100, // Shotgun
// };
// if (!isTestnet) {
//   pricesCache.rowsObj = hardcodedPrices;
//   pricesCache.lastFetch = Date.now();
//   return ({ rowsObj: pricesCache.rowsObj });
// }
let priceFetchFailed = false;

const fetchPrices = async () => {
  return fetch(
    `${fetchEndpoint}/v1/chain/get_table_rows`,
    {
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      "body": JSON.stringify({
        "json": true,
        "code": MINTER_ACCOUNT,
        "scope": MINTER_ACCOUNT,
        "table": "marketplace",
        "lower_bound": null,
        "upper_bound": null,
        "index_position": 1,
        "key_type": "",
        "limit": "100",
        "reverse": false,
        "show_payer": false,
      }),
      "method": "POST",
    },
  )
    .then(res => res.json())
    .then((data) => {
      isLocal && console.log('Marketplace rows:', { size: data.rows.length, data: data.rows });
      data.rows.forEach((row) => {
        pricesCache.rowsObj[`${row.template_id}`] = +row.cost;
      });
      pricesCache.lastFetch = Date.now();
      isLocal && console.log('Cached prices:', pricesCache);
      return ({ rowsObj: pricesCache.rowsObj });
    })
    .catch((err) => {
      console.error('Caught error fetching prices:', err);
      const endpoints = fallbackEndpoints;
      const randId = Math.floor(Math.random() * endpoints.length);
      fetchEndpoint = endpoints[randId];
      priceFetchFailed = true;
      return ({ err });
    });
};
// INITIAL FETCH !
fetchPrices();


export const getCachedPrices = async ({ idsOnly = false } = {}) => {
  // Template ids only
  const allTemplateIds = Object.keys(pricesCache.rowsObj);
  const adjustedTemplateIds = isTestnet
    ? allTemplateIds.map(template => templateReplacement({ testnetTemplate: template }))
    : allTemplateIds;
  // isLocal && console.log('Available items:', { adjustedAvailableItems: adjustedTemplateIds });
  if (idsOnly) return adjustedTemplateIds;
  // All data
  const noRows = allTemplateIds.length === 0;
  if (priceFetchFailed) return ({ err: 'initial fetch failed, not bombing int' });
  let err;
  if (noRows || ((Date.now() - pricesCache.lastFetch) > PRICES_CACHE_DURATION_MS)) {
    if (noRows && pricesCache.loading) return { rowsObj: {} };
    pricesCache.loading = true;
    const res = await fetchPrices();
    err = res.err;
    delete pricesCache.loading;
  }
  return ({ err, rowsObj: pricesCache.rowsObj });
};


export const templateReplacement = ({ testnetTemplate, mainnetTemplate }) => {
  if (!isTestnet) { throw new Error('Why are you using the template replacement on mainnet?!'); }
  if (testnetTemplate && mainnetTemplate) {
    throw new Error('Provide either the testnet OR the mainnet template id, not both');
  }
  if (mainnetTemplate) {
    if (`${mainnetTemplate}` === ITEMS_BY_CATEGORY.WEAPONS.BG.ID) return '280338'; // BG
    else if (`${mainnetTemplate}` === ITEMS_BY_CATEGORY.WEAPONS.LS.ID) return '338061'; // LS
  } else {
    if (`${testnetTemplate}` === '280338') return ITEMS_BY_CATEGORY.WEAPONS.BG.ID; // BG
    else if (`${testnetTemplate}` === '338061') return ITEMS_BY_CATEGORY.WEAPONS.LS.ID; // LS
  }
  return testnetTemplate || mainnetTemplate;
};

export const getItemPrice = async ({ templateId }) => {
  const { rowsObj = {}, err } = await getCachedPrices();
  if (Object.keys(rowsObj).length === 0) return 'n/a';
  if (isTestnet) {
    templateId = templateReplacement({ mainnetTemplate: templateId });
  }
  const price = +rowsObj[`${templateId}`];
  if (!price) {
    // console.error('Unexpected price:', { templateId, rowsObj });
  }
  return ({ err, price });
};
