import React, { useState } from 'react';
import { get, invoke } from 'lodash';
import { useSelector } from 'react-redux';
import { isLocal, isTestnet } from '../../constants/envs';
import { ClaimDisclaimer } from './subComponents/claims/ClaimDisclaimer';
import { getPendingRewardToClaim, getUnstakedSum } from './subComponents/claims/wrapperData/extractors';
import { checkIsTokensClaimDisabled } from './subComponents/claims/wrapperData/claimBoomRequirements';
import { PendingRewards } from './subComponents/claims/PendingRewards';
import { BoomRewardsActions } from './subComponents/claims/buttonsPanes/BoomRewardsActions';
import { MarsRewardsActions } from './subComponents/claims/buttonsPanes/MarsRewardsActions';
import { ProxRewardsActions } from './subComponents/claims/buttonsPanes/ProxRewardsAction';
import { getPlayerUsername } from '../../generalUtils/utils';
import { UserService } from '../../services/UserService';
import { claimAllTokens } from './subComponents/claims/claim';
import { HIGHEST_REWARD, LOWEST_THRESHOLD } from '../../config/appConfig';
import { LoadingSpinner } from '../../images/gifs/LoadingSpinner';
import { ClaimResult } from './subComponents/claims/ClaimResult';
import { WhichChains } from './subComponents/WhichChains';
import { storeAppDispatch } from '../../GlobalState/Store';
import { setClaimResult } from '../../reducers/PlayReducer';
import { antiCheatersCheck, multiTabCheckBeforePlayAndClaim } from '../../generalUtils/secure';
import Swal from 'sweetalert2';


export const RightPaneRewards = () => {
  // PLAY STATE
  const PlayState = useSelector((store) => store.play);
  const { wrapperData = {}, claimResult = {} } = PlayState;
  const { apiResponse: apiInfo = {}, gameData = {}, sessionData = {}, unityLevel } = wrapperData;
  const totalRewards = +(getPendingRewardToClaim(apiInfo.boomRewardsToClaim)).toFixed(4);
  const totalBoomRewards = +(getPendingRewardToClaim(apiInfo.boomRewardsToClaim)).toFixed(4);
  const totalMarsRewards = +(getPendingRewardToClaim(apiInfo.marsRewardsToClaim)).toFixed(4)
  const hasAnyRewards = !!(totalBoomRewards || totalMarsRewards);
  const hasAnyGamingOnlyRewards = (
    totalBoomRewards - getUnstakedSum(get(apiInfo, 'boomRewardsToClaim.unstaked', []))
    + totalMarsRewards - getUnstakedSum(get(apiInfo, 'marsRewardsToClaim.unstaked', []))
  ) > 0;
  const activeLevel = PlayState.activeLevel;

  // INVENTORY STATE
  const InventoryState = useSelector((store) => store.inventory);
  const waxScholarshipData = get(InventoryState, 'scholarship', {});
  const { teacherCut, isScholar, teacherName, scholarCut } = (waxScholarshipData || {});
  const hiveData = get(InventoryState, 'hive', {});
  const { nfts: hiveNfts, username: hiveUsername, teacher: hiveTeacher } = hiveData;
  const waxNfts = get(InventoryState, 'csAssets.csNfts', []);
  const hasOnlyHiveNfts = waxNfts.length === 0 && hiveNfts.length > 0;

  // USER STATE
  const UserState = useSelector((store) => store.user);
  const isWaxUser = UserState.chainLogin === 'wax';

  // LOCAL STATE
  const [whichActiveToken, setWhichActiveToken] = useState('BOOM');
  const [withdrawLots, setWithdrawLots] = useState(false);
  const [claimInProgress, setClaimInProgress] = useState(false);
  const [stakeInProgress, setStakeInProgress] = useState(false);
  const [unstakeInProgress, setUnstakeInProgress] = useState(false);
  const shouldLockNavigation = claimInProgress || stakeInProgress || unstakeInProgress;
  const actions = {
    withdrawLots, setWithdrawLots,
    claimInProgress, setClaimInProgress,
    stakeInProgress, setStakeInProgress,
    unstakeInProgress, setUnstakeInProgress,
  };

  // BOOM CLAIM BTN STATUS
  const { result: isBoomClaimDisabled, reasons: boomDisReasons = {} } = checkIsTokensClaimDisabled({
    apiInfo, sessionData, scholarCut: isScholar && scholarCut, unityLevel
  });
  const { isChampionOverflow, isMostlyUnstaker } = boomDisReasons;
  const isBoomClaimBtnDisabled = isBoomClaimDisabled || claimInProgress || stakeInProgress;
  const shouldBtnShine = hasAnyRewards && !claimInProgress;
  // (debug)
  isLocal && console.debug('[--] All-rewards claim buttons disabled?', {
    isBoomClaimBtnDisabled, isBoomClaimDisabled, claimInProgress, stakeInProgress,
  });

  const onHandleClaim = async () => {
    // VALIDATIONS
    const claimBtn = document.getElementById('claim-boom');
    if (claimBtn.getAttribute('disabled') === 'true' || claimBtn.getAttribute('bigger')) return; // anti-cheat
    claimBtn.setAttribute('disabled', 'true');
    isTestnet && console.debug('Initialized claim request..');
    setClaimInProgress(true);
    const username = getPlayerUsername({ UserService, UserState, InventoryState });

    if (!sessionStorage.getItem('verified')) {
      Swal.fire({ text: `Authentication expired. Do not clear cache during your gaming session. Login again to claim.\n[client-issue:no-refund]` })
      return;
    }
    // anti-cheats
    await antiCheatersCheck({ waxNfts, isWaxUser, isScholar });
    multiTabCheckBeforePlayAndClaim();
    try {
      const scholarCutValue = isScholar && (scholarCut || (100 - teacherCut));
      const { err = '', claimResult: apiResponse = {} } = await claimAllTokens({
        username, teacherName, scholarCut: scholarCutValue,
        isMostlyUnstaker, hiveData, nfts: waxNfts
      });
      if (err) return; // not resetting UI, they can try again
      // React State update
      isLocal && console.debug('Dispatching claim result', { apiResponse });
      setClaimInProgress(false);
      storeAppDispatch(setClaimResult({ claimResult: apiResponse }));
      // UI RESET
      console.debug('Resetting claim UI..');
      invoke(window, 'fetchUserData');
    } catch (err) {
      console.error('caught error in claim:', err);
      Swal.fire({ text: `Claim failed. IF you have been logged in for a long time please log in again.\n\n${err}` })
      setClaimInProgress(false);
      // !isTestnet && window.location.reload(); // issue with spaceship payments
    }
  };

  return (
    <div
      className="col-md-6 mt-4"
      style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div className="container row rewards">
        <h2 className="text-white text-center mt-4"><b>PVE</b> &nbsp; PENDING REWARDS</h2>
        <div className="col-6 text-center">
          <div className="col-12" style={{ lineHeight: '8px !important', fontSize: '14px', marginTop: '5px' }}>
            {/* tech debt - used by wrapper, hidden now */}
            <p id="db-balances" className="flashy-box" style={{ display: 'none' }}>
              <i style={{ color: '#0ef20e' }}>No pending igBOOM rewards</i>
            </p>
            <PendingRewards name="BOOM"
              {...{ whichActiveToken, setWhichActiveToken, shouldLockNavigation }}
              rewards={apiInfo?.boomRewardsToClaim}
            />
            <PendingRewards name="MARS"
              {...{ whichActiveToken, setWhichActiveToken, shouldLockNavigation }}
              rewards={apiInfo?.marsRewardsToClaim}
            />
            <PendingRewards name="PROX"
              {...{ whichActiveToken, setWhichActiveToken, shouldLockNavigation }}
              rewards={undefined}
            />
          </div>
          &nbsp;
          <div className="claim-boom" id="claim-disclaimer">
            <ClaimDisclaimer {...{
              whichActiveToken,
              activeLevel, totalRewards, scholarCut: isScholar && scholarCut, boomClaimInfo: boomDisReasons,
            }} />
          </div>
          &nbsp;
        </div>
        <div className="col-6 text-center play-section-buttons">
          <div
            id="claim-boom-container"
            className={`col-12 btn-claim mt-4 flashy-box ${shouldBtnShine ? 'flashy-green' : ''}`}
          >
            <p className="claim-boom" id="over-claim-btn-message"></p>

            <button
              id="claim-boom"
              className={`btn btn-success btn-lg ${shouldBtnShine ? 'fake-a' : ''}`}
              onClick={() => onHandleClaim()}
              disabled={isBoomClaimBtnDisabled}
            >
              {shouldBtnShine && <div id="shiny-rotating-cool-effect">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>}
              Claim all your pending rewards<br />
              <sub><b>{totalBoomRewards.toFixed(2)}</b> <sub>ig</sub>B{!!scholarCut
                && <sub>&nbsp; (you: {(totalBoomRewards * scholarCut / 100).toFixed(2)}),</sub>}
              </sub>
              <sub>&nbsp;+ <b>{totalMarsRewards.toFixed(2)}</b> <sub>ig</sub>M{!!scholarCut
                && <sub>&nbsp; (you: {(totalMarsRewards * scholarCut / 100).toFixed(2)})</sub>}
              </sub>
              {(hasOnlyHiveNfts && !scholarCut) && <sub>&nbsp;= <b style={{ color: 'gold' }}>{
                  (totalBoomRewards + totalMarsRewards).toFixed(2)
                } $DOOM</b></sub>}
            </button>

            <p className="text-white" style={{ margin: 'thin solid red !important' }}>
              {!!sessionData.matchCount && isBoomClaimDisabled && !hasAnyRewards && !isChampionOverflow
                && !isMostlyUnstaker && <sub>🔒</sub>}
              {!sessionData.matchCount && isBoomClaimDisabled && !hasAnyGamingOnlyRewards
                && <sub>[PLAYER: WIN at least 1 match to claim/unstake 🔫]</sub>}
              {!isBoomClaimDisabled && hasAnyGamingOnlyRewards
                && <sub>[PLAYER: you can claim gaming rewards 💰]</sub>}
              {isChampionOverflow && (totalBoomRewards - HIGHEST_REWARD > 0)
                && <sub>[CHAMPION: {(totalBoomRewards - HIGHEST_REWARD).toFixed(2)} into stake 🏆]</sub>}
              {isMostlyUnstaker && (totalBoomRewards - LOWEST_THRESHOLD > 0)
                && <sub>[UNSTAKER: {(totalBoomRewards - LOWEST_THRESHOLD).toFixed(2)} into stake 🏦]</sub>}
            </p>
            {claimResult.mainUser && <button
                onClick={() => storeAppDispatch(setClaimResult({ claimResult: {} }))}
                style={{
                  color: 'white', paddingTop: '4px', paddingRight: '4px', backgroundColor: 'rgba(100,100,100,0.3)',
                  borderTopRightRadius: '20px', display: 'block', marginLeft: '0px', marginBottom: '-5px',
                  width: '25px', height: '25px', outline: 'none', border: 'none', fontSize: '12px',
                }}
              >X</button>}
            {claimInProgress
              ? <LoadingSpinner />
              : <ClaimResult {...{ scholarshipData: waxScholarshipData, claimResult }} />
            }
            <WhichChains {...{ hiveNfts, waxNfts }} />
          </div>
          <div id="stake-and-unstake-box">
            <p id="active-token-title">MANAGE <span className="boldo">{whichActiveToken}</span> REWARDS</p>
            {whichActiveToken === 'BOOM' && <BoomRewardsActions
                {...{
                  whichActiveToken, actions,
                  isClaimBtnDisabled: isBoomClaimBtnDisabled, isBoomClaimDisabled, boomDisReasons,
                }}
              />
            }
            {whichActiveToken === 'MARS' && <MarsRewardsActions
                {...{
                  whichActiveToken, actions,
                }}
              />
            }
            {whichActiveToken === 'PROX' && <ProxRewardsActions
                {...{ whichActiveToken, isClaimBtnDisabled: true }}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
