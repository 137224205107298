import { get } from 'lodash';

import { WAX_NODES } from '../../../config/endpoints';
import { isLocal } from '../../../constants/envs';
import { UserService } from '../../../services/UserService';

import { getCurrentEnvName } from '../../../constants/envs';
const env = getCurrentEnvName();


const { DEFAULT: defaultEndpoint, ALTS: altEndpoints } = WAX_NODES;
const randId = Math.floor(Math.random() * altEndpoints.length);
const waxNode = Math.random() > 0.5 ? defaultEndpoint : altEndpoints[randId];


export const fetchBoomBalance = async (account) => {
  if (UserService.session) {
    return UserService.fetchWaxTokens()
      .then((tokens = {}) => {
        return tokens.boom;
      })
      .catch(err => console.error('Caught error fetching boom balance:', err));
  } else {
    // For wax username validation even before the wax login for the swap:
    return fetch(`${waxNode}/v2/state/get_tokens?account=${account}`, {
      "headers": {
        "accept": "application/json",
        // "cache-control": "no-cache",
        // "sec-fetch-mode": "cors",
        // "sec-fetch-site": "cross-site"
      },
      "referrer": `${waxNode}/`, // boomFetchReferrer
      // "referrerPolicy": "strict-origin-when-cross-origin",
      "body": null,
      "method": "GET",
      // "mode": "cors",
      // "credentials": "omit"
    })
      .then(res => res.json())
      .then((data = {}) => {
        const boomFetchContract = env === 'prod' ? 'csboomcsboom' : 'boomfungible';
        const result = get(data, 'tokens', []).find(
          tokenObj => tokenObj.symbol === 'BOOM' && tokenObj.contract === boomFetchContract
        ) || { amount: 0 };
        return result.amount;
      })
      .catch(err => console.error('Caught error fetching boom balance:', err));
  }
};


// NOTE: must update to 1.3.0 by end April '23. Older versions are deprecated @@
// !!
import * as waxjs from "@waxio/waxjs/dist"; // eslint-disable-line
const client = new waxjs.WaxJS({ rpcEndpoint: waxNode });
if (isLocal) { window.session2 = client; }

// Used for account linking and swaps @@
// !!
export const checkWaxUserExists = async ({ account }) => {
  if (isLocal) return true;
  isLocal && console.debug('Account lookup with wax lib for', account);
  return client.rpc.get_account(account)
    .then((data) => {
      isLocal && console.log('Account info:', data); // creation date too
      return data.account_name === account;
    })
    .catch((err) => {
      console.error('Caught looking up wax user:', err);
      return false; // 404 error
    });
  // THE OLD WAY:
  // return fetch(
  //   `https://wax.greymass.com/v2/state/get_tokens?account=${account}`,
  //   {
  //     "headers": {
  //       "accept": "application/json",
  //     },
  //     "referrer": `https://wax.greymass.com/`,
  //     "body": null,
  //     "method": "GET",
  //   })
  //     .then(res => res.json())
  //     .then((data = {}) => {
  //       return (data.code < 300);
  //       // console.log('>>> ', data.code === 404);
  //     });
};

// Eg. [{ balance: "8840.0000 GAS" }]
export const extractRowBalance = (rows = [], tokenLc = '') => {
  const tokenUc = tokenLc.toUpperCase();
  const balanceObj = rows.find(
    ({ balance } = {}) => balance.endsWith(` ${tokenUc}`),
  );
  isLocal && console.debug('Balance:', balanceObj);
  return balanceObj ? +(balanceObj.balance.split(` ${tokenUc}`)[0]) : 0;
};

export const getWaxNode = () => {
  const { DEFAULT: defaultEndpoint, ALTS: altEndpoints } = WAX_NODES;
  const randId = Math.floor(Math.random() * altEndpoints.length);
  const waxNode = Math.random() > 0.5 ? defaultEndpoint : altEndpoints[randId];
  isLocal && console.log('Using wax node', waxNode);
  return waxNode;
};
