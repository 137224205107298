import { get } from 'lodash';
import {
  BONUS_AMOUNT, claimLimits, HIGHEST_REWARD,
  KILLS_PER_LEVEL, PERCENT_WINS, STAKE_TO_CLAIM
} from '../../../../../config/appConfig';
import { isLocal } from '../../../../../constants/envs';
import { isObjectEmpty } from '../../../../../generalUtils/utils';
import { calcTotWins } from './utils';
import { getPendingRewardToClaim, getUnstakedSum } from './extractors';
import Swal from 'sweetalert2';


const THRESHOLDS = {
  MIN_CLAIMABLE_PER_DAY: claimLimits().minBoom,
  MAX_CLAIMABLE_PER_DAY_L0: claimLimits().maxBoomL0,
  MAX_CLAIMABLE_PER_DAY_L1: claimLimits().maxBoomL1,
  // MAX_CLAIMABLE_PER_DAY_L2: claimLimits().maxMarsL2,
  // MAX_CLAIMABLE_PER_DAY_L3: claimLimits().maxMarsL3,
};
const {
  MIN_CLAIMABLE_PER_DAY,
  MAX_CLAIMABLE_PER_DAY_L0, MAX_CLAIMABLE_PER_DAY_L1,
  // MAX_CLAIMABLE_PER_DAY_L2, MAX_CLAIMABLE_PER_DAY_L3,
} = THRESHOLDS;

  // 1/3 CHAMPION REQUIREMENTS CHECK
const checkEnoughWins = ({ totalWins = {}, matchCount }) => {
  const totWins = calcTotWins({ totalWins });
  return (totWins / matchCount) >= PERCENT_WINS;
};

export const isChampion = ({ sessionData }) => {
  const {
    totalWins, matchCount,
    unlockedFastKills, unlockedConsecutiveWins,
  } = sessionData;
  const hasEnoughWins = checkEnoughWins({ totalWins, matchCount });
  const wonBonus = hasEnoughWins && unlockedFastKills && unlockedConsecutiveWins; // ALL 3 REQUIREMENTS CHAMPIONS
  isLocal && console.debug('Is Champion?', { isChampion: wonBonus,
    reasons: { hasEnoughWins, unlockedFastKills, unlockedConsecutiveWins }, more: { totalWins, matchCount }});
  return wonBonus;
};

export const calcMaxBoomClaimable = ({ apiInfo, sessionData, unityLevel }) => {
  if (isObjectEmpty(apiInfo)) return 0;
  if (window.skippedResourcesPaym) return MAX_CLAIMABLE_PER_DAY_L0;
  const wonBonus = isChampion({ sessionData });
  const bonus = wonBonus ? BONUS_AMOUNT : 0;
  const wonCurrentLevel = sessionData.totalWins[`level${unityLevel}`] > 0;
  isLocal && console.debug('[react] max claimable input:',
    { unityLevel, wonCurrentLevel, wonBonus, bonus });
  if (!wonCurrentLevel) {
    const temp = Math.max(0, unityLevel - 1);
    const level = Math.min(1, temp); // BOOM: level 0 and 1
    return THRESHOLDS[`MAX_CLAIMABLE_PER_DAY_L${level}`];
  }
  switch (unityLevel) {
    case 0: return MAX_CLAIMABLE_PER_DAY_L0;
    case 1:
    default:
      return MAX_CLAIMABLE_PER_DAY_L1 + bonus; // Even if they're playing level 5, max 105 BOOM!
  }
};

// NOTE: when they go over their BOOM daily max claim, they cant claim MARS neither. (unless champion)
// Required otherwise the client-side 62 vs 90/105 threshold check fails.
const checkRewardsClaimRequirements = ({ apiInfo, sessionData, scholarCut, unityLevel }) => {
  // Extraction
  const boomClaimableRewards = getPendingRewardToClaim(apiInfo.boomRewardsToClaim);
  const marsClaimableRewards = getPendingRewardToClaim(apiInfo.marsRewardsToClaim);
  const allClaimableRewards = boomClaimableRewards + marsClaimableRewards;
  const totBoomClaimedForCurrentDay = get(apiInfo, 'transactionStats.totBoomClaimedForCurrentDay'); // eg. 0.75
  const dateOfLastClaim = get(apiInfo, 'transactionStats.dateOfLastClaim'); // eg. '2022-08-06'
  const lastClaimWasToday = dateOfLastClaim === new Date().toISOString().substring(0, 10);
  const claimedToday = lastClaimWasToday ? +(totBoomClaimedForCurrentDay || 0).toFixed(4) : 0;
  const totalKills = get(apiInfo, 'gameStats.level0.totalKills', 0)
    + get(apiInfo, 'gameStats.level1.totalKills', 0)
    + get(apiInfo, 'gameStats.level2.totalKills', 0) + get(apiInfo, 'gameStats.level3.totalKills', 0);
  const playerLevel = 1 + Math.floor(totalKills / KILLS_PER_LEVEL);
  const stakeToClaim = (playerLevel - 1) * STAKE_TO_CLAIM;
  const currentSavings = get(apiInfo, 'savings.boom.amount'); // eg. 1203.23
  const stakeAllowsToClaim = (currentSavings >= stakeToClaim);
  // Results
  const hasMinClaim = allClaimableRewards >= MIN_CLAIMABLE_PER_DAY;
  const yourCurrentMaxClaimable = calcMaxBoomClaimable({ apiInfo, sessionData, unityLevel });
  const playerRewards = scholarCut ? (allClaimableRewards * scholarCut / 100) : allClaimableRewards;
  const isBelowMaxDaily = (playerRewards + claimedToday) <= yourCurrentMaxClaimable;
  const isAboveStakeToClaim = (playerLevel < 2 || stakeAllowsToClaim);
  // OVERFLOW REQUIREMENTS
  let isChampionOverflow, isMostlyUnstaker;
  if ((playerRewards + claimedToday) > HIGHEST_REWARD) {
    const unlockedHighestThreshold = yourCurrentMaxClaimable === HIGHEST_REWARD;
    // (above max threshold and) unlocked the very max claim
    if (unlockedHighestThreshold && !window.skippedResourcesPaym) {
      isChampionOverflow = true; // NOTE: allowing scholar champion to withdraw 105 at the time. Is saving logic ok in BE?? ⚠️
    }
    // (above max threshold and) more than 50% is unstake -> reduce max claim in api call
    const unstakedSum = getUnstakedSum(get(apiInfo, 'boomRewardsToClaim.unstaked'));
    const mostlyUnstake = (unstakedSum / allClaimableRewards) > 0.5;
    if (!unlockedHighestThreshold && mostlyUnstake) {
      isMostlyUnstaker = true;
    }
  }
  isLocal && console.debug('--- claim requirements:',
  {
    hasMinClaim: { value: hasMinClaim, parts: {
      allClaimableRewards, MIN_CLAIMABLE_PER_DAY,
    }},
    isBelowMaxDaily: { value: isBelowMaxDaily, parts: {
      allClaimableRewards, claimedToday, lastClaimWasToday, yourCurrentMaxClaimable,
    }},
    isAboveStakeToClaim: { value: isAboveStakeToClaim, parts: {
      playerLevel, STAKE_TO_CLAIM, stakeAllowsToClaim, currentSavings, stakeToClaim,
    }},
    overflow: { isChampionOverflow, isMostlyUnstaker },
  });
  return ({ hasMinClaim, yourCurrentMaxClaimable, isBelowMaxDaily, isAboveStakeToClaim, isChampionOverflow, isMostlyUnstaker });
};

export const checkIsTokensClaimDisabled = ({ apiInfo, sessionData, scholarCut, unityLevel = 0 }) => {
  if (isObjectEmpty(apiInfo)) return ({ result: true });
  try {
    const args = { apiInfo, sessionData, unityLevel };
    if (scholarCut) { args.scholarCut = scholarCut; }
    const reasons = checkRewardsClaimRequirements(args);
    const { hasMinClaim, isBelowMaxDaily, isAboveStakeToClaim, isChampionOverflow, isMostlyUnstaker } = reasons;
    const canOverflow = isChampionOverflow || isMostlyUnstaker; // isMostlyUnstaker -> enable and BE reduced claim
    const shouldEnableClaimBtn = hasMinClaim && isAboveStakeToClaim && (isBelowMaxDaily || canOverflow);
    isLocal && console.debug('Claim btn disabling debug:',
      { shouldEnableClaimBtn }, { hasMinClaim, isAboveStakeToClaim, isBelowMaxDaily, canOverflow },
    );
    const claimBtn = document.getElementById('claim-boom');
    if (shouldEnableClaimBtn) {
      isLocal && console.log('Enabling claim btn. Allowing player to claim.');
      // NOTE: Leave these to avoid fast clickers to claim before api response
      claimBtn && claimBtn.removeAttribute('disabled');
      claimBtn && claimBtn.removeAttribute('bigger'); // anti-cheat
      return ({ result: false, reasons });
    } else {
      claimBtn && claimBtn.setAttribute('disabled', true);
      claimBtn && claimBtn.setAttribute('bigger', '1.02x'); // anti-cheat
      return ({ result: true, reasons });
    }
  } catch (err) {
    console.error('check boom claim disabled broke:', err);
    Swal.fire({ text: `Something broke in claim prep logic. Please report to support.` })
  }
};
