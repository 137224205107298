import React from 'react';

const CHAIN_ATTRIBUTES = {
  HIVE: [
    'FAST. ZERO fees.',
    'Truly Decentralized.',
    'Your username is your wallet.',
  ],
  WAX: [
    'No Transaction Fees.',
    'Top Gaming Chain.',
    'Easy To Use.',
  ],
  BTC: [
    'Cheap Transaction Fees.',
    'High Liquidity.',
    'Wide Accessibilty.',
  ],
  SKL: [
    'The Fastest.',
    'No Fees.',
    'High Liquidity.',
  ],
};

const getAttribute = ({ chain = '', index }) => {
  const attrs = CHAIN_ATTRIBUTES[chain.toUpperCase()] || [];
  return attrs[index] || null;
};

export const ChainProps = ({ chain = '' }) => {
  return (
    <div style={{ fontSize: '13px', marginTop: '5px' }}>
      <span>{getAttribute({ chain, index: 0 })}</span><br />
      <span>{getAttribute({ chain, index: 1 })}</span><br />
      <span>{getAttribute({ chain, index: 2 })}</span><br />
    </div>
  );
};
