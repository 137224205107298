import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLocal } from '../../../constants/envs';

import { fetchDoomBalance, validateHiveUsername } from '../../../sagas/helpers/wallet/hiveUtils';
import { nap } from '../../../generalUtils/utils';
import { LoadingSpinner } from '../../../images/gifs/LoadingSpinner';
import { fetchBoomBalance } from '../../../sagas/helpers/wallet/waxUtils';
import { validateDoomToBoomInput, sendSwapToBackend, transferDoomToBurner, validateBoomToDoomInput, transferBoomToBurner } from './SwapUtils/swapCore';
import { updateAllBalances } from '../utils';
import { MinSwapAmounts } from './MinSwapAmounts';
import { isFeatureEnabled } from '../../../config/featureFlags';
import { triggerSwapAlert } from '../../../services/monitoring/discordNotifications';
import { SWAP_RANGES } from './SwapUtils/constants';
import { warmupLambdas } from '../../../services/api/warmup';
import { KeychainSwapWidget } from './KeychainSwapWidget';


export const Swaps = ({ chainLogin }) => {
  const UserState = useSelector((store) => store.user);
  const waxUsername = UserState.name || '';

  const InventoryState = useSelector((store) => store.inventory);
  const hiveData = get(InventoryState, 'hive', {});
  const { username: hiveUsername } = hiveData;
  
  const authenticatedUser = chainLogin === 'hive' ? hiveUsername : waxUsername;

  // doom -> boom
  const [amountDoomSwap, setAmountDoomSwap] = useState(undefined);
  const [doomSenderSwap, setDoomSenderSwap] = useState('');
  const [boomReceiverSwap, setBoomReceiverSwap] = useState('');
  // boom -> doom
  const [amountBoomSwap, setAmountBoomSwap] = useState(undefined);
  const [boomSenderSwap, setBoomSenderSwap] = useState('');
  const [doomReceiverSwap, setDoomReceiverSwap] = useState('');
  // set sender username and balance
  const [altAccObj, setAltAccObj] = useState({ name: '', balance: 0 });

  let cachedName;
  let cachedSymbol;

  const fetchBalance = async (name = '', tokenId = '') => {
    try {
      // ## prevent spamming username check requests
      if (name.length < 5) return;
      cachedName = name;
      cachedSymbol = tokenId;
      await nap(500);
      if (cachedName !== name) return; // name changed during wait
      // ## fetches
      if (tokenId === 'B' && chainLogin === 'hive') {
        // validation
        if (name.endsWith('.wam') || name.endsWith('.gm') || name.length === 12) {
          const boomAmount = await fetchBoomBalance(name);
          if (boomAmount) setAltAccObj({ name: name, balance: (+boomAmount).toFixed(4) });
          isLocal && console.debug('Alt acc data found on input change:', { boomAmount });
        }
      }
      else if (tokenId === 'D' && chainLogin === 'wax') {
        // validation
        const { err } = await validateHiveUsername({ username: name });
        if (!err) {
          const doomAmount = await fetchDoomBalance({ username: name });
          if (doomAmount) setAltAccObj({ name: name, balance: (+doomAmount).toFixed(4) });
          isLocal && console.debug('Alt acc data found on input change:', { doomAmount });
        }
      }
    } catch (err) {
      console.error('Caught fetching alt acc balances for swap:', err);
    }
  };

  const clearErrors = () => {
    if (doomSwapStatus.err) setSwapStatus(initialSwapState);
  };

  useEffect(() => {
    const setDefaultValues = async () => {
      if (chainLogin === 'hive') {
        isLocal && console.debug('Autofilling swap form:', { hiveUsername, waxUsername });
        setDoomSenderSwap(hiveUsername);
        setDoomReceiverSwap(hiveUsername);
        waxUsername && fetchBalance(waxUsername, 'B');
      }
      else if (chainLogin === 'wax') {
        isLocal && console.debug('Autofilling swap form:', { hiveUsername, waxUsername });
        setBoomSenderSwap(waxUsername);
        setDoomReceiverSwap(hiveUsername);
        setDoomSenderSwap(hiveUsername);
        setBoomReceiverSwap(waxUsername);
        hiveUsername && fetchBalance(hiveUsername, 'D');
      }
    };
    setDefaultValues();
  }, []);

  const initialSwapState = { loading: false, err: '', criticalErr: '' };
  const [doomSwapStatus, setSwapStatus] = useState(initialSwapState);

  const swapDoomForBoom = async () => {
    if (!navigator.onLine) {
      alert('Please make sure you have a stable Internet connection before initiating swaps.');
      return;
    }
    setSwapStatus({ loading: true });
    warmupLambdas(); // avoid cold boot for swap
    const startTime = Date.now();
    // VALIDATIONS
    const { err: validationErr } = await validateDoomToBoomInput({
      amountDoomSwap, doomSenderSwap, boomReceiverSwap,
    });
    if (validationErr) {
      isLocal && console.debug('Swap validation error:', validationErr);
      setSwapStatus({ loading: false, err: validationErr });
      return;
    }
    // TRIGGER TRANSFER
    isLocal && console.debug('Initating DOOM transfer:', { amountDoomSwap, doomSenderSwap, boomReceiverSwap });
    const { err: doomTransferErr, notify, errDetails, hiveTx, blockNum, seconds } = await transferDoomToBurner({ amountDoomSwap, doomSenderSwap });
    if (doomTransferErr) {
      isLocal && console.debug('DOOM transfer error:', doomTransferErr);
      notify !== false && triggerSwapAlert({ type: 'doom-transfer-and-scan',
        requestArgs: { amountDoomSwap, doomSenderSwap, boomReceiverSwap },
        response: { doomTransferErr, errDetails, hiveTx, blockNum, seconds },
      });
      setSwapStatus({ loading: false, txSent: hiveTx, err: doomTransferErr, errDetails: JSON.stringify(errDetails) });
      return;
    }

    // SEND TX TO BACKEND
    const startTs = Date.now();
    const { err: doomBeErr, txRec } = await sendSwapToBackend({
      hiveSender: doomSenderSwap, hiveTx, waxUsername: boomReceiverSwap, hiveBlockNum: blockNum,
      authenticatedUser,
    });
    if (doomBeErr) {
      const endTs = Date.now();
      triggerSwapAlert({ type: 'be-doom-swap',
        times: { start: startTs, end: endTs, durationMs: endTs - startTs },
        requestArgs: { doomSenderSwap, hiveTx, boomReceiverSwap, blockNum, authenticatedUser },
        response: { doomBeErr, txRec },
      });
      setSwapStatus({ loading: false, txSent: `https://hiveblocks.com/tx/${hiveTx}`, criticalErr: true, errDetails: JSON.stringify(doomBeErr) });
      return;
    }
    const totalTimeSwap = ((Date.now() - startTime) / 1000).toFixed(0);
    setSwapStatus({ loading: false, txSent: `https://hiveblocks.com/tx/${hiveTx}`, txRec, seconds: totalTimeSwap });
    isLocal && console.debug('Success args:', { hiveTx, txRec, totalTimeSwap });

    // REFRESH ALL BALANCES
    await nap(1000);
    updateAllBalances({ hiveUsername });
    fetchBalance(cachedName, cachedSymbol); // for balance preview in form
  };

  const swapBoomForDoom = async () => {
    if (!navigator.onLine) {
      alert('Please make sure you have a stable Internet connection before initiating swaps.');
      return;
    }
    setSwapStatus({ loading: true });
    warmupLambdas(); // avoid cold boot for swap
    const startTime = Date.now();
    // VALIDATIONS
    const { err: validationErr } = await validateBoomToDoomInput({
      amountBoomSwap, boomSenderSwap, doomReceiverSwap,
    });
    if (validationErr) {
      isLocal && console.debug('Swap validation error:', validationErr);
      setSwapStatus({ loading: false, err: validationErr });
      return;
    }
    // TRIGGER TRANSFER
    isLocal && console.debug('Initating BOOM transfer:', { amountBoomSwap, boomSenderSwap, doomReceiverSwap });
    const { err: boomTransferErr, errDetails, waxTx, seconds } = await transferBoomToBurner({
      amountBoomSwap, boomSenderSwap,
    });
    if (boomTransferErr) {
      // @@ triggerSwapAlert({ type: 'transf', nftId, errDetails, user: UserState.username, err: errTransfer, tx, block, seconds });
      isLocal && console.debug('BOOM transfer error:', boomTransferErr, errDetails);
      setSwapStatus({ loading: false, txSent: waxTx, err: boomTransferErr, errDetails: JSON.stringify(errDetails) });
      return;
    }

    // SEND TX TO BACKEND
    const { err: boomBeErr, txRec } = await sendSwapToBackend({
      waxSender: boomSenderSwap, waxTx, hiveUsername: doomReceiverSwap,
      authenticatedUser,
    });
    if (boomBeErr) {
      triggerSwapAlert({ type: 'be-boom-swap',
        swapRequest: { boomSenderSwap, amountBoomSwap, waxTx, doomReceiverSwap, authenticatedUser },
        apiResponse: { boomBeErr, txRec },
      });
      setSwapStatus({ loading: false, txSent: `https://wax.bloks.io/transaction/${waxTx}`, criticalErr: true, errDetails: JSON.stringify(boomBeErr) });
      return;
    }
    const totalTimeSwap = ((Date.now() - startTime) / 1000).toFixed(0);
    isLocal && console.debug('Success args:', { waxTx, txRec, totalTimeSwap });
    setSwapStatus({ loading: false, txSent: `https://wax.bloks.io/transaction/${waxTx}`, txRec, seconds: totalTimeSwap });

    // REFRESH ALL BALANCES
    await nap(1000);
    updateAllBalances({ hiveUsername });
  };

  if (!isFeatureEnabled('SWAPS')) return (
    <div className="col-12 text-center container-left flashy-box">
      <p className="text-white" style={{ fontSize: '20px', marginTop: '-10px' }}>SWAPS</p>
      <div className="flashy-box">
        <div className="row text-white">
          <p className="col-12">Coming VERY SOON !!</p>
        </div>
      </div>
    </div>
  );

  return (<div className="col-12 text-center container-left flashy-box">
    <p className="text-white" style={{ fontSize: '20px', marginTop: '-10px' }}>SWAPS</p>
    <div className="flashy-box">
      <div className="row">
        {chainLogin === 'hive' && <div
            className="col-4 mt-2"
            style={{
              width: '310px', height: '345px', padding: 0, overflow: 'hidden', position: 'relative', marginLeft: '10px',
              borderRadius: '40px',
            }}
          >
            <KeychainSwapWidget hiveUsername={hiveUsername} />
          </div>}

        <div className="col-6" style={{ borderLeft: 'thin solid white', paddingLeft: '15px', paddingRight: 0, marginLeft: '10px' }}>
          <div className="col-12 mt-4" style={{ LeftpaddingBottom: '10px' }}>
            <div className="" style={{ padding: '0px' }}>
              <div className="row">
                <div className="col-6">
                  <div className="btn-send">
                    <button
                      className="btn btn-success" style={{ marginBottom: '5px', width: '100%', fontSize: '16px' }}
                      onClick={swapDoomForBoom}
                      disabled={doomSwapStatus.loading}
                    >DOOM->$BOOM</button>
                  </div>
                  <input
                    id="doom-sender-swap" placeholder='DOOM SENDER' type='text'
                    style={{ width: '140px', fontSize: '14px' }}
                    value={doomSenderSwap}
                    onChange={(e) => { setDoomSenderSwap(e.target.value); fetchBalance(e.target.value, 'D'); }}
                  ></input>
                  <input
                    id="amount-doom-swap" placeholder='DOOM AMOUNT' type='number'
                    style={{ width: '140px', fontSize: '14px' }}
                    value={amountDoomSwap}
                    onChange={(e) => { setAmountDoomSwap(+e.target.value); clearErrors(); }}
                  ></input><br />
                  <input
                    id="boom-receiver-swap" placeholder='$BOOM RECIPIENT' type='text'
                    style={{ width: '140px', fontSize: '14px', marginTop: '10px' }}
                    value={boomReceiverSwap}
                    onChange={(e) => { setBoomReceiverSwap(e.target.value); fetchBalance(e.target.value, 'B'); }}
                  ></input><br />
                  {!!amountDoomSwap && <p className="text-white">
                    ..receives {amountDoomSwap * (1 / SWAP_RANGES.BD_RATIO) - amountDoomSwap * (1 / SWAP_RANGES.BD_RATIO) * SWAP_RANGES.FEE} $BOOM
                  </p>}
                </div>
                <div className="col-6">
                  <div className="btn-send">
                    <button
                      className="btn btn-success" style={{ marginBottom: '5px', width: '100%', fontSize: '16px' }}
                      onClick={swapBoomForDoom}
                      disabled={doomSwapStatus.loading}
                    >$BOOM->DOOM</button>
                  </div>
                  <input
                    id="boom-sender-swap" placeholder='$BOOM SENDER' type='text'
                    style={{ width: '140px', fontSize: '14px' }}
                    value={boomSenderSwap}
                    onChange={(e) => { setBoomSenderSwap(e.target.value); fetchBalance(e.target.value, 'B'); }}
                  ></input>
                  <input
                    id="amount-boom-swap" placeholder='$BOOM AMOUNT' type='number'
                    style={{ width: '140px', fontSize: '14px' }}
                    value={amountBoomSwap}
                    onChange={(e) => { setAmountBoomSwap(+e.target.value); clearErrors(); }}
                  ></input><br />
                  <input
                    id="doom-receiver-swap" placeholder='DOOM RECEIVER' type='text'
                    style={{ width: '140px', fontSize: '14px', marginTop: '10px' }}
                    value={doomReceiverSwap}
                    onChange={(e) => { setDoomReceiverSwap(e.target.value); fetchBalance(e.target.value, 'D'); }}
                  ></input><br />
                  {!!amountBoomSwap && <p className="text-white">
                    ..receives {amountBoomSwap * SWAP_RANGES.BD_RATIO - amountBoomSwap * SWAP_RANGES.BD_RATIO * SWAP_RANGES.FEE} DOOM
                  </p>}
                </div>
                <div className="col-12" style={{ fontSize: '13px !important', margin: '5px auto -5px' }}>
                  {doomSwapStatus.loading && <p style={{ color: 'yellow' }}>
                    SWAP in progress. <b>DO NOT CLOSE</b><br/>this tab until complete. <sub>(takes about {!!amountDoomSwap ? 60 : 5}s)</sub>
                  </p>}
                  {doomSwapStatus.err && <p style={{ color: 'orange' }}>Error: {doomSwapStatus.err}<br/><sub><sub>{doomSwapStatus.errDetails?.slice(0, 250)}</sub></sub></p>}
                  {doomSwapStatus.criticalErr && <p style={{ color: 'orange' }}>
                      Uh oh, SWAP failed. Reach out to our mods. {doomSwapStatus.txSent && <span>Tx: <a
                        href={doomSwapStatus.txSent} target="_blank" rel="noreferrer noopener"
                      >{doomSwapStatus.txSent.split('/').pop()}</a></span>}
                      {doomSwapStatus.errDetails && <sub className="text-black"><br/><sub>{doomSwapStatus.errDetails?.slice(0, 250)}</sub></sub>}
                    </p>}
                  {doomSwapStatus.txRec && !doomSwapStatus.loading && !doomSwapStatus.err && <p
                    style={{ color: '#51ff51', marginTop: '-15px' }}
                    className="flashy-box"
                  >
                    Success ✔️ <sub><sub>(took {doomSwapStatus.seconds}s)</sub></sub><br/>
                    <sub>Sent tx <a
                        href={doomSwapStatus.txSent} target="_blank" rel="noreferrer noopener"
                      >{doomSwapStatus.txSent.slice(-20)}</a><br/>Received tx <a
                      href={doomSwapStatus.txRec} target="_blank" rel="noreferrer noopener"
                    >{doomSwapStatus.txRec.slice(-20)}</a></sub>
                    </p>
                  }
                </div>
              </div>
            </div>
          </div>

          {chainLogin !== 'hive'
            ?
              <div className='text-white mt-3'>
                {!!altAccObj.balance && <p style={{ fontSize: '14px', lineHeight: '18px' }}>
                  <a
                    href={`https://wax.bloks.io/account/${altAccObj.name}`}
                    target="_blank" rel="noreferrer noopener"
                  >
                    {altAccObj.name}
                  </a>'s<br/>$BOOM balance:<br/>
                  <span id="boom-balance-wallet"><b>{altAccObj.balance}</b></span>
                </p>}
                <p style={{ marginTop: '5px' }}>
                  <a className="alcor-buy-boom"
                    href="https://wax.alcor.exchange/trade/boom-csboomcsboom_wax-eosio.token"
                    target="_blank" rel="noreferrer noopener"
                  >
                    BUY $BOOM
                  </a>
                </p>
                <MinSwapAmounts {...{ chainLogin, amountBoomSwap, amountDoomSwap }}/>
              </div>
            : <div className='text-white mt-3'>
                {!!altAccObj.balance && <p style={{ fontSize: '14px', lineHeight: '18px' }}>
                  <a
                    href={`https://peakd.com/@${altAccObj.name}`}
                    target="_blank" rel="noreferrer noopener"
                  >
                    @{altAccObj.name}
                  </a>'s<br/>DOOM balance:&nbsp;
                  <span id="boom-balance-wallet"><b>{altAccObj.balance}</b></span>
                </p>}
                <p style={{ marginTop: '5px' }}>
                  <a className="he-buy-doom"
                    href="https://hive-engine.com/trade/DOOM"
                    target="_blank" rel="noreferrer noopener"
                  >
                    BUY DOOM
                  </a>
                </p>
                <MinSwapAmounts {...{ chainLogin, amountBoomSwap, amountDoomSwap }}/>
              </div>
          }
        </div>
        {doomSwapStatus.loading && <LoadingSpinner
            style={{ margin: '-60px auto auto 80px', border: 'none', borderRadius: '10px', width: '80px' }}
          />}
      </div>
    </div>
  </div>);
};
