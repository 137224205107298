import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { fixMintNumber } from './utils/minMint';
import { AccountLinking } from './AccountLinking';
import { capitalize, getPlayerUsername } from '../../../generalUtils/utils';
import { linkedHiveAccount } from '../../../reducers/InventoryReducer';
import { storeAppDispatch } from '../../../GlobalState/Store';
import './HiveInventory.scss';
import { unlinkAccount } from '../../../sagas/helpers/login/linking';
import { useState } from 'react';


const fixDescription = ({ schema, name }) => {
  if (schema === 'game.items' && name === 'Force Field, level-1') return 'Life shield against the boss of the 2nd level.';
  return 'n/a';
};

const NftData = ({ data }) => {
  const { mint, img, name, previousOwner, schema, description } = data;
  const mintNumber = fixMintNumber({ name, mint });
  const underscoredName = name.replace(/ /g, '_');
  const s3Img = `https://play.crypto-shots.com/nft-images/hive/${schema}/${underscoredName}.gif`;
  return (
    <div
      key={`${schema}${mintNumber}`}
      className="hive-nft-tile"
    >
        {img && <a
            href={`https://gateway.pinata.cloud/ipfs/${img}`}
            target="_blank" rel="noreferrer"
            className="nft-image-wrap"
          >
            <div>
              <img width="160px" src={s3Img} alt={`${name} hive nft`}></img>
            </div>
          </a>}
        <div id="tile-details">
          <div id="mint-and-name">
            <p className="bolder text-center">{name}</p>
            <p className="text-center">MINT # {mintNumber}</p>
          </div>
          <div id="nft-details">
            <p className="schema">
              <span className="bold">Schema:</span> {schema}
            </p>
            <p className="decent-lines">
              <span className="bold">Prev. owner:</span> {previousOwner || 'n/a'}
            </p>
            <p className="decent-lines">
              <span className="italic">{description || fixDescription({ schema, name })}</span>
            </p>
          </div>
        </div>
    </div>
  );
};

export const BuyHiveNFts = () => {
  return (<div className="buy-more row">
    <div className="col-6">
      <span className="bold">TRADE OUR NFTs</span> &nbsp; | &nbsp; <span className="bold">OPEN PACKS</span>
      <br/>on &nbsp;
      <a
        href="https://hive.crypto-shots.com" target="_blank" rel="noreferrer"
        style={{ lineHeight: '22px' }}
      >
        hive.crypto-shots.com
      </a>
    </div>
    <div className="col-6">
    <span className="bold">TRADE OUR NFTs and PACKS</span><br/>on &nbsp;
      <a
        href="https://nftm.art/buy/cshots" target="_blank" rel="noreferrer"
        style={{ lineHeight: '22px' }}
      >
        NFTM.ART
      </a>
    </div>
  </div>);
};


const extractCategories = ({ hiveNfts = [] }) => {
  const categories = [];
  hiveNfts.forEach(nft => { categories.push(nft.schema); });
  const noDupes = Array.from(new Set(categories));
  return noDupes.reverse();
}

const parseSchemaName = (category = '') => {
  const [part1, part2] = category.split('.');
  return `${capitalize(part1)} ${capitalize(part2) || ''}`;
}

const HiveItems = () => {
  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;

  const InventoryState = useSelector((store) => store.inventory);
  const hiveData = get(InventoryState, 'hive', {});
  const { nfts: hiveNfts, username: hiveUsername, teacher } = hiveData;
  const foundCategories = extractCategories({ hiveNfts });

  const [unlinkWip, setUnlinkWip] = useState(false);

  const unlinkChain = async () => {
    setUnlinkWip(true);
    try {
      const currentAuthUser = getPlayerUsername({ UserState, InventoryState });
      const ok = window.confirm(`Are you sure?\n\nUnlink your HIVE account ${hiveUsername
        }\nfrom your ${chainLogin.toUpperCase()} account ${currentAuthUser}?`);
      if (!ok) return;

      const { result, err, hiveErr } = await unlinkAccount({
        currentAuthUser, inputAccount: hiveUsername, tab: 'hive',
      });
      // Unlink Failure
      if (err) alert(`Failed to unlink Hive account ${hiveUsername}: ${err}`);
      // Unlink Success
      else {
        storeAppDispatch(linkedHiveAccount({ hiveAccount: null }));
        // Hive DB Failure
        if (hiveErr) alert('Hive account unlinked in our Database but not in Hive DB. Please contact support.');
        // Hive DB Success
        else console.log('Hive account unlink success - Hive tx:', result);
      }
    } catch (err) {
      console.error('Something broke unlinking chain:', err);
      alert('Uh oh, something broke..');
    } finally {
      setUnlinkWip(false);
    }
  };

  return (
    <div className="black-shade">
      <div className="col-12 fixed-header row">
        <div className="col-6 text-center" id="hive-inventory-title">
            <span id="nft-inventory-label">MY NFTs</span>
            {hiveUsername && <span>
                <a
                  href={`https://peakd.com/@${hiveUsername}`} target="_blank" rel="noreferrer"
                >@{hiveUsername}
                </a>
                <button
                  style={{
                    backgroundColor: 'white', color: 'red', width: '140px', float: 'right',
                    margin: 'auto', marginTop: '0px', fontSize: '14px', fontWeight: 'bold',
                    minHeight: '25px',
                    display: chainLogin !== 'hive' ? 'block' : 'none',
                  }}
                  onClick={unlinkChain}
                >
                  {unlinkWip
                    ? <i class="fa fa-spinner fa-spin" style={{ fontSize: '20px', minHeight: '35px', color: 'red' }}></i>
                    : <span>UNLINK ACCOUNT</span>}
                </button>
              </span>}
            {teacher && <span id="nft-from"><br/>
                (borrowed from @<a
                  href={`https://peakd.com/@${teacher}`} target="_blank" rel="noreferrer"
                >{teacher}
              </a>)
            </span>
            }
        </div>
        <div className="col-6">
          <BuyHiveNFts />
        </div>
      </div>
      <br/><br/><br/><br/>
      <div className="col-12 hive-nfts-panel">
        {hiveNfts.length
          ? foundCategories.map(category => <>
            <div className="category-name">
              {parseSchemaName(category)}
            </div>
            <p>
              {hiveNfts.filter(({ schema }) => schema === category).map(nft => <NftData data={nft} />)}
            </p>
          </>)
          : <div className="color-white">
              <h3 id="no-hive-nfts">No Hive NFTs found</h3>
              <p id="disclaimer-sell">Note: Hive NFTs listed for sale won't appear here.</p>
            </div>
        }
      </div>
    </div>
  );
};

export const HiveInventory = () => {
  const InventoryState = useSelector((store) => store.inventory);
  const { username } = get(InventoryState, 'hive', {});

  return (
    <div
      className="container hive-inventory-container"
    >
      {username
        ? <HiveItems/>
        : <>
            <br />
            <div style={{ width: '450px', margin: 'auto' }}>
              <AccountLinking tab="hive"/>
            </div>
          </>
      }
    </div>
  );
};
