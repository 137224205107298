
// Eg. makeNotWritable({ parent: window, target: 'someObj' });
export const makeNotWritable = ({ parent = {}, target = '' }) => {
  try {
    const pristine = parent[target];
    if (!parent || !target || !pristine) {
      const stack = new Error().stack;
      console.error('Unable to make unwritable. Argument missing!', stack);
      return;
    }
    try {
      Object.freeze(pristine);
      Object.defineProperty(parent, target, { value: pristine, writable: false });
    } catch (_) {}
  } catch (err) {
    const stack = new Error().stack;
    console.error('Caught error in makeNotWritable', stack);
  }
};
