
export const MINUTES = 60 * 1000;
export const HOURS = 60 * MINUTES;

// # WHITELIST
export const freeTrippers = [
  'crypto4shots',
  'boomfungible',
];

// # OTHERS
export const TRAVEL_TX_ID_PREFIX = 'travelTxHash'; // eg. 'travelTxHash-L0'
export const MEAL_TX_ID_PREFIX = 'mealTxHash';
