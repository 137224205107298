import { JoyStick } from './controllersLib';
import { JOY_CONFIG, JOYSTICK_CHECK_RATE } from './config';
import { isSmartphoneOrTablet, startPressingKey, stopPressingKey } from './messages';
import { makeNotWritable } from '../../../generalUtils/security/utils';
import { isLocal } from '../../../constants/envs';
import { windowSetChecksum } from '../../../generalUtils/secure/checksums';

window.joysticks = {};

let joySx;
let joyDx;

let joysticksTs;
let mobMatchCount = 0;
let element;


// ## STOP
export const stopJoysticks = () => {
  if (!isSmartphoneOrTablet) return;
  clearInterval(joysticksTs);
  element.style.display = 'none';
}
window.joysticks.stop = stopJoysticks;


// ## STATUS
const readJoyStatus = () => {
  // left controller - WASD
  const leftDir = joySx.GetDir();
  processWasdDir(leftDir);
  // console.log(mobMatchCount, '[O][.] JOY SX:', leftDir);

  // right controller
  const rightDir = joyDx.GetDir();
  // console.log(mobMatchCount, '[.][O] JOY dX:', rightDir);
};


// ## START / RESUME
export const startJoysticks = () => {
  if (!isSmartphoneOrTablet) return;
  mobMatchCount++;
  joysticksTs = setInterval(readJoyStatus, JOYSTICK_CHECK_RATE);
};
window.joysticks.start = startJoysticks;


// ## INIT
export const initMobileControllers = () => {
  element = document.getElementById('joysticks');
  element.style.display = 'block';
  joySx = new JoyStick('joyDivSx', JOY_CONFIG);
  joyDx = new JoyStick('joyDivDx', JOY_CONFIG);
  startJoysticks();
};


// ## RESTART TEST @@
// setTimeout(() => {
//   console.log('RESTARTING JOYSTICKS ----------');
//   stopJoysticks();
//   startJoysticks();
// }, 5 * 1000);

// (secured)
makeNotWritable({ parent: window, target: 'joysticks' });
windowSetChecksum({ key: 'joysticks', value: window.joysticks });

// ------------------------------


// ## PROCESSING

let lastPress = []; // eg. ['W', 'D']
let lastDirection = 'C';

const getLetters = ({ direction = '' }) => {
  const directions = direction.split('');
  return directions.map((letter) => {
    switch (letter) {
      case 'N': return 'W'; case 'W': return 'A'; case 'S': return 'S'; case 'E': return 'D';
      default: { throw new Error(`Unexpected letter: ${letter}`); }
    }
  });
};

const cancelUnusedKeyPresses = ({ letterArr = [] } = {}) => {
  lastPress.forEach((oldLetter) => {
    if (!letterArr.includes(oldLetter)) {
      stopPressingKey({ letter: oldLetter });
      lastPress = lastPress.filter(existingLetter => existingLetter !== oldLetter);
      isLocal && console.log(`Stopped pressing ${oldLetter}. Last presses without old ones:`, lastPress);
    }
  });
};

const dispatchKeyPresses = ({ letterArr }) => {
  letterArr.forEach((letter) => {
    if (lastPress.includes(letter)) return; // already pressed
    startPressingKey({ letter });
    lastPress.push(letter);
    isLocal && console.log(`Started pressing ${letter}. Updated last presses:`, lastPress);
  });
};

const processWasdDir = (direction) => {
  // Logging
  if (lastDirection !== direction && isLocal) console.log('New direction detected:', direction);
  lastDirection = direction;
  // Events
  switch (direction) {
    case 'N': case 'NE': case 'E': case 'SE': case 'S': case 'SW': case 'W': case 'NW': {
      const letterArr = getLetters({ direction });
      cancelUnusedKeyPresses({ letterArr });
      dispatchKeyPresses({ letterArr });
      break;
    }
    case 'C': {
      cancelUnusedKeyPresses();
      lastPress = [];
      break;
    }
    default: console.error('Unexpected direction', direction);
  }
};
