import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLocal } from '../../../../constants/envs';
import { REMOTE_JSON_PATH } from '../../Prizes/config';
import { PVP_BANNER_SRC, TOURNAMENTS_CONFIG_FILENAME } from './config';
import { PrizesCurrency } from '../Shared/PrizesCurrency';
import './PvpPrizes.scss';


export const PvpPrizes = () => {
  const UserState = useSelector((store) => store.user);
  const chainLogin = localStorage.getItem('chainParam') || UserState.chainLogin;
  // const [chainData, setChainData] = useState({ chainLogin: chainLogin.toUpperCase() });
  
  const [tournamentsConfig, setTournamentsConfig] = useState({});

  useEffect(() => {
    // Fetch Prizes Config
    const fetchPvePrizes = async () => {
      const url = isLocal
        ? `./${TOURNAMENTS_CONFIG_FILENAME}` // uses package.json > proxy
        : `${REMOTE_JSON_PATH}/${TOURNAMENTS_CONFIG_FILENAME}`;
      try {
        const json = await fetch(url).then(res => res.json());
        setTournamentsConfig(json);
      } catch (err) { console.error(`Unable to fetch pve prizes: ${err}`); }
    };
    fetchPvePrizes();
  }, []);

  return (
    <div id="PvpPrizes" className="row">
      <div className="col-12">
        <p id="prizes-main-title">Automated <span className="bold">PVP</span> CRYPTO PRIZES, on top of our tokens</p>
      </div>
      <div className="col-12 row">
        <PvpBanner />
        <PvpInfo {...{ chainLogin, tournamentsConfig }} />
      </div>
      <div className="col-12">
        <HostedTourneys {...{ tournamentsConfig, chainLogin }} />
        <HeadlessTourneys {...{ tournamentsConfig, chainLogin }} />
      </div>
      <div className="col-6">
      </div>
    </div>
  );
};

const REWARD_PER_WIN = 1;

const PvpInfo = ({ chainLogin = '', tournamentsConfig = {} }) => {
  const streamers = tournamentsConfig.tournaments?.map(
    ({ streamer, IS_TEST } = {}) => {
      const { displayName, streamUrl = '' } = streamer;
      return displayName && !IS_TEST && ({ name: displayName, url: streamUrl.replace('https://', '').replace('www.', '') })
    }).filter(el => !!el);
  return (
    <div className="col-6 row tourney-group">
      <p>REWARD for each 5-min Tournament match:
        <br/><span className="green bold">{REWARD_PER_WIN} <PrizesCurrency chainLogin={chainLogin}/></span></p>
      <p>REQUIREMENTS to earn:
        <br/>- PvP Pass, unless Free-To-Earn
        <br/>- 4 mins match, at least 1.0 KDR, your team won</p>
      <p>STREAMERS:
        {
          streamers?.map(
            ({ name, url } = {}) => <div key={name}>
                - <span className="bold">{name}</span> @ <a href={`https://${url
                }`} target="_blank" rel="noreferrer">{url}</a>
              </div>
          )
        }
      </p>
    </div>
  );
};

const PvpBanner = () => {
  return (
    <div id="pvp-banner" className="col-6">
      <br/>
      <img src={PVP_BANNER_SRC} alt="pvp-banner"/>
    </div>
  );
};

const HostedTourneys = ({ tournamentsConfig = {}, chainLogin = '' }) => {
  const allTourneys = tournamentsConfig.tournaments || [];
  const hostedTourneys = allTourneys.filter(tourney => !tourney.IS_TEST && tourney.headless);
  const { sponsor = {} } = hostedTourneys[0] || {};
  const { sponsorSymbol, projectName, sponsorDiscordInvite: discord } = sponsor;

  return (
    <div className="col-12 row tourney-group mt-2">
      <p className="tourney-category">
        Weekly Hosted Tournaments
        <div className="sponsor"><span className="bold">SPONSOR REWARD:</span> $<a href={
            `https://hive-engine.com/trade/${sponsorSymbol}`} target="_blank" rel="noreferrer"
          >
            {sponsorSymbol}
          </a>
          <br/>from <a href={discord} target="_blank" rel="noreferrer">{projectName}</a>
        </div>
      </p>
      {hostedTourneys.map(tourney => <TourneyDetails {...{ tourney, chainLogin }}/>)}
    </div>
  );
};

const HeadlessTourneys = ({ tournamentsConfig = {}, chainLogin = '' }) => {
  const allTourneys = tournamentsConfig.tournaments || [];
  const headlessTourneys = allTourneys.filter(tourney => !tourney.IS_TEST && !tourney.headless);
  const { sponsor = {} } = headlessTourneys[0] || {};
  const { sponsorSymbol, projectName, sponsorDiscordInvite: discord } = sponsor;
  return (
    <div className="col-12 row tourney-group mt-2">
      <p className="tourney-category">
        Weekly Headless Tournaments
        <div className="sponsor"><span className="bold">SPONSOR REWARD:</span> $<a href={
            `https://hive-engine.com/trade/${sponsorSymbol}`} target="_blank" rel="noreferrer"
          >
            {sponsorSymbol}
          </a>
          <br/>from <a href={discord} target="_blank" rel="noreferrer">{projectName}</a>
        </div>
      </p>
      {headlessTourneys.map(tourney => <TourneyDetails {...{ tourney, chainLogin }}/>)}
    </div>
  );
};

const TourneyDetails = ({ tourney = {}, chainLogin = '' }) => {
  const {
    day = '', start,
    headless = false,
    streamer = {},
    sponsor = {},
    rewardMultiplier: multi = 1,
    tournamentRegionOverride = 'USW',
    autoRewardsOnTourneyEnd, // false,
    raffleEnabled, // false,
    skipPvpPassCheck, // false
    anyoneCreateCustomRoom, // false,
  } = tourney;

  const [hh, mm, ss] = start.split(':');
  const PRIZES = headless
    ? { KDR: multi * 3, KILLS: multi * 2, WINS: multi * 1, RAFFLE: raffleEnabled ? (multi * 1) : 0 }
    : { KDR: (multi * 5), KILLS: (multi * 4), WINS: (multi * 3), RAFFLE: raffleEnabled ? (multi * 1) : 0 };

  return (
    <div className="col-3 tourney-tile" key={`${day}_${start}`}>
      <b>{day.toUpperCase()}</b>
      <br/><span>{hh}:{mm} UTC in {tournamentRegionOverride || 'USW'}</span>
      <div><b>Prizes in <PrizesCurrency {...{ chainLogin, short: true }}/>:</b>
        {autoRewardsOnTourneyEnd
          ? <>
              <br/>
              Top KDR: <span className="green bold">{PRIZES.KDR}</span>, Most Kills: <span className="green bold">{PRIZES.KILLS}</span>
              , Most Wins: <span className="green bold">{PRIZES.WINS}</span>. Raffled: <span className="green bold">{PRIZES.RAFFLE}</span>
            </>
          : <i>none</i>
        }
        {skipPvpPassCheck && <span className="red bold"><br/>FREE-TO-EARN</span>}
        {multi > 1 && <span className="red bold"><br/>PRIZES X{multi}</span>}
      </div>
    </div>
  );
};
