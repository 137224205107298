import { isMainnet } from './envs';

const symbolNft = isMainnet ? 'CSHOTS' : 'BASEGUN';
const symbolPack = isMainnet ? 'CSPACKS' : 'CSPACKT';


export const HIVE_TEMPLATES = {
  // ## level.packs
  // 1st level packs
  'MYTHIC_PACK': {
    name: 'Mythic Pack',
    schema: 'level0.packs',
    img: 'QmWh8bw3XFtz63v5e1toDKfyw6PyUZWoGpFwnYdKLdudf5',
    description: '1x Mythic Ammo, 3x Base Gun, 1x Rifle, 1x Shotgun, 2x common and 1x rare character. Max supply: 80',
    collection: symbolPack,
  },
  'STARTER_PACK': {
    name: 'Starter Pack',
    schema: 'level0.packs',
    img: 'QmYvCZ8UQ3eDDoosimGApRn4D5YzR2KiDFMVoq7uHDkKue',
    description: '1x Legendary Ammo, 1x Base Gun, 1x Rifle, 1x Shotgun, 1x common and 1x rare character',
    collection: symbolPack,
  },
  // 2nd level packs
  'LEV_1_PACK_COMMON': {
    name: '2nd Level Pack, Common',
    schema: 'level1.packs',
    img: 'QmZw5a4cQCiVWhkQ8tKR7S2BwZbhoREb2NcEE82uQoxXKS',
    description: 'Weapons: 1x Savage (C), 1x Tremor (Rare), 1x Vortex (Epic). Characters: 2x Ivan (C)',
    collection: symbolPack,
  },
  'LEV_2_PACK_EPIC': {
    name: '2nd Level Pack, Epic',
    schema: 'level1.packs',
    img: 'QmdEpBdMCMaMSm91cHVhU4nreqKFhbNvptf4Z4GakbE1Jf',
    description: 'Weapons: 3x Savage (C), 2x Tremor (R), 1x Vortex (Epic). Characters: 1x Ivan (C), 1x Minta (Epic)',
    collection: symbolPack,
  },
  // 3rd level packs
  'LEV_2_PACK_COMMON': {
    name: '3rd Level Pack, Common',
    schema: 'level2.packs',
    img: 'QmapoFnkw6FTvWrmMu82GWhyV4Jbze5ZjaYmQgWeXRkhN8',
    description: 'Weapons: 1x Muran (C), 1x Theta (R), 1x Venga (Epic). Characters: 1x Lexi (C), 1x Evelyn (Rare).',
    collection: symbolPack,
  },
  'LEV_2_PACK_RARE': {
    name: '3rd Level Pack, Rare',
    schema: 'level2.packs',
    img: 'QmaLBSEM2T8ST3Jmz6WDA17xNYyznT2Rwp1jgX2ER9Adsd',
    description: 'Weapons: 3x Muran (C), 2x Theta (R), 1x Venga (Epic). Characters: 2x Lexi (C), 1x Evelyn (Rare).',
    collection: symbolPack,
  },

  // ## game.items
  'FORCE_FIELD': {
    name: 'Force Field, level-1',
    schema: 'game.items',
    img: 'QmYd5hyPp1Y18QV3XTSHQcreLaivWbRtYSrLK1ZRuLyu48',
    description: '',
    collection: symbolNft,
  },
  'FORCE_FIELD_L2': {
    name: 'Force Field, level-2',
    schema: 'game.items',
    img: 'QmdBDMpmCNQ4hsh6HtGJM5hJFLqbfMiaSvK5yarjNNcr9t',
    description: 'Allows you to pick up the shields in the 3rd level of the game. Protection against the level boss!',
    collection: symbolNft,
  },
  'FORCE_FIELD_L0': {
    name: 'Force Field, level-0',
    schema: 'game.items',
    img: 'QmbjytHiGjEdhFGdV6EsAgaEFJhMnTQyBEvHZ6XjPf7Kz4',
    description: 'Allows you to pick up the shields in the 1st level of the game',
    collection: symbolNft,
  },
  'TORCH': {
    name: 'Torch Attachment',
    schema: 'game.items',
    img: 'QmUqiRd4LUqpww15cjMK7Vei9M2zJ552dtLvicqCH6zGRo',
    description: 'Allows you to pick up and use the torch in all levels of the game',
    collection: symbolNft,
  },

  // ## game.ammo
  'MYTHIC_AMMO': {
    name: 'Mythic Ammo',
    schema: 'game.ammo',
    img: 'QmTWRWmwwmw5AFRniZFuq35B4z6b8KigzDNioNc6SVXmkh',
    description: 'Max ammo for every match of the game. Max supply: 80',
    collection: symbolNft,
  },
  'LEGENDARY_AMMO': {
    name: 'Legendary Ammo',
    schema: 'game.ammo',
    img: 'QmXNNgRQZy3cmNkw7iPKiDLeoYFXHiESd9iVRxGGXp1fRg',
    description: 'Medium amount of ammo for every match of the game. Max supply: 300',
    collection: symbolNft,
  },

  // ## game.weapons
  // level-0
  'BASE_GUN': {
    name: 'Base Gun',
    schema: 'game.weapons',
    img: 'QmW1vqpqzttK5cVWQTJJxL9tFEBUXsjVPk3gDk7dtnpxxp',
    description: 'Default weapon, equipped in all levels. Rewards multiplier: +1%, max counted: 15',
    collection: symbolNft,
  },
  'LASER_RIFLE': {
    name: 'Rifle',
    schema: 'game.weapons',
    img: 'QmT5KDZCBXm8y1Lx1uExbjM4L1VRBBX8yAQwtZvbpzNTtK',
    description: 'Level-0 weapon. Rewards multiplier: +3%, max counted: 2',
    collection: symbolNft,
  },
  'LASER_SHOTGUN': {
    name: 'Shotgun',
    schema: 'game.weapons',
    img: 'QmNqAmy2wGC48cSHmMv5cisMAsimUuDrRCjsCDNn4k6df5',
    description: 'Level-0 weapon. Rewards multiplier: +5%, max counted: 2',
    collection: symbolNft,
  },
  // level-1
  'SAVAGE': {
    name: 'Savage',
    schema: 'game.weapons',
    img: 'QmQhEuiCP8J3JyEcQEJf7pjpcth7gYULb1seAZ2JEjFDhf',
    description: 'Level-1 weapon. Reward multiplier: +1%, max stacking: 3',
    collection: symbolNft,
  },
  'TREMOR': {
    name: 'Tremor',
    schema: 'game.weapons',
    img: 'QmUXmpsBrAekVnY2k58YZ1tprqGdS1sBxTcD9xhKgzq7Ya',
    description: 'Level-1 weapon. Reward multiplier: +3%, max stacking: 2',
    collection: symbolNft,
  },
  'VORTEX': {
    name: 'Vortex',
    schema: 'game.weapons',
    img: 'QmcfxsRoRtadsUsBhYAW39hoTMWokWjVVm2SGG9XLK3zhA',
    description: 'Level-1 weapon. Reward multiplier: +5%, max stacking: 2',
    collection: symbolNft,
  },
  // level-2
  'MURAN': {
    name: 'Muran',
    schema: 'game.weapons',
    img: 'QmPxaQt3vDGVmJUcyub1tGsSo6MVBeW1g5ughQJVjXBDsL',
    description: 'Level-2 weapon. Reward multiplier: +1%, max stacking: 3',
    collection: symbolNft,
  },
  'THETA': {
    name: 'Theta',
    schema: 'game.weapons',
    img: 'QmVc9QZv3nLhfN3Vzg7DGNwYNrixML8couhWGs95ALb58o',
    description: 'Level-2 weapon. Reward multiplier: +3%, max stacking: 2',
    collection: symbolNft,
  },
  'VENGA': {
    name: 'Venga',
    schema: 'game.weapons',
    img: 'QmPuu2aSBFPQXmeKVjudb78txmnjygcwernEJeauhwDv8G',
    description: 'Level-2 weapon. Rewards multiplier: +5%, max stacking: 2',
    collection: symbolNft,
  },


  // ## game.characters
  // level-0
  'VANCE_TYRRELL': {
    name: 'Vance Tyrrell',
    schema: 'game.characters',
    img: 'QmSP2f3hTKFwPAMRc4gbaoaNJgbyk89CcHQ3Ur2LXT1irS',
    description: 'Level-0 character. Play time: 5 min, max amount: 4, rarity: common',
    collection: symbolNft,
  },
  'MASHA_TALASHOVA': {
    name: 'Masha Talashova',
    schema: 'game.characters',
    img: 'QmPTzsPu58et2JFaDem7g8WRDZ6kmv9fTi8Pq1a9ATMzxn',
    description: 'Level-0 character. Play time: 15 min, max amount 3, rarity: rare',
      collection: symbolNft,
  },
  // level-1
  'IVAN_BARRICK': {
    name: 'Ivan Barrick',
    schema: 'game.characters',
    img: 'QmYtgfYZtN3wtxRmUW8e8DbDsd2SJeQ7BNYFrwrXqra44p',
    description: 'Level-1 character. Play time: 5 min, max amount: 4, rarity: common',
    collection: symbolNft,
  },
  'MINTA_HANI': {
    name: 'Minta H\'ani',
    schema: 'game.characters',
    img: 'QmYe5TgktBydKcRLpqGQBmtEwAHAmsjj3XyvUVkY4vTCoF',
    description: 'Level-1 character. Play time: 25 min, max amount 2, rarity: epic',
    collection: symbolNft,
  },
  // level-2
  'LEXI_CAYNE': {
    name: 'Lexi Cayne',
    schema: 'game.characters',
    img: 'QmXwSTNLsjgjwVDdxVdEubfFRRMQ8bkDnnwUAHME8s3Daa',
    description: 'Level-2 character. Play time: 5 min, max amount: 4, rarity: common',
    collection: symbolNft,
  },
  'EVELYN_MOORE': {
    name: 'Evelyn Moore',
    schema: 'game.characters',
    img: 'QmPig6M74PHWDQ9vvkYhLsQLokMgRHTe9uko2NYt5spTSw',
    description: 'Level-2 character. Play time: 15 min, max amount 3, rarity: rare',
    collection: symbolNft,
  },

  // ## weapon.skins
  'ELEKTRA_L2_SKIN': {
    name: 'Skin for Elektra',
    schema: 'weapon.skins',
    img: 'QmUdxDPqnMdQWzY9BBSfA9kogsMJowseYao3ay2LwfK8js',
    description: 'Game Level: 2. Event: Halloween 2022. Limited edition!',
    collection: symbolNft,
  },
  'JUSTICE_L2_SKIN': {
    name: 'Skin for Justice',
    schema: 'weapon.skins',
    img: 'Qma2GVagcgSBGCGFgS48p8QcjF3eXzKopfBwiMUPYDuuJD',
    description: 'Game Level: 2. Event: Halloween 2022. Limited edition!',
    collection: symbolNft,
  },
  'UTOPIA_L2_SKIN': {
    name: 'Skin for Utopia',
    schema: 'weapon.skins',
    img: 'QmTgWY5Lzr12pAK5X5fMZRQYnDESt8SyrpnHZC1VY9iPRR',
    description: 'Game Level: 2. Event: Halloween 2022. Limited edition!',
    collection: symbolNft,
  },

  // ## cs.cybergirl
  'KIARA_JANUARY': {
    name: 'Kiara - January edition',
    schema: 'cs.cybergirl',
    img: 'QmQAJEnEgxfEYJGHPzi5UTz9EG2qoYjPVH2ng3UbfHNMLf',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_FEBRUARY': {
    name: 'Kiara - February edition',
    schema: 'cs.cybergirl',
    img: 'QmZdbkJboDjcqPNPD1CWC3QX29FiviQ5o79m46eyhfRHyB',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_MARCH': {
    name: 'Kiara - March edition',
    schema: 'cs.cybergirl',
    img: 'QmdL7ry14fm5eyk2eVLxybuZ3VS2ff35UcZYqKsuHJ1f4X',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_APRIL': {
    name: 'Kiara - April edition',
    schema: 'cs.cybergirl',
    img: 'QmQYPkCEcU3WracmkS9YNLLKytpF4Yid1WadGHFZz8soQu',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_MAY': {
    name: 'Kiara - May edition',
    schema: 'cs.cybergirl',
    img: 'QmRBUhbS9pFQRcZmDsMmGjjxz1HFC9VhR86a3u95yWPcda',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_JUNE': {
    name: 'Kiara - June edition',
    schema: 'cs.cybergirl',
    img: 'QmboTNWD1LSyF95NbPei1odqRotwBNQyUcmzhYmAgrHgh3',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_JULY': {
    name: 'Kiara - July edition',
    schema: 'cs.cybergirl',
    img: 'QmaVVcVzSoTjSuPegoPLzkKMzyUJtyVu8Cj3AeUTfLxFhw',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_AUGUST': {
    name: 'Kiara - August edition',
    schema: 'cs.cybergirl',
    img: 'QmYMXKTkZmYrbKYggBMm2ctF44QVRxQ2hCf7mBA37JZpF7',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KARINA_JANUARY': {
    name: 'Karina - January edition',
    schema: 'cs.cybergirl',
    img: 'QmSnLnetwCWpWa7knah5PWgf3XvxWJqdyxE2rgWDqGFGUd',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_FEBRUARY': {
    name: 'Karina - February edition',
    schema: 'cs.cybergirl',
    img: 'QmdrefLvSVfbG9BkXeB278j9SmV56dLnmt4cY7zEfjsgUA',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_MARCH': {
    name: 'Karina - March edition',
    schema: 'cs.cybergirl',
    img: 'QmPFMEhcAM7T7BH5Lc3SoGniWcPyEYoSQoeTtqtTs4UjNT',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_APRIL': {
    name: 'Karina - April edition',
    schema: 'cs.cybergirl',
    img: 'QmQeoHTX5gHHuqVHBLYFCPA2XwoxB59ETZ1KsFQmdDS7gp',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_MAY': {
    name: 'Karina - May edition',
    schema: 'cs.cybergirl',
    img: 'QmZJxmeLR665cfY55M9CuCkMm3eNG6mfQiMTNNYF87vUuT',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_JUNE': {
    name: 'Karina - June edition',
    schema: 'cs.cybergirl',
    img: 'QmRLwcL4SNSi871yn76CTEVjeJCX8zNFquYm2FyEDDQbm3',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_JULY': {
    name: 'Karina - July edition',
    schema: 'cs.cybergirl',
    img: 'Qmc3UiMASRpszFQu4fJqie68EVdaGV3KLia63zG8MifM9H',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_AUGUST': {
    name: 'Karina - August edition',
    schema: 'cs.cybergirl',
    img: 'QmPvujWAcSymnnvXvZk1mt2YKLkfEh556PfFPzWcY5kwDG',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'MARTE_JANUARY': {
    name: 'Marte - January edition',
    schema: 'cs.cybergirl',
    img: 'QmQd5xnpgLC1qd8scUybM6tSHCV7U79kPndE3SjVwmLMvJ',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_FEBRUARY': {
    name: 'Marte - February edition',
    schema: 'cs.cybergirl',
    img: 'QmUXefuui33qEWC2eBMMdx53j4aw2jnmmkNCnP5n6MZWwv',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_MARCH': {
    name: 'Marte - March edition',
    schema: 'cs.cybergirl',
    img: 'QmctUfzp6HjsJSwCGAFig7LBPfBorUPVmGB5eU73mLdmU6',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_APRIL': {
    name: 'Marte - April edition',
    schema: 'cs.cybergirl',
    img: 'QmTuZFZ25aoMCaMMhA1rfyfoT9zvk3P1r3nG66LDAEusTf',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_MAY': {
    name: 'Marte - May edition',
    schema: 'cs.cybergirl',
    img: 'QmTLFEFLvjKiecyBdjoxrFypHnWKLWzuBDiwsCnCchCxd5',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_JUNE': {
    name: 'Marte - June edition',
    schema: 'cs.cybergirl',
    img: 'QmQMFuQzsQBsRBtoe1qUH4PoKbVQpPyuV8pvVQcHWQWyqM',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_JULY': {
    name: 'Marte - July edition',
    schema: 'cs.cybergirl',
    img: 'QmWMEXdTNgvJy5zofQfVrPMwZ1gSc6dfuJP4jVND9F9GQU',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_AUGUST': {
    name: 'Marte - August edition',
    schema: 'cs.cybergirl',
    img: 'QmNo5UixVaxx2Y1JGLDhMvmdTkLtJDNxJ6UJcMUtWLej8D',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },

  // PS. 2023 remaining cybergirls

  'KIARA_SEPTEMBER': {
    name: 'Kiara - September edition',
    schema: 'cs.cybergirl',
    img: 'QmSwHeBghGidbxURdDPU4DymUTWiuJPECeatsynYsQuVCa',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_OCTOBER': {
    name: 'Kiara - October edition',
    schema: 'cs.cybergirl',
    img: 'Qmbs6ExLyGNSY4WEhor9ebEm5hx9Xf1Bcbk4qPagCVrByD',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_NOVEMBER': {
    name: 'Kiara - November edition',
    schema: 'cs.cybergirl',
    img: 'QmU5KEryYD8sHjAbk4AReSrqLHLrVJV19wdK1sRVCNQ4ms',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },
  'KIARA_DECEMBER': {
    name: 'Kiara - December edition',
    schema: 'cs.cybergirl',
    img: 'QmSCSNVLEUzmAtR2ZJCvD5KUNmo1piFSKNJZxQFvpwQxED',
    description: 'not very chatty, long replies, loves jokes, likes emojis, lower chance prizes, medium tip size',
    collection: symbolNft,
  },

  'KARINA_SEPTEMBER': {
    name: 'Karina - September edition',
    schema: 'cs.cybergirl',
    img: 'QmWps6P3QYLyow5dLHeksYZCcFJUVvZBQivcz3pEuzAZ3e',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_OCTOBER': {
    name: 'Karina - October edition',
    schema: 'cs.cybergirl',
    img: 'QmTHUTZa4xKPTobJ9keu95bSfEqY2MSwGtZWd2vezMvxsJ',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_NOVEMBER': {
    name: 'Karina - November edition',
    schema: 'cs.cybergirl',
    img: 'QmTZmAaJNwXuYAQcGWh7ivJ2WS7V72JyHhhzJ9veMTG3co',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },
  'KARINA_DECEMBER': {
    name: 'Karina - December edition',
    schema: 'cs.cybergirl',
    img: 'QmRxENDChRQbeLNn5fomBvcceDXQ6YbDcbmPXVXXrEeQWX',
    description: 'chatty, short replies, funny, casual, loves emojis, medium chance prizes, low tip size',
    collection: symbolNft,
  },

  'MARTE_SEPTEMBER': {
    name: 'Marte - September edition',
    schema: 'cs.cybergirl',
    img: 'QmQHdJBALkzwr7uVpjKNT9SCnHJz5htsSf46qMQPhXaMf5',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_OCTOBER': {
    name: 'Marte - October edition',
    schema: 'cs.cybergirl',
    img: 'Qme8ibc8dPQ77UqNCBbJPRecZ9WYpL2WkSQmfmS9L6WaZp',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_NOVEMBER': {
    name: 'Marte - November edition',
    schema: 'cs.cybergirl',
    img: 'QmeeZqhCUyCgNdmMX15cRHStmgnBu2ut3RpemJoSiP7PNK',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },
  'MARTE_DECEMBER': {
    name: 'Marte - December edition',
    schema: 'cs.cybergirl',
    img: 'QmTn44LY6AyJZHQ4MJrAnEBQCecScaHdAaYP22JTUxkA2V',
    description: 'very chatty, medium replies, wise, friendly, rare use emojis, high chance prizes, high tip size',
    collection: symbolNft,
  },

  // ## early.access
  'PVP_ALPHA_PASS': {
    name: 'PVP Alpha Pass',
    schema: 'early.access',
    img: 'QmTuRGriPK69WpZ11bW7avoBM2uGchXbqMycMQYdVzjktL',
    description: 'This asset grants early access to the alpha version of our Multiplayer Shooter game',
    collection: symbolNft,
  },
};
// NOTE: the name attribute is used as primary key for .filter(), MUST BE UNIQUE.
