
export const newrelic = {
    notifyHackAttempt: ({ user, msg }) => {
    const webhook = 'https://discord.com/api/webhooks/1094341140758679582/4TFcrecuAeHW_UYcH6bF-yk7Al3r6yCYssNxcaLCDKrsGWGzZKDuMah2F9bdaJ7JvDzy';
    try {
      return fetch(
        webhook,
        {
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({ content: `- Attempt from ${user}: ${msg}` }),
        },
      )
        .then((res) => {
          if (res.status < 300) { /* localstorage? */ }
          else console.error('y', { res });
        })
        .catch(err => console.error('y2', err));
    } catch (err) { console.error('w', err); }
  },
};
