import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';


const initialState = {
    messageUiRank: [],
    messageUiSchool: [],
    failedUsersRankArr: [],
    failedUsersEndSchoolArr: [],
};

const adminPane = createSlice({
    name: 'adminPane',
    initialState,
    reducers: {
      setUiMessageRank: (state, action) => {
        const msg = get(action, 'payload.msg');
        const messages = msg ? [...state.messageUiRank, msg] : [];
        return ({ ...state, messageUiRank: messages });
      },
      setUiMessageSchool: (state, action) => {
        const msg = get(action, 'payload.msg');
        const messages = msg ? [...state.messageUiSchool, msg] : [];
        return ({ ...state, messageUiSchool: messages });
      },
      setFailedUsersRank: (state, action) => {
        const errors = get(action, 'payload.failedUsersRankArr');
        return ({
          ...state,
          failedUsersRankArr: errors ? [...state.failedUsersRankArr, ...errors] : [],
        });
      },
      setFailedUsersEndSchool: (state, action) => {
        const errors = get(action, 'payload.failedUsersEndSchoolArr');
        return ({
          ...state,
          failedUsersEndSchoolArr: errors ? [...state.failedUsersEndSchoolArr, ...errors] : [],
        });
      },
    }
});

export const {
  setUiMessageRank,
  setUiMessageSchool,
  setFailedUsersRank,
  setFailedUsersEndSchool,
} = adminPane.actions;

export default adminPane.reducer;
