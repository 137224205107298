import React from 'react';
import { storeAppDispatch } from '../../../../GlobalState/Store';
import { setChainLogin } from '../../../../reducers/UserReducer';
import { isMainnet } from '../../../../constants/envs';

const IS_SKALE_LIVE = isMainnet ? false : true;


export const EthNetworkChoice = () => {

  const ethChainChoiceHandler = (chain = '') => {
    sessionStorage.removeItem('exclusiveLogin');
    storeAppDispatch(setChainLogin({ chainLogin: chain }));
  };

  const handleBack = () => {
    storeAppDispatch(setChainLogin({ chainLogin: '' }));
  };

  return (
    <div id="eth-network-choice-wrapper" className="row">
      <div className="col-12">
        <p id="eth-choice-back" onClick={handleBack}>Back &gt;</p>
      </div>
      <div className="col-2"></div>
      <div className="col-4">
        <p style={{ fontSize: '18px', fontFamily: 'Verdena' }}>Ethereum {isMainnet ? 'Mainnet' : 'Testnet'}</p>
        <button
          className="btn btn-warning"
          onClick={() => ethChainChoiceHandler('eth')}
          style={{ color: 'black', fontSize: '22px' }}
        >
          <span>ETH</span>
        </button>
      </div>
      <div className="col-4">
        <p style={{ fontSize: '18px', fontFamily: 'Verdena' }}>Ethereum Layer-2</p>
        <button
          className="btn btn-warning"
          onClick={() => ethChainChoiceHandler('skl')}
          style={{ fontSize: '22px', display: 'inline-block' }}
          disabled={!IS_SKALE_LIVE}
        >
          {!IS_SKALE_LIVE ? '? ?' : 'SKALE'}
        </button>
        <button
          className="btn btn-warning"
          style={{ marginLeft: '10px', fontSize: '22px', display: 'none' }}
        >
          <span>NEXT ETH L2 @@</span>
        </button>
      </div>
    </div>
  );
};
