
const msToHMS = (ms) => {
  let h = ms / 3.6e6 | 0;
  let m = (ms % 3.6e6) / 6e4 | 0;
  let s = (ms % 6e4) / 1e3 | 0;
  // let ss = (ms % 1e3);
  // .${(''+ss).padStart(3, '0')}`;
  return `${h}:${('' + m).padStart(2, '0')}:${('' + s).padStart(2, '0')}`;
};

export const timeToUTCMidnight = (d = new Date()) => msToHMS(
  new Date(d).setUTCHours(24, 0, 0, 0) - d,
);
