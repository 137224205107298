import { RESOURCES_PRICES } from '../../overlays/Resources/resourcesPrices';


const PC_DISCOUNT_WAX_PAYM = 5;


// # TOKEN VALUE
const waxPriceInUsd = 0.06;
const boomPriceInWax = 0.2;
const gasPriceInBoom = 0.2;
const marsPriceInBoom = 2;


// # SPACESHIP PRICES -- TODO: read from owners' bio (or high default)
// L-1
const usdPriceSpaceship1 = 0.3;
const usdPriceSpaceship2 = 0.35;
const usdPriceSpaceship3 = 0.4;
// L-2
const usdPriceSpaceship4 = 0.3;
const usdPriceSpaceship5 = 0.4;
const usdPriceSpaceship6 = 0.5;


// const ss1Boom = Math.round(usdPriceSpaceship1 / boomPriceInWax / waxPriceInUsd);
const ss1Mars = Math.round((usdPriceSpaceship1 / boomPriceInWax / waxPriceInUsd) * marsPriceInBoom);
const ss1Wax = Math.round(usdPriceSpaceship1 / waxPriceInUsd);

const ss2Boom = Math.round(usdPriceSpaceship2 / boomPriceInWax / waxPriceInUsd);
const ss2Wax = Math.round(usdPriceSpaceship2 / waxPriceInUsd);

const ss3Boom = Math.round(usdPriceSpaceship3 / boomPriceInWax / waxPriceInUsd);
const ss3Wax = Math.round(usdPriceSpaceship3 / waxPriceInUsd);

const ss4Boom = Math.round(usdPriceSpaceship4 / boomPriceInWax / waxPriceInUsd);
const ss4Wax = Math.round(usdPriceSpaceship4 / waxPriceInUsd);

const ss5Mars = Math.round(usdPriceSpaceship5 / marsPriceInBoom / boomPriceInWax / waxPriceInUsd); // 100 mars
const ss5Wax = Math.round(usdPriceSpaceship5 / waxPriceInUsd);

// (divided by MIN chars to get to L2!!)
const ss6Gas = Math.round(usdPriceSpaceship6 / gasPriceInBoom / boomPriceInWax / waxPriceInUsd) / 6; // 80 gas
const ss6Wax = Math.round(usdPriceSpaceship6 / waxPriceInUsd);


export const SS_PRICES = {
  // level 0
  SS0: {
    G: RESOURCES_PRICES.GAS, // multiplied by X characters
  },
  // level 1
  SS1: {
    // B: ss1Boom,
    M: ss1Mars,
    W: ss1Wax - (ss1Wax * PC_DISCOUNT_WAX_PAYM / 100),
  },
  SS2: {
    B: ss2Boom,
    W: ss2Wax - (ss2Wax * PC_DISCOUNT_WAX_PAYM / 100),
  },
  SS3: {
    B: ss3Boom,
    W: ss3Wax - (ss3Wax * PC_DISCOUNT_WAX_PAYM / 100),
  },
  SS4: {
    B: ss4Boom,
    W: ss4Wax - (ss4Wax * PC_DISCOUNT_WAX_PAYM / 100),
  },
  SS5: {
    M: ss5Mars,
    W: ss5Wax - (ss5Wax * PC_DISCOUNT_WAX_PAYM / 100),
  },
  SS6: {
    G: ss6Gas,
    W: ss6Wax - (ss6Wax * PC_DISCOUNT_WAX_PAYM / 100),
  },
};
