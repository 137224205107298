import { createSlice } from '@reduxjs/toolkit';
import { isLocal } from '../constants/envs';
import { storeAppDispatch } from '../GlobalState/Store';

const initialState = {
  isPercentLoaded: 0,
  // web game status
  isPvpGameMode: false,
  paidResources: false,
  activeLevel: 0,
  isTravellingToZero: false,
  isInTravel: false,
  // rewards
  claimResult: {},
  // game data
  wrapperData: {},
};

const play = createSlice({
    name: 'play',
    initialState,
    reducers: {      
      setPercentLoaded: (state, action) => ({ ...state, isPercentLoaded: action.payload.isPercentLoaded }),
      setGameMode: (state, action) => ({ ...state, isPvpGameMode: action.payload.gameMode === 'pvp' }),
      setActiveLevel: (state, action) => ({ ...state, activeLevel: action.payload.activeLevel }),
      setInTravel: (state, action) => ({ ...state, isInTravel: action.payload.travelling }),
      setPaidResources: (state, action) => ({ ...state, paidResources: action.payload.paidResources }),
      setFirstLevTravel: (state, action) => ({ ...state, isTravellingToZero: action.payload.isTravellingToZero }),
      setClaimResult: (state, action) => ({ ...state, claimResult: action.payload.claimResult }),
      setWrapperData: (state, action) => ({ ...state, wrapperData: action.payload.wrapperData }),
      resetLastMatch: (state, _) => ({
        ...state,
        wrapperData: {
          ...state.wrapperData,
          gameData: {
            ...state.wrapperData.gameData,
            rewards: {},
          },
          sessionData: {
            ...state.wrapperData.sessionData,
            lastMatchInfo: {},
          },
        },
      }),
    }
});

export const {
  setPercentLoaded,
  setGameMode,
  setActiveLevel,
  setInTravel,
  setPaidResources,
  setFirstLevTravel,
  setClaimResult,
  setWrapperData,
  resetLastMatch,
} = play.actions;

export default play.reducer;


// TESTS
//
// SEE ALSO: src\GlobalState\extReduxBridge.js
//
window.testClaimResult_marsOnly = () => isLocal && storeAppDispatch(setClaimResult({
  claimResult: {
    mainUser: {
      amount: "n/a",
      doomPlayer: undefined,
      id: undefined,
      marsAmount: 1.134,
      marsStakedAmount: "n/a",
      marsTx: "fab6189f8c5af1566b82531e1395ccaa5115d0111adb47c81957a1fef0bfdfb8",
      playerAddr: "crypto5shots(?)",
      playerDoomTxLink: undefined,
      stakedAmount: "n/a",
    },
    teacher: {
      amount: "n/a",
      doomTeacher: undefined,
      id: undefined,
      marsAmountTeacher: 0.756,
      marsTxTeacher: "85fb3f983790cd33355a03a209fc5c70bcf1acc58ed1d219d5d865b359db085a",
      teacherAddr: undefined,
      teacherDoomTxLink: undefined,
    },
  },
}));
window.testClaimResult_boomOnly = () => isLocal && storeAppDispatch(setClaimResult({
  claimResult: {
    mainUser: {
      amount: 47.57669999999999,
      doomPlayer: undefined,
      id: "f7c309aadc416854871692794b4ef920b7de8067dc1e74ebc99dfbf7aa638d9d",
      marsAmount: "n/a",
      marsStakedAmount: "n/a",
      marsTx: undefined,
      playerAddr: "crypto5shots",
      playerDoomTxLink: undefined,
      stakedAmount: "n/a",
    },
    teacher: {
      amount: 31.717799999999997,
      doomTeacher: undefined,
      id: "b96d504cbef8933f6078578501ae070729dd41ad0b82b6f71399f6b3ab8a9c95",
      marsAmountTeacher: "n/a",
      marsTxTeacher: undefined,
      teacherAddr: "crypto1shots",
      teacherDoomTxLink: undefined,
    }
  }
}));
window.testClaimResult_boomAndMars = () => isLocal && storeAppDispatch(setClaimResult({
  mainUser: {
    amount: 3.024,
    doomPlayer: undefined,
    id: "d408375cf914cab40fa649dac781d2a97cc8aa0028d70c1871555958e127f375",
    marsAmount: 1.26,
    marsStakedAmount: "n/a",
    marsTx: "ebd8c70e6096ca47a84db896220de058a2cf876b377d14fe67fe2235a76caa25",
    playerAddr: "crypto5shots",
    playerDoomTxLink: undefined,
    stakedAmount: "n/a",
  },
  teacher: {
    amount: 2.016,
    doomTeacher: undefined,
    id: "0199a543d63820f2bc3525bfa619b78ebfe89f30b52e43152288bfa863b59fae",
    marsAmountTeacher: 0.8400000000000001,
    marsTxTeacher: "5caaf57151bce9bdc6cd0ae6953407877a9fde16483c5be2bf954a83c2571256",
    teacherAddr: "crypto1shots",
    teacherDoomTxLink: undefined,
  },
}));
