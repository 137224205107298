import React from 'react';
import { isFeatureEnabled } from '../../../../config/featureFlags';
import './AnonAd.scss';


export const AnonAdBanner = () => {
  if (!isFeatureEnabled('ADS')) return null;
  return (
    <div id="anon-banner">
      <iframe
        title="a-ads.com"
        src="//ad.a-ads.com/2187684?size=728x90"
        data-aa="2187684"
      ></iframe>
      <a href="https://a-ads.com/?partner=2187684" target="_blank" rel="noreferrer">
        Promote your crypto project here
      </a>
    </div>
  );
};

export const AnonAdSquare = () => {
  if (!isFeatureEnabled('ADS')) return null;
  return (
    <div id="anon-square">
      <iframe
        title="a-ads.com"
        data-aa="2187705"
        src="https://ad.a-ads.com/2187705?size=125x125"
      ></iframe>
    </div>
  );
};
