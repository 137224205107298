import { client } from '../../../../helpers/client';
import { isLocal } from '../../../../constants/envs';
import { get } from 'lodash';
import { isFeatureEnabled } from '../../../../config/featureFlags';
import Swal from 'sweetalert2';


export const calcPercentageDoom = ({ waxNfts = [], hiveNfts = [] }) => {
  const hiveNftsCount = hiveNfts.length;
  if (!hiveNftsCount) return undefined;
  const waxNftsCount = waxNfts.length;
  if (!isFeatureEnabled('HIVE')) return hiveNftsCount > 0 ? 1 : 0;
  const percentDoom = +((hiveNftsCount / (hiveNftsCount + waxNftsCount)) * 100).toFixed(0);
  return percentDoom;
};

const prepClaimResMsg = ({ resProcessClaimDoom, who}) => {
  // const doomStatusCode = get(resProcessClaimDoom, 'statusCode');
  // const doomPlayerMsg = get(resProcessClaimDoom, 'resClaimDoomForPlayer.response.msg', ''); // msg vs statusCode
  try {
    const tx = get(resProcessClaimDoom, `resClaimDoomFor${who}.response.hiveTrxId`, '');
    const broadcastedJson = get(resProcessClaimDoom, `resClaimDoomFor${who}.response.broadcastedJson`, '{}');
    const { contractPayload } = (JSON.parse(broadcastedJson) || {});
    if (!contractPayload) return ({});
    // "contractPayload":{"symbol":"DOOM","to":"ilovecryptos","quantity":"0.1372","memo":"claim 0.1372 DOOM for ygnre.wam/ilovecryptos"}}"
    const { quantity, symbol, to } = contractPayload;
    isLocal && console.debug('[] [] [] contract payload', { contractPayload, quantity, symbol, to }); // TEMP @@
    return ({ tx, msg: quantity ? `${quantity} ${symbol} sent to ${to}` : '' });
  } catch (err) {
    console.error('Caught error parsing claim response:', err);
    return ({});
  }
};

export const claimAllTokens = async ({
  username = '', teacherName = '', scholarCut = 0, isMostlyUnstaker,
  hiveData = {}, nfts = []
}) => {
  // ## REQUEST
  const { nfts: hiveNfts = [], username: hiveUsername, teacher: hiveTeacher, linkedWaxAccount } = hiveData;
  const percentageDoom = calcPercentageDoom({ waxNfts: nfts, hiveNfts, username });
  const scholarCutDecimals = (scholarCut / 100) || undefined;
  const teacherNameValue = teacherName || undefined;
  const requestData = {
    account: username, // eg. 'boomfungible'
    linkedWaxUsername: linkedWaxAccount,
    teacher: teacherNameValue, // 'hitstoken123'
    scholarCut: scholarCutDecimals, // 0.6
    percentageDoom, // 40
    hiveUsername: hiveUsername || undefined, // hivedev0404
    hiveUsernameOfTeacher: hiveTeacher || undefined, // hivedev5040
    isMostlyUnstaker, // reduces max claim to 62 if not a champion
  };
  console.debug('[claim-step] Claim api request data:', { requestData });
  try {
    const response = await client.post(
      `/api/transaction/claim-pending-rewards`,
      requestData,
      { timeout: 2 * 60 * 1000 }
    );

    // ## RESPONSE
    isLocal && console.log('[claim-step] Claim result:', { response: response.data });

    // retrocompatibility v1
    const { claim, updateUser, restoreUser } = response.data;
    const txIdOldStyle = get(claim, 'resultClaimBOOM.response.transaction_id');

    // retrocompatibility v2
    let isPlayerClaimBoomOk = get(claim, 'resClaimBoomForPlayer.statusCode') < 300;
    let isTeacherClaimBoomOk = !teacherName || get(claim, 'resClaimBoomForTeacher.statusCode') < 300;
    let isUserUpdateOk = get(updateUser, 'updateUserInfoStatusCode') < 300;
    let isUserRestoreOk = !get(restoreUser, 'restoreUserInfoStatusCode') || get(restoreUser, 'restoreUserInfoStatusCode') < 300;

    // v3
    const { resProcessClaimBoom = {}, resProcessClaimDoom = {}, resProcessClaimMars = {} } = response.data;
    isPlayerClaimBoomOk = isPlayerClaimBoomOk || !resProcessClaimBoom || (resProcessClaimBoom.statusCode < 300);
    isTeacherClaimBoomOk = isTeacherClaimBoomOk
      || !resProcessClaimBoom || (!teacherName || get(resProcessClaimBoom, 'resClaimBoomForTeacher.statusCode') < 300);
    isUserUpdateOk = isUserUpdateOk || !resProcessClaimBoom || get(resProcessClaimBoom, 'resUpdateUserInfo.statusCode') < 300;

    if (/*response.status > 300 ||*/ !isPlayerClaimBoomOk || !isTeacherClaimBoomOk || !isUserUpdateOk || !isUserRestoreOk) {
      const statusSummary = { isPlayerClaimBoomOk, isTeacherClaimBoomOk, isUserUpdateOk, isUserRestoreOk };
      console.error('Something failed in the BE for claim:', { statusSummary });
      Swal.fire({ text: `An error occur. Please check your transactions and report anything missing.`})
      return ({ err: JSON.stringify(statusSummary) });
    }
    // Boom claim result
    const {
      toAccount: playerAddr = `${username}(?)`,
      transaction_id: playerTx = txIdOldStyle,
    } = get(claim, 'resClaimBoomForPlayer.response') || get(resProcessClaimBoom, 'resClaimBoomForPlayer.response') || {};
    const playerClaimAmount = get(claim, 'amountToClaimForPlayer')
      || get(resProcessClaimBoom, 'amountBoomToClaimForPlayer') || 'n/a';
    const stakedAmount = get(claim, 'amountToStaking')
      || get(resProcessClaimBoom, 'amountBoomToStaking') || 'n/a';
    // Claim details IF SCHOLARSHIP is active
    const {
      toAccount: teacherAddr, transaction_id: teacherTx,
    } = get(claim, 'resClaimBoomForTeacher.response') || get(resProcessClaimBoom, 'resClaimBoomForTeacher.response') || {};
    const teacherClaimAmount = get(claim, 'amountToClaimForTeacher')
      || get(resProcessClaimBoom, 'amountBoomToClaimForTeacher') || 'n/a';
      
    // DOOM
    const { tx: playerDoomTxLink, msg: doomPlayerMsg } = prepClaimResMsg({ resProcessClaimDoom, who: 'Player' });
    const { tx: teacherDoomTxLink, msg: doomTeacherMsg } = prepClaimResMsg({ resProcessClaimDoom, who: 'Teacher' });

    // MARS
    const {
      transaction_id: marsTx,
    } = get(claim, 'resClaimMarsForPlayer.response') || get(resProcessClaimMars, 'resClaimMarsForPlayer.response') || {};
    const marsAmount = get(claim, 'amountToClaimForPlayer')
      || get(resProcessClaimMars, 'amountMarsToClaimForPlayer') || 'n/a';
    const marsStakedAmount = get(claim, 'amountToStaking')
      || get(resProcessClaimMars, 'amountMarsToStaking') || 'n/a';
    // teacher (if any)
    const {
      transaction_id: marsTxTeacher,
    } = get(claim, 'resClaimMarsForTeacher.response') || get(resProcessClaimMars, 'resClaimMarsForTeacher.response') || {};
    const marsAmountTeacher = get(claim, 'amountToClaimForTeacher')
      || get(resProcessClaimMars, 'amountMarsToClaimForTeacher') || 'n/a';

    // Result
    const mainUser = {
      id: playerTx, amount: playerClaimAmount, playerAddr, stakedAmount,
      doomPlayer: doomPlayerMsg, playerDoomTxLink,
      marsTx, marsAmount, marsStakedAmount,
    };
    const teacher = {
      id: teacherTx, amount: teacherClaimAmount, teacherAddr,
      doomTeacher: doomTeacherMsg, teacherDoomTxLink,
      marsTxTeacher, marsAmountTeacher,
    };
    const claimResult = { mainUser, teacher };
    isLocal && console.log('[claim-step] claim result data for redux:', { claimResult });
    return ({ claimResult });
  } catch (err) {
    console.error('Claim failed:', err);
    Swal.fire({text: `Uh oh, something went wrong during your claim. Please reach out to support.\n\nError: ${err} - ${JSON.stringify(err)}`})
    return ({ err });
  }
};


// // mars
// resProcessClaimMars.amountMarsToClaimForPlayer // 0.62808
// resProcessClaimMars.resClaimMarsForPlayer.statusCode === 200
// resProcessClaimMars.resClaimMarsForPlayer.response.toAccount
// resProcessClaimMars.resClaimMarsForPlayer.response.transaction_id

// resProcessClaimMars.amountMarsToClaimForTeacher
// resProcessClaimMars.resClaimMarsForTeacher.statusCode === 200
// resProcessClaimMars.resClaimMarsForTeacher.response.toAccount
// resProcessClaimMars.resClaimMarsForTeacher.response.transaction_id

// resProcessClaimMars.amountMarsToStaking
// resProcessClaimMars.resUpdateUserInfo.statusCode === 204
