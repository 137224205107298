
import { get } from "lodash";
import { isLocal } from "../../../constants/envs";
import { cacheBustParam } from "../../../helpers/client";


// const DROPLIVENOW_BANNER = 'https://files.peakd.com/file/peakd-hive/crypto-shots/23vsUgX3FvJ88JMDzNqa9jYpYS2aH5jzqrqGX9CDjEEoMDL1HTHUJyWhvGohnqPYVrhsi.png';
// const BANNER_LINK = 'https://wax.atomichub.io/drops/89979+89978+89977+89976';
const DISCORD_INVITE = 'https://crypto-shots.com/discord';


const bannersDynConfig = {
  // empty = use ALT message
  adSrc: '',
  // what webpage it opens on click
  adWebpage: DISCORD_INVITE,
  // displayed if no src, or if there's src then becomes label under the ad
  adAlt: '',
};

export const getBannersConfig = () => ({ ...bannersDynConfig });

// ads.json
// {
//     "primary": {
//         "desktop": {
//             "src": "https://files.peakd.com/file/peakd-hive/crypto-shots/EogLsFMDzjXWFWAQBfeXWWPaWnRixFHAsKqGmAY4WTByArGpJyStrY4Jcrz6nFH3xMb.jpg",
//             "link": "https://www.crypto-shots.com/drops"
//         }
//     }
// }
const fetchAds = () => {
  isLocal && console.debug('fetching banners data..');
  if (isLocal) return;
  fetch(`/json/ads.json${cacheBustParam()}`)
    .then(res => res.json())
    .then((data = {}) => {
      isLocal && console.debug('banners data:', data);
      const { src, link, alt = '' } = get(data, 'primary.desktop', {});
      bannersDynConfig.adSrc = src;
      bannersDynConfig.adWebpage = link;
      bannersDynConfig.adAlt = alt;
    })
    .catch(err => console.error('banners data fetch err:', err));
};
fetchAds();
