import stringHash from 'string-hash';
import { isLocal } from '../constants/envs';
import { setPercentLoaded, setWrapperData } from '../reducers/PlayReducer';
import { storeAppDispatch } from './Store';
import { windowSetChecksum } from '../generalUtils/secure/checksums';
import Swal from 'sweetalert2';


// BRIDGE TO VANILLA
export const setBridgeVanillaToRedux = () => {
  // -- percent loaded
  const handlePcLoad = ({ amount }) => {
    const payload = { isPercentLoaded: amount };
    storeAppDispatch(setPercentLoaded(payload));
  };
  window.setPercentLoaded = handlePcLoad;
  windowSetChecksum({ key: 'setPercentLoaded', value: handlePcLoad });
  // -- redux data
  const handleReduxData = ({ data, token }) => {
    const expectedBody = btoa(new Date().toISOString().substring(0, 17)).replace(/=/g, '');
    const expected = stringHash(expectedBody);
    if (!isLocal && (token !== expected)) {
      Swal.fire({text: "deggalf SO dna PI ,tnuoccA [st]".split('').reverse().join('')})
      return;
    }
    const payload = { wrapperData: data };
    isLocal && console.debug('wrapper data being dispatched:', { payload });
    storeAppDispatch(setWrapperData(payload));
  };
  window.setWrapperData = handleReduxData;
  windowSetChecksum({ key: 'setWrapperData', value: handleReduxData });
};


// TEST UTILITY
//
// SEE ALSO: src\reducers\PlayReducer.js
//
// const dataToDispatch = {
//   apiResponse: cloneDeep(data),
//   gameData: cloneDeep(gameData),
//   sessionData: {
//     ...cloneDeep(sessionData),
//     // UPDATED
//     playerLevel,
//     totalKills,
//     playerStake,
//     // DELETE attributes that may cause issues because updated right after redux dispatch
//     isLimitedResNewstarter: undefined,
//     hiveNfts: undefined,
//     inventory: undefined,
//     scholarCut: undefined,
//   },
//   unityLevel,
// };
window.testWrappedData = () => isLocal && window.setWrapperData({ data: {
    "apiResponse": {
      "gameStats": {
        "level1": {
          "totalKills": 18,
          "bestTime": {
            "easy": {
              "result": "won",
              "matchId": "crypto5shots_1659816315756",
              "time": 33164
            },
            "medium": {},
            "hard": {}
          }
        },
        "level0": {
          "bestTime": {
            "easy": {
              "result": "won",
              "matchId": "crypto5shots_1656671180151",
              "time": 63060
            },
            "medium": {
              "result": "won",
              "matchId": "crypto5shots_1656828907059",
              "time": 103145
            },
            "hard": {}
          },
          "average_difficulty": {},
          "totalKills": 393,
          "average_time_mins": {},
          "resultsCount": {}
        },
        "level2": {
          "totalKills": 12,
          "bestTime": {
            "easy": {
              "result": "won",
              "matchId": "crypto5shots_1659515398812",
              "time": 112208
            },
            "medium": {},
            "hard": {}
          }
        }
      },
      "marsRewardsToClaim": {
        "validTimeout": [],
        "lost": [
          { amount: 0.4000000001, timestamp: 1669988651153 },
          { amount: 0.4, timestamp: 1669989075927 },
          { amount: 0.2, timestamp: 1669989107395 },
          { amount: 0.155, timestamp: 1670182002024 },
          { amount: 0.1, timestamp: 1670182002024 },
          { amount: 0.2, timestamp: 1670182002024 },
          { amount: 0.3, timestamp: 1670182002024 },
          { amount: 0.4, timestamp: 1670182002024 },
        ],
        "won": [
          { amount: 5.4525, timestamp: 1670183178993 },
        ],
        "unstaked": []
      },
      "boomRewardsToClaim": {
        "validTimeout": [],
        "lost": [],
        "won": [
          { amount: 42.4, timestamp: 1669988151153 },
        ],
        "unstaked": []
      },
      "playTimeSince0utc": {
        "gun": 0,
        "shotgun": 0,
        "playing": 0,
        "base": 0,
        "rifle": 0
      },
      "currentMatch": {
        "gameLevel": null,
        "startTime": null,
        "jwtToken": "xxx",
        "weaponsTime": {
          "gun": 0,
          "shotgun": 0,
          "base": 0,
          "rifle": 0
        },
        "monthDay": "2022-08-06",
        "elapsedTime": 0
      },
      "joinDate": null,
      "lastMatch": {
        "difficulty": 0,
        "finalScore": {
          "result": "won",
          "reward": "0.22949999999999998 BOOM"
        },
        "monthDay": "2022-08-06",
        "gameLevel": 1,
        "startTime": 1659816282592,
        "endTime": 1659816315756,
        "matchID": "crypto5shots_1659816315756"
      },
      "purchases": {
        "ammoInGame": {},
        "totalBoomSpent": 0,
        "inGameMarketplace": {}
      },
      "isBlacklisted": false,
      "nftsToClaim": [],
      "teacher": "crypto1shots",
      "transactionStats": {
        "totBoomClaimedForCurrentDay": 0.7505999999999999,
        "totNftMintsForCurrentDay": 0,
        "totMarsClaimedForCurrentDay": 0,
        "dateOfLastMint": null,
        "claimFlag": false,
        "mintFlag": false,
        "totBoomClaimed": 38.00314,
        "totMarsClaimed": 0.55848,
        "totNftMints": 0,
        "dateOfLastClaim": "2022-08-06"
      },
      "account": "crypto5shots",
      "savings": {
        "boom": {
          "amount": 1098.8123999999998,
          "lastWithdrawTimestamp": 1660014874383
        },
        "mars": {
          "amount": 18.85,
          "lastWithdrawTimestamp": 1660014874383
        }
      },
      "hiveUsername": null
    },
    "gameData": {
      "startTimeTs": null,
      "difficulty": null,
      "kills": [],
      "weaponsUse": {
        "newWeaponInUse": "BG",
        "lastSwapTs": null,
        "BG": {
          "totUseMs": 0
        },
        "LG": {
          "totUseMs": 0
        },
        "LR": {
          "totUseMs": 0
        },
        "LS": {
          "totUseMs": 0
        },
        "SN": {
          "totUseMs": 0
        }
      },
      "teknoTimer": {
        "ts": null
      },
      "rewards": {
        "levelKills": 12,
        "boostsTot": 2,
        "stakedNfts": 2,
        "weapons": 0.8,
        "tekno": 0.5,
        "difficultyAdjustment": -2,
        "taxApplied": 2,
        "finalEarnings": 8.2,
        "breakdownPerKill": [{
            enemyName: 'wing', weaponUsed: 'bg', percentageBoost: 0.1,
            enemyReward: 0.1, immortalReward: 0.1, killTotReward: 0.3,
          }, {
            enemyName: 'wing', weaponUsed: 'bg', percentageBoost: 0.1,
            enemyReward: 0.1, immortalReward: 0.1, killTotReward: 0.3,
        }],
      }
    },
    "sessionData": {
      "account": "crypto5shots",
      "token": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YXhBZGRyZXNzIjoiY3J5cHRvNXNob3RzIiwiaWF0IjoxNjU5OTk0NTcwLCJleHAiOjE2NjAwMTI1NzB9.qr2sOtlkQnpKN_bzku0S36qT5CI7ZAmg3kBcCxHtygQ",
      // "inventory": undefined,
      // "hiveNfts": undefined,
      "maxTime": {
        "weapons": null,
        "characters": null
      },
      "playerLevel": 1,
      "totalKills": 423,
      "playerStake": 50.467,
      "start3dPack": "XL",
      "canPlay2ndLevel": false,
      "matchCount": 0,
      "totalWins": {
        "level0": 0,
        "level1": 0,
        "level2": 0,
        "level3": 0,
        "level4": 0
      },
      "unlockedFastKills": false,
      "unlockedConsecutiveWins": false,
      "lastMatchInfo": {
        resMatch: 'won',
        score: 2.31,
        killBalance: 12,
        remainingTime: '00:01:04',
        difficulty: 0,
      },
    },
    "unityLevel": 0
  }
});
