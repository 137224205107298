import Swal from 'sweetalert2';
import { isLocal } from '../../../constants/envs';
import { initUnity } from './reactMsgSender_obf';
import stringHash from 'string-hash';


export const cachedInitData = {
  inventorySize: 0, // hack: false or inventory size
  jwtAuth: null,
};


export const setBridgeToken = ({ headerValue, username }) => {
  const twistedJwtToken = headerValue.split('').reverse().join('');
  const twistedUname = btoa(username.toUpperCase());
  const combined = `${twistedJwtToken}_${twistedUname}`;
  const hash = stringHash(combined);
  cachedInitData.jwtAuth = `1${btoa(combined).replace(/=/g, '')}.${+hash * 2}`;
  isLocal && console.debug('jwt token set');
};

export const updateUnityInventory = ({
  csNfts = [], shouldRefreshInventory, scholarCut, chainLogin,
}) => {
  if (csNfts.length === 0) {
    console.warn('Bridging with empty inventory.');
    // return; // PS. Allowing this to let new players try the game
  }
  if (cachedInitData.inventorySize && !shouldRefreshInventory) {
    isLocal && console.log('ignoring additional inventory fetch');
    return;
  }
  if (csNfts.length && !cachedInitData.jwtAuth) {
    isLocal && console.error('Auth missing!?!', { csNfts, cachedInitData });
    Swal.fire({text: 'Something is off with your session, resetting for security reasons.'})
    window.location.reload();
    throw new Error('reload');
  }
  if (csNfts.length > 0 && cachedInitData.inventorySize === csNfts.length) {
    return; // identical inventory, dont update. Prevents sendMessage cheats.
  }
  cachedInitData.inventorySize = csNfts.length;
  isLocal && console.log('sending msg to middleware');
  initUnity(cachedInitData.jwtAuth, csNfts, scholarCut, chainLogin); // sets inventory in web wrapper (+postMessages)
};
