import { isLocal } from '../../../../constants/envs';
import { freeTrippers } from "./constants";
import { getNftCount } from '../../../../generalUtils/utils';
import { ITEMS_BY_CATEGORY } from '../../../../constants/templateIds';
import { HIVE_TEMPLATES } from '../../../../constants/hiveNfts';


const MIN_CHARS_NEW_LEVEL = 2;

const countNextLevChars = ({ user, waxNfts, hiveNfts, travelingTo }) => {
  if (freeTrippers.includes(user)) return true;
  switch (travelingTo) {
    case 1: {
      const lev1Chars = 0
        // wax
        + getNftCount({ nfts: waxNfts, id: ITEMS_BY_CATEGORY.CHARACTERS.IV.ID })
        + getNftCount({ nfts: waxNfts, id: ITEMS_BY_CATEGORY.CHARACTERS.MI.ID })
        // hive
        + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.IVAN_BARRICK.name })
        + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.MINTA_HANI.name });
      return lev1Chars >= MIN_CHARS_NEW_LEVEL;
    }
    case 2: {
      const lev2Chars = 0
        // wax
        + getNftCount({ nfts: waxNfts, id: ITEMS_BY_CATEGORY.CHARACTERS.LEX.ID })
        + getNftCount({ nfts: waxNfts, id: ITEMS_BY_CATEGORY.CHARACTERS.EVE.ID })
        // hive
        + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.LEXI_CAYNE.name })
        + getNftCount({ hiveNfts, name: HIVE_TEMPLATES.EVELYN_MOORE.name });
      return lev2Chars >= MIN_CHARS_NEW_LEVEL;
    }
    case 3: { isLocal && console.debug('Ignored characters check for L3 - button not available anyway'); break; }
    default: { throw new Error(`Unexpected level ${travelingTo} in countNextLevChars`); }
  }
};


const wonCurrentLevel = ({ user, travelingTo, sessionData }) => {
  if (freeTrippers.includes(user)) return true;
  const sessionUnlockedLev = sessionData.currentUnlockedLev;
  return sessionUnlockedLev === travelingTo;
};


const checkHasCurrentEolNft = ({ nfts, travelingTo }) => {
  // TEMP @@
  // TEMP @@
  return true;
};


export const checkNextLevRequirements = ({
  waxNfts, hiveNfts, travelingTo, user, sessionData,
}) => {
  try {
    const hasEnoughNextLevCharacters = countNextLevChars({ user, waxNfts, hiveNfts, travelingTo });
    const wonCurrentLev = wonCurrentLevel({ user, travelingTo, sessionData });
    const hasEolNft = checkHasCurrentEolNft({ nfts: waxNfts, travelingTo });
    isLocal && console.log('Next level travel reqs:', { hasEnoughNextLevCharacters, wonCurrentLev, hasEolNft });
    return ({ hasEnoughNextLevCharacters, wonCurrentLev, hasEolNft });
  } catch (err) {
    console.error('Check next lev requirements broke:', err);
    return ({});
  }
};
