
const defaultState = { won: [], lost: [], validTimeout: [], unstaked: [] };

export const getPendingRewardToClaim = (rewardsToClaim = defaultState) => {
  let allClaimableAmount = 0;
  rewardsToClaim?.won.forEach((element) => {
    const amount = (element.boomAmount || element.amount || 0);
    allClaimableAmount += amount;
  });
  rewardsToClaim?.lost.forEach((element) => {
    const amount = (element.boomAmount || element.amount || 0);
    allClaimableAmount += amount;
  });
  rewardsToClaim?.validTimeout.forEach((element) => {
    const amount = (element.boomAmount || element.amount || 0);
    allClaimableAmount += amount;
  });

  rewardsToClaim?.unstaked.forEach((element) => {
    const amount = (element.boomAmount || element.amount || 0);
    allClaimableAmount += amount;
  });
  return allClaimableAmount;
};

export const getUnstakedSum = (unstakeArr = []) => {
  let sumToUnstake = 0;
  unstakeArr.forEach((element) => {
    sumToUnstake += (element.amount || element.boomAmount);
  });
  return sumToUnstake;
};
