import { isTestnet } from '../constants/envs';


export const WAX_NODES = {
  DEFAULT: isTestnet
    ? 'https://testnet.waxsweden.org'
    : 'https://wax.greymass.com',
  ALTS: isTestnet
    ? [
        // 'https://testnet.wax.eosdetroit.io',
        'https://waxtestnet.greymass.com',
      ]
    : [
        'https://wax.eosrio.io',
        'https://wax.eosphere.io',
        'https://api.waxsweden.org',
        'https://wax.blokcrafters.io',
    ],
};
