import { get } from 'lodash';
import { DOOM_SWAPS_RECEIVER, SWAP_WL } from '../../../../config/accounts';
import { isLocal } from '../../../../constants/envs';
import { chainId } from '../../../../constants/genericConstants';
import { pickHiveNode } from '../../../../generalUtils/hiveData/reader';
import { validateHiveUsername } from '../../../../sagas/helpers/wallet/hiveUtils';
import { nap } from '../../../../generalUtils/utils';
import { checkWaxUserExists } from '../../../../sagas/helpers/wallet/waxUtils';
import { transferBoomWithSession } from './sendBoomWithSession';
import { client } from '../../../../helpers/client';
import { LOST_BLOCKS_THRESHOLD, SWAP_RANGES } from './constants';


// ## SWAP INPUT VALIDATION

export const validateDoomToBoomInput = async ({ amountDoomSwap, doomSenderSwap, boomReceiverSwap }) => {
  isLocal && console.debug('Validating swap input:', { amountDoomSwap, doomSenderSwap, boomReceiverSwap });
  // amount
  if (Number.isNaN(amountDoomSwap)) return ({ err: 'The DOOM amount was not a number' });
  if (SWAP_WL.includes(doomSenderSwap)) {
    isLocal && console.log('Allowing transfer out of ranges for admin:', doomSenderSwap);
  } else {
    if (amountDoomSwap < SWAP_RANGES.DOOM.MIN) return ({ err: `the minimum amount of DOOM to swap is ${SWAP_RANGES.DOOM.MIN}` });
    if (amountDoomSwap > SWAP_RANGES.DOOM.MAX) return ({ err: `the max amount of DOOM to swap is ${SWAP_RANGES.DOOM.MAX}` });
  }
  if (!(amountDoomSwap > 0)) return ({ err: 'you must enter a positive amount' });
  // Hive sender
  const { err } = await validateHiveUsername({ username: doomSenderSwap });
  if (err) return ({ err });
  // Wax receiver
  const exists = await checkWaxUserExists({ account: boomReceiverSwap });
  if (!exists) return ({ err: `The provided Wax user ${boomReceiverSwap} does not exist` });
  return ({});
};

export const validateBoomToDoomInput = async ({ amountBoomSwap, boomSenderSwap, doomReceiverSwap }) => {
  isLocal && console.debug('Validating swap input:', { amountBoomSwap, boomSenderSwap, doomReceiverSwap });
  // amount
  if (Number.isNaN(amountBoomSwap)) return ({ err: 'The BOOM amount was not a number' });
  if (SWAP_WL.includes(boomSenderSwap)) {
    isLocal && console.log('Allowing transfer out of ranges for admin:', boomSenderSwap);
  } else {
    if (amountBoomSwap < SWAP_RANGES.BOOM.MIN) return ({ err: `the minimum amount of BOOM to swap is ${SWAP_RANGES.BOOM.MIN}` });
    if (amountBoomSwap > SWAP_RANGES.BOOM.MAX) return ({ err: `the max amount of BOOM to swap is ${SWAP_RANGES.BOOM.MAX}` });
  }
  if (!(amountBoomSwap > 0)) return ({ err: 'you must enter a positive amount' });
  // Hive sender
  const { err } = await validateHiveUsername({ username: doomReceiverSwap });
  if (err) return ({ err });
  // Wax receiver
  const exists = await checkWaxUserExists({ account: boomSenderSwap });
  if (!exists) return ({ err: `The provided Wax user ${boomSenderSwap} does not exist` });
  return ({});
};


// ## DOOM TRANSFER BROADCAST, FIND AND VALIDATE

const dhive = require('@hiveio/dhive');
const hiveClient = new dhive.Client(pickHiveNode());
const SCAN_TIMEOUT = 2.5 * 60 * 1000;
let stream;

let startTimeDoomSwap = 0;

export const transferDoomToBurner = ({ amountDoomSwap, doomSenderSwap }) => new Promise(async (resolve) => {
  if (startTimeDoomSwap) { // OR use time range: (Date.now() - startTimeDoomSwap) < DELAY_BETWEEN_SWAPS) {
    return resolve({ err: 'Only one DOOM swap allowed per session', notify: false });
  }
  startTimeDoomSwap = Date.now(); // here as first thing since following scans dont work (yet)
  try {
    // 1. ## Start litening for tx ID and block #
    console.log('Streaming the chain to find DOOM transfer..');
    listenForDoomTransferOnChain({ amountDoomSwap, doomSenderSwap, resolve }); // @@
  } catch (err) {
    resolve({ err: 'caught error while transfering DOOM', errDetails: err, seconds: (Date.now() - startTimeDoomSwap) / 1000 });
    stream && stream.pause();
    return;
  }
  try {
    await nap(1000); // COLD BOOT WAIT TIME
    // 2. ## DOOM Transfer
    isLocal && console.log('Starting DOOM transfer', { amountDoomSwap, doomSenderSwap });
    const success = await doomTransferBroadcast({ doomSenderSwap, amountDoomSwap });
    if (!success) {
      console.error('DOOM transfer failed:', { amountDoomSwap, doomSenderSwap });
      resolve({ err: 'DOOM transfer not completed successfully', notify: false, seconds: (Date.now() - startTimeDoomSwap) / 1000 });
      stream && stream.pause();
    }
  } catch (err) {
    resolve({ err: 'caught error while scanning for DOOM transfer', errDetails: err, notify: false, seconds: (Date.now() - startTimeDoomSwap) / 1000 });
    stream && stream.pause();
  }
});


export const doomTransferBroadcast = ({ doomSenderSwap, amountDoomSwap }) => new Promise((resolve) => {
  isLocal && console.debug('doomTransferBroadcast', { doomSenderSwap, amountDoomSwap });
  const payload = {
    contractName: 'tokens',
    contractAction: 'transfer',
    contractPayload: {
      symbol: 'DOOM',
      to: DOOM_SWAPS_RECEIVER,
      quantity: (+amountDoomSwap).toFixed(4),
      memo: 'DOOM swap',
    }
  };
  try {
    window.hive_keychain.requestCustomJson(
      doomSenderSwap, chainId, 'Active', JSON.stringify(payload), 'DOOM Swap',
      (response) => {
        if (response.success && response.result) {
            isLocal && console.debug('DOOM Transfer result:', response.result);
            resolve(true);
        } else {
            console.error('Oops, the DOOM Transfer failed!', response);
            resolve(false);
        }
      }
    );
  } catch (err) {
    console.error('Caught error transferring DOOM:', err);
    resolve(false);
  }
});

const listenForDoomTransferOnChain = ({ amountDoomSwap, doomSenderSwap, resolve }) => {
  // ## Handle Timeout
  setTimeout(() => {
    resolve({ err: 'scan-timeout' });
    stream && stream.pause();
  }, SCAN_TIMEOUT);
  // ## Active Streaming
  try {
    if (stream) stream.resume();
    else { stream = hiveClient.blockchain.getBlockStream(); }
    let missedBlocksCount = 0;
    stream
      .on('data', async (block = { transactions: [] }) => {
        // console.log(new Date(), 'Received block:', block);
        const foundInfo = findDoomTransfer({ block, amountDoomSwap, doomSenderSwap });
        if (foundInfo) {
          // check no errors in HE - best effort: if error ko, if breaks ok
          const { err: heErrArr } = await heHasNoErrors({ tx: foundInfo.tx });
          if (heErrArr) {
            resolve({ err: heErrArr, seconds: (Date.now() - startTimeDoomSwap) / 1000 });
            return;
          }
          const { tx, block } = foundInfo;
          resolve ({ hiveTx: tx, blockNum: block });
          stream.pause();
        }
      })
      .on('error', async (err) => {
        console.log('Stream error:', err);
        missedBlocksCount++;
        if (missedBlocksCount >= LOST_BLOCKS_THRESHOLD) resolve({
          err: `Lost at least ${LOST_BLOCKS_THRESHOLD} blocks scanning the Hive blockchain.\n(${err})`,
          seconds: (Date.now() - startTimeDoomSwap) / 1000,
        });
      })
      .on('end', (arg) => console.log('Done streaming', arg));
  } catch (err) {
    console.error('Caught error starting stream:', err);
    resolve({ err: 'getBlockStream broke', seconds: (Date.now() - startTimeDoomSwap) / 1000 });
  }
};

const findDoomTransfer = ({ block = {}, doomSenderSwap: sender, amountDoomSwap } = {}) => {
  const foundInfo = block.transactions.map(
    (blockData = {}, txIndex) => {
      isLocal && console.debug(`Checking tx ${txIndex} of current block..`);
      const currentBlockNum = blockData.block_num;
      const foundOp = get(blockData, 'operations', [])
        .find(([name = '', data = {} ] = []) => {
          // console.debug(`Checking operation "${name}" of tx ${txIndex}..`);
          if (!name || name !== 'custom_json') return false;
          if (data.required_auths[0] !== sender) return false;
          try {
            const parsedJson = JSON.parse(data.json);
            console.log('VALIDATING the custom json sent by the user:', { currentBlockNum, parsedJson, sender, amountDoomSwap });
            return isDoomTransfer({ parsedJson, amountDoomSwap });
          } catch (err) { console.error(`Error parsing custom json:`, { txIndex, name, data }); }
          return false;
        });
      if (foundOp) return ({ block: currentBlockNum, tx: get(block, `transaction_ids[${txIndex}]`) });
      return null;
    }
  ).filter(el => !!el)[0];
  foundInfo && console.debug('Find DOOM transfer outcome ::::', { foundInfo });
  return foundInfo;
};

const isDoomTransfer = ({ parsedJson, amountDoomSwap }) => {
  // {contractName: 'tokens', contractAction: 'transfer', contractPayload: { symbol: 'DOOM', to: DOOM_SWAPS_RECEIVER, quantity: +amountDoomSwap.toFixed(4), memo: 'DOOM swap'}}
  if (!parsedJson) return false;
  const { contractName, contractAction, contractPayload = {} } = parsedJson;
  if (contractName !== 'tokens' || contractAction !== 'transfer') return false;
  const { to, symbol, quantity, memo } = contractPayload;
  if (!to || to !== DOOM_SWAPS_RECEIVER || symbol !== 'DOOM' || quantity !== (+amountDoomSwap).toFixed(4)) return false;
  console.log('Found it!', { parsedJson });
  return true;
};


const SSC = require('sscjs');
const heApi = new SSC('https://api.hive-engine.com/rpc');
// heApi.stream((err, res) => {
// 	console.log(err, res);
// });

const heHasNoErrors = ({ tx }) => new Promise((resolve) => {
  isLocal && console.log('Querying HE for tx', tx);
  try {
    heApi.getTransactionInfo(tx, (err, result) => {
      if (err) {
        return resolve({}); // best effort
      }
      isLocal && console.log('HE tx:', err, result);
      try {
        const parsedJson = JSON.parse(result.logs);
        const { errors: errorsArr } = parsedJson || {};
        if (errorsArr && errorsArr.length)
        console.log('Found errrors in HE:', { errorsArr });
        resolve({ err: errorsArr });
      } catch (err) {
        console.error(`Error parsing custom json:`, { result }); // no failure, best-effort
      }
      return resolve({});
      // {"action":"transfer","blockNumber":20591280,"contract":"tokens","databaseHash":"1ab2dc3a794a0d2ab4c90ff81621403c4021ba89c8d29656a1322b23f6fef884","executedCodeHash":"815ad74975ef2099fb0d332f42eb5503e27c52c78002f3d408fb0ca67bc37edb","hash":"4cee93c049be6c6e5d5e7346e4f45b375ff1ea853d3628a1231b19510baf24a6","logs":{"events":[{"contract":"tokens","data":{"from":"crypto-shots","quantity":"20.0000","symbol":"DOOM","to":"cryptoshots.burn"},"event":"transfer"}]},"payload":{"isSignedWithActiveKey":true,"memo":"DOOM swap","quantity":"20.0000","symbol":"DOOM","to":"cryptoshots.burn"},"refHiveBlockNumber":67586367,"sender":"crypto-shots","transactionId":"bf80ad020a5ad1d641abf4d8a31240710abf956d"}
    })
  } catch (err) {
    console.error('Something broke fetching HE tx:', err);
    resolve({}); // best effort
  }
});


// ## BOOM TRANSFER. Spins up temporary session if needed.

const DELAY_BETWEEN_SWAPS = 70 * 1000; // keep synched with api
let startTimeBoomSwap = 0;

export const transferBoomToBurner = ({ amountBoomSwap, boomSenderSwap }) => new Promise(async (resolve) => {
  if ((Date.now() - startTimeBoomSwap) < DELAY_BETWEEN_SWAPS) {
    return resolve({ err: 'please wait about one minute between BOOM swaps' });
  }
  try {
    setTimeout(() => resolve({ err: 'boom send timeout' }), 60 * 1000);
    // ## BOOM Transfer
    isLocal && console.log('Starting BOOM transfer', { amountBoomSwap, boomSenderSwap });
    const { err, errDetails, tx } = await transferBoomWithSession({
      amountBoomSwap, boomSenderSwap,
    });
    if (err || errDetails) {
      console.error('Boom transfer error:', { err, errDetails });
      resolve({ err: err || 'BOOM transfer KO', errDetails });
      return;
    }
    isLocal && console.debug('Boom transfer done:', { tx });
    startTimeBoomSwap = Date.now();
    resolve({
      waxTx: tx,
    });
  } catch (err) {
    console.error('caught error while transfering BOOM:', err);
    resolve({ err: 'caught error while transfering BOOM', errDetails: err });
  }
});


// ## ASK BACKEND TO USE VALID TX TO PROCESS SWAP

export const sendSwapToBackend = async ({
  hiveSender, hiveTx, waxUsername, hiveBlockNum, // Hive
  waxSender, waxTx, hiveUsername, // Wax
  authenticatedUser,
}) => {
  // CREATE USER IF DOES NOT EXIST
  isLocal && console.log('Sending tx to backend:', { authenticatedUser,
    hive: { hiveSender, hiveTx, waxUsername, hiveBlockNum },
    wax: { waxSender, waxTx, hiveUsername },
  });
  try {
    isLocal && console.log('Sending lookup request (create user if needed)');
    const testCallResp = await client.post('/api/user/get',
      { account: authenticatedUser },
    );
    if (testCallResp.status > 300) {
      console.error('account creation/lookup call fail:', { testCallResp });
      return ({ err: 'something broke looking up your account in our system' });
    } else {
      isLocal && console.log('LFG >>>>', { testCallResp });
    }
  } catch (err) {
    console.error('Caught error during account creation/lookup:', err);
    return({ err: `Caught during account creation/lookup: ${err} - ${JSON.stringify(err)}` });
  }
  // SWAP REQUEST TO BACKEND
  isLocal && console.log('Sending swap api request');
  if (hiveTx) {
    let swapResp;
    const wasOnline = navigator.onLine;
    const apiCallStartTime = Date.now();
    try {
      swapResp = await client.post('/api/hive/bridge/doom-boom',
      { account: authenticatedUser, hiveSender, trxId: hiveTx, waxUsername, blockNum: hiveBlockNum },
      );
      if (swapResp.status >= 300 || swapResp.data?.err) {
        console.error('Uh oh, something went wrong during doom swap:', { swapResp });
        return ({ err: JSON.stringify(swapResp.data) });
      }
      const txRec = get(swapResp, 'data.response.transaction_id');
      isLocal && console.log('doom swap success', { swapResp });
      return ({ txRec: `https://wax.bloks.io/transaction/${txRec}` });
    } catch (err) {
      if (err.response) {
        // The request was made and the server responded with a status code not 2xx
        const { status, data, headers } = err.response; // eslint-disable-line no-unused-vars
        return ({ err: `[err.response case] Caught during doom swap: ${JSON.stringify({ status, data })}` });
      } else if (err.request) {
        // The request was made but no response was received
        console.error('No response received:', err.request);
        return ({
          err: `[no err.response, just err.request] Caught during doom swap: ${err} - ${
            JSON.stringify({ ...err, request: err.request })}`
        });
      }
      const durationMs = Date.now() - apiCallStartTime;
      return({ err: `Caught during doom swap: [wasOnline? ${wasOnline}] [durationMs:${durationMs}] ${
        err} - ${err.message} - ${JSON.stringify(err)}` });
    }
  } else if (waxTx) {
    let swapResp;
    const wasOnline = navigator.onLine;
    const apiCallStartTime = Date.now();
    try {
      swapResp = await client.post('/api/hive/bridge/boom-doom',
      { account: authenticatedUser, waxSender, trxId: waxTx, hiveUsername },
      );
      if (swapResp.status >= 300 || swapResp.data?.err) {
        console.error('Uh oh, something went wrong during boom swap:', { swapResp });
        return ({ err: JSON.stringify(swapResp.data) });
      }
      const txRec = get(swapResp, 'data.response.hiveTrxId');
      isLocal && console.log('boom swap success', { swapResp });
      return ({ txRec: `https://hiveblocks.com/tx/${txRec}` });
    } catch (err) {
      console.error('Caught error during boom swap:', err);
      const durationMs = Date.now() - apiCallStartTime;
      return({ err: `Caught during boom swap: [wasOnline? ${wasOnline}] [durationMs:${durationMs}] ${
        err} - ${err.message} - ${JSON.stringify(err)}` });
    }
  } else {
    console.error('?wat?', { hiveSender, waxSender });
  }
};
