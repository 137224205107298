import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Animated } from "react-animated-css";

import { SendWax } from './subComponents/SendWax';
import { WaxBalance } from './subComponents/WaxBalance';
import { LiquidityPools } from './subComponents/LiquidityPools';
import { ChainChoice } from './subComponents/ChainChoice';
import { Swaps } from './subComponents/Swaps';
import { BoomPane, MarsPane } from './subComponents/WaxTokensPanes';
import { BoomStakingForMars, MarsStakingForGas } from './subComponents/WaxTokensStaking';
import { HiveBalanceAndSend, DoomBalanceAndSend } from './HiveTokensPanes';
import './Wallet.scss';
import { BtcLinkPane } from './subComponents/BtcLinkPane';


const TokenPanes = ({ whichActiveChain }) => {
  const PlayState = useSelector((store) => store.play);
  const wrapperData = PlayState.wrapperData;
  
  const InventoryState = useSelector((store) => store.inventory);
  const scholarshipData = get(InventoryState, 'scholarship', {});

  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;

  return (
    <>
      <div className="container col-6">
        <div className="col-12 text-center container-left flashy-box">
          <div className="row">
            {whichActiveChain === 2
              ? <>
                  <HiveBalanceAndSend />
                </>
              : <>
                  <WaxBalance/>
                  <SendWax chainLogin={chainLogin} />
                </>}
          </div>
        </div>
        <div className="col-12 mt-4 text-center container-left flashy-box">
          <div style={{ padding: '10px' }}>
            {whichActiveChain === 2
              ? <div>
                  <p className="text-white" style={{ fontSize: '20px', margin: '-15px auto -10px' }}>HIVE TOKENS</p>
                  <BoomPane {...{ wrapperData, scholarshipData, chainLogin }} />
                  <MarsPane {...{ wrapperData, scholarshipData, chainLogin }} />
                </div>
              : <>
                  <p className="text-white" style={{ fontSize: '20px', margin: '-15px auto -10px' }}>WAX TOKENS</p>
                  <BoomPane {...{ wrapperData, scholarshipData, chainLogin }} />
                  <MarsPane {...{ wrapperData, scholarshipData, chainLogin }} />
                </>}
          </div>
        </div>
      </div>
      <div className="container col-6">
        <div className="row" style={{ marginTop: '-10px' }}>
          <Swaps chainLogin={chainLogin}/>
          <div className="col-12 mt-2 text-center container-left flashy-box">
            <p className="text-white" style={{ fontSize: '20px', marginTop: '-10px' }}>STAKING</p>
            <div className="row mt-2 pad10">
              <div className="col-12 pad10">
                  <LiquidityPools whichActiveChain={whichActiveChain} />
              </div>
              {whichActiveChain === 1 && <>
                  <div
                    className="row text-white text-center" style={{ margin: '30px auto -10px' }}
                  >
                    <p>Smart Contracts</p>
                  </div>
                  <div className="col-6 mt-0 flashy-box" style={{ fontSize: '14px' }}>
                    <h6 className='mt-3 text-white'>
                      $BOOM STAKING <sub>(to earn $MARS)</sub>
                    </h6>
                    <BoomStakingForMars/>
                  </div>
                  <div className="col-6 mt-0 flashy-box"  style={{ fontSize: '14px' }}>
                    <h6 className='mt-3 text-white'>
                      $MARS STAKING <sub>(to earn $GAS)</sub>
                    </h6>
                    <MarsStakingForGas/>
                  </div>
                </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export const Wallet = () => {
  const UserState = useSelector((store) => store.user);
  const chainLogin = UserState.chainLogin;
  const [whichActiveChain, setActiveChain] = useState(chainLogin === 'wax' ? 1 : 2);

  return (
    <div className="container wallet">
      <Animated animationIn="slideInDown" animationOut="slideOutLeft" animationInDuration={500} animationInDelay={50} animationOutDuration={3000} isVisible={true}>
      <ChainChoice choseChain={setActiveChain} activeBtn={whichActiveChain} />
        <br/>
        <div id="wallet-wrapper" className="row">
          {whichActiveChain === 3 ? <BtcLinkPane /> : <TokenPanes {...{ whichActiveChain }}/>}
        </div>
      </Animated>
    </div>
  );
}
