import React from 'react';
import { UserService } from '../../../services/UserService';
import { isTestnet } from '../../../constants/envs';
import { clearFields, waxWalletFieldsValidation } from '../utils';
import Swal from 'sweetalert2';


export const SendWax = ({ chainLogin }) => {
  const onHandleSendWax = () => {
    const amountWaxElem = document.getElementById('amount-wax');
    const recipientWaxElem = document.getElementById('recipient-wax');
    const memoWaxElem = document.getElementById('memo-wax');
    const { err, recipient, toSend, memo } = waxWalletFieldsValidation({
      amountElem: amountWaxElem, recipientElement: recipientWaxElem, memoElement: memoWaxElem,
    });
    if (err) {
      console.error('Wax send validation error:', err);
      return;
    }
    isTestnet && console.debug('sending wax request');
    UserService.session.signTransaction(
      {
        actions: [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: UserService.authName,
            permission: 'active'
          }],
          data: {
            from: UserService.authName,
            to: recipient,
            quantity: `${toSend} WAX`,
            memo: `WAX transfer from Crypto-Shots Web UI: ${memo}`,
          },
        }],
      },
      {
        blocksBehind: 3,
        expireSeconds: 30
      }
    ).then((response) => {
      isTestnet && console.log('wax send response status:', { response });
      clearFields({ amountElem: amountWaxElem, recipientElement: recipientWaxElem });
      const isOk = window.confirm('✅ WAX Transfer success! Do you want to see the transaction?');
      if (isOk)
        window.open(`https://wax${isTestnet ? '-test' : ''}.bloks.io/transaction/${response.transactionId}`, '_blank');
      if (response.status === 'executed') {
        UserService.getBalance();
      }
    })
      .catch(err => Swal.fire({text: `❌ WAX transfer failed - ${err}`}));
  };

  return (<div className="col-7" style={{ padding: '10px 15px' }}>
    <div id="wax-send-wrap" className="row flashy-box" style={{ textAlign: 'left !important', padding: '10px', width: '350px' }}>
      <div className="col-3 btn-send">
        <button
          id="send-wax-btn"
          onClick={onHandleSendWax}
          className="btn btn-success btn-lg"
          style={{ margin: '10px auto auto -15px' }}
          disabled={chainLogin === 'hive'}
        >
          Send WAX
        </button>
      </div>
      <div className="col-6 send-wax" style={{ marginLeft: '80px' }}>
        <input id="recipient-wax" type='text' placeholder='RECIPIENT'></input>
        <input id="amount-wax" type='number' placeholder='AMOUNT'></input>
        <input id="memo-wax" type='text' placeholder='MEMO (optional)' style={{ marginTop: '10px' }}></input>
      </div>
    </div>
  </div>);
};
