import React from 'react';
import { useSelector } from 'react-redux';
import { ICONS } from '../../config/config';


export const PlayerMode = ({ singleplayerHandler, multiplayerHandler, mode }) => {
  const { CONTROLLER } = ICONS;
  
  const PlayState = useSelector((store) => store.play);
  const percentLoaded = PlayState.isPercentLoaded;
  const isPvp = localStorage.getItem('lastModePlayed') === 'pvp';

  const extraTextPlayBtn = percentLoaded < 100
    ? <span className="italic bold">{percentLoaded.toFixed()}% loaded</span>
    : <></>;

  return (<>
    <div className="col-2"></div>
    <div id={`${mode}-btn-wrap-pve`} className="col-4 text-center">
      <div className="free-wrapper">
        <button
          className="free-trial btn btn-success btn-lg"
          onClick={singleplayerHandler}
          style={{ paddingTop: 0, minWidth: '220px' }}
        >
          <img
            src={CONTROLLER} alt="Loggin" width="54"
            style={{ float: 'left', marginTop: '10px' }}
          /> &nbsp;
          <p style={{ marginTop: '-5px', fontSize: '16px', lineHeight: '30px' }}>
            <span>SINGLE PLAYER</span><br />
            {isPvp ? <></> : <span className="pc-loaded">{extraTextPlayBtn}</span>}
          </p>
        </button>
      </div>
    </div>
    <div
      id={`${mode}-btn-wrap-pvp`} className="col-4 text-center"
      style={{ marginLeft: '10px' }}
    >
      <div className="free-wrapper">
        <button
          className="free-trial btn btn-success btn-lg"
          onClick={multiplayerHandler}
          style={{ paddingLeft: 0, minWidth: '220px' }}
        >
          <div style={{ float: 'left', marginLeft: '35px' }}>
            <img
              src={CONTROLLER} alt="Loggin" width="54"
              style={{ marginLeft: '-30px' }}
            /> &nbsp;
            <img
              src={CONTROLLER} alt="Loggin" width="54"
              style={{ marginLeft: '-23px' }}
            />
          </div>
          <p style={{ marginTop: '5px', lineHeight: '30px' }}>
            <span style={{ fontSize: '15px' }}>MULTIPLAYER</span>
            {isPvp ? <span className="pc-loaded">{extraTextPlayBtn}</span> : <></>}
          </p>
        </button>
      </div>
    </div>
  </>);
};
