import React from 'react';
import { get, invoke } from 'lodash';
import { storeAppDispatch } from '../../../../../GlobalState/Store';
import { UserService } from '../../../../../services/UserService';
import { isLocal } from '../../../../../constants/envs';
import { useSelector } from 'react-redux';
import { resetLastMatch, setActiveLevel, setInTravel } from '../../../../../reducers/PlayReducer';
import { nap } from '../../../../../generalUtils/utils';
import { getTravelDuration, payForSpaceshipTravel, spaceshipTimer } from '../travels';
import { checkSpaceshipPassOrOwner } from '../freeTravels';
import { hasImmutableValueOf } from '../../../../../generalUtils/secure';
import { uiHacks } from '../../../../../generalUtils/uiHacks/uiHacks';
import { Animated } from 'react-animated-css';
import { mapSrc } from '../ui';
import { checkNextLevRequirements } from '../nextLevRequirements';
import { ThreeSpaceships } from './ThreeSpaceships';
import { countCharsToPayFor } from '../../utils/countChars';
import { SS_INFO } from './spaceshipsInfo';
import { setFreeSpaceshipUsage } from '../../../../../components/Menu/LeftMenu/storage/utils';


export const TravelSection = ({ travelPage, resetLevelChoice, inTravel }) => {
  const PlayState = useSelector((store) => store.play);
  const activeLevel = PlayState.activeLevel;
  // if (!travelPage) return null;

  const StorageState = useSelector((store) => store.marsStorage);
  const { storageType } = StorageState;

  return (
    <div className="row inner-container" id="traveling">
      {/* IN TRAVEL */}
      {inTravel && <div>
        <Animated
          animationIn="slideInDown" animationOut="slideOutLeft" animationInDuration={3000}
          animationInDelay={500} animationOutDuration={3000} isVisible={true}
        >
          <img id="loading-map" src={mapSrc(activeLevel + 1)} alt="map" /><br/><br/>
        </Animated>
          <p id="time-to-destination">Time to your destination: <span id="countdown">00:00:00</span></p>
          {!storageType && <span style={{ fontWeight: 'bold', color: 'yellow', backgroundColor: 'black' }}>
              <b>No travel insurance!</b>👇 Get a $MARS subscription and chill.
            </span>
          }
        </div>
      }
      {/* SPACESHIP CHOICE */}
      {!inTravel && <SpaceTravelChoice {...{ travelPage, resetLevelChoice }} />}
    </div>
  );
};

const SpaceTravelChoice = ({ travelPage, resetLevelChoice }) => {
  // play state
  const PlayState = useSelector((store) => store.play);
  const activeLevel = PlayState.activeLevel;
  const travelingTo = activeLevel + 1;

  // inventory
  const InventoryState = useSelector((store) => store.inventory);
  const nfts = get(InventoryState, 'csAssets.csNfts', []);
  const hiveNfts = get(InventoryState, 'hive.nfts', []);
  const waxScholarshipData = get(InventoryState, 'scholarship', {});
  const { isScholar } = waxScholarshipData;

  // storage
  const StorageState = useSelector((store) => store.marsStorage);
  const { resourcesPaid = [], storageType } = StorageState;
  const subAlreadyPaidTripWithSs1 = !!resourcesPaid.find(
    paidObj => paidObj?.type === 'travel' && paidObj?.toLevel === (activeLevel + 1) && paidObj?.spaceshipId === 1
  );
  const subAlreadyPaidTripWithSs2 = !!resourcesPaid.find(
    paidObj => paidObj?.type === 'travel' && paidObj?.toLevel === (activeLevel + 1) && paidObj?.spaceshipId === 2
  );
  const subAlreadyPaidTripWithSs3 = !!resourcesPaid.find(
    paidObj => paidObj?.type === 'travel' && paidObj?.toLevel === (activeLevel + 1) && paidObj?.spaceshipId === 3
  );

  // freebies
  const { freeSs1: freeSs1Og, freeSs2: freeSs2Og, freeSs3: freeSs3Og } = checkSpaceshipPassOrOwner({
    nfts, user: UserService.authName, travelingTo,
  });
  const freeSs1 = freeSs1Og || subAlreadyPaidTripWithSs1 || window.skippedResourcesPaym;
  const freeSs2 = freeSs2Og || subAlreadyPaidTripWithSs2 || window.skippedResourcesPaym;
  const freeSs3 = freeSs3Og || subAlreadyPaidTripWithSs3 || window.skippedResourcesPaym;
  isLocal && console.debug('Free travels:', { freeSs1, freeSs2, freeSs3 }, { resourcesPaid });

  // requirements
  const previousLevel = Math.max(0, travelPage - 1);
  const { sessionData = {} } = PlayState.wrapperData;
  const { hasEnoughNextLevCharacters, wonCurrentLev, hasEolNft } = checkNextLevRequirements({
    waxNfts: nfts, hiveNfts, travelingTo, user: UserService.authName, sessionData,
  });
  const canUnlockNextLevel = isLocal || (hasEnoughNextLevCharacters && wonCurrentLev && hasEolNft);

  // spaceships info
  const charactersCount = countCharsToPayFor({ isScholar, waxNfts: nfts, hiveNfts });
  const { NAME: busName, ENGINE: busEngine, SRC: busSrc, DURATION: busDuration } = SS_INFO.TO_L0;


  const bookTravel = async ({ spaceshipId, currency, isMostlyHive }) => {
    try {
      // set half rewards if free
      if (spaceshipId === 0) {
        // - cond1: for following levels that re-use the free bus
        // - cond2: 90% fee for skipping food/gas takes precedence
        if (window.freeShip !== true && !window.skippedResourcesPaym) setFreeSpaceshipUsage();
      } else {
        const skippedPayments = hasImmutableValueOf({
          target: window, attribute: 'skippedResourcesPaym', matchValue: true,
        });
        const isThisTripFree = (spaceshipId === 1 && freeSs1) || (spaceshipId === 2 && freeSs2)
          || (spaceshipId === 3 && freeSs3) || skippedPayments || isMostlyHive;
        if (!isThisTripFree) {
          isLocal && console.debug('[l1-load] Asking for payment...');
          const { err } = await payForSpaceshipTravel({ spaceshipId, currency, charactersCount, activeLevel, storageType });
          if (err) return;
        }
      }
      // travelling
      const newState = { travelling: true };
      storeAppDispatch(setInTravel(newState));
      // start timer
      isLocal && console.debug('[l1-load] Starting travel timer...');
      const travelDuration = getTravelDuration({ spaceshipId, travelingTo });
      await spaceshipTimer({ travelDurationMs: travelDuration, unityLoad: true });
      // launch level-1 in unity
      isLocal && console.debug('[l1-load] Launching Unity...');
      invoke(window, 'loadUnity', { level: activeLevel + 1 });
      isLocal && console.debug('[l1-load] Unity launched.');
      // Show map + (loading..)
      // @@@
      await nap(10 * 1000);
      // Reset UI
      isLocal && console.debug('[l1-load] Updating Play page state: travel state, active level, last match, ..');
      storeAppDispatch(setInTravel({ travelling: false }));
      storeAppDispatch(setActiveLevel({ activeLevel: activeLevel + 1 }));
      resetLevelChoice(activeLevel + 1);
      storeAppDispatch(resetLastMatch());
      uiHacks.disableClaimAndPlayBtnsAtFirst({ claimOnly: true });
    } catch (err) {
      console.error('Book travel broke:', err);
    }
  };

  return (
    <>
      {/* TRAVEL CLOSE BTN */}
      <button
        onClick={() => resetLevelChoice(activeLevel)}
        style={{
          color: 'white', padding: '3px', backgroundColor: 'rgba(100,100,100,0.3)',
          borderBottomRightRadius: '20px', display: 'block',
          width: '35px', height: '35px', outline: 'none', border: 'none',
        }}
      >X</button>
      <div className="col-12" id="fares-title">
        SPACESHIP TRAVEL FARES
      </div>
      {/* TRAVEL REQUIREMENTS */}
      <div id="requirements">
        <h4>Are you able to travel to LEVEL {travelPage}?</h4>
        <ul>
          <li>You have the End-Of-Level {previousLevel} NFT? <sub>(won defeating all enemies on HARD)</sub>&nbsp;
            <span className="green">&nbsp; {hasEolNft ? <span className="green">true</span> : <span className="red">false</span>}</span> <sub>(temporarily always true)</sub>
          </li>
          <li>Completed Level-{previousLevel} on MEDIUM at least once in this session? &nbsp; {wonCurrentLev ? <span className="green">true</span> : <span className="red">false</span>}</li>
          <li>You have at least 2x Level-{activeLevel + 1} characters? &nbsp; {hasEnoughNextLevCharacters ? <span className="green">true</span> : <span className="red">false</span>}</li>
        </ul>
      </div>
      {/* SPACESHIP PRICES AND TIMES */}
      <div className="row spaceship-choice">
        {/* FREE SPACESHIP */}
        <div className="col-3 spaceship-pane">
          <img id="free-bus" src={busSrc} style={{ marginTop: '10px' }} alt="Free Spaceship"></img>
          <div class="row">
            <p className="spaceship-name">{busName}</p>
            <p className="wait-time">Travel duration: <b>{busDuration}</b></p>
            {/* <p className="travel-type">propulsion engines</p> SPACESHIP CAPTAIN = LAND? :D */}
            <p className="engine-type">{busEngine}</p>
          </div>
          {canUnlockNextLevel && <>
              <button onClick={() => bookTravel({ spaceshipId: 0 })}>FREE...</button>
              {!window.skippedResourcesPaym && <p className="warning">
                {!window.freeShip
                  ? <span>...but the spaceship <span id="damn-captain">captain keeps half</span><br />of all your <b>NEW</b> rewards!</span>
                  : <b>The captain is already keeping half rewards from your matches!</b>
                }
              </p>}
            </>
          }
        </div>
        {/* NON-FREE SPACESHIPS */}
        <ThreeSpaceships {...{
          bookTravel, canUnlockNextLevel, charactersCount,
          freeSs1, freeSs2, freeSs3,
        }} />
      </div>
    </>
  );
};
