import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { storeAppDispatch } from '../../../../../GlobalState/Store';
import { setPlayerData } from '../../../../../reducers/UserReducer';
import { useHistory } from 'react-router-dom';
import { verifyUserIdentity } from '../../../../../sagas/helpers/login/verifyUser';
import { setPlayerInventory } from '../../../../../reducers/InventoryReducer';
import { isLocal, isTestnet } from '../../../../../constants/envs';
import { blacklistedUsers, multiTabCheckAfterLogin, usernamesChecks } from '../../../../../generalUtils/secure';
import { setFreeTrial } from '../../../utils/freeTrial';
import { loadStorage } from '../../../../../components/Menu/LeftMenu/storage/init/loadStorage';
import { setUrlParamApiCalls } from '../../../../../helpers/client';
import { ICONS, LINKS } from '../config';
import { navigatePostLogin } from '../../Shared/utils/navigation';


export const SkaleLoginPanel = () => {
  const UserState = useSelector((store) => store.user);
  const { chainLogin, acceptedTac } = UserState;

  const PlayState = useSelector((store) => store.play);
  const { isPvpGameMode: isPvp } = PlayState;

  const locationHistory = useHistory();

  
  const skaleLoginSteps = async ({ account, message, signature }) => {
    // # BLACKLIST CHECK
    const blacklistedUserData = blacklistedUsers[account];
    if (blacklistedUserData) {
      isTestnet && console.log('401 reason: ', blacklistedUserData);
      Swal.fire({ text: `Uh oh, there seems to be a problem with your account.\nPlease reach out to support on Discord.` });
      return;
    }
    // # VERIFICATION IN BACKEND
    setUrlParamApiCalls({ chain: chainLogin });
    try {
      await verifyUserIdentity({ username: account, message, signed_message: signature, chainLogin });
    } catch (err) {
      handleLoginError('Something broke. Please try again.');
      return;
    }
    // Free Trial flag
    setFreeTrial({ value: false, from: 'login-btn' });

    // ## Set LOGGED-IN USER in local state
    storeAppDispatch(setPlayerData({
      name: account,
      isLogged: true,
    }));
    localStorage.setItem('chainLogin', chainLogin);

    // anti-cheat
    usernamesChecks(account);

    // // # FETCH HIVE NFTS
    // const { hiveNftsArr /*, teacher*/ } = await fetchHiveNfts({ hiveUsername: username });
    // // UI next login wait
    // localStorage[hiveNftsArr.length ? 'setItem' : 'removeItem']('hivenfts', true);

    // const { linkedAccounts, err: errDataFetch } = await fetchUserDataOnly({ account: username, chain: chainLogin });
    // isLocal && console.debug('cross-chain account linked:', { linkedAccounts, errDataFetch });f
    // if (errDataFetch) {
    //   Swal.fire({ text: `Something went wrong fetching your data. Please try again.` });
    //   storeAppDispatch(setPlayerLogout());
    //   return;
    // }
    // const { wax: linkedWaxAccount, btc: linkedBtcAddress } = linkedAccounts || {};
    // isLocal && console.log('{hl} fetching inventory for Wax linked user', linkedWaxAccount);
    // let csNfts = [];
    // let hotNfts = {};
    // if (linkedWaxAccount) {
    //   // # FETCH WAX {INVENTORY}
    //   // eslint-disable-next-line no-unused-vars
    //   const inventoryResponse = await processInventory({ username: linkedWaxAccount });
    //   const { err: errWaxInventory, scholarship: _notSupported_ } = inventoryResponse;
    //   csNfts = inventoryResponse.csNfts;
    //   hotNfts = inventoryResponse.hotNfts;
    //   if (errWaxInventory) {
    //     console.error('Error fetching linked wax inventory for hive user', errWaxInventory);
    //     storeAppDispatch(setPlayerLogout());
    //     return;
    //   }
    //   localStorage[csNfts.length === 0 ? 'removeItem' : 'setItem']('waxnfts', true);
    //   // let kitNft;
    //   // const hasNoNfts = csNfts.length === 0 && Object.keys(hotNfts).length === 0;
    //   // if (hasNoNfts) {
    //   //   kitNft = { [MINIMUM_REQUIREMENTS_INFO]: '' }; // HACK @@
    //   // }
    // }

    const currentState = {
      chainLogin: chainLogin,
      // wax
      csAssets: { csNfts: [], hotNfts: [] },
      // hive
      hive: { nfts: [] },
      // skale
      skale: { nfts: [] },
      // eth
      eth: { nfts: [], username: account },
      // scholarship: null,
    };
    storeAppDispatch(setPlayerInventory(currentState));

    // ## Routing
    navigatePostLogin({ locationHistory });

    // ## Fetch Balances
    // isLocal && console.log('{hl} fetching boom balance');
    // const boomAmount = await fetchBoomBalance(UserService.authName);
    // const savingsAmount = await Promise.resolve(0); // TEMP for savings @@ @@ @@ @@
    // storeAppDispatch(setPlayerWaxTokensBalances({
    //   tokens: { boom: boomAmount },
    //   stakedSavings: { boom: savingsAmount }
    // }));
    // // Whitelist check (TESTNET only)
    // isLocal && console.log('{hl} checking whitelist items');
    // const missingItem = await verifyAccessAllowed(UserService.authName, csNfts); // IMPROVEMENT: just check the NFTs just fetched above !!!
    // if (missingItem) {
    //   const isOk = window.confirm(`${UserService.authName} the following item is required to access this version of the game: ${(missingItem || '').replace(/_/g, ' ')}\n\nUnless it's a new mint, keep in mind the 12 hour cooldown.`);
    //   if (isOk)
    //     window.open('https://wax.atomichub.io/market?collection_name=crypto4shots&order=desc&schema_name=early.access&sort=created&symbol=WAX', '_blank');
    //   storeAppDispatch(setPlayerLogout());
    //   !isLocal && window.location.reload();
    // }

    // # MULTI SESSION CHECKS
    multiTabCheckAfterLogin();

    // # LOAD STORAGE 
    loadStorage({ user: account });
    isLocal && console.log('{hl} all done');
  };

  
  const SKALE_NETWORK = {
    chainId: isTestnet
      ? '0x235ddd0'   // ie. 37084624
      : '0x585eb4b1', // ie. 1482601649
    chainName: isTestnet ? 'SKALE Nebula Testnet' : 'SKALE Nebula Hub',
    nativeCurrency: {
      name: 'sFUEL',
      symbol: 'sFUEL',
      decimals: 18,
    },
    rpcUrls: [
      isTestnet
        ? 'https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet'
        : 'https://mainnet.skalenodes.com/v1/green-giddy-denebola',
    ],
    blockExplorerUrls: [
      isTestnet
        ? 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com'
        : 'https://green-giddy-denebola.explorer.mainnet.skalenodes.com',
    ],
  };

  const addSkaleNetwork = async () => {
    try {
      await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [SKALE_NETWORK]
      });
      isLocal && console.log('SKALE network added to MetaMask');
      return ({});
    } catch (err) {
      console.error('Error adding SKALE network to MetaMask', err);
      const errMsg = <span>Failed to add Skale Network.</span>;
      handleLoginError(errMsg);
      return ({ err });
    }
  }

  const checkAndAddSkaleNetwork = async () => {
    try {
      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
      if (currentChainId !== SKALE_NETWORK.chainId) {
        const { err } = await addSkaleNetwork();
        if (err) return ({ err });
      }
    } catch (err) {
      console.error('Error checking if the SKALE network is available', err);
      const errMsg = <span>Failed to check Skale Network status.</span>;
      handleLoginError(errMsg);
      return ({ err });
    }
    return ({});
  }

  const signMessage = async (account, message) => {
    try {
      const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [message, account],
      });
      return ({ signature });
    } catch (error) {
      console.error('Something went wrong signing the message:', error);
      return ({ err: 'Failed to sign the message to log in' });
    }
  }

  const handleLogin = async () => {
    // Game mode check
    if (!isPvp) {
      Swal.fire({ text: `For now only Multiplayer mode is supported on Skale` });
      return;
    }
    // UI
    setIsLoading(true);
    // Metamask Check
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      const errMsg = <span>
          <a
            id="keychain-installer"
            href={LINKS.METAMASK_INSTALL} target="_blank" rel="noreferrer"
          >MetaMask </a> not found, please install it.
        </span>;
      handleLoginError(errMsg);
      toast.error('Ethereum wallet not found');
      return;
    }
    // Skale Network check
    const { err: errChain } = await checkAndAddSkaleNetwork();
    if (errChain) return;
    isLocal && console.log('Skale network enabled in MetaMask');
    // Retrieve ETH address to use
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0]; // use the first account
    if (!account) {
      const errMsg = <span>No Ethereum address found</span>;
      handleLoginError(errMsg);
      return;
    }
    // Sign message
    const message = "Crypto Shots login";
    const { signature, err } = await signMessage(account, message);
    if (err) {
      const errMsg = <span>Failed to sign the message to log in</span>;
      handleLoginError(errMsg);
      return;
    }
    isLocal && console.log('Signature:', signature);
    // Verify signature and next steps
    skaleLoginSteps({ account, message, signature });
  };


  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginError = (errMsg) => {
    setLoginError(errMsg);
    setIsLoading(false);
  };

  return (<div>
    <div id="login-wrapper" className="text-center">
      <button
        id="login-btn"
        className={`${acceptedTac ? 'active' : 'disabled'} skl-login`}
        disabled={!acceptedTac || isLoading}
        onClick={handleLogin}
      >
        <img src={ICONS.DOOR_LOGIN} alt="Loggin" width="24" /> &nbsp;
        {isLoading
          ? <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i>
          : <span>MetaMask Login</span>}
      </button>
      {loginError && <p id="hive-login-error">{loginError}</p>}
    </div>
    {!loginError && <h6 id="sign-up" className="mt-4 text-center">
        No MetaMask Browser Wallet? &nbsp;
        <a
          href={LINKS.METAMASK_INSTALL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <sub id="signup-cta">Get It Here</sub>
        </a>
      </h6>
    }
  </div>);
};
