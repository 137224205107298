import { extractNftsInfo, groupHotNfts } from './extractNfts';
import { nap } from '../../../generalUtils/utils';
import { get } from 'lodash';
import { isLocal } from '../../../constants/envs';


export const fetchInventory = async (waxAccountName) => {
  try {
    return fetch(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=crypto4shots&owner=${
        waxAccountName
      }&page=1&limit=100&order=desc&sort=asset_id`,
      {
        headers: {
          accept: 'application/json',
          'cache-control': 'no-cache',
          pragma: 'no-cache',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'cross-site',
          'sec-gpc': '1',
        },
        referrer: 'https://wax.bloks.io/',
        referrerPolicy: 'strict-origin-when-cross-origin',
        body: null,
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
      })
        .then(res => res.json())
        .then(async (result) => {
          let nftsArr = result.data;
          if (nftsArr.length === 0) {
            // const isOk = window.confirm('It looks like you dont have the NFTs required to play. Want to purchase them from AtomicHub?');
            // if (isOk) window.open('https://wax.atomichub.io/market?collection_name=crypto4shots&order=asc&schema_name=cs.startpack&sort=price&symbol=WAX', '_blank');
          }
          const responseOwner = get(result, 'data[0].owner');
          if (responseOwner && responseOwner !== waxAccountName) {
            console.error('Account mismatch:', responseOwner, waxAccountName);
            nftsArr = []; // need to empty because otherwise it has another account data (yeh wtf)
          }
          const { currentAssets, ignoredItems } = extractNftsInfo({ nftsArr, waxAccountName });
          let additionalCsAssets = [];
          let additionalIgnored = [];
          console.debug(`CS NFTs in reply: ${nftsArr.length}`);
          if (nftsArr.length === 100) {
            await nap(window.ahCors ? 3000 : 500);
            const { err, res } = await fetchAdditionalPage({ page: 2, waxAccountName }); // eslint-disable-line no-use-before-define
            if (err) return ({ err });
            additionalCsAssets = res.additionalCsAssets;
            additionalIgnored = res.additionalIgnored;
          }
          const csNfts = [...currentAssets, ...additionalCsAssets];
          const hotNfts = groupHotNfts([...ignoredItems, ...additionalIgnored]); //  { home: 2, sweet: 1 }
          window.ahCors = false;
          console.debug(`Unique NFT types in cooldown: ${Object.keys(hotNfts).length}`);
          return ({ csNfts, hotNfts });
        })
        .catch((err) => {
          console.error('>>>>>>>> WAX initial retrieve error:', err);
          window.ahCors = true;
          return ({ err });
        });
  } catch (err) {
    console.error('Fetch ext caught:', err);
    window.ahCors = true;
  }
};

const fetchAdditionalPage = ({ page, waxAccountName }) => {
  console.debug(`Fetching page ${page} for ${waxAccountName}'s assets`);
  try {
    return fetch(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=crypto4shots&owner=${
      waxAccountName
    }&page=${page}&limit=100&order=desc&sort=asset_id`, {
      headers: {
        accept: 'application/json',
        'sec-ch-ua': '"Microsoft Edge";v="93", " Not;A Brand";v="99", "Chromium";v="93"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"Windows"',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'cross-site',
      },
      referrer: 'https://wax.bloks.io/',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: null,
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    })
      .then(res => res.json()).then(async (result) => {
        const nftsArr = result.data;
        let additionalCsAssets = [];
        let additionalIgnored = [];
        if (nftsArr.length === 100) {
          if (page === 51) return ({ err: 'More than 5000 NFTs???' });
          await nap(window.ahCors ? 3000 : 500);
          const { err, res } = await fetchAdditionalPage({ page: page + 1, waxAccountName });
          if (err) return ({ err });
          additionalCsAssets = res.additionalCsAssets;
          additionalIgnored = res.additionalIgnored;
        }
        const { currentAssets, ignoredItems } = extractNftsInfo({ nftsArr, waxAccountName });
        console.debug(`Page ${page} of NFTs:`, currentAssets.length);
        const csNfts = [...currentAssets, ...additionalCsAssets];
        return ({ res: {
          additionalCsAssets: csNfts,
          additionalIgnored: [...ignoredItems, ...additionalIgnored],
        } });
      })
      .catch((err) => {
        console.error(`>>>>>>>> WAX error for page ${page}:`, err);
        window.ahCors = true;
        return ({ err });
      });
  } catch (err) {
    console.error('Fetch ext caught:', err);
    window.ahCors = true;
  }
};


// ALTERNATIVE WAY OF FETCHING NFTS ---------------------------------------------------

const { ExplorerApi } = require('atomicassets'); // NFTs lookup!

// https://test.wax.api.atomicassets.io
const nftsLookupNodes = [
  'https://wax.api.atomicassets.io',
];

let nftsClient;

// https://www.npmjs.com/package/atomicassets
const getNFTsClient = () => {
  if (nftsClient && Math.random() > 0.5) return nftsClient;
  const randId = Math.floor(Math.random() * nftsLookupNodes.length);
  const waxNode = nftsLookupNodes[randId];
  nftsClient = new ExplorerApi(waxNode, 'atomicassets', { fetch });
  return nftsClient;
};
window.getNFTsClient = getNFTsClient; // TEMP

// #### NFTS LOOKUP

const fetchWaxNfts = async ({ waxAddr }) => {
  isLocal && console.log('Loading nft asset_ids for wallet', waxAddr);
  const api = getNFTsClient();
  const waxNfts = await api.getAssets({ owner: waxAddr });
  isLocal && console.log('Fetched::::', JSON.stringify(waxNfts[0], null, 2));
  return ({ waxNfts });
};
