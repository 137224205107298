import { isTestnet } from '../../../constants/envs';


export const PRIZES_FILENAME = 'pve-leaderboard.json';
export const REMOTE_JSON_PATH = `https://${isTestnet ? 'playtest' : 'play'}.crypto-shots.com/json`;

export const LDB_BANNER_PRICE = {
  WEEK: 10,
  MONTH: 30,
  YEAR: 200,
};
